import * as t from "io-ts";

import { NumberFromUnknown } from "@scripts/codecs/numberFromUnknown";

import { makeParams } from "../base";
import type { CalendarViewTypes } from "../investorportal/baseRoutes";
import { qsWithTableParams } from "./tableParams";

export const calendarSortColumnTypesC = t.union([
  t.literal("saleDate-asc"),
  t.literal("saleDate-desc"),
  t.literal("issuerName-asc"),
  t.literal("issuerName-desc"),
  t.literal("offering-asc"),
  t.literal("offering-desc"),
  t.literal("parAmount-asc"),
  t.literal("parAmount-desc"),
  t.literal("sector-asc"),
  t.literal("sector-desc"),
  t.literal("state-asc"),
  t.literal("state-desc"),
]);
type CalendarSortColumnTypesC = typeof calendarSortColumnTypesC;
export type CalendarSortColumnTypes = t.TypeOf<CalendarSortColumnTypesC>;

export const calendarParamsC = t.type({
  sector: NumberFromUnknown,
  state: NumberFromUnknown,
});
type BaseCalendarFiltersC = typeof calendarParamsC;
export type BaseCalendarFilters = t.TypeOf<BaseCalendarFiltersC>;

export const calendarQueryParams = qsWithTableParams(calendarSortColumnTypesC, calendarParamsC);

export type CalendarQueryParamsPartial = typeof calendarQueryParams._A;
export type CalendarQueryParams = Required<CalendarQueryParamsPartial>;

/** @knipignore - used in corp */
export const calendarDefaultPage = 1;
/** @knipignore - used in corp */
export const calendarDefaultSearch = "";
export const calendarDefaultSector = 0;
export const calendarDefaultSortBy: CalendarSortColumnTypes = "saleDate-asc";
export const calendarDefaultState = 0;

export const calendarDefaultFilters = {
  sector: calendarDefaultSector,
  state: calendarDefaultState,
};

export const calendarDefaultParams = {
  ...calendarDefaultFilters,
  page: calendarDefaultPage,
  search: calendarDefaultSearch,
  sortBy: calendarDefaultSortBy,
};

export const makeCalendarQueryParams = makeParams<CalendarQueryParamsPartial>(calendarDefaultParams);

export type FullCalendarFiltersState = BaseCalendarFilters & { view: CalendarViewTypes };
