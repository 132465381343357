import { pipe } from "fp-ts/lib/function";

import { Eq, N, O, s } from "@scripts/fp-ts";
import type { UserPersonaU } from "@scripts/generated/domaintables/userPersonas";
import { allUserPersona, UserPersonaCU } from "@scripts/generated/domaintables/userPersonas";

import { tagLookupC } from "./tagLookup";

export const allUserPersonaLookupC = tagLookupC(UserPersonaCU);

export const unspecifiedPersona = "Unspecified";
export const getUserPersonaName = (personaId: O.Option<number>) => pipe(
  personaId,
  O.chain((id) => O.fromNullable(allUserPersona.find(up => up.id === id))),
  O.fold(() => unspecifiedPersona, up => up.personaName),
);

export const userPersonaEq = Eq.struct<UserPersonaU>({
  _tag: s.Eq,
  id: N.Eq,
  personaName: s.Eq,
});
