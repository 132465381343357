import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const name = {
  _tag: `Name`,
  id: 1,
  name: `Name`
} as const;

export type NameTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Name`>
}>;
export const nameTaggedC: NameTaggedC = t.type({
  _tag: t.literal(`Name`)
});
export type NameTagged = t.TypeOf<NameTaggedC>;
export type Name = NameTagged & typeof name;
export type NameC = t.Type<Name, NameTagged>;
export const nameC: NameC = pipe(nameTaggedC, c => new t.Type<Name, NameTagged>(
  `Name`,
  (u: unknown): u is Name => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Name> => pipe(c.decode(u), E.map(x => ({ ...x, ...name }))),
  (x: Name): NameTagged => ({ ...x, _tag: `Name`}),
)) satisfies t.Type<Name, unknown>;


export const emailAddress = {
  _tag: `EmailAddress`,
  id: 2,
  name: `Email address`
} as const;

export type EmailAddressTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EmailAddress`>
}>;
export const emailAddressTaggedC: EmailAddressTaggedC = t.type({
  _tag: t.literal(`EmailAddress`)
});
export type EmailAddressTagged = t.TypeOf<EmailAddressTaggedC>;
export type EmailAddress = EmailAddressTagged & typeof emailAddress;
export type EmailAddressC = t.Type<EmailAddress, EmailAddressTagged>;
export const emailAddressC: EmailAddressC = pipe(emailAddressTaggedC, c => new t.Type<EmailAddress, EmailAddressTagged>(
  `EmailAddress`,
  (u: unknown): u is EmailAddress => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EmailAddress> => pipe(c.decode(u), E.map(x => ({ ...x, ...emailAddress }))),
  (x: EmailAddress): EmailAddressTagged => ({ ...x, _tag: `EmailAddress`}),
)) satisfies t.Type<EmailAddress, unknown>;


export const phoneNumber = {
  _tag: `PhoneNumber`,
  id: 3,
  name: `Phone number`
} as const;

export type PhoneNumberTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PhoneNumber`>
}>;
export const phoneNumberTaggedC: PhoneNumberTaggedC = t.type({
  _tag: t.literal(`PhoneNumber`)
});
export type PhoneNumberTagged = t.TypeOf<PhoneNumberTaggedC>;
export type PhoneNumber = PhoneNumberTagged & typeof phoneNumber;
export type PhoneNumberC = t.Type<PhoneNumber, PhoneNumberTagged>;
export const phoneNumberC: PhoneNumberC = pipe(phoneNumberTaggedC, c => new t.Type<PhoneNumber, PhoneNumberTagged>(
  `PhoneNumber`,
  (u: unknown): u is PhoneNumber => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PhoneNumber> => pipe(c.decode(u), E.map(x => ({ ...x, ...phoneNumber }))),
  (x: PhoneNumber): PhoneNumberTagged => ({ ...x, _tag: `PhoneNumber`}),
)) satisfies t.Type<PhoneNumber, unknown>;


export const organization = {
  _tag: `Organization`,
  id: 4,
  name: `Organization`
} as const;

export type OrganizationTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Organization`>
}>;
export const organizationTaggedC: OrganizationTaggedC = t.type({
  _tag: t.literal(`Organization`)
});
export type OrganizationTagged = t.TypeOf<OrganizationTaggedC>;
export type Organization = OrganizationTagged & typeof organization;
export type OrganizationC = t.Type<Organization, OrganizationTagged>;
export const organizationC: OrganizationC = pipe(organizationTaggedC, c => new t.Type<Organization, OrganizationTagged>(
  `Organization`,
  (u: unknown): u is Organization => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Organization> => pipe(c.decode(u), E.map(x => ({ ...x, ...organization }))),
  (x: Organization): OrganizationTagged => ({ ...x, _tag: `Organization`}),
)) satisfies t.Type<Organization, unknown>;


export const title = {
  _tag: `Title`,
  id: 5,
  name: `Title`
} as const;

export type TitleTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Title`>
}>;
export const titleTaggedC: TitleTaggedC = t.type({
  _tag: t.literal(`Title`)
});
export type TitleTagged = t.TypeOf<TitleTaggedC>;
export type Title = TitleTagged & typeof title;
export type TitleC = t.Type<Title, TitleTagged>;
export const titleC: TitleC = pipe(titleTaggedC, c => new t.Type<Title, TitleTagged>(
  `Title`,
  (u: unknown): u is Title => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Title> => pipe(c.decode(u), E.map(x => ({ ...x, ...title }))),
  (x: Title): TitleTagged => ({ ...x, _tag: `Title`}),
)) satisfies t.Type<Title, unknown>;


export const userType = {
  _tag: `UserType`,
  id: 6,
  name: `User type`
} as const;

export type UserTypeTaggedC = t.TypeC<{
  _tag: t.LiteralC<`UserType`>
}>;
export const userTypeTaggedC: UserTypeTaggedC = t.type({
  _tag: t.literal(`UserType`)
});
export type UserTypeTagged = t.TypeOf<UserTypeTaggedC>;
export type UserType = UserTypeTagged & typeof userType;
export type UserTypeC = t.Type<UserType, UserTypeTagged>;
export const userTypeC: UserTypeC = pipe(userTypeTaggedC, c => new t.Type<UserType, UserTypeTagged>(
  `UserType`,
  (u: unknown): u is UserType => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UserType> => pipe(c.decode(u), E.map(x => ({ ...x, ...userType }))),
  (x: UserType): UserTypeTagged => ({ ...x, _tag: `UserType`}),
)) satisfies t.Type<UserType, unknown>;


export const institutionalInvestorType = {
  _tag: `InstitutionalInvestorType`,
  id: 7,
  name: `Institutional investor type`
} as const;

export type InstitutionalInvestorTypeTaggedC = t.TypeC<{
  _tag: t.LiteralC<`InstitutionalInvestorType`>
}>;
export const institutionalInvestorTypeTaggedC: InstitutionalInvestorTypeTaggedC = t.type({
  _tag: t.literal(`InstitutionalInvestorType`)
});
export type InstitutionalInvestorTypeTagged = t.TypeOf<InstitutionalInvestorTypeTaggedC>;
export type InstitutionalInvestorType = InstitutionalInvestorTypeTagged & typeof institutionalInvestorType;
export type InstitutionalInvestorTypeC = t.Type<InstitutionalInvestorType, InstitutionalInvestorTypeTagged>;
export const institutionalInvestorTypeC: InstitutionalInvestorTypeC = pipe(institutionalInvestorTypeTaggedC, c => new t.Type<InstitutionalInvestorType, InstitutionalInvestorTypeTagged>(
  `InstitutionalInvestorType`,
  (u: unknown): u is InstitutionalInvestorType => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InstitutionalInvestorType> => pipe(c.decode(u), E.map(x => ({ ...x, ...institutionalInvestorType }))),
  (x: InstitutionalInvestorType): InstitutionalInvestorTypeTagged => ({ ...x, _tag: `InstitutionalInvestorType`}),
)) satisfies t.Type<InstitutionalInvestorType, unknown>;


export const productFocus = {
  _tag: `ProductFocus`,
  id: 8,
  name: `Product focus`
} as const;

export type ProductFocusTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ProductFocus`>
}>;
export const productFocusTaggedC: ProductFocusTaggedC = t.type({
  _tag: t.literal(`ProductFocus`)
});
export type ProductFocusTagged = t.TypeOf<ProductFocusTaggedC>;
export type ProductFocus = ProductFocusTagged & typeof productFocus;
export type ProductFocusC = t.Type<ProductFocus, ProductFocusTagged>;
export const productFocusC: ProductFocusC = pipe(productFocusTaggedC, c => new t.Type<ProductFocus, ProductFocusTagged>(
  `ProductFocus`,
  (u: unknown): u is ProductFocus => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ProductFocus> => pipe(c.decode(u), E.map(x => ({ ...x, ...productFocus }))),
  (x: ProductFocus): ProductFocusTagged => ({ ...x, _tag: `ProductFocus`}),
)) satisfies t.Type<ProductFocus, unknown>;


export const sectorFocus = {
  _tag: `SectorFocus`,
  id: 9,
  name: `Sector focus`
} as const;

export type SectorFocusTaggedC = t.TypeC<{
  _tag: t.LiteralC<`SectorFocus`>
}>;
export const sectorFocusTaggedC: SectorFocusTaggedC = t.type({
  _tag: t.literal(`SectorFocus`)
});
export type SectorFocusTagged = t.TypeOf<SectorFocusTaggedC>;
export type SectorFocus = SectorFocusTagged & typeof sectorFocus;
export type SectorFocusC = t.Type<SectorFocus, SectorFocusTagged>;
export const sectorFocusC: SectorFocusC = pipe(sectorFocusTaggedC, c => new t.Type<SectorFocus, SectorFocusTagged>(
  `SectorFocus`,
  (u: unknown): u is SectorFocus => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SectorFocus> => pipe(c.decode(u), E.map(x => ({ ...x, ...sectorFocus }))),
  (x: SectorFocus): SectorFocusTagged => ({ ...x, _tag: `SectorFocus`}),
)) satisfies t.Type<SectorFocus, unknown>;


export const allActivityReportProfileDataPointC = [nameC, emailAddressC, phoneNumberC, organizationC, titleC, userTypeC, institutionalInvestorTypeC, productFocusC, sectorFocusC] as const;
export const allActivityReportProfileDataPointNames = [`Name`, `EmailAddress`, `PhoneNumber`, `Organization`, `Title`, `UserType`, `InstitutionalInvestorType`, `ProductFocus`, `SectorFocus`] as const;
export type ActivityReportProfileDataPointName = (typeof allActivityReportProfileDataPointNames)[number];

export type ActivityReportProfileDataPointCU = t.UnionC<[NameC, EmailAddressC, PhoneNumberC, OrganizationC, TitleC, UserTypeC, InstitutionalInvestorTypeC, ProductFocusC, SectorFocusC]>;
export type ActivityReportProfileDataPointU = Name | EmailAddress | PhoneNumber | Organization | Title | UserType | InstitutionalInvestorType | ProductFocus | SectorFocus;
export const ActivityReportProfileDataPointCU: ActivityReportProfileDataPointCU = t.union([nameC, emailAddressC, phoneNumberC, organizationC, titleC, userTypeC, institutionalInvestorTypeC, productFocusC, sectorFocusC]) satisfies t.Type<ActivityReportProfileDataPointU, unknown>;

export const activityReportProfileDataPointOrd: Ord.Ord<ActivityReportProfileDataPointU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allActivityReportProfileDataPoint = [name, emailAddress, phoneNumber, organization, title, userType, institutionalInvestorType, productFocus, sectorFocus] as const;
export type ActivityReportProfileDataPointMap<A> = { [K in ActivityReportProfileDataPointName]: A };


export const engagementScore = {
  _tag: `EngagementScore`,
  id: 10,
  name: `Engagement score`
} as const;

export type EngagementScoreTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EngagementScore`>
}>;
export const engagementScoreTaggedC: EngagementScoreTaggedC = t.type({
  _tag: t.literal(`EngagementScore`)
});
export type EngagementScoreTagged = t.TypeOf<EngagementScoreTaggedC>;
export type EngagementScore = EngagementScoreTagged & typeof engagementScore;
export type EngagementScoreC = t.Type<EngagementScore, EngagementScoreTagged>;
export const engagementScoreC: EngagementScoreC = pipe(engagementScoreTaggedC, c => new t.Type<EngagementScore, EngagementScoreTagged>(
  `EngagementScore`,
  (u: unknown): u is EngagementScore => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EngagementScore> => pipe(c.decode(u), E.map(x => ({ ...x, ...engagementScore }))),
  (x: EngagementScore): EngagementScoreTagged => ({ ...x, _tag: `EngagementScore`}),
)) satisfies t.Type<EngagementScore, unknown>;


export const lastActivityDate = {
  _tag: `LastActivityDate`,
  id: 11,
  name: `Date of last activity`
} as const;

export type LastActivityDateTaggedC = t.TypeC<{
  _tag: t.LiteralC<`LastActivityDate`>
}>;
export const lastActivityDateTaggedC: LastActivityDateTaggedC = t.type({
  _tag: t.literal(`LastActivityDate`)
});
export type LastActivityDateTagged = t.TypeOf<LastActivityDateTaggedC>;
export type LastActivityDate = LastActivityDateTagged & typeof lastActivityDate;
export type LastActivityDateC = t.Type<LastActivityDate, LastActivityDateTagged>;
export const lastActivityDateC: LastActivityDateC = pipe(lastActivityDateTaggedC, c => new t.Type<LastActivityDate, LastActivityDateTagged>(
  `LastActivityDate`,
  (u: unknown): u is LastActivityDate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, LastActivityDate> => pipe(c.decode(u), E.map(x => ({ ...x, ...lastActivityDate }))),
  (x: LastActivityDate): LastActivityDateTagged => ({ ...x, _tag: `LastActivityDate`}),
)) satisfies t.Type<LastActivityDate, unknown>;


export const offeringViews = {
  _tag: `OfferingViews`,
  id: 12,
  name: `Bond offering views`
} as const;

export type OfferingViewsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`OfferingViews`>
}>;
export const offeringViewsTaggedC: OfferingViewsTaggedC = t.type({
  _tag: t.literal(`OfferingViews`)
});
export type OfferingViewsTagged = t.TypeOf<OfferingViewsTaggedC>;
export type OfferingViews = OfferingViewsTagged & typeof offeringViews;
export type OfferingViewsC = t.Type<OfferingViews, OfferingViewsTagged>;
export const offeringViewsC: OfferingViewsC = pipe(offeringViewsTaggedC, c => new t.Type<OfferingViews, OfferingViewsTagged>(
  `OfferingViews`,
  (u: unknown): u is OfferingViews => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, OfferingViews> => pipe(c.decode(u), E.map(x => ({ ...x, ...offeringViews }))),
  (x: OfferingViews): OfferingViewsTagged => ({ ...x, _tag: `OfferingViews`}),
)) satisfies t.Type<OfferingViews, unknown>;


export const roadshowViews = {
  _tag: `RoadshowViews`,
  id: 13,
  name: `Roadshow views`
} as const;

export type RoadshowViewsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RoadshowViews`>
}>;
export const roadshowViewsTaggedC: RoadshowViewsTaggedC = t.type({
  _tag: t.literal(`RoadshowViews`)
});
export type RoadshowViewsTagged = t.TypeOf<RoadshowViewsTaggedC>;
export type RoadshowViews = RoadshowViewsTagged & typeof roadshowViews;
export type RoadshowViewsC = t.Type<RoadshowViews, RoadshowViewsTagged>;
export const roadshowViewsC: RoadshowViewsC = pipe(roadshowViewsTaggedC, c => new t.Type<RoadshowViews, RoadshowViewsTagged>(
  `RoadshowViews`,
  (u: unknown): u is RoadshowViews => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RoadshowViews> => pipe(c.decode(u), E.map(x => ({ ...x, ...roadshowViews }))),
  (x: RoadshowViews): RoadshowViewsTagged => ({ ...x, _tag: `RoadshowViews`}),
)) satisfies t.Type<RoadshowViews, unknown>;


export const documentDownloads = {
  _tag: `DocumentDownloads`,
  id: 14,
  name: `Document downloads`
} as const;

export type DocumentDownloadsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`DocumentDownloads`>
}>;
export const documentDownloadsTaggedC: DocumentDownloadsTaggedC = t.type({
  _tag: t.literal(`DocumentDownloads`)
});
export type DocumentDownloadsTagged = t.TypeOf<DocumentDownloadsTaggedC>;
export type DocumentDownloads = DocumentDownloadsTagged & typeof documentDownloads;
export type DocumentDownloadsC = t.Type<DocumentDownloads, DocumentDownloadsTagged>;
export const documentDownloadsC: DocumentDownloadsC = pipe(documentDownloadsTaggedC, c => new t.Type<DocumentDownloads, DocumentDownloadsTagged>(
  `DocumentDownloads`,
  (u: unknown): u is DocumentDownloads => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DocumentDownloads> => pipe(c.decode(u), E.map(x => ({ ...x, ...documentDownloads }))),
  (x: DocumentDownloads): DocumentDownloadsTagged => ({ ...x, _tag: `DocumentDownloads`}),
)) satisfies t.Type<DocumentDownloads, unknown>;


export const infoRequests = {
  _tag: `InfoRequests`,
  id: 15,
  name: `Information requests`
} as const;

export type InfoRequestsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`InfoRequests`>
}>;
export const infoRequestsTaggedC: InfoRequestsTaggedC = t.type({
  _tag: t.literal(`InfoRequests`)
});
export type InfoRequestsTagged = t.TypeOf<InfoRequestsTaggedC>;
export type InfoRequests = InfoRequestsTagged & typeof infoRequests;
export type InfoRequestsC = t.Type<InfoRequests, InfoRequestsTagged>;
export const infoRequestsC: InfoRequestsC = pipe(infoRequestsTaggedC, c => new t.Type<InfoRequests, InfoRequestsTagged>(
  `InfoRequests`,
  (u: unknown): u is InfoRequests => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InfoRequests> => pipe(c.decode(u), E.map(x => ({ ...x, ...infoRequests }))),
  (x: InfoRequests): InfoRequestsTagged => ({ ...x, _tag: `InfoRequests`}),
)) satisfies t.Type<InfoRequests, unknown>;


export const emailsReceived = {
  _tag: `EmailsReceived`,
  id: 16,
  name: `Emails received`
} as const;

export type EmailsReceivedTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EmailsReceived`>
}>;
export const emailsReceivedTaggedC: EmailsReceivedTaggedC = t.type({
  _tag: t.literal(`EmailsReceived`)
});
export type EmailsReceivedTagged = t.TypeOf<EmailsReceivedTaggedC>;
export type EmailsReceived = EmailsReceivedTagged & typeof emailsReceived;
export type EmailsReceivedC = t.Type<EmailsReceived, EmailsReceivedTagged>;
export const emailsReceivedC: EmailsReceivedC = pipe(emailsReceivedTaggedC, c => new t.Type<EmailsReceived, EmailsReceivedTagged>(
  `EmailsReceived`,
  (u: unknown): u is EmailsReceived => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EmailsReceived> => pipe(c.decode(u), E.map(x => ({ ...x, ...emailsReceived }))),
  (x: EmailsReceived): EmailsReceivedTagged => ({ ...x, _tag: `EmailsReceived`}),
)) satisfies t.Type<EmailsReceived, unknown>;


export const emailsOpened = {
  _tag: `EmailsOpened`,
  id: 17,
  name: `Emails opened`
} as const;

export type EmailsOpenedTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EmailsOpened`>
}>;
export const emailsOpenedTaggedC: EmailsOpenedTaggedC = t.type({
  _tag: t.literal(`EmailsOpened`)
});
export type EmailsOpenedTagged = t.TypeOf<EmailsOpenedTaggedC>;
export type EmailsOpened = EmailsOpenedTagged & typeof emailsOpened;
export type EmailsOpenedC = t.Type<EmailsOpened, EmailsOpenedTagged>;
export const emailsOpenedC: EmailsOpenedC = pipe(emailsOpenedTaggedC, c => new t.Type<EmailsOpened, EmailsOpenedTagged>(
  `EmailsOpened`,
  (u: unknown): u is EmailsOpened => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EmailsOpened> => pipe(c.decode(u), E.map(x => ({ ...x, ...emailsOpened }))),
  (x: EmailsOpened): EmailsOpenedTagged => ({ ...x, _tag: `EmailsOpened`}),
)) satisfies t.Type<EmailsOpened, unknown>;


export const emailsClicked = {
  _tag: `EmailsClicked`,
  id: 18,
  name: `Emails clicked`
} as const;

export type EmailsClickedTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EmailsClicked`>
}>;
export const emailsClickedTaggedC: EmailsClickedTaggedC = t.type({
  _tag: t.literal(`EmailsClicked`)
});
export type EmailsClickedTagged = t.TypeOf<EmailsClickedTaggedC>;
export type EmailsClicked = EmailsClickedTagged & typeof emailsClicked;
export type EmailsClickedC = t.Type<EmailsClicked, EmailsClickedTagged>;
export const emailsClickedC: EmailsClickedC = pipe(emailsClickedTaggedC, c => new t.Type<EmailsClicked, EmailsClickedTagged>(
  `EmailsClicked`,
  (u: unknown): u is EmailsClicked => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EmailsClicked> => pipe(c.decode(u), E.map(x => ({ ...x, ...emailsClicked }))),
  (x: EmailsClicked): EmailsClickedTagged => ({ ...x, _tag: `EmailsClicked`}),
)) satisfies t.Type<EmailsClicked, unknown>;


export const allActivityReportActivityDataPointC = [engagementScoreC, lastActivityDateC, offeringViewsC, roadshowViewsC, documentDownloadsC, infoRequestsC, emailsReceivedC, emailsOpenedC, emailsClickedC] as const;
export const allActivityReportActivityDataPointNames = [`EngagementScore`, `LastActivityDate`, `OfferingViews`, `RoadshowViews`, `DocumentDownloads`, `InfoRequests`, `EmailsReceived`, `EmailsOpened`, `EmailsClicked`] as const;
export type ActivityReportActivityDataPointName = (typeof allActivityReportActivityDataPointNames)[number];

export type ActivityReportActivityDataPointCU = t.UnionC<[EngagementScoreC, LastActivityDateC, OfferingViewsC, RoadshowViewsC, DocumentDownloadsC, InfoRequestsC, EmailsReceivedC, EmailsOpenedC, EmailsClickedC]>;
export type ActivityReportActivityDataPointU = EngagementScore | LastActivityDate | OfferingViews | RoadshowViews | DocumentDownloads | InfoRequests | EmailsReceived | EmailsOpened | EmailsClicked;
export const ActivityReportActivityDataPointCU: ActivityReportActivityDataPointCU = t.union([engagementScoreC, lastActivityDateC, offeringViewsC, roadshowViewsC, documentDownloadsC, infoRequestsC, emailsReceivedC, emailsOpenedC, emailsClickedC]) satisfies t.Type<ActivityReportActivityDataPointU, unknown>;

export const activityReportActivityDataPointOrd: Ord.Ord<ActivityReportActivityDataPointU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allActivityReportActivityDataPoint = [engagementScore, lastActivityDate, offeringViews, roadshowViews, documentDownloads, infoRequests, emailsReceived, emailsOpened, emailsClicked] as const;
export type ActivityReportActivityDataPointMap<A> = { [K in ActivityReportActivityDataPointName]: A };


export const allActivityReportDataPointC = [nameC, emailAddressC, phoneNumberC, organizationC, titleC, userTypeC, institutionalInvestorTypeC, productFocusC, sectorFocusC, engagementScoreC, lastActivityDateC, offeringViewsC, roadshowViewsC, documentDownloadsC, infoRequestsC, emailsReceivedC, emailsOpenedC, emailsClickedC] as const;
export const allActivityReportDataPointNames = [`Name`, `EmailAddress`, `PhoneNumber`, `Organization`, `Title`, `UserType`, `InstitutionalInvestorType`, `ProductFocus`, `SectorFocus`, `EngagementScore`, `LastActivityDate`, `OfferingViews`, `RoadshowViews`, `DocumentDownloads`, `InfoRequests`, `EmailsReceived`, `EmailsOpened`, `EmailsClicked`] as const;
export type ActivityReportDataPointName = (typeof allActivityReportDataPointNames)[number];

export type ActivityReportDataPointCU = t.UnionC<[NameC, EmailAddressC, PhoneNumberC, OrganizationC, TitleC, UserTypeC, InstitutionalInvestorTypeC, ProductFocusC, SectorFocusC, EngagementScoreC, LastActivityDateC, OfferingViewsC, RoadshowViewsC, DocumentDownloadsC, InfoRequestsC, EmailsReceivedC, EmailsOpenedC, EmailsClickedC]>;
export type ActivityReportDataPointU = Name | EmailAddress | PhoneNumber | Organization | Title | UserType | InstitutionalInvestorType | ProductFocus | SectorFocus | EngagementScore | LastActivityDate | OfferingViews | RoadshowViews | DocumentDownloads | InfoRequests | EmailsReceived | EmailsOpened | EmailsClicked;
export const ActivityReportDataPointCU: ActivityReportDataPointCU = t.union([nameC, emailAddressC, phoneNumberC, organizationC, titleC, userTypeC, institutionalInvestorTypeC, productFocusC, sectorFocusC, engagementScoreC, lastActivityDateC, offeringViewsC, roadshowViewsC, documentDownloadsC, infoRequestsC, emailsReceivedC, emailsOpenedC, emailsClickedC]) satisfies t.Type<ActivityReportDataPointU, unknown>;

export const activityReportDataPointOrd: Ord.Ord<ActivityReportDataPointU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allActivityReportDataPoint = [name, emailAddress, phoneNumber, organization, title, userType, institutionalInvestorType, productFocus, sectorFocus, engagementScore, lastActivityDate, offeringViews, roadshowViews, documentDownloads, infoRequests, emailsReceived, emailsOpened, emailsClicked] as const;
export type ActivityReportDataPointMap<A> = { [K in ActivityReportDataPointName]: A };


