import { O } from "./fp-ts";
import type { BLConfig } from "./generated/models/blConfig";
import type { exhaustive } from "./util/exhaustive";

type LogLevels = "debug" | "info" | "warn" | "error" | "fatal";
type LogFunction = (msg: string, ...args: unknown[]) => void;

export type Logger = { [key in LogLevels]: LogFunction };

export type BLConfigWithLog = BLConfig & {
  exhaustive: ReturnType<typeof exhaustive>;
  log: Logger;
};

export type BLWindow = Window & { readonly BLConfig: BLConfig };

export const formatS3CdnUrl = (config: BLConfigWithLog) => (url: string): string => {
  return url.startsWith("https:") ? url : `${config.s3.cdn}/${url}`;
};

export const isProd = (config: BLConfig): boolean => config.environment._tag === "PRODUCTION";

export const isDev = (config: BLConfig): boolean => config.environment._tag === "DEV";

const stripProtocol = (s: string) => s.startsWith("http") ? s.replace(/https?:\/\//, "") : s;

export const isCDN = (config: BLConfig) => (url: string) => {
  const cdn = stripProtocol(config.s3.cdn);
  const _url = stripProtocol(url);
  return _url.includes(cdn);
};

export const eventName = (namespace: string, event: string): string => `{bl:${namespace}:${event};`;

export const ConfigO = () =>
  O.flatten(
    O.tryCatch(
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      () => O.fromNullable((globalThis as unknown as BLWindow).BLConfig),
    )
  );
