import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const nav = {
  _tag: `Nav`,
  id: 1,
  name: `Navigation Menu`
} as const;

export type NavTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Nav`>
}>;
export const navTaggedC: NavTaggedC = t.type({
  _tag: t.literal(`Nav`)
});
export type NavTagged = t.TypeOf<NavTaggedC>;
export type Nav = NavTagged & typeof nav;
export type NavC = t.Type<Nav, NavTagged>;
export const navC: NavC = pipe(navTaggedC, c => new t.Type<Nav, NavTagged>(
  `Nav`,
  (u: unknown): u is Nav => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Nav> => pipe(c.decode(u), E.map(x => ({ ...x, ...nav }))),
  (x: Nav): NavTagged => ({ ...x, _tag: `Nav`}),
)) satisfies t.Type<Nav, unknown>;


export const footer = {
  _tag: `Footer`,
  id: 2,
  name: `Footer`
} as const;

export type FooterTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Footer`>
}>;
export const footerTaggedC: FooterTaggedC = t.type({
  _tag: t.literal(`Footer`)
});
export type FooterTagged = t.TypeOf<FooterTaggedC>;
export type Footer = FooterTagged & typeof footer;
export type FooterC = t.Type<Footer, FooterTagged>;
export const footerC: FooterC = pipe(footerTaggedC, c => new t.Type<Footer, FooterTagged>(
  `Footer`,
  (u: unknown): u is Footer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Footer> => pipe(c.decode(u), E.map(x => ({ ...x, ...footer }))),
  (x: Footer): FooterTagged => ({ ...x, _tag: `Footer`}),
)) satisfies t.Type<Footer, unknown>;


export const allHrefLocationC = [navC, footerC] as const;
export const allHrefLocationNames = [`Nav`, `Footer`] as const;
export type HrefLocationName = (typeof allHrefLocationNames)[number];

export type HrefLocationCU = t.UnionC<[NavC, FooterC]>;
export type HrefLocationU = Nav | Footer;
export const HrefLocationCU: HrefLocationCU = t.union([navC, footerC]) satisfies t.Type<HrefLocationU, unknown>;

export const hrefLocationOrd: Ord.Ord<HrefLocationU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allHrefLocation = [nav, footer] as const;
export type HrefLocationMap<A> = { [K in HrefLocationName]: A };


