import * as t from "io-ts";

export const bondLinkClientId = {
  _tag: `BondLinkClientId`,
  id: 1
} as const;

export type BondLinkClientIdC = t.TypeC<{
  _tag: t.LiteralC<`BondLinkClientId`>,
  id: t.LiteralC<1>
}>;
export type BondLinkClientId = {
  _tag: `BondLinkClientId`,
  id: 1
};
export const bondLinkClientIdC: BondLinkClientIdC = t.type({
  _tag: t.literal(`BondLinkClientId`),
  id: t.literal(1)
}) satisfies t.Type<BondLinkClientId, unknown>;


