import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import { ReadonlySetFromArrayC, readonlySetFromArray } from "io-ts-types/lib/readonlySetFromArray";
import * as Ord from "fp-ts/lib/Ord";
import { esgProgram as imported8_esgProgram } from "./featureFlags";

export const ESG = {
  _tag: `ESG`,
  enablingFlag: imported8_esgProgram,
  id: 1
} as const;

export type ESGTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ESG`>
}>;
export const ESGTaggedC: ESGTaggedC = t.type({
  _tag: t.literal(`ESG`)
});
export type ESGTagged = t.TypeOf<ESGTaggedC>;
export type ESG = ESGTagged & typeof ESG;
export type ESGC = t.Type<ESG, ESGTagged>;
export const ESGC: ESGC = pipe(ESGTaggedC, c => new t.Type<ESG, ESGTagged>(
  `ESG`,
  (u: unknown): u is ESG => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ESG> => pipe(c.decode(u), E.map(x => ({ ...x, ...ESG }))),
  (x: ESG): ESGTagged => ({ ...x, _tag: `ESG`}),
)) satisfies t.Type<ESG, unknown>;


export const allContentTagTypeC = [ESGC] as const;
export const allContentTagTypeNames = [`ESG`] as const;
export type ContentTagTypeName = (typeof allContentTagTypeNames)[number];

export type ContentTagTypeCU = ESGC;
export type ContentTagTypeU = ESG;
export const ContentTagTypeCU: ContentTagTypeCU = ESGC satisfies t.Type<ContentTagTypeU, unknown>;

export const contentTagTypeOrd: Ord.Ord<ContentTagTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allContentTagType = [ESG] as const;
export type ContentTagTypeMap<A> = { [K in ContentTagTypeName]: A };


export type ContentTagsC = ReadonlySetFromArrayC<ContentTagTypeCU>;
export type ContentTags = ReadonlySet<ContentTagTypeU>;
export const contentTagsC: ContentTagsC = readonlySetFromArray(ContentTagTypeCU, contentTagTypeOrd) satisfies t.Type<ContentTags, unknown>;


