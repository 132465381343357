import type { JSX, PropsWithChildren, ReactElement, ReactNode } from "react";
import { Fragment, useCallback, useState } from "react";
import type { Options } from "@popperjs/core";
import type { TippyProps } from "@tippyjs/react";
import Tippy from "@tippyjs/react";
import type { Lazy } from "fp-ts/lib/function";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as RNA from "fp-ts/lib/ReadonlyNonEmptyArray";
import type { Instance, Placement, Plugin, Props as TippyPropsJS } from "tippy.js";
import { Key } from "ts-key-enum";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { Static } from "@scripts/bondlinkStatic";
import type { Merge } from "@scripts/fp-ts/lib/types";
import { Empty, mapOrEmpty } from "@scripts/react/components/Empty";
import { isOffsetOutsideViewport } from "@scripts/syntax/popper";
import { truncate } from "@scripts/util/truncate";

import infoIcon from "@svgs/information.svg";

import { useConfig } from "../context/Config";
import type { Klass, KlassProp } from "../util/classnames";
import { klass, klassPropO } from "../util/classnames";
import type { Target } from "./Anchor";
import { AnchorUnsafe } from "./Anchor";
import { Svg } from "./Svg";

export const defaultDuration = [300, 250] satisfies [number, number];

type TooltipLink = {
  href: string;
  target: Target;
};

type TooltipListItemLink = {
  link: O.Option<TooltipLink>;
  text: string;
};

interface TooltipListItemComponent {
  component: O.Option<ReactNode>;
  text: string;
}

export type TooltipListItem = TooltipListItemLink | TooltipListItemComponent;

export interface HeaderBarNoButton {
  type: "HeaderBarNoButton";
  title: string | ReactElement;
}

interface HeaderBarButtonEnabled {
  type: "HeaderBarButtonEnabled";
  title: string | ReactElement;
  buttonText: string;
  buttonAction: () => void;
}

interface HeaderBarButtonDisabled {
  type: "HeaderBarButtonDisabled";
  title: string | ReactElement;
  buttonText: string;
}

export type HeaderBar = HeaderBarNoButton | HeaderBarButtonEnabled | HeaderBarButtonDisabled;

interface DescriptionContent {
  type: "DescriptionContent";
  text: string;
}

interface DescriptionContentItalicized {
  type: "DescriptionContentItalicized";
  text: string;
}

interface DescriptionContentElement {
  type: "DescriptionContentElement";
  element: ReactElement;
}

export type Description = DescriptionContent | DescriptionContentItalicized | DescriptionContentElement;

type TooltipControlDefault = {
  type: "TooltipControlDefault";
  hideOnClick?: TippyPropsJS["hideOnClick"] | null;
  trigger?: TippyPropsJS["trigger"] | null;
};

type TooltipControlUser = {
  type: "TooltipControlUser";
  visible: boolean;
};

type TooltipControlOptions = TooltipControlDefault | TooltipControlUser;

export type TooltipDelay = "default" | "table" | [number, number];

export const tooltipDelayDefault: [number, number] = [0, 400];
const tooltipDelayTable: [number, number] = [250, 0]; // Prevent multiple tooltips from rendering when cursor happens to line up with a table icon on scroll.
export const tooltipDelayTransition: [number, number] = [Static.baseTransitionDelay, 0]; // Accomodates css transitions

export type TooltipPropsBase = {
  /** Appends a class string to the element/component wrapped by a Tooltip component. */
  addClassToTargetNode?: Klass | null;
  /** Appends a KlassProp to the Tooltip popover element. */
  addKlassToTooltip?: KlassProp | null;
  appendTo?: TippyPropsJS["appendTo"] | null;
  controlOptions?: TooltipControlOptions | undefined;
  description?: Description;
  headerBar?: HeaderBar;
  listItems?: ReadonlyArray<TooltipListItem>;
  muted?: boolean;
  placement?: TippyPropsJS["placement"] | null;
  popperOptions?: Partial<Options> | null;
  disableTabIndex?: boolean;
  offset?: [number, number];
  interactiveBorder?: number;
};

export type TooltipProps = TooltipPropsBase & {
  delay: TooltipDelay;
};

export const header = (config: BLConfigWithLog) => (props: TooltipPropsBase): ReactNode => {
  if (props.headerBar == null) {
    return (<Empty />);
  }

  switch (props.headerBar.type) {
    case "HeaderBarNoButton":
      return (
        <div className="bl-tooltip-header">
          <h5 className={`title ${props.muted ?? false ? "muted" : ""}`}>
            {props.headerBar.title}
          </h5>
        </div>
      );
    case "HeaderBarButtonEnabled":
      return (
        <div className="bl-tooltip-header">
          <h5 className={`title ${props.muted ?? false ? "muted" : ""}`}>
            {props.headerBar.title}
          </h5>

          <button tabIndex={0}
            type="button"
            className="btn-link tooltip-action"
            onClick={props.headerBar.buttonAction}
          >
            {props.headerBar.buttonText}
          </button>
        </div>
      );
    case "HeaderBarButtonDisabled":
      return (
        <div className="bl-tooltip-header">
          <h5 className={`title ${props.muted ?? false ? "muted" : ""}`}>
            {props.headerBar.title}
          </h5>

          <button
            tabIndex={0}
            type="button"
            className="btn-link tooltip-action"
            disabled
          >
            {props.headerBar.buttonText}
          </button>
        </div>
      );
  }
  return config.exhaustive(props.headerBar);
};

export const description = (config: BLConfigWithLog) => (props: TooltipPropsBase): JSX.Element => {
  if (props.description == null || ("text" in props.description && props.description.text === ""))
    return <Empty />;

  switch (props.description.type) {
    case "DescriptionContent":
      return (
        <p className={`bl-tooltip-content ${props.muted ?? false ? "gray-600" : ""}`}>
          {truncate(props.description.text, 400)}
        </p>
      );
    case "DescriptionContentItalicized":
      return (
        <p className={`bl-tooltip-content emphasized ${props.muted ?? false ? "gray-600" : ""}`}>
          {truncate(props.description.text, 400)}
        </p>
      );
    case "DescriptionContentElement":
      return (
        <div className={`bl-tooltip-content ${props.muted ?? false ? "gray-600" : ""}`}>
          {props.description.element}
        </div>
      );
  }
  return config.exhaustive(props.description);
};

const isLinkItem = (listItem: TooltipListItem): listItem is TooltipListItemLink => "link" in listItem;

const listItems = (props: TooltipProps): JSX.Element => pipe(
  O.fromNullable(props.listItems),
  O.chain(RNA.fromReadonlyArray),
  mapOrEmpty(items => (
    <ul className="bl-tooltip-list-content">
      {items.map((listItem, i: number) => {
        if (isLinkItem(listItem)) {
          return pipe(
            listItem.link,
            O.fold(
              () =>
                <li className="tooltip-list-item" key={listItem.text + i.toString()} tabIndex={0}>{listItem.text}</li>,
              (link: TooltipLink) =>
                <li className="tooltip-list-item" key={link.href + i.toString()}>
                  <AnchorUnsafe
                    externalLinkLocation="none"
                    href={link.href}
                    klasses={"no-decoration"}
                    target={link.target}
                    title={listItem.text}
                  />
                </li>
            )
          );
        } else {
          return pipe(
            listItem.component,
            O.fold(
              () =>
                <li className="tooltip-list-item" key={listItem.text + i.toString()} tabIndex={0}>{listItem.text}</li>,
              (component: ReactNode) =>
                component && <li className="tooltip-list-item" key={listItem.text + i.toString()}>
                  {component}
                </li>
            )
          );
        }
      })}
    </ul>
  ))
);

const tooltipDisplay = (config: BLConfigWithLog) => (props: TooltipProps): JSX.Element =>
  <div
    {...klassPropO("bl-tooltip-content-container")(props.addKlassToTooltip || "")}
    tabIndex={0}
  >
    {header(config)(props)}
    {description(config)(props)}
    {listItems(props)}
  </div>;

type TippyLifecycleEvent = (instance: Instance<TippyPropsJS>) => void;
type TippyLifecycleEvents = { onHide?: TippyLifecycleEvent, onShow?: TippyLifecycleEvent };

interface ReferenceElement<TProps = TippyPropsJS> extends HTMLDivElement {
  _tippy?: Instance<TProps>;
}

type AppendTo = TippyPropsJS["appendTo"];
type LazyTippyProps = Merge<TippyProps, {
  content: Lazy<ReactNode>;
  appendTo: AppendTo;
}>;

export const LazyTippy = (props: LazyTippyProps & TippyLifecycleEvents): JSX.Element => {
  const [mounted, setMounted] = useState(false);
  const lazyPlugin: Plugin = {
    fn: () => ({
      onMount: () => setMounted(true),
      onHidden: () => setMounted(false),
    }),
  };
  // refactored from Source: https://atomiks.github.io/tippyjs/v6/plugins/#hideonpopperblur
  const hideOnPopperBlur: Plugin = {
    name: "hideOnPopperBlur",
    defaultValue: true,
    fn: (instance: Instance<TippyPropsJS>) => ({
      onCreate: () => instance.popper.addEventListener("focusout", (event) =>
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        event.relatedTarget && !instance.popper.contains(event.relatedTarget as Node) && instance.hide()),
    }),
  };

  const escapeTriggerBlur: Plugin = {
    name: "escapeTriggerBlur",
    defaultValue: true,
    fn: (instance: Instance<TippyPropsJS>) => ({
      onCreate: () => instance.popper.addEventListener("keyup", ev => ev.key === Key.Escape && instance.hide()),
    }),
  };

  const isOneOf = (ev: KeyboardEvent) => (keys: Array<Key | string>): boolean => keys.some(key => key === ev.key);

  const keyboardToggle: Plugin = {
    name: "onKeyUp",
    defaultValue: true,
    fn: (instance: Instance<TippyPropsJS>) => ({
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      onCreate: () => (instance.reference as ReferenceElement).addEventListener(
        "keyup", ev => isOneOf(ev)([Key.Enter, Key.Escape, " "]) && instance.state.isShown ? instance.hide : instance.show
      ),
    }),
  };

  const preventSpacebarScroll: Plugin = {
    name: "keyDownSpace",
    defaultValue: true,
    fn: (instance: Instance<TippyPropsJS>) => ({
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      onCreate: () => (instance.reference as ReferenceElement).addEventListener(
        "keydown",
        ev => {
          if (ev.key === " " && !(ev.target instanceof HTMLInputElement)) {
            ev.preventDefault();
          }
        }
      ),
    }),
  };

  const hideOutsideViewport: Plugin = {
    name: "hideOutsideViewport",
    defaultValue: true,
    fn: (instance) => ({
      onCreate: () => globalThis.addEventListener("scroll", () => {
        if (instance.popperInstance?.state && isOffsetOutsideViewport(instance.popperInstance.state)) {
          instance.hide();
        }
      }),
    }),
  };

  const computedProps: TippyProps = {
    ...props,
    content: mounted ? props.content() : <Empty />,
    duration: defaultDuration,
    plugins: [lazyPlugin, hideOnPopperBlur, keyboardToggle, escapeTriggerBlur, preventSpacebarScroll, hideOutsideViewport],
  };
  return <Tippy {...computedProps} />;
};

const defaultControlOptions: Pick<TippyProps, "hideOnClick" | "trigger"> = {
  hideOnClick: "toggle",
  trigger: "mouseenter focus",
};

const buildControllableProps = (config: BLConfigWithLog) =>
  (options: TooltipControlOptions): Pick<TippyProps, "visible" | "hideOnClick" | "trigger"> => {
    switch (options.type) {
      case "TooltipControlUser": return { visible: options.visible };
      case "TooltipControlDefault": return {
        hideOnClick: pipe(O.fromNullable(options.hideOnClick), O.getOrElse((): TippyPropsJS["hideOnClick"] => "toggle")),
        trigger: options.trigger ?? defaultControlOptions.trigger,
      };
    }
    return config.exhaustive(options);
  };

export const parseDelay = (delay: TooltipDelay) =>
  delay === "default"
    ? tooltipDelayDefault
    : delay === "table"
      ? tooltipDelayTable
      : delay;

export const Tooltip = (props: PropsWithChildren<TooltipProps & TippyLifecycleEvents>): JSX.Element => {
  const config = useConfig();

  // All tippy props are here https://atomiks.github.io/tippyjs/all-props/
  // Need the span below, read here https://github.com/atomiks/tippy.js-react#component-children
  return <LazyTippy
    appendTo={O.getOrElse((): AppendTo => "parent")(O.fromNullable(props.appendTo))}
    content={useCallback(() => tooltipDisplay(config)(props), [config, props])}
    className={klass("bl-tooltip").className}
    delay={parseDelay(props.delay)}
    inertia={true}
    interactive={true}
    maxWidth="18rem"
    offset={props.offset || [0, 12]}
    role="tooltip"
    onHide={props.onHide}
    onShow={props.onShow}
    onClickOutside={(instance: Instance) => instance.hide()}
    theme="light"
    placement={O.getOrElse((): Placement => "top")(O.fromNullable(props.placement))}
    popperOptions={O.getOrElse(() => ({}))(O.fromNullable(props.popperOptions))}
    {...(props.controlOptions ? buildControllableProps(config)(props.controlOptions) : defaultControlOptions)}
    interactiveBorder={props.interactiveBorder}
  >
    <span
      {...klass("tooltip-trigger", props.addClassToTargetNode ?? "")}
      tabIndex={props.disableTabIndex ? -1 : 0}
    >
      {props.children}
    </span>
  </LazyTippy>;
};

export const TooltipO = (props: PropsWithChildren<{ tooltip: O.Option<TooltipProps> }>): JSX.Element => {
  return (
    <Fragment>
      {pipe(
        props.tooltip,
        O.fold(
          () => props.children,
          (tooltipProps) => <Tooltip {...tooltipProps}>{props.children}</Tooltip>
        )
      )}
    </Fragment>
  );
};

export const TooltipAnchor = (props: PropsWithChildren<TooltipProps & { anchorKlass?: Klass }>): JSX.Element =>
  <Tooltip {...props}>
    <span {...klassPropO(["a-decoration", "no-decoration"])(props.anchorKlass)}>
      {props.children}
    </span>
  </Tooltip>;

export const TooltipInfo = (props: TooltipProps): JSX.Element =>
  <Tooltip {...props}>
    <Svg src={infoIcon} {...klass("accent-1-700")} />
  </Tooltip>;
