import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const ASC = {
  _tag: `ASC`,
  dir: `asc`
} as const;

export type ASCTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ASC`>
}>;
export const ASCTaggedC: ASCTaggedC = t.type({
  _tag: t.literal(`ASC`)
});
export type ASCTagged = t.TypeOf<ASCTaggedC>;
export type ASC = ASCTagged & typeof ASC;
export type ASCC = t.Type<ASC, ASCTagged>;
export const ASCC: ASCC = pipe(ASCTaggedC, c => new t.Type<ASC, ASCTagged>(
  `ASC`,
  (u: unknown): u is ASC => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ASC> => pipe(c.decode(u), E.map(x => ({ ...x, ...ASC }))),
  (x: ASC): ASCTagged => ({ ...x, _tag: `ASC`}),
)) satisfies t.Type<ASC, unknown>;


export const DESC = {
  _tag: `DESC`,
  dir: `desc`
} as const;

export type DESCTaggedC = t.TypeC<{
  _tag: t.LiteralC<`DESC`>
}>;
export const DESCTaggedC: DESCTaggedC = t.type({
  _tag: t.literal(`DESC`)
});
export type DESCTagged = t.TypeOf<DESCTaggedC>;
export type DESC = DESCTagged & typeof DESC;
export type DESCC = t.Type<DESC, DESCTagged>;
export const DESCC: DESCC = pipe(DESCTaggedC, c => new t.Type<DESC, DESCTagged>(
  `DESC`,
  (u: unknown): u is DESC => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DESC> => pipe(c.decode(u), E.map(x => ({ ...x, ...DESC }))),
  (x: DESC): DESCTagged => ({ ...x, _tag: `DESC`}),
)) satisfies t.Type<DESC, unknown>;


export const allSortDirC = [ASCC, DESCC] as const;
export const allSortDirNames = [`ASC`, `DESC`] as const;
export type SortDirName = (typeof allSortDirNames)[number];

export type SortDirCU = t.UnionC<[ASCC, DESCC]>;
export type SortDirU = ASC | DESC;
export const SortDirCU: SortDirCU = t.union([ASCC, DESCC]) satisfies t.Type<SortDirU, unknown>;

export const sortDirOrd: Ord.Ord<SortDirU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allSortDir = [ASC, DESC] as const;
export type SortDirMap<A> = { [K in SortDirName]: A };


