import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const competitive = {
  _tag: `Competitive`,
  id: 1,
  name: `Competitive`
} as const;

export type CompetitiveTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Competitive`>
}>;
export const competitiveTaggedC: CompetitiveTaggedC = t.type({
  _tag: t.literal(`Competitive`)
});
export type CompetitiveTagged = t.TypeOf<CompetitiveTaggedC>;
export type Competitive = CompetitiveTagged & typeof competitive;
export type CompetitiveC = t.Type<Competitive, CompetitiveTagged>;
export const competitiveC: CompetitiveC = pipe(competitiveTaggedC, c => new t.Type<Competitive, CompetitiveTagged>(
  `Competitive`,
  (u: unknown): u is Competitive => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Competitive> => pipe(c.decode(u), E.map(x => ({ ...x, ...competitive }))),
  (x: Competitive): CompetitiveTagged => ({ ...x, _tag: `Competitive`}),
)) satisfies t.Type<Competitive, unknown>;


export const negotiated = {
  _tag: `Negotiated`,
  id: 2,
  name: `Negotiated`
} as const;

export type NegotiatedTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Negotiated`>
}>;
export const negotiatedTaggedC: NegotiatedTaggedC = t.type({
  _tag: t.literal(`Negotiated`)
});
export type NegotiatedTagged = t.TypeOf<NegotiatedTaggedC>;
export type Negotiated = NegotiatedTagged & typeof negotiated;
export type NegotiatedC = t.Type<Negotiated, NegotiatedTagged>;
export const negotiatedC: NegotiatedC = pipe(negotiatedTaggedC, c => new t.Type<Negotiated, NegotiatedTagged>(
  `Negotiated`,
  (u: unknown): u is Negotiated => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Negotiated> => pipe(c.decode(u), E.map(x => ({ ...x, ...negotiated }))),
  (x: Negotiated): NegotiatedTagged => ({ ...x, _tag: `Negotiated`}),
)) satisfies t.Type<Negotiated, unknown>;


export const allMethodOfSaleC = [competitiveC, negotiatedC] as const;
export const allMethodOfSaleNames = [`Competitive`, `Negotiated`] as const;
export type MethodOfSaleName = (typeof allMethodOfSaleNames)[number];

export type MethodOfSaleCU = t.UnionC<[CompetitiveC, NegotiatedC]>;
export type MethodOfSaleU = Competitive | Negotiated;
export const MethodOfSaleCU: MethodOfSaleCU = t.union([competitiveC, negotiatedC]) satisfies t.Type<MethodOfSaleU, unknown>;

export const methodOfSaleOrd: Ord.Ord<MethodOfSaleU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allMethodOfSale = [competitive, negotiated] as const;
export type MethodOfSaleMap<A> = { [K in MethodOfSaleName]: A };


