import { constant } from "@scripts/fp-ts";
import { mdash } from "@scripts/literals/mdash";

export const noneStr = mdash;
export const noneStrConst = constant(noneStr);

export const naStr = "N/A";
export const naStrConst = constant(naStr);

export const emptyStr = "";
export const emptyStrConst = constant(emptyStr);
