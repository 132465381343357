import type { Option } from "fp-ts/lib/Option";
import * as O from "fp-ts/lib/Option";
import { fromOptionK } from "fp-ts/lib/Refinement";

import type { Match } from "@scripts/fp-ts/lib/types";

export const isString = fromOptionK((u: unknown): Option<string> =>
  typeof u === "string" ? O.some(u) : O.none
);

export const isNumber = fromOptionK((u: unknown): Option<number> =>
  typeof u === "number" ? O.some(u) : O.none
);

export const isFunction = (u: unknown): u is Match.AnyFunction => typeof u === "function";

export const isNotNull = <A>(a: A): a is Exclude<A, null> => a != null;

/** `A & {}` is substitutable for `NonNullable<A>` */
export const isNonNullable = <A>(a: A): a is A & {} => a !== null;
