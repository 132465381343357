import { type ReactElement, useState } from "react";
import type * as t from "io-ts";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { constVoid, O } from "@scripts/fp-ts";
import type { ApiRedirectC, RelativeApiRedirect } from "@scripts/generated/models/apiRedirect";
import type { EmptyObjectC } from "@scripts/generated/models/emptyObject";
import * as corpRouter from "@scripts/generated/routers/corpRouter";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { AnchorWithChildren } from "@scripts/react/components/Anchor";
import { ButtonSubmit } from "@scripts/react/components/Button";
import { Form } from "@scripts/react/components/form/Form";
import { useConfig } from "@scripts/react/context/Config";
import { emptyFormState } from "@scripts/react/form/form";
import { klass } from "@scripts/react/util/classnames";
import { openInSameTab } from "@scripts/routes/router";
import type { UrlInterface, UrlInterfaceIO } from "@scripts/routes/urlInterface";

import { MinimalContainer, MinimalFormContainer } from "@scripts-ssr/components/MinimalLayout";

type ReviewTermsVariant = "default" | "blp";

type VariantDetails = {
  termsRoute: (config: BLConfigWithLog) => UrlInterface<"GET">;
  termsLink: (anchor: ReactElement) => ReactElement;
  acceptTermsRoute: (redirect: RelativeApiRedirect) => UrlInterfaceIO<"POST", EmptyObjectC, ApiRedirectC>;
};

const variantDetails: Record<ReviewTermsVariant, VariantDetails> = {
  default: {
    termsRoute: () => corpRouter.corporateStaticTerms(),
    termsLink: anchor => <>Before continuing, please take a moment to review and accept BondLink’s {anchor}.</>,
    acceptTermsRoute: r => V2Router.investorPortalInvestorControllerAcceptTerms({ redirect: r.redirect }),
  },
  blp: {
    termsRoute: config => ({ method: "GET", url: config.bondlinkPrimaryTermsUrl }),
    termsLink: anchor => <>Before continuing, please take a moment to review and accept {anchor} for the BondLink Primary Platform.</>,
    acceptTermsRoute: r => V2Router.investorPortalInvestorControllerAcceptBLPTerms({ redirect: r.redirect }),
  },
};

export const ReviewTermsPage = (props: { redirect: RelativeApiRedirect, variant: ReviewTermsVariant }) => {
  const [formState, setFormState] = useState(emptyFormState<t.TypeC<t.Props>>());
  const config = useConfig();
  const details = variantDetails[props.variant];

  return (
    <MinimalContainer>
      <p>
        {details.termsLink(
          <AnchorWithChildren
            route={{ title: "Terms", route: details.termsRoute(config) }}
            target="_blank"
            externalLinkLocation="none"
          >
            terms &amp; conditions
          </AnchorWithChildren>
        )}
      </p>
      <MinimalFormContainer>
        <Form
          url={details.acceptTermsRoute(props.redirect)}
          state={formState}
          setState={setFormState}
          onSuccess={() => openInSameTab(props.redirect.redirect)}
          onFailure={O.none}
          headers={O.none}
        >
          <ButtonSubmit text="Accept" onClick={constVoid} loading={formState.loading} loadingText="Submitting" {...klass("w-100")} />
        </Form>
      </MinimalFormContainer>
    </MinimalContainer>
  );
};
