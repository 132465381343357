import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const lastDay = {
  _tag: `LastDay`,
  days: 1,
  months: 1
} as const;

export type LastDayTaggedC = t.TypeC<{
  _tag: t.LiteralC<`LastDay`>
}>;
export const lastDayTaggedC: LastDayTaggedC = t.type({
  _tag: t.literal(`LastDay`)
});
export type LastDayTagged = t.TypeOf<LastDayTaggedC>;
export type LastDay = LastDayTagged & typeof lastDay;
export type LastDayC = t.Type<LastDay, LastDayTagged>;
export const lastDayC: LastDayC = pipe(lastDayTaggedC, c => new t.Type<LastDay, LastDayTagged>(
  `LastDay`,
  (u: unknown): u is LastDay => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, LastDay> => pipe(c.decode(u), E.map(x => ({ ...x, ...lastDay }))),
  (x: LastDay): LastDayTagged => ({ ...x, _tag: `LastDay`}),
)) satisfies t.Type<LastDay, unknown>;


export const lastWeek = {
  _tag: `LastWeek`,
  days: 7,
  months: 1
} as const;

export type LastWeekTaggedC = t.TypeC<{
  _tag: t.LiteralC<`LastWeek`>
}>;
export const lastWeekTaggedC: LastWeekTaggedC = t.type({
  _tag: t.literal(`LastWeek`)
});
export type LastWeekTagged = t.TypeOf<LastWeekTaggedC>;
export type LastWeek = LastWeekTagged & typeof lastWeek;
export type LastWeekC = t.Type<LastWeek, LastWeekTagged>;
export const lastWeekC: LastWeekC = pipe(lastWeekTaggedC, c => new t.Type<LastWeek, LastWeekTagged>(
  `LastWeek`,
  (u: unknown): u is LastWeek => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, LastWeek> => pipe(c.decode(u), E.map(x => ({ ...x, ...lastWeek }))),
  (x: LastWeek): LastWeekTagged => ({ ...x, _tag: `LastWeek`}),
)) satisfies t.Type<LastWeek, unknown>;


export const last30Days = {
  _tag: `Last30Days`,
  days: 30,
  months: 1
} as const;

export type Last30DaysTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Last30Days`>
}>;
export const last30DaysTaggedC: Last30DaysTaggedC = t.type({
  _tag: t.literal(`Last30Days`)
});
export type Last30DaysTagged = t.TypeOf<Last30DaysTaggedC>;
export type Last30Days = Last30DaysTagged & typeof last30Days;
export type Last30DaysC = t.Type<Last30Days, Last30DaysTagged>;
export const last30DaysC: Last30DaysC = pipe(last30DaysTaggedC, c => new t.Type<Last30Days, Last30DaysTagged>(
  `Last30Days`,
  (u: unknown): u is Last30Days => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Last30Days> => pipe(c.decode(u), E.map(x => ({ ...x, ...last30Days }))),
  (x: Last30Days): Last30DaysTagged => ({ ...x, _tag: `Last30Days`}),
)) satisfies t.Type<Last30Days, unknown>;


export const last90Days = {
  _tag: `Last90Days`,
  days: 90,
  months: 3
} as const;

export type Last90DaysTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Last90Days`>
}>;
export const last90DaysTaggedC: Last90DaysTaggedC = t.type({
  _tag: t.literal(`Last90Days`)
});
export type Last90DaysTagged = t.TypeOf<Last90DaysTaggedC>;
export type Last90Days = Last90DaysTagged & typeof last90Days;
export type Last90DaysC = t.Type<Last90Days, Last90DaysTagged>;
export const last90DaysC: Last90DaysC = pipe(last90DaysTaggedC, c => new t.Type<Last90Days, Last90DaysTagged>(
  `Last90Days`,
  (u: unknown): u is Last90Days => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Last90Days> => pipe(c.decode(u), E.map(x => ({ ...x, ...last90Days }))),
  (x: Last90Days): Last90DaysTagged => ({ ...x, _tag: `Last90Days`}),
)) satisfies t.Type<Last90Days, unknown>;


export const lastYear = {
  _tag: `LastYear`,
  days: 365,
  months: 12
} as const;

export type LastYearTaggedC = t.TypeC<{
  _tag: t.LiteralC<`LastYear`>
}>;
export const lastYearTaggedC: LastYearTaggedC = t.type({
  _tag: t.literal(`LastYear`)
});
export type LastYearTagged = t.TypeOf<LastYearTaggedC>;
export type LastYear = LastYearTagged & typeof lastYear;
export type LastYearC = t.Type<LastYear, LastYearTagged>;
export const lastYearC: LastYearC = pipe(lastYearTaggedC, c => new t.Type<LastYear, LastYearTagged>(
  `LastYear`,
  (u: unknown): u is LastYear => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, LastYear> => pipe(c.decode(u), E.map(x => ({ ...x, ...lastYear }))),
  (x: LastYear): LastYearTagged => ({ ...x, _tag: `LastYear`}),
)) satisfies t.Type<LastYear, unknown>;


export const allDateRangeC = [lastDayC, lastWeekC, last30DaysC, last90DaysC, lastYearC] as const;
export const allDateRangeNames = [`LastDay`, `LastWeek`, `Last30Days`, `Last90Days`, `LastYear`] as const;
export type DateRangeName = (typeof allDateRangeNames)[number];

export type DateRangeCU = t.UnionC<[LastDayC, LastWeekC, Last30DaysC, Last90DaysC, LastYearC]>;
export type DateRangeU = LastDay | LastWeek | Last30Days | Last90Days | LastYear;
export const DateRangeCU: DateRangeCU = t.union([lastDayC, lastWeekC, last30DaysC, last90DaysC, lastYearC]) satisfies t.Type<DateRangeU, unknown>;

export const dateRangeOrd: Ord.Ord<DateRangeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allDateRange = [lastDay, lastWeek, last30Days, last90Days, lastYear] as const;
export type DateRangeMap<A> = { [K in DateRangeName]: A };


