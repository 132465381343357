import * as A from "fp-ts/lib/Array";
import * as Eq from "fp-ts/lib/Eq";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as R from "fp-ts/lib/Record";
import { useStableEffect } from "fp-ts-react-stable-hooks";
import type { Action } from "redux";

import type { BLConfigWithLog } from "@scripts/bondlink";

import type { NotificationBaseProps } from "../components/Notification";
import { useConfig } from "../context/Config";

type NotificationActionTypes = NotificationAdd | NotificationRemove | NotificationRemoveAll;
type LastNotification = { action: NotificationActionTypes, id: string };
export type NotificationStore = { lastAction: O.Option<LastNotification>, notifications: NotificationBaseProps[] };

export const emptyNotificationStore: NotificationStore = {
  lastAction: O.none,
  notifications: [],
};

export const notificationAdd = (notification: NotificationBaseProps)
  : Action<"NOTIFICATION_ADD"> & { notification: NotificationBaseProps } => ({
    type: "NOTIFICATION_ADD",
    notification: notification,
  });

export const notificationRemove = (id: string): Action<"NOTIFICATION_REMOVE"> & { id: string } => ({
  type: "NOTIFICATION_REMOVE",
  id,
});


export const notificationReducer = (config: BLConfigWithLog) =>
  (state: NotificationStore, action: NotificationAction): NotificationStore => {
    switch (action.type) {
      case "NOTIFICATION_ADD":
        return pipe(
          state.notifications,
          A.findIndex(c => c.id === action.notification.id),
          O.fold(
            () => ({
              notifications: A.append(action.notification)(state.notifications),
              lastAction: O.some({ action, id: action.notification.id }),
            }),
            () => state,
          )
        );
      case "NOTIFICATION_REMOVE":
        return pipe(
          state.notifications,
          A.findIndex(c => c.id === action.id),
          O.fold(
            () => state,
            d => ({
              notifications: A.unsafeDeleteAt(d, state.notifications),
              lastAction: O.some({ action, id: action.id }),
            })
          )
        );
      case "NOTIFICATION_REMOVE_ALL":
        return emptyNotificationStore;
    }
    return config.exhaustive(action);
  };

type ListenerActions = { onRemoveAction: (last: LastNotification) => void, onAddAction: (last: LastNotification) => void };
export type Listeners = { [listenerKey: string]: ListenerActions };

const listenerActionhandler = (config: BLConfigWithLog) =>
  (lastNotification: LastNotification) => (listenerAction: ListenerActions) => {
    switch (lastNotification.action.type) {
      case "NOTIFICATION_ADD":
        return listenerAction.onAddAction(lastNotification);
      case "NOTIFICATION_REMOVE":
        return listenerAction.onRemoveAction(lastNotification);
      case "NOTIFICATION_REMOVE_ALL":
        return listenerAction.onRemoveAction(lastNotification);
    }
    return config.exhaustive(lastNotification.action);
  };

export const useNotificationHandler = (
  listeners: Listeners,
  notifications: NotificationStore
) => {
  const config = useConfig();

  useStableEffect(() => {
    pipe(
      notifications.lastAction,
      O.chain(a =>
        pipe(
          listeners,
          R.lookup(a.id),
          O.map(listenerActionhandler(config)(a))
        )
      )
    );
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notifications.lastAction],
    Eq.tuple(O.getEq(Eq.eqStrict))
  );
};

export type NotificationAdd = ReturnType<typeof notificationAdd>;
type NotificationRemove = ReturnType<typeof notificationRemove>;
type NotificationRemoveAll = Action<"NOTIFICATION_REMOVE_ALL">;

export type NotificationAction = NotificationAdd | NotificationRemove | NotificationRemoveAll;
