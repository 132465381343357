import type { Lazy } from "fp-ts/lib/function";
import { flow, pipe } from "fp-ts/lib/function";
import type { Option } from "fp-ts/lib/Option";
import { none, some } from "fp-ts/lib/Option";
import * as O from "fp-ts/lib/Option";
import * as RA from "fp-ts/lib/ReadonlyArray";
import * as RNA from "fp-ts/lib/ReadonlyNonEmptyArray";
import { Lens } from "monocle-ts";

import { fromNativeReadonlyNonEmptyArray } from "@scripts/fp-ts/ReadonlyNonEmptyArray";
import { bondLinkClientId } from "@scripts/generated/domaintables/clients";
import type { RoleU } from "@scripts/generated/domaintables/roles";
import { admin, bankerAdmin, bankerUser, issuerAdmin, issuerUser, salesAdmin } from "@scripts/generated/domaintables/roles";
import type { Bank } from "@scripts/generated/models/bank";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { ClientRoles, UserWithRoles } from "@scripts/generated/models/user";
import type { AnyPageMeta, PageRoutingWithRender } from "@scripts/routes/routing/base";
import type { RouteOption, Routes } from "@scripts/routes/routing/nav";

export const bondlinkAdminRoles: RNA.ReadonlyNonEmptyArray<RoleU> = [admin, salesAdmin];

const issuerAdminRoles: RNA.ReadonlyNonEmptyArray<RoleU> = [issuerAdmin];
const bankerAdminRoles: RNA.ReadonlyNonEmptyArray<RoleU> = [bankerAdmin];

export const combinedPortalAdminRoles: RNA.ReadonlyNonEmptyArray<RoleU> = RNA.concat(issuerAdminRoles)(bankerAdminRoles);

const hasRole = (roleIds: ReadonlyArray<ClientRoles>) =>
  (id: number, clientId?: number): boolean => roleIds.some(
    (c: ClientRoles) => clientId
      ? c.clientId === clientId && c.roles.some((n: number) => n === id)
      : c.roles.some((n: number) => n === id));

/*
 * Unsafely check if a user has a role WITHOUT checking against a clientId
 */
const userHasRoleUnsafe = (r: RoleU) =>
  (u: UserWithRoles): Option<RoleU> =>
    hasRole(u.roleIds)(r.id) ? some(r) : none;

/*
 * @param clientId is the clientId to be guarded against. For example, to make sure a user has
 *        a role with a client id that matches a particular issuer or bondlink client id
 */
const userHasRole = (r: RoleU) => (clientId: number) =>
  (u: UserWithRoles): Option<RoleU> =>
    hasRole(u.roleIds)(r.id, clientId) ? some(r) : none;

/*
 * Unsafely check if a user has any role WITHOUT checking against a clientId
 */
const userHasAnyRoleUnsafe = (rs: RNA.ReadonlyNonEmptyArray<RoleU>) =>
  (u: UserWithRoles): Option<RoleU> =>
    RA.findFirstMap((r: RoleU) => userHasRoleUnsafe(r)(u))(rs);

const userHasAnyRole = (rs: RNA.ReadonlyNonEmptyArray<RoleU>) => (clientId: number) => (u: UserWithRoles): Option<RoleU> =>
  RA.findFirstMap((r: RoleU) => userHasRole(r)(clientId)(u))(rs);

export const hasBLRoles = (roles: RNA.ReadonlyNonEmptyArray<RoleU>, user: UserWithRoles): O.Option<RoleU> =>
  userHasAnyRole(roles)(bondLinkClientId.id)(user);

export const hasRolesOrBLAdmin = (roles: RNA.ReadonlyNonEmptyArray<RoleU>, administrator: Bank | Issuer, user: UserWithRoles): O.Option<RoleU> => pipe(
  userHasAnyRole(roles)(administrator.clientId)(user),
  O.alt(() => hasBLRoles(bondlinkAdminRoles, user)),
);

export const userL = Lens.fromProp<UserWithRoles>()("user");

export const checkSubNavRouteRoles = (bank: Bank, user: UserWithRoles) => (fallbackRoute: Lazy<AnyPageMeta & PageRoutingWithRender>) => flow(
  RA.filter((ro: RouteOption) => O.isSome(hasRolesOrBLAdmin(ro.route.authDetails.rolesAllowed, bank, user))),
  RA.map((ro: RouteOption) => ({
    ...ro,
    subRoutes: O.chain(
      RNA.filter((meta: AnyPageMeta) =>
        O.isSome(hasRolesOrBLAdmin(meta.authDetails.rolesAllowed, bank, user))
      )
    )(ro.subRoutes),
  })),
  RNA.fromReadonlyArray,
  O.getOrElse((): Routes => fromNativeReadonlyNonEmptyArray([{
    icon: O.none,
    route: fallbackRoute(),
    subRoutes: O.none,
  }]))
);

export const userHasAnyBankRoles = userHasAnyRoleUnsafe([bankerUser, bankerAdmin]);
export const userHasAnyIssuerRoles = userHasAnyRoleUnsafe([issuerUser, issuerAdmin]);
export const userHasIssuer = (user: UserWithRoles) => O.isSome(userHasAnyIssuerRoles(user));
export const userHasBank = (user: UserWithRoles) => O.isSome(userHasAnyBankRoles(user));
