import { ReadonlyNonEmptyArrayType, readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import * as E from "fp-ts/lib/Either";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import { EitherC, either } from "io-ts-types/lib/either";
import { RfpC as imported63_RfpC, Rfp as imported63_Rfp, rfpC as imported60_rfpC } from "./rfpBase";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray";
import { BondOfferingDetailsC as imported40_BondOfferingDetailsC, BondOfferingDetails as imported40_BondOfferingDetails, bondOfferingDetailsC as imported40_bondOfferingDetailsC } from "./bondOffering";
import { Actor as imported8_Actor, actorC as imported8_actorC, EmailContactActor as imported1_EmailContactActor, ActorC as imported8_ActorC, emailContactActorC as imported1_emailContactActorC, EmailContactActorC as imported1_EmailContactActorC } from "./actor";
import { WithId as imported233_WithId, WithModInfoC as imported169_WithModInfoC, withModInfoC as imported168_withModInfoC, withIdC as imported232_withIdC, WithModInfo as imported169_WithModInfo, WithId as imported232_WithId, WithModInfoC as imported168_WithModInfoC, withModInfoC as imported169_withModInfoC, WithIdC as imported233_WithIdC, WithId as imported231_WithId, WithIdC as imported231_WithIdC, WithIdC as imported232_WithIdC, withIdC as imported231_withIdC, WithModInfo as imported168_WithModInfo, withIdC as imported233_withIdC } from "./threadThrough";
import { issuerC as imported52_issuerC, IssuerC as imported52_IssuerC, issuerC as imported51_issuerC, Issuer as imported52_Issuer, IssuerC as imported53_IssuerC, Issuer as imported53_Issuer } from "./issuer";
import { BankC as imported18_BankC, Bank as imported18_Bank, bankC as imported18_bankC } from "./bank";
import { LocalDateTime } from "@js-joda/core";

export type EmailCampaignC = t.TypeC<{
  subject: t.StringC,
  sent: LocalDateTimeFromIsoStringC,
  associatedIssuer: OptionFromNullableC<imported52_IssuerC>,
  bondOffering: OptionFromNullableC<imported231_WithIdC<imported168_WithModInfoC<imported40_BondOfferingDetailsC>>>,
  rfp: OptionFromNullableC<imported232_WithIdC<imported169_WithModInfoC<imported63_RfpC>>>
}>;
export type EmailCampaign = {
  subject: string,
  sent: LocalDateTime,
  associatedIssuer: O.Option<imported52_Issuer>,
  bondOffering: O.Option<imported231_WithId<imported168_WithModInfo<imported40_BondOfferingDetails>>>,
  rfp: O.Option<imported232_WithId<imported169_WithModInfo<imported63_Rfp>>>
};
export const emailCampaignC: EmailCampaignC = t.type({
  subject: t.string,
  sent: LocalDateTimeFromIsoStringC,
  associatedIssuer: optionFromNullable(imported51_issuerC),
  bondOffering: optionFromNullable(imported231_withIdC(imported168_withModInfoC(imported40_bondOfferingDetailsC))),
  rfp: optionFromNullable(imported232_withIdC(imported169_withModInfoC(imported60_rfpC)))
}) satisfies t.Type<EmailCampaign, unknown>;


export type EmailCampaignStatsC<A1 extends t.Mixed> = t.TypeC<{
  recipients: A1,
  suppressions: A1,
  unsubscribes: A1,
  opens: A1,
  clicks: A1,
  bounces: A1,
  drops: A1,
  blocks: A1,
  spamReports: A1
}>;
export type EmailCampaignStats<A1> = {
  recipients: A1,
  suppressions: A1,
  unsubscribes: A1,
  opens: A1,
  clicks: A1,
  bounces: A1,
  drops: A1,
  blocks: A1,
  spamReports: A1
};
export const emailCampaignStatsC = <A1 extends t.Mixed>(A1: A1): EmailCampaignStatsC<A1> => t.type({
  recipients: A1,
  suppressions: A1,
  unsubscribes: A1,
  opens: A1,
  clicks: A1,
  bounces: A1,
  drops: A1,
  blocks: A1,
  spamReports: A1
}) satisfies t.Type<EmailCampaignStats<t.TypeOf<A1>>, unknown>;


export type EmailCampaignWithStatsC = t.TypeC<{
  campaign: imported233_WithIdC<EmailCampaignC>,
  stats: EmailCampaignStatsC<t.NumberC>
}>;
export type EmailCampaignWithStats = {
  campaign: imported233_WithId<EmailCampaign>,
  stats: EmailCampaignStats<number>
};
export const emailCampaignWithStatsC: EmailCampaignWithStatsC = t.type({
  campaign: imported233_withIdC(emailCampaignC),
  stats: emailCampaignStatsC(t.number)
}) satisfies t.Type<EmailCampaignWithStats, unknown>;


export type EmailCampaignRecipientC = t.TypeC<{
  actor: EitherC<imported1_EmailContactActorC, imported8_ActorC>,
  stats: EmailCampaignStatsC<t.BooleanC>
}>;
export type EmailCampaignRecipient = {
  actor: E.Either<imported1_EmailContactActor, imported8_Actor>,
  stats: EmailCampaignStats<boolean>
};
export const emailCampaignRecipientC: EmailCampaignRecipientC = t.type({
  actor: either(imported1_emailContactActorC, imported8_actorC),
  stats: emailCampaignStatsC(t.boolean)
}) satisfies t.Type<EmailCampaignRecipient, unknown>;


export type EmailCampaignWithRecipientsC = t.TypeC<{
  campaign: EmailCampaignWithStatsC,
  recipients: ReadonlyNonEmptyArrayType<EmailCampaignRecipientC>
}>;
export type EmailCampaignWithRecipients = {
  campaign: EmailCampaignWithStats,
  recipients: RNEA.ReadonlyNonEmptyArray<EmailCampaignRecipient>
};
export const emailCampaignWithRecipientsC: EmailCampaignWithRecipientsC = t.type({
  campaign: emailCampaignWithStatsC,
  recipients: readonlyNonEmptyArrayC(emailCampaignRecipientC)
}) satisfies t.Type<EmailCampaignWithRecipients, unknown>;


export type BankEmailCampaignPostC = t.TypeC<{
  id: t.NumberC,
  associatedIssuerId: OptionFromNullableC<t.NumberC>,
  bondOfferingId: OptionFromNullableC<t.NumberC>,
  rfpId: OptionFromNullableC<t.NumberC>
}>;
export type BankEmailCampaignPost = {
  id: number,
  associatedIssuerId: O.Option<number>,
  bondOfferingId: O.Option<number>,
  rfpId: O.Option<number>
};
export const bankEmailCampaignPostC: BankEmailCampaignPostC = t.type({
  id: t.number,
  associatedIssuerId: optionFromNullable(t.number),
  bondOfferingId: optionFromNullable(t.number),
  rfpId: optionFromNullable(t.number)
}) satisfies t.Type<BankEmailCampaignPost, unknown>;


export type IssuerEmailCampaignPostC = t.TypeC<{
  id: t.NumberC,
  bondOfferingId: OptionFromNullableC<t.NumberC>,
  rfpId: OptionFromNullableC<t.NumberC>
}>;
export type IssuerEmailCampaignPost = {
  id: number,
  bondOfferingId: O.Option<number>,
  rfpId: O.Option<number>
};
export const issuerEmailCampaignPostC: IssuerEmailCampaignPostC = t.type({
  id: t.number,
  bondOfferingId: optionFromNullable(t.number),
  rfpId: optionFromNullable(t.number)
}) satisfies t.Type<IssuerEmailCampaignPost, unknown>;


export type EmailUnsubscribeDataC = t.TypeC<{
  emailAddress: t.StringC,
  issuer: OptionFromNullableC<imported53_IssuerC>,
  bank: OptionFromNullableC<imported18_BankC>
}>;
export type EmailUnsubscribeData = {
  emailAddress: string,
  issuer: O.Option<imported53_Issuer>,
  bank: O.Option<imported18_Bank>
};
export const emailUnsubscribeDataC: EmailUnsubscribeDataC = t.type({
  emailAddress: t.string,
  issuer: optionFromNullable(imported52_issuerC),
  bank: optionFromNullable(imported18_bankC)
}) satisfies t.Type<EmailUnsubscribeData, unknown>;


export type UnsubscribeBankC = t.TypeC<{
  _tag: t.LiteralC<`UnsubscribeBank`>,
  id: t.NumberC
}>;
export type UnsubscribeBank = {
  _tag: `UnsubscribeBank`,
  id: number
};
export const unsubscribeBankC: UnsubscribeBankC = t.type({
  _tag: t.literal(`UnsubscribeBank`),
  id: t.number
}) satisfies t.Type<UnsubscribeBank, unknown>;


export type UnsubscribeIssuerC = t.TypeC<{
  _tag: t.LiteralC<`UnsubscribeIssuer`>,
  id: t.NumberC
}>;
export type UnsubscribeIssuer = {
  _tag: `UnsubscribeIssuer`,
  id: number
};
export const unsubscribeIssuerC: UnsubscribeIssuerC = t.type({
  _tag: t.literal(`UnsubscribeIssuer`),
  id: t.number
}) satisfies t.Type<UnsubscribeIssuer, unknown>;


export const unsubscribeAll = {
  _tag: `UnsubscribeAll`
} as const;

export type UnsubscribeAllTaggedC = t.TypeC<{
  _tag: t.LiteralC<`UnsubscribeAll`>
}>;
export const unsubscribeAllTaggedC: UnsubscribeAllTaggedC = t.type({
  _tag: t.literal(`UnsubscribeAll`)
});
export type UnsubscribeAllTagged = t.TypeOf<UnsubscribeAllTaggedC>;
export type UnsubscribeAll = UnsubscribeAllTagged & typeof unsubscribeAll;
export type UnsubscribeAllC = t.Type<UnsubscribeAll, UnsubscribeAllTagged>;
export const unsubscribeAllC: UnsubscribeAllC = pipe(unsubscribeAllTaggedC, c => new t.Type<UnsubscribeAll, UnsubscribeAllTagged>(
  `UnsubscribeAll`,
  (u: unknown): u is UnsubscribeAll => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UnsubscribeAll> => pipe(c.decode(u), E.map(x => ({ ...x, ...unsubscribeAll }))),
  (x: UnsubscribeAll): UnsubscribeAllTagged => ({ ...x, _tag: `UnsubscribeAll`}),
)) satisfies t.Type<UnsubscribeAll, unknown>;


export const allUnsubscribeTypeC = [unsubscribeAllC, unsubscribeIssuerC, unsubscribeBankC] as const;
export const allUnsubscribeTypeNames = [`UnsubscribeAll`, `UnsubscribeIssuer`, `UnsubscribeBank`] as const;
export type UnsubscribeTypeName = (typeof allUnsubscribeTypeNames)[number];

export type UnsubscribeTypeCU = t.UnionC<[UnsubscribeAllC, UnsubscribeIssuerC, UnsubscribeBankC]>;
export type UnsubscribeTypeU = UnsubscribeAll | UnsubscribeIssuer | UnsubscribeBank;
export const UnsubscribeTypeCU: UnsubscribeTypeCU = t.union([unsubscribeAllC, unsubscribeIssuerC, unsubscribeBankC]) satisfies t.Type<UnsubscribeTypeU, unknown>;

export type UnsubscribeTypeMap<A> = { [K in UnsubscribeTypeName]: A };


export type EmailUnsubscribePostC = t.TypeC<{
  email: t.StringC,
  type: UnsubscribeTypeCU
}>;
export type EmailUnsubscribePost = {
  email: string,
  type: UnsubscribeTypeU
};
export const emailUnsubscribePostC: EmailUnsubscribePostC = t.type({
  email: t.string,
  type: UnsubscribeTypeCU
}) satisfies t.Type<EmailUnsubscribePost, unknown>;


