import type { FormEvent, ReactElement } from "react";
import { Fragment, useEffect, useRef, useState } from "react";
import { pipe } from "fp-ts/lib/function";
import { filter, fold, fromNullable, some } from "fp-ts/lib/Option";
import * as T from "fp-ts/lib/Task";
import * as TE from "fp-ts/lib/TaskEither";
import { Lens } from "monocle-ts";
import V from "voca";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { O } from "@scripts/fp-ts";
import { danger } from "@scripts/generated/domaintables/alertTypes";
import * as rc from "@scripts/generated/domaintables/responseCodes";
import type { ForgotPasswordPostC } from "@scripts/generated/models/login";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { Alert } from "@scripts/react/components/Alert";
import { Empty } from "@scripts/react/components/Empty";
import { useConfig } from "@scripts/react/context/Config";
import { type Codec } from "@scripts/react/form/codecs";
import type { DataCodec, FormErrors, FormProps, FormState, ResponseCodec, StateProps } from "@scripts/react/form/form";
import { emptyFormState, formErrorsLens, onSubmit } from "@scripts/react/form/form";
import * as respCodecs from "@scripts/react/form/responseCodecs";
import { klass } from "@scripts/react/util/classnames";
import type { CloseModalFn } from "@scripts/react/util/useModal";
import { useModal } from "@scripts/react/util/useModal";

import { ButtonLink } from "../Button";
import type { ModalDismissable, ModalOpenable } from "../modal/Modal";
import { Modal } from "../modal/Modal";
import { ForgotPasswordFormBody, Submitted } from "./ForgotPassword";
import { badSession, refresh, retryLater } from "./FormErrorMessages";

const ForgotPasswordForm = (props: { dismissAction: CloseModalFn }) => {
  const [submitted, setSubmitted] = useState(false);
  const [formState, setFormState] = useState(emptyFormState<ForgotPasswordPostC>());

  return submitted
    ? <Submitted dismissAction={props.dismissAction} />
    : <Form
      url={V2Router.baseAuthControllerForgotPasswordRequest()}
      state={formState}
      setState={setFormState}
      // eslint-disable-next-line react/jsx-no-bind
      onSuccess={() => setSubmitted(true)}
      onFailure={O.none}
      headers={O.none}
    >
      <ForgotPasswordFormBody
        dismissAction={props.dismissAction}
        formState={formState}
        setFormState={setFormState}
      />
    </Form>;
};

export const ForgotPasswordModal = (props: ModalOpenable & ModalDismissable) =>
  <Modal
    dismissAction={props.dismissAction}
    id="forgotPasswordModal"
    title="Password Reset"
    icon={O.none}
    type="primary"
    open={props.modalOpen}
    size="modal-lg"
    body={<ForgotPasswordForm dismissAction={props.dismissAction} />}
  />;

export type BaseInputFieldProps<KV> = {
  codec: Codec<KV>;
  disabled?: boolean;
  requiredOverride?: boolean;
  tabIndex?: number;
};
export type BaseInputProps<PC extends DataCodec, KV, KVLinked = unknown> = StateProps<PC, KV, KVLinked> & BaseInputFieldProps<KV>;

export const Form = <PC extends DataCodec, RC extends ResponseCodec>(p: FormProps<PC, RC>): ReactElement => {
  const config = useConfig();
  const propsLens = Lens.fromProp<FormProps<PC, RC>>()("state");
  const onSub = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    pipe(
      fromNullable(p.beforeSubmit),
      fold(
        () => T.of(onSubmit(config)(p)),
        fn => pipe(
          fn(p.state),
          TE.match(
            (e: respCodecs.ResponseErrors["errors"]) => onSubmit(config)(propsLens.compose(formErrorsLens<PC>()).set(e)(p)),
            (s: FormState<PC>) => onSubmit(config)(propsLens.set(s)(p)),
          )
        )
      ),
      T.map((fn: (e: FormEvent<HTMLFormElement>) => void) => fn(evt))
    )();
  };

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    const firstInput = ((p.formRef && "current" in p.formRef && p.formRef.current) || formRef.current)?.getElementsByTagName("input")[0];
    const formFocus = window.setTimeout(() => {
      firstInput?.focus();
    }, 0);
    return () => window.clearTimeout(formFocus);
  }, [p.formRef]);

  return <form onSubmit={onSub} ref={p.formRef || formRef}>
    {p.children}
    {!p.errorOverride && <UnexpectedErrors errors={p.state.errors} />}
  </form>;
};

const ForgotPasswordLink = () => {
  const [modalOpen, openModal, closeModal] = useModal("Forgot Password Modal");
  return (
    <>
      <ButtonLink {...klass("font-sans-normal-700")} onClick={openModal}>Forgot your password?</ButtonLink>
      <ForgotPasswordModal modalOpen={modalOpen} dismissAction={closeModal} />
    </>
  );
};

const responseErrorMessage = (config: BLConfigWithLog) => (ue: respCodecs.ResponseError): ReactElement => {
  switch (ue.error._tag) {
    case respCodecs.CODEC_ERROR._tag:
      return retryLater;
    case respCodecs.NETWORK_ERROR._tag:
      return retryLater;
    case respCodecs.RESPONSE_TEXT_ERROR._tag:
      return retryLater;
    case respCodecs.RECAPTCHA_ERROR._tag:
      return <Fragment>There was an issue verifying the captcha challenge.</Fragment>;
    case rc.RESOURCE_NOT_FOUND._tag:
      return retryLater;
    case rc.ID_NOT_FOUND._tag:
      return retryLater;
    // MISSING_REQUIRED_FIELD isn't used for form handling
    case rc.MISSING_REQUIRED_FIELD._tag:
      return retryLater;
    case rc.EMPTY_RESULT._tag:
      return refresh;
    case rc.FOREIGN_KEY_CONSTRAINT._tag:
      return refresh;
    case rc.UNIQUE_CONSTRAINT.error:
      return refresh;
    case rc.INTERNAL_SERVER_ERROR._tag:
      return refresh;
    case rc.TOO_MANY_RESULTS._tag:
      return refresh;
    case rc.TOO_MANY_REQUESTS._tag:
      return <Fragment>We’re receiving too many requests from this location. Please wait several minutes before trying again.</Fragment>;
    case rc.SESSION_ERROR._tag:
      return badSession;
    case rc.PASSWORD_EXPIRED._tag:
      return <Fragment>Your password has expired. Please click <ForgotPasswordLink /> to reset it.</Fragment>;
    case rc.CREDENTIALS_INVALID._tag:
      return <Fragment>This email address or password is invalid. Please double-check that you’ve entered your information correctly or click <ForgotPasswordLink /> to reset your password.</Fragment>;
    case rc.CLEAR_SESSION._tag:
      return badSession;
    case respCodecs.EXISTING_ERRORS._tag:
      return <Fragment>Please address existing errors before submitting.</Fragment>;
    case rc.FIELD_ERRORS._tag:
      return <Fragment>{pipe(
        some(ue.field),
        filter(_ => _.length > 0),
        fold(() => "Please make sure you’ve entered valid information in all required fields before submitting.", f => `There are issues with ${f}.`))}
      </Fragment>;
    case rc.CUSTOM._tag:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <Fragment>{V.isEmpty(ue.extra) ? "Unknown error." : ue.extra}</Fragment>;
  }

  return config.exhaustive(ue.error);
};

const responseErrorCode = (config: BLConfigWithLog) => (ue: respCodecs.ResponseError): number => {
  switch (ue.error._tag) {
    case respCodecs.CODEC_ERROR._tag:
      return 101;
    case respCodecs.NETWORK_ERROR._tag:
      return 102;
    case respCodecs.RESPONSE_TEXT_ERROR._tag:
      return 103;
    case rc.RESOURCE_NOT_FOUND._tag:
      return 104;
    case rc.ID_NOT_FOUND._tag:
      return 105;
    // MISSING_REQUIRED_FIELD isn't used for form handling
    case rc.MISSING_REQUIRED_FIELD._tag:
      return 106;
    case rc.EMPTY_RESULT._tag:
      return 107;
    case rc.FOREIGN_KEY_CONSTRAINT._tag:
      return 108;
    case rc.INTERNAL_SERVER_ERROR._tag:
      return 109;
    case rc.TOO_MANY_RESULTS._tag:
      return 110;
    case rc.TOO_MANY_REQUESTS._tag:
      return 111;
    case rc.SESSION_ERROR._tag:
      return 112;
    case rc.PASSWORD_EXPIRED._tag:
      return 113;
    case rc.CREDENTIALS_INVALID._tag:
      return 114;
    case rc.CLEAR_SESSION._tag:
      return 115;
    case respCodecs.EXISTING_ERRORS._tag:
      return 116;
    case rc.FIELD_ERRORS._tag:
      return 117;
    case rc.UNIQUE_CONSTRAINT._tag:
      return 118;
    case rc.CUSTOM._tag:
      return 119;
    case respCodecs.RECAPTCHA_ERROR._tag:
      return 120;
  }

  return config.exhaustive(ue.error);
};

export function UnexpectedErrors(props: { errors: FormErrors<DataCodec>["errors"] }): ReactElement {
  const config = useConfig();
  return props.errors.length > 0
    ? <div>{props.errors.map((e: respCodecs.ResponseError) =>
      <Alert
        icon={false}
        pill={false}
        type={danger}
        key={`${e.error._tag}_${e.field}`}
      ><span>{responseErrorMessage(config)(e)} Error&nbsp;Code:&nbsp;{responseErrorCode(config)(e)}</span></Alert>)}
    </div>
    : <Empty />;
}
