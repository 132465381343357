import { Route, query, lit, end, Match } from "fp-ts-routing/lib";
import * as O from "fp-ts/lib/Option";
import * as t from "io-ts";
import { NumberFromString } from "io-ts-types/lib/NumberFromString";

import { NoKeyOverlapC } from "../../codecs/noKeyOverlap";
import { unsafeFromRelativeUrlString } from "../../codecs/relativeUrl";
import { Describe } from "../../fp-ts/lib/types/describe";
import { NullableToOptionalFields } from "../../fp-ts/Record";
import { RelativeUrlInterfaceIO, relativeUrlInterfaceIO, RelativeUrlInterface, relativeUrlInterface } from "../../routes/urlInterface";
import { CorpEmailSignupPostC as imported0_CorpEmailSignupPostC, corpDemoPostC as imported0_corpDemoPostC, CorpDemoPostC as imported0_CorpDemoPostC, CorpContactPostC as imported0_CorpContactPostC, corpContactPostC as imported0_corpContactPostC, corpEmailSignupPostC as imported0_corpEmailSignupPostC } from "../models/corp";
import { EmptyObjectC as imported0_EmptyObjectC, emptyObjectC as imported0_emptyObjectC } from "../models/emptyObject";
import { IssuerSearchRequestPostC as imported0_IssuerSearchRequestPostC, issuerSearchRequestPostC as imported0_issuerSearchRequestPostC } from "../models/issuerSearch";

const corporateDemoRequestPath = lit("corporate").then(lit("demo")).then(lit("request"));
const corporateDemoRequestPathParts = ["corporate", "demo", "request"] as const;
export type CorporateDemoRequestPathParts = typeof corporateDemoRequestPathParts;

const corporateDemoRequestQuery = t.strict({});
export type CorporateDemoRequestParams = Describe<typeof corporateDemoRequestPath._A & typeof corporateDemoRequestQuery._A>;

export type CorporateDemoRequestRoute<A = {}> = { match: Match<CorporateDemoRequestParams & A>; pathParts: CorporateDemoRequestPathParts };

export function corporateDemoRequestRoute(): CorporateDemoRequestRoute;
export function corporateDemoRequestRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, CorporateDemoRequestParams>): CorporateDemoRequestRoute<t.TypeOf<A>>;
export function corporateDemoRequestRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, CorporateDemoRequestParams>) {
  return {
    match: (q ? corporateDemoRequestPath.then(query(t.intersection([corporateDemoRequestQuery, q]))) : corporateDemoRequestPath.then(query(corporateDemoRequestQuery))).then(end),
    pathParts: corporateDemoRequestPathParts,
  };
}

export const corporateDemoRequestCodecs: { input: imported0_CorpDemoPostC, output: imported0_EmptyObjectC } = {
  input: imported0_corpDemoPostC,
  output: imported0_emptyObjectC
};

export const corporateDemoRequest = (): RelativeUrlInterfaceIO<"POST", imported0_CorpDemoPostC, imported0_EmptyObjectC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(corporateDemoRequestRoute().match.formatter.run(Route.empty, {}).toString(true)), corporateDemoRequestCodecs);

const corporateContactRequestPath = lit("corporate").then(lit("contact")).then(lit("request"));
const corporateContactRequestPathParts = ["corporate", "contact", "request"] as const;
export type CorporateContactRequestPathParts = typeof corporateContactRequestPathParts;

const corporateContactRequestQuery = t.strict({});
export type CorporateContactRequestParams = Describe<typeof corporateContactRequestPath._A & typeof corporateContactRequestQuery._A>;

export type CorporateContactRequestRoute<A = {}> = { match: Match<CorporateContactRequestParams & A>; pathParts: CorporateContactRequestPathParts };

export function corporateContactRequestRoute(): CorporateContactRequestRoute;
export function corporateContactRequestRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, CorporateContactRequestParams>): CorporateContactRequestRoute<t.TypeOf<A>>;
export function corporateContactRequestRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, CorporateContactRequestParams>) {
  return {
    match: (q ? corporateContactRequestPath.then(query(t.intersection([corporateContactRequestQuery, q]))) : corporateContactRequestPath.then(query(corporateContactRequestQuery))).then(end),
    pathParts: corporateContactRequestPathParts,
  };
}

export const corporateContactRequestCodecs: { input: imported0_CorpContactPostC, output: imported0_EmptyObjectC } = {
  input: imported0_corpContactPostC,
  output: imported0_emptyObjectC
};

export const corporateContactRequest = (): RelativeUrlInterfaceIO<"POST", imported0_CorpContactPostC, imported0_EmptyObjectC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(corporateContactRequestRoute().match.formatter.run(Route.empty, {}).toString(true)), corporateContactRequestCodecs);

const corporateIssuerRequestPath = lit("corporate").then(lit("search")).then(lit("issuer")).then(lit("request"));
const corporateIssuerRequestPathParts = ["corporate", "search", "issuer", "request"] as const;
export type CorporateIssuerRequestPathParts = typeof corporateIssuerRequestPathParts;

const corporateIssuerRequestQuery = t.strict({});
export type CorporateIssuerRequestParams = Describe<typeof corporateIssuerRequestPath._A & typeof corporateIssuerRequestQuery._A>;

export type CorporateIssuerRequestRoute<A = {}> = { match: Match<CorporateIssuerRequestParams & A>; pathParts: CorporateIssuerRequestPathParts };

export function corporateIssuerRequestRoute(): CorporateIssuerRequestRoute;
export function corporateIssuerRequestRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, CorporateIssuerRequestParams>): CorporateIssuerRequestRoute<t.TypeOf<A>>;
export function corporateIssuerRequestRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, CorporateIssuerRequestParams>) {
  return {
    match: (q ? corporateIssuerRequestPath.then(query(t.intersection([corporateIssuerRequestQuery, q]))) : corporateIssuerRequestPath.then(query(corporateIssuerRequestQuery))).then(end),
    pathParts: corporateIssuerRequestPathParts,
  };
}

export const corporateIssuerRequestCodecs: {
  input: imported0_IssuerSearchRequestPostC,
  output: imported0_EmptyObjectC
} = { input: imported0_issuerSearchRequestPostC, output: imported0_emptyObjectC };

export const corporateIssuerRequest = (): RelativeUrlInterfaceIO<"POST", imported0_IssuerSearchRequestPostC, imported0_EmptyObjectC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(corporateIssuerRequestRoute().match.formatter.run(Route.empty, {}).toString(true)), corporateIssuerRequestCodecs);

const corporateEmailSignupPath = lit("corporate").then(lit("hs-subscribe"));
const corporateEmailSignupPathParts = ["corporate", "hs-subscribe"] as const;
export type CorporateEmailSignupPathParts = typeof corporateEmailSignupPathParts;

const corporateEmailSignupQuery = t.strict({});
export type CorporateEmailSignupParams = Describe<typeof corporateEmailSignupPath._A & typeof corporateEmailSignupQuery._A>;

export type CorporateEmailSignupRoute<A = {}> = { match: Match<CorporateEmailSignupParams & A>; pathParts: CorporateEmailSignupPathParts };

export function corporateEmailSignupRoute(): CorporateEmailSignupRoute;
export function corporateEmailSignupRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, CorporateEmailSignupParams>): CorporateEmailSignupRoute<t.TypeOf<A>>;
export function corporateEmailSignupRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, CorporateEmailSignupParams>) {
  return {
    match: (q ? corporateEmailSignupPath.then(query(t.intersection([corporateEmailSignupQuery, q]))) : corporateEmailSignupPath.then(query(corporateEmailSignupQuery))).then(end),
    pathParts: corporateEmailSignupPathParts,
  };
}

export const corporateEmailSignupCodecs: { input: imported0_CorpEmailSignupPostC, output: imported0_EmptyObjectC } = {
  input: imported0_corpEmailSignupPostC,
  output: imported0_emptyObjectC
};

export const corporateEmailSignup = (): RelativeUrlInterfaceIO<"POST", imported0_CorpEmailSignupPostC, imported0_EmptyObjectC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(corporateEmailSignupRoute().match.formatter.run(Route.empty, {}).toString(true)), corporateEmailSignupCodecs);


const corporateStaticIndexPathParts = [] as const;
export type CorporateStaticIndexPathParts = typeof corporateStaticIndexPathParts;

const corporateStaticIndexQuery = t.strict({});
export type CorporateStaticIndexParams = Describe<typeof corporateStaticIndexQuery._A>;

export type CorporateStaticIndexRoute<A = {}> = { match: Match<CorporateStaticIndexParams & A>; pathParts: CorporateStaticIndexPathParts };

export function corporateStaticIndexRoute(): CorporateStaticIndexRoute;
export function corporateStaticIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, CorporateStaticIndexParams>): CorporateStaticIndexRoute<t.TypeOf<A>>;
export function corporateStaticIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, CorporateStaticIndexParams>) {
  return {
    match: (q ? query(t.intersection([corporateStaticIndexQuery, q])) : query(corporateStaticIndexQuery)).then(end),
    pathParts: corporateStaticIndexPathParts,
  };
}

export const corporateStaticIndex = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(corporateStaticIndexRoute().match.formatter.run(Route.empty, {}).toString(true)));

const corporateStaticTermsPath = lit("terms");
const corporateStaticTermsPathParts = ["terms"] as const;
export type CorporateStaticTermsPathParts = typeof corporateStaticTermsPathParts;

const corporateStaticTermsQuery = t.strict({});
export type CorporateStaticTermsParams = Describe<typeof corporateStaticTermsPath._A & typeof corporateStaticTermsQuery._A>;

export type CorporateStaticTermsRoute<A = {}> = { match: Match<CorporateStaticTermsParams & A>; pathParts: CorporateStaticTermsPathParts };

export function corporateStaticTermsRoute(): CorporateStaticTermsRoute;
export function corporateStaticTermsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, CorporateStaticTermsParams>): CorporateStaticTermsRoute<t.TypeOf<A>>;
export function corporateStaticTermsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, CorporateStaticTermsParams>) {
  return {
    match: (q ? corporateStaticTermsPath.then(query(t.intersection([corporateStaticTermsQuery, q]))) : corporateStaticTermsPath.then(query(corporateStaticTermsQuery))).then(end),
    pathParts: corporateStaticTermsPathParts,
  };
}

export const corporateStaticTerms = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(corporateStaticTermsRoute().match.formatter.run(Route.empty, {}).toString(true)));

const corporateStaticPrivacyPath = lit("privacy");
const corporateStaticPrivacyPathParts = ["privacy"] as const;
export type CorporateStaticPrivacyPathParts = typeof corporateStaticPrivacyPathParts;

const corporateStaticPrivacyQuery = t.strict({});
export type CorporateStaticPrivacyParams = Describe<typeof corporateStaticPrivacyPath._A & typeof corporateStaticPrivacyQuery._A>;

export type CorporateStaticPrivacyRoute<A = {}> = { match: Match<CorporateStaticPrivacyParams & A>; pathParts: CorporateStaticPrivacyPathParts };

export function corporateStaticPrivacyRoute(): CorporateStaticPrivacyRoute;
export function corporateStaticPrivacyRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, CorporateStaticPrivacyParams>): CorporateStaticPrivacyRoute<t.TypeOf<A>>;
export function corporateStaticPrivacyRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, CorporateStaticPrivacyParams>) {
  return {
    match: (q ? corporateStaticPrivacyPath.then(query(t.intersection([corporateStaticPrivacyQuery, q]))) : corporateStaticPrivacyPath.then(query(corporateStaticPrivacyQuery))).then(end),
    pathParts: corporateStaticPrivacyPathParts,
  };
}

export const corporateStaticPrivacy = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(corporateStaticPrivacyRoute().match.formatter.run(Route.empty, {}).toString(true)));

const corporateStaticDisclaimerPath = lit("disclaimer");
const corporateStaticDisclaimerPathParts = ["disclaimer"] as const;
export type CorporateStaticDisclaimerPathParts = typeof corporateStaticDisclaimerPathParts;

const corporateStaticDisclaimerQuery = t.strict({});
export type CorporateStaticDisclaimerParams = Describe<typeof corporateStaticDisclaimerPath._A & typeof corporateStaticDisclaimerQuery._A>;

export type CorporateStaticDisclaimerRoute<A = {}> = { match: Match<CorporateStaticDisclaimerParams & A>; pathParts: CorporateStaticDisclaimerPathParts };

export function corporateStaticDisclaimerRoute(): CorporateStaticDisclaimerRoute;
export function corporateStaticDisclaimerRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, CorporateStaticDisclaimerParams>): CorporateStaticDisclaimerRoute<t.TypeOf<A>>;
export function corporateStaticDisclaimerRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, CorporateStaticDisclaimerParams>) {
  return {
    match: (q ? corporateStaticDisclaimerPath.then(query(t.intersection([corporateStaticDisclaimerQuery, q]))) : corporateStaticDisclaimerPath.then(query(corporateStaticDisclaimerQuery))).then(end),
    pathParts: corporateStaticDisclaimerPathParts,
  };
}

export const corporateStaticDisclaimer = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(corporateStaticDisclaimerRoute().match.formatter.run(Route.empty, {}).toString(true)));

const corporateStaticHubspotLandingPagesPath = lit("corporate").then(lit("hs-landing-pages"));
const corporateStaticHubspotLandingPagesPathParts = ["corporate", "hs-landing-pages"] as const;
export type CorporateStaticHubspotLandingPagesPathParts = typeof corporateStaticHubspotLandingPagesPathParts;

const corporateStaticHubspotLandingPagesQuery = t.strict({});
export type CorporateStaticHubspotLandingPagesParams = Describe<typeof corporateStaticHubspotLandingPagesPath._A & typeof corporateStaticHubspotLandingPagesQuery._A>;

export type CorporateStaticHubspotLandingPagesRoute<A = {}> = { match: Match<CorporateStaticHubspotLandingPagesParams & A>; pathParts: CorporateStaticHubspotLandingPagesPathParts };

export function corporateStaticHubspotLandingPagesRoute(): CorporateStaticHubspotLandingPagesRoute;
export function corporateStaticHubspotLandingPagesRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, CorporateStaticHubspotLandingPagesParams>): CorporateStaticHubspotLandingPagesRoute<t.TypeOf<A>>;
export function corporateStaticHubspotLandingPagesRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, CorporateStaticHubspotLandingPagesParams>) {
  return {
    match: (q ? corporateStaticHubspotLandingPagesPath.then(query(t.intersection([corporateStaticHubspotLandingPagesQuery, q]))) : corporateStaticHubspotLandingPagesPath.then(query(corporateStaticHubspotLandingPagesQuery))).then(end),
    pathParts: corporateStaticHubspotLandingPagesPathParts,
  };
}

export const corporateStaticHubspotLandingPages = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(corporateStaticHubspotLandingPagesRoute().match.formatter.run(Route.empty, {}).toString(true)));

const corporateStaticHubspotBlogPagesPath = lit("corporate").then(lit("hs-blog-pages"));
const corporateStaticHubspotBlogPagesPathParts = ["corporate", "hs-blog-pages"] as const;
export type CorporateStaticHubspotBlogPagesPathParts = typeof corporateStaticHubspotBlogPagesPathParts;

const corporateStaticHubspotBlogPagesQuery = t.intersection([  t.exact(t.type({
                                                                 "limit": NumberFromString
                                                               })), t.exact(t.partial({
                                                                 "after": t.string,
                                                                 "state": t.string
                                                               }))]);;
export type CorporateStaticHubspotBlogPagesParamsRaw = Describe<typeof corporateStaticHubspotBlogPagesPath._A & typeof corporateStaticHubspotBlogPagesQuery._A>;
export type CorporateStaticHubspotBlogPagesParams = Describe<NullableToOptionalFields<CorporateStaticHubspotBlogPagesParamsRaw>>;

export type CorporateStaticHubspotBlogPagesRoute<A = {}> = { match: Match<CorporateStaticHubspotBlogPagesParams & A>; pathParts: CorporateStaticHubspotBlogPagesPathParts };

export function corporateStaticHubspotBlogPagesRoute(): CorporateStaticHubspotBlogPagesRoute;
export function corporateStaticHubspotBlogPagesRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, CorporateStaticHubspotBlogPagesParamsRaw>): CorporateStaticHubspotBlogPagesRoute<t.TypeOf<A>>;
export function corporateStaticHubspotBlogPagesRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, CorporateStaticHubspotBlogPagesParamsRaw>) {
  return {
    match: (q ? corporateStaticHubspotBlogPagesPath.then(query(t.intersection([corporateStaticHubspotBlogPagesQuery, q]))) : corporateStaticHubspotBlogPagesPath.then(query(corporateStaticHubspotBlogPagesQuery))).then(end).imap(
      (x: CorporateStaticHubspotBlogPagesParamsRaw & t.TypeOf<A>): CorporateStaticHubspotBlogPagesParams & t.TypeOf<A> => ({ ...x, after: O.fromNullable(x.after), state: O.fromNullable(x.state) }),
      (x: CorporateStaticHubspotBlogPagesParams & t.TypeOf<A>): CorporateStaticHubspotBlogPagesParamsRaw & t.TypeOf<A> => ({ ...x, after: O.toUndefined(x.after), state: O.toUndefined(x.state) })
    ),
    pathParts: corporateStaticHubspotBlogPagesPathParts,
  };
}

export const corporateStaticHubspotBlogPages = (p: CorporateStaticHubspotBlogPagesParams): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(corporateStaticHubspotBlogPagesRoute().match.formatter.run(Route.empty, p).toString(true)));