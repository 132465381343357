import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const general = {
  _tag: `General`,
  id: 1,
  showName: `general-disclaimer`,
  typeName: `general`
} as const;

export type GeneralTaggedC = t.TypeC<{
  _tag: t.LiteralC<`General`>
}>;
export const generalTaggedC: GeneralTaggedC = t.type({
  _tag: t.literal(`General`)
});
export type GeneralTagged = t.TypeOf<GeneralTaggedC>;
export type General = GeneralTagged & typeof general;
export type GeneralC = t.Type<General, GeneralTagged>;
export const generalC: GeneralC = pipe(generalTaggedC, c => new t.Type<General, GeneralTagged>(
  `General`,
  (u: unknown): u is General => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, General> => pipe(c.decode(u), E.map(x => ({ ...x, ...general }))),
  (x: General): GeneralTagged => ({ ...x, _tag: `General`}),
)) satisfies t.Type<General, unknown>;


export const offering = {
  _tag: `Offering`,
  id: 2,
  showName: `offering-disclaimer`,
  typeName: `offering`
} as const;

export type OfferingTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Offering`>
}>;
export const offeringTaggedC: OfferingTaggedC = t.type({
  _tag: t.literal(`Offering`)
});
export type OfferingTagged = t.TypeOf<OfferingTaggedC>;
export type Offering = OfferingTagged & typeof offering;
export type OfferingC = t.Type<Offering, OfferingTagged>;
export const offeringC: OfferingC = pipe(offeringTaggedC, c => new t.Type<Offering, OfferingTagged>(
  `Offering`,
  (u: unknown): u is Offering => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Offering> => pipe(c.decode(u), E.map(x => ({ ...x, ...offering }))),
  (x: Offering): OfferingTagged => ({ ...x, _tag: `Offering`}),
)) satisfies t.Type<Offering, unknown>;


export const exit = {
  _tag: `Exit`,
  id: 3,
  showName: `exit-disclaimer`,
  typeName: `exit`
} as const;

export type ExitTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Exit`>
}>;
export const exitTaggedC: ExitTaggedC = t.type({
  _tag: t.literal(`Exit`)
});
export type ExitTagged = t.TypeOf<ExitTaggedC>;
export type Exit = ExitTagged & typeof exit;
export type ExitC = t.Type<Exit, ExitTagged>;
export const exitC: ExitC = pipe(exitTaggedC, c => new t.Type<Exit, ExitTagged>(
  `Exit`,
  (u: unknown): u is Exit => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Exit> => pipe(c.decode(u), E.map(x => ({ ...x, ...exit }))),
  (x: Exit): ExitTagged => ({ ...x, _tag: `Exit`}),
)) satisfies t.Type<Exit, unknown>;


export const document = {
  _tag: `Document`,
  id: 4,
  showName: `document-disclaimer`,
  typeName: `document`
} as const;

export type DocumentTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Document`>
}>;
export const documentTaggedC: DocumentTaggedC = t.type({
  _tag: t.literal(`Document`)
});
export type DocumentTagged = t.TypeOf<DocumentTaggedC>;
export type Document = DocumentTagged & typeof document;
export type DocumentC = t.Type<Document, DocumentTagged>;
export const documentC: DocumentC = pipe(documentTaggedC, c => new t.Type<Document, DocumentTagged>(
  `Document`,
  (u: unknown): u is Document => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Document> => pipe(c.decode(u), E.map(x => ({ ...x, ...document }))),
  (x: Document): DocumentTagged => ({ ...x, _tag: `Document`}),
)) satisfies t.Type<Document, unknown>;


export const allDisclaimerTypeC = [generalC, offeringC, exitC, documentC] as const;
export const allDisclaimerTypeNames = [`General`, `Offering`, `Exit`, `Document`] as const;
export type DisclaimerTypeName = (typeof allDisclaimerTypeNames)[number];

export type DisclaimerTypeCU = t.UnionC<[GeneralC, OfferingC, ExitC, DocumentC]>;
export type DisclaimerTypeU = General | Offering | Exit | Document;
export const DisclaimerTypeCU: DisclaimerTypeCU = t.union([generalC, offeringC, exitC, documentC]) satisfies t.Type<DisclaimerTypeU, unknown>;

export const disclaimerTypeOrd: Ord.Ord<DisclaimerTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allDisclaimerType = [general, offering, exit, document] as const;
export type DisclaimerTypeMap<A> = { [K in DisclaimerTypeName]: A };


