import type { EqualityFn, NoInfer, UseDispatch, UseSelector } from "react-redux";
import { useDispatch, useSelector } from "react-redux"; // eslint-disable-line @typescript-eslint/no-restricted-imports
import { configureStore } from "@reduxjs/toolkit";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as t from "io-ts";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import type { Dispatch, Reducer, Store } from "redux";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { flashC } from "@scripts/generated/models/flash";
import { userWithRolesC } from "@scripts/generated/models/user";
import { type FlashAction, flashReducer } from "@scripts/react/state/flash";
import type { NotificationAction, NotificationStore } from "@scripts/react/state/notifications";
import { emptyNotificationStore, notificationReducer } from "@scripts/react/state/notifications";
import { isDevtoolAction, isFlashAction, isNotificationAction, isReduxAction, isUserAction } from "@scripts/react/state/store";
import type { UserAction } from "@scripts/react/state/user";
import { userReducer } from "@scripts/react/state/user";

type PagesActions = NotificationAction | UserAction | FlashAction;

export const pagesInitialStateC = t.type({
  flash: t.readonlyArray(flashC),
  user: optionFromNullable(userWithRolesC),
});
type PagesInitialStateC = typeof pagesInitialStateC;
export type PagesInitialState = t.TypeOf<PagesInitialStateC>;

type PagesState = PagesInitialState & {
  notifications: NotificationStore;
};

const emptyState = (initialState: PagesInitialState): PagesState => ({
  ...initialState,
  notifications: emptyNotificationStore,
});

const reducers = (config: BLConfigWithLog) =>
  (initialState: PagesInitialState): Reducer<PagesState, PagesActions> =>
    (state: PagesState = emptyState(initialState), action: PagesActions): PagesState => {
      if (isFlashAction(action)) {
        return {
          ...state,
          flash: flashReducer(config)(state.flash, action),
        };
      } else if (isNotificationAction(action)) {
        return {
          ...state,
          notifications: notificationReducer(config)(state.notifications, action),
        };
      } else if (isUserAction(action)) {
        return {
          ...state,
          user: pipe(state.user, O.map(u => userReducer(config)(u, action))),
        };
      } else if (isReduxAction(action) || isDevtoolAction(action)) {
        return state;
      }
      return config.exhaustive(action);
    };

type PagesStore = Store<PagesState, PagesActions>;

export const createPagesStore = (config: BLConfigWithLog) =>
  (initialState: PagesInitialState): PagesStore =>
    configureStore({
      reducer: reducers(config)(initialState),
      preloadedState: emptyState(initialState),
      middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
      }),
      devTools: {
        name: "Pages",
        serialize: true,
      },
    });

const usePagesSelectorUnsafe: UseSelector<PagesState> = useSelector;
export const usePagesSelector = <K extends keyof PagesState>(
  k: K,
  equalityFn?: EqualityFn<NoInfer<PagesState[K]>>
): PagesState[K] => usePagesSelectorUnsafe((_): PagesState[K] => _[k], equalityFn);

type PagesDispatch = Dispatch<PagesActions>;
export const usePagesDispatch: UseDispatch<PagesDispatch> = useDispatch;
