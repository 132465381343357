import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const downloadDoc = {
  _tag: `DownloadDoc`,
  machineReadable: `download-doc`
} as const;

export type DownloadDocTaggedC = t.TypeC<{
  _tag: t.LiteralC<`DownloadDoc`>
}>;
export const downloadDocTaggedC: DownloadDocTaggedC = t.type({
  _tag: t.literal(`DownloadDoc`)
});
export type DownloadDocTagged = t.TypeOf<DownloadDocTaggedC>;
export type DownloadDoc = DownloadDocTagged & typeof downloadDoc;
export type DownloadDocC = t.Type<DownloadDoc, DownloadDocTagged>;
export const downloadDocC: DownloadDocC = pipe(downloadDocTaggedC, c => new t.Type<DownloadDoc, DownloadDocTagged>(
  `DownloadDoc`,
  (u: unknown): u is DownloadDoc => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DownloadDoc> => pipe(c.decode(u), E.map(x => ({ ...x, ...downloadDoc }))),
  (x: DownloadDoc): DownloadDocTagged => ({ ...x, _tag: `DownloadDoc`}),
)) satisfies t.Type<DownloadDoc, unknown>;


export const viewDoc = {
  _tag: `ViewDoc`,
  machineReadable: `view-doc`
} as const;

export type ViewDocTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ViewDoc`>
}>;
export const viewDocTaggedC: ViewDocTaggedC = t.type({
  _tag: t.literal(`ViewDoc`)
});
export type ViewDocTagged = t.TypeOf<ViewDocTaggedC>;
export type ViewDoc = ViewDocTagged & typeof viewDoc;
export type ViewDocC = t.Type<ViewDoc, ViewDocTagged>;
export const viewDocC: ViewDocC = pipe(viewDocTaggedC, c => new t.Type<ViewDoc, ViewDocTagged>(
  `ViewDoc`,
  (u: unknown): u is ViewDoc => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ViewDoc> => pipe(c.decode(u), E.map(x => ({ ...x, ...viewDoc }))),
  (x: ViewDoc): ViewDocTagged => ({ ...x, _tag: `ViewDoc`}),
)) satisfies t.Type<ViewDoc, unknown>;


export const viewBond = {
  _tag: `ViewBond`,
  machineReadable: `view-bond`
} as const;

export type ViewBondTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ViewBond`>
}>;
export const viewBondTaggedC: ViewBondTaggedC = t.type({
  _tag: t.literal(`ViewBond`)
});
export type ViewBondTagged = t.TypeOf<ViewBondTaggedC>;
export type ViewBond = ViewBondTagged & typeof viewBond;
export type ViewBondC = t.Type<ViewBond, ViewBondTagged>;
export const viewBondC: ViewBondC = pipe(viewBondTaggedC, c => new t.Type<ViewBond, ViewBondTagged>(
  `ViewBond`,
  (u: unknown): u is ViewBond => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ViewBond> => pipe(c.decode(u), E.map(x => ({ ...x, ...viewBond }))),
  (x: ViewBond): ViewBondTagged => ({ ...x, _tag: `ViewBond`}),
)) satisfies t.Type<ViewBond, unknown>;


export const bookmarkBond = {
  _tag: `BookmarkBond`,
  machineReadable: `bookmark-bond`
} as const;

export type BookmarkBondTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BookmarkBond`>
}>;
export const bookmarkBondTaggedC: BookmarkBondTaggedC = t.type({
  _tag: t.literal(`BookmarkBond`)
});
export type BookmarkBondTagged = t.TypeOf<BookmarkBondTaggedC>;
export type BookmarkBond = BookmarkBondTagged & typeof bookmarkBond;
export type BookmarkBondC = t.Type<BookmarkBond, BookmarkBondTagged>;
export const bookmarkBondC: BookmarkBondC = pipe(bookmarkBondTaggedC, c => new t.Type<BookmarkBond, BookmarkBondTagged>(
  `BookmarkBond`,
  (u: unknown): u is BookmarkBond => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BookmarkBond> => pipe(c.decode(u), E.map(x => ({ ...x, ...bookmarkBond }))),
  (x: BookmarkBond): BookmarkBondTagged => ({ ...x, _tag: `BookmarkBond`}),
)) satisfies t.Type<BookmarkBond, unknown>;


export const bookmarkRfp = {
  _tag: `BookmarkRfp`,
  machineReadable: `bookmark-rfp`
} as const;

export type BookmarkRfpTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BookmarkRfp`>
}>;
export const bookmarkRfpTaggedC: BookmarkRfpTaggedC = t.type({
  _tag: t.literal(`BookmarkRfp`)
});
export type BookmarkRfpTagged = t.TypeOf<BookmarkRfpTaggedC>;
export type BookmarkRfp = BookmarkRfpTagged & typeof bookmarkRfp;
export type BookmarkRfpC = t.Type<BookmarkRfp, BookmarkRfpTagged>;
export const bookmarkRfpC: BookmarkRfpC = pipe(bookmarkRfpTaggedC, c => new t.Type<BookmarkRfp, BookmarkRfpTagged>(
  `BookmarkRfp`,
  (u: unknown): u is BookmarkRfp => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BookmarkRfp> => pipe(c.decode(u), E.map(x => ({ ...x, ...bookmarkRfp }))),
  (x: BookmarkRfp): BookmarkRfpTagged => ({ ...x, _tag: `BookmarkRfp`}),
)) satisfies t.Type<BookmarkRfp, unknown>;


export const roadshow = {
  _tag: `Roadshow`,
  machineReadable: `roadshow`
} as const;

export type RoadshowTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Roadshow`>
}>;
export const roadshowTaggedC: RoadshowTaggedC = t.type({
  _tag: t.literal(`Roadshow`)
});
export type RoadshowTagged = t.TypeOf<RoadshowTaggedC>;
export type Roadshow = RoadshowTagged & typeof roadshow;
export type RoadshowC = t.Type<Roadshow, RoadshowTagged>;
export const roadshowC: RoadshowC = pipe(roadshowTaggedC, c => new t.Type<Roadshow, RoadshowTagged>(
  `Roadshow`,
  (u: unknown): u is Roadshow => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Roadshow> => pipe(c.decode(u), E.map(x => ({ ...x, ...roadshow }))),
  (x: Roadshow): RoadshowTagged => ({ ...x, _tag: `Roadshow`}),
)) satisfies t.Type<Roadshow, unknown>;


export const notifications = {
  _tag: `Notifications`,
  machineReadable: `notifications`
} as const;

export type NotificationsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Notifications`>
}>;
export const notificationsTaggedC: NotificationsTaggedC = t.type({
  _tag: t.literal(`Notifications`)
});
export type NotificationsTagged = t.TypeOf<NotificationsTaggedC>;
export type Notifications = NotificationsTagged & typeof notifications;
export type NotificationsC = t.Type<Notifications, NotificationsTagged>;
export const notificationsC: NotificationsC = pipe(notificationsTaggedC, c => new t.Type<Notifications, NotificationsTagged>(
  `Notifications`,
  (u: unknown): u is Notifications => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Notifications> => pipe(c.decode(u), E.map(x => ({ ...x, ...notifications }))),
  (x: Notifications): NotificationsTagged => ({ ...x, _tag: `Notifications`}),
)) satisfies t.Type<Notifications, unknown>;


export const submitRfpBid = {
  _tag: `SubmitRfpBid`,
  machineReadable: `submit-rfp-bid`
} as const;

export type SubmitRfpBidTaggedC = t.TypeC<{
  _tag: t.LiteralC<`SubmitRfpBid`>
}>;
export const submitRfpBidTaggedC: SubmitRfpBidTaggedC = t.type({
  _tag: t.literal(`SubmitRfpBid`)
});
export type SubmitRfpBidTagged = t.TypeOf<SubmitRfpBidTaggedC>;
export type SubmitRfpBid = SubmitRfpBidTagged & typeof submitRfpBid;
export type SubmitRfpBidC = t.Type<SubmitRfpBid, SubmitRfpBidTagged>;
export const submitRfpBidC: SubmitRfpBidC = pipe(submitRfpBidTaggedC, c => new t.Type<SubmitRfpBid, SubmitRfpBidTagged>(
  `SubmitRfpBid`,
  (u: unknown): u is SubmitRfpBid => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SubmitRfpBid> => pipe(c.decode(u), E.map(x => ({ ...x, ...submitRfpBid }))),
  (x: SubmitRfpBid): SubmitRfpBidTagged => ({ ...x, _tag: `SubmitRfpBid`}),
)) satisfies t.Type<SubmitRfpBid, unknown>;


export const allLoginReasonC = [downloadDocC, viewDocC, viewBondC, bookmarkBondC, bookmarkRfpC, roadshowC, notificationsC, submitRfpBidC] as const;
export const allLoginReasonNames = [`DownloadDoc`, `ViewDoc`, `ViewBond`, `BookmarkBond`, `BookmarkRfp`, `Roadshow`, `Notifications`, `SubmitRfpBid`] as const;
export type LoginReasonName = (typeof allLoginReasonNames)[number];

export type LoginReasonCU = t.UnionC<[DownloadDocC, ViewDocC, ViewBondC, BookmarkBondC, BookmarkRfpC, RoadshowC, NotificationsC, SubmitRfpBidC]>;
export type LoginReasonU = DownloadDoc | ViewDoc | ViewBond | BookmarkBond | BookmarkRfp | Roadshow | Notifications | SubmitRfpBid;
export const LoginReasonCU: LoginReasonCU = t.union([downloadDocC, viewDocC, viewBondC, bookmarkBondC, bookmarkRfpC, roadshowC, notificationsC, submitRfpBidC]) satisfies t.Type<LoginReasonU, unknown>;

export const loginReasonOrd: Ord.Ord<LoginReasonU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allLoginReason = [downloadDoc, viewDoc, viewBond, bookmarkBond, bookmarkRfp, roadshow, notifications, submitRfpBid] as const;
export type LoginReasonMap<A> = { [K in LoginReasonName]: A };


