import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const fundSlashETF = {
  _tag: `FundSlashETF`,
  id: 1,
  name: `Fund / ETF (Exchange-Traded Fund)`
} as const;

export type FundSlashETFTaggedC = t.TypeC<{
  _tag: t.LiteralC<`FundSlashETF`>
}>;
export const fundSlashETFTaggedC: FundSlashETFTaggedC = t.type({
  _tag: t.literal(`FundSlashETF`)
});
export type FundSlashETFTagged = t.TypeOf<FundSlashETFTaggedC>;
export type FundSlashETF = FundSlashETFTagged & typeof fundSlashETF;
export type FundSlashETFC = t.Type<FundSlashETF, FundSlashETFTagged>;
export const fundSlashETFC: FundSlashETFC = pipe(fundSlashETFTaggedC, c => new t.Type<FundSlashETF, FundSlashETFTagged>(
  `FundSlashETF`,
  (u: unknown): u is FundSlashETF => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FundSlashETF> => pipe(c.decode(u), E.map(x => ({ ...x, ...fundSlashETF }))),
  (x: FundSlashETF): FundSlashETFTagged => ({ ...x, _tag: `FundSlashETF`}),
)) satisfies t.Type<FundSlashETF, unknown>;


export const SMA = {
  _tag: `SMA`,
  id: 2,
  name: `SMA (Separately Managed Accounts)`
} as const;

export type SMATaggedC = t.TypeC<{
  _tag: t.LiteralC<`SMA`>
}>;
export const SMATaggedC: SMATaggedC = t.type({
  _tag: t.literal(`SMA`)
});
export type SMATagged = t.TypeOf<SMATaggedC>;
export type SMA = SMATagged & typeof SMA;
export type SMAC = t.Type<SMA, SMATagged>;
export const SMAC: SMAC = pipe(SMATaggedC, c => new t.Type<SMA, SMATagged>(
  `SMA`,
  (u: unknown): u is SMA => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SMA> => pipe(c.decode(u), E.map(x => ({ ...x, ...SMA }))),
  (x: SMA): SMATagged => ({ ...x, _tag: `SMA`}),
)) satisfies t.Type<SMA, unknown>;


export const insurance = {
  _tag: `Insurance`,
  id: 3,
  name: `Insurance`
} as const;

export type InsuranceTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Insurance`>
}>;
export const insuranceTaggedC: InsuranceTaggedC = t.type({
  _tag: t.literal(`Insurance`)
});
export type InsuranceTagged = t.TypeOf<InsuranceTaggedC>;
export type Insurance = InsuranceTagged & typeof insurance;
export type InsuranceC = t.Type<Insurance, InsuranceTagged>;
export const insuranceC: InsuranceC = pipe(insuranceTaggedC, c => new t.Type<Insurance, InsuranceTagged>(
  `Insurance`,
  (u: unknown): u is Insurance => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Insurance> => pipe(c.decode(u), E.map(x => ({ ...x, ...insurance }))),
  (x: Insurance): InsuranceTagged => ({ ...x, _tag: `Insurance`}),
)) satisfies t.Type<Insurance, unknown>;


export const bankPortfolio = {
  _tag: `BankPortfolio`,
  id: 4,
  name: `Bank Portfolio`
} as const;

export type BankPortfolioTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BankPortfolio`>
}>;
export const bankPortfolioTaggedC: BankPortfolioTaggedC = t.type({
  _tag: t.literal(`BankPortfolio`)
});
export type BankPortfolioTagged = t.TypeOf<BankPortfolioTaggedC>;
export type BankPortfolio = BankPortfolioTagged & typeof bankPortfolio;
export type BankPortfolioC = t.Type<BankPortfolio, BankPortfolioTagged>;
export const bankPortfolioC: BankPortfolioC = pipe(bankPortfolioTaggedC, c => new t.Type<BankPortfolio, BankPortfolioTagged>(
  `BankPortfolio`,
  (u: unknown): u is BankPortfolio => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BankPortfolio> => pipe(c.decode(u), E.map(x => ({ ...x, ...bankPortfolio }))),
  (x: BankPortfolio): BankPortfolioTagged => ({ ...x, _tag: `BankPortfolio`}),
)) satisfies t.Type<BankPortfolio, unknown>;


export const esgGreenBonds = {
  _tag: `EsgGreenBonds`,
  id: 5,
  name: `ESG / Green Bonds`
} as const;

export type EsgGreenBondsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EsgGreenBonds`>
}>;
export const esgGreenBondsTaggedC: EsgGreenBondsTaggedC = t.type({
  _tag: t.literal(`EsgGreenBonds`)
});
export type EsgGreenBondsTagged = t.TypeOf<EsgGreenBondsTaggedC>;
export type EsgGreenBonds = EsgGreenBondsTagged & typeof esgGreenBonds;
export type EsgGreenBondsC = t.Type<EsgGreenBonds, EsgGreenBondsTagged>;
export const esgGreenBondsC: EsgGreenBondsC = pipe(esgGreenBondsTaggedC, c => new t.Type<EsgGreenBonds, EsgGreenBondsTagged>(
  `EsgGreenBonds`,
  (u: unknown): u is EsgGreenBonds => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EsgGreenBonds> => pipe(c.decode(u), E.map(x => ({ ...x, ...esgGreenBonds }))),
  (x: EsgGreenBonds): EsgGreenBondsTagged => ({ ...x, _tag: `EsgGreenBonds`}),
)) satisfies t.Type<EsgGreenBonds, unknown>;


export const highYield = {
  _tag: `HighYield`,
  id: 6,
  name: `High Yield`
} as const;

export type HighYieldTaggedC = t.TypeC<{
  _tag: t.LiteralC<`HighYield`>
}>;
export const highYieldTaggedC: HighYieldTaggedC = t.type({
  _tag: t.literal(`HighYield`)
});
export type HighYieldTagged = t.TypeOf<HighYieldTaggedC>;
export type HighYield = HighYieldTagged & typeof highYield;
export type HighYieldC = t.Type<HighYield, HighYieldTagged>;
export const highYieldC: HighYieldC = pipe(highYieldTaggedC, c => new t.Type<HighYield, HighYieldTagged>(
  `HighYield`,
  (u: unknown): u is HighYield => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, HighYield> => pipe(c.decode(u), E.map(x => ({ ...x, ...highYield }))),
  (x: HighYield): HighYieldTagged => ({ ...x, _tag: `HighYield`}),
)) satisfies t.Type<HighYield, unknown>;


export const other = {
  _tag: `Other`,
  id: 7,
  name: `Other (Pension, Foundation, Endowment, Government)`
} as const;

export type OtherTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Other`>
}>;
export const otherTaggedC: OtherTaggedC = t.type({
  _tag: t.literal(`Other`)
});
export type OtherTagged = t.TypeOf<OtherTaggedC>;
export type Other = OtherTagged & typeof other;
export type OtherC = t.Type<Other, OtherTagged>;
export const otherC: OtherC = pipe(otherTaggedC, c => new t.Type<Other, OtherTagged>(
  `Other`,
  (u: unknown): u is Other => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Other> => pipe(c.decode(u), E.map(x => ({ ...x, ...other }))),
  (x: Other): OtherTagged => ({ ...x, _tag: `Other`}),
)) satisfies t.Type<Other, unknown>;


export const privateSlashCommercialLoans = {
  _tag: `PrivateSlashCommercialLoans`,
  id: 8,
  name: `Private / Commercial Loans`
} as const;

export type PrivateSlashCommercialLoansTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PrivateSlashCommercialLoans`>
}>;
export const privateSlashCommercialLoansTaggedC: PrivateSlashCommercialLoansTaggedC = t.type({
  _tag: t.literal(`PrivateSlashCommercialLoans`)
});
export type PrivateSlashCommercialLoansTagged = t.TypeOf<PrivateSlashCommercialLoansTaggedC>;
export type PrivateSlashCommercialLoans = PrivateSlashCommercialLoansTagged & typeof privateSlashCommercialLoans;
export type PrivateSlashCommercialLoansC = t.Type<PrivateSlashCommercialLoans, PrivateSlashCommercialLoansTagged>;
export const privateSlashCommercialLoansC: PrivateSlashCommercialLoansC = pipe(privateSlashCommercialLoansTaggedC, c => new t.Type<PrivateSlashCommercialLoans, PrivateSlashCommercialLoansTagged>(
  `PrivateSlashCommercialLoans`,
  (u: unknown): u is PrivateSlashCommercialLoans => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PrivateSlashCommercialLoans> => pipe(c.decode(u), E.map(x => ({ ...x, ...privateSlashCommercialLoans }))),
  (x: PrivateSlashCommercialLoans): PrivateSlashCommercialLoansTagged => ({ ...x, _tag: `PrivateSlashCommercialLoans`}),
)) satisfies t.Type<PrivateSlashCommercialLoans, unknown>;


export const commercialLinesOfCredit = {
  _tag: `CommercialLinesOfCredit`,
  id: 9,
  name: `Commercial Lines of Credit`
} as const;

export type CommercialLinesOfCreditTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CommercialLinesOfCredit`>
}>;
export const commercialLinesOfCreditTaggedC: CommercialLinesOfCreditTaggedC = t.type({
  _tag: t.literal(`CommercialLinesOfCredit`)
});
export type CommercialLinesOfCreditTagged = t.TypeOf<CommercialLinesOfCreditTaggedC>;
export type CommercialLinesOfCredit = CommercialLinesOfCreditTagged & typeof commercialLinesOfCredit;
export type CommercialLinesOfCreditC = t.Type<CommercialLinesOfCredit, CommercialLinesOfCreditTagged>;
export const commercialLinesOfCreditC: CommercialLinesOfCreditC = pipe(commercialLinesOfCreditTaggedC, c => new t.Type<CommercialLinesOfCredit, CommercialLinesOfCreditTagged>(
  `CommercialLinesOfCredit`,
  (u: unknown): u is CommercialLinesOfCredit => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CommercialLinesOfCredit> => pipe(c.decode(u), E.map(x => ({ ...x, ...commercialLinesOfCredit }))),
  (x: CommercialLinesOfCredit): CommercialLinesOfCreditTagged => ({ ...x, _tag: `CommercialLinesOfCredit`}),
)) satisfies t.Type<CommercialLinesOfCredit, unknown>;


export const financialServices = {
  _tag: `FinancialServices`,
  id: 10,
  name: `Financial Services (Underwriting, Advisory, Counsel, Banking)`
} as const;

export type FinancialServicesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`FinancialServices`>
}>;
export const financialServicesTaggedC: FinancialServicesTaggedC = t.type({
  _tag: t.literal(`FinancialServices`)
});
export type FinancialServicesTagged = t.TypeOf<FinancialServicesTaggedC>;
export type FinancialServices = FinancialServicesTagged & typeof financialServices;
export type FinancialServicesC = t.Type<FinancialServices, FinancialServicesTagged>;
export const financialServicesC: FinancialServicesC = pipe(financialServicesTaggedC, c => new t.Type<FinancialServices, FinancialServicesTagged>(
  `FinancialServices`,
  (u: unknown): u is FinancialServices => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FinancialServices> => pipe(c.decode(u), E.map(x => ({ ...x, ...financialServices }))),
  (x: FinancialServices): FinancialServicesTagged => ({ ...x, _tag: `FinancialServices`}),
)) satisfies t.Type<FinancialServices, unknown>;


export const allInvestorFocusC = [fundSlashETFC, SMAC, insuranceC, bankPortfolioC, esgGreenBondsC, highYieldC, otherC, privateSlashCommercialLoansC, commercialLinesOfCreditC, financialServicesC] as const;
export const allInvestorFocusNames = [`FundSlashETF`, `SMA`, `Insurance`, `BankPortfolio`, `EsgGreenBonds`, `HighYield`, `Other`, `PrivateSlashCommercialLoans`, `CommercialLinesOfCredit`, `FinancialServices`] as const;
export type InvestorFocusName = (typeof allInvestorFocusNames)[number];

export type InvestorFocusCU = t.UnionC<[FundSlashETFC, SMAC, InsuranceC, BankPortfolioC, EsgGreenBondsC, HighYieldC, OtherC, PrivateSlashCommercialLoansC, CommercialLinesOfCreditC, FinancialServicesC]>;
export type InvestorFocusU = FundSlashETF | SMA | Insurance | BankPortfolio | EsgGreenBonds | HighYield | Other | PrivateSlashCommercialLoans | CommercialLinesOfCredit | FinancialServices;
export const InvestorFocusCU: InvestorFocusCU = t.union([fundSlashETFC, SMAC, insuranceC, bankPortfolioC, esgGreenBondsC, highYieldC, otherC, privateSlashCommercialLoansC, commercialLinesOfCreditC, financialServicesC]) satisfies t.Type<InvestorFocusU, unknown>;

export const investorFocusOrd: Ord.Ord<InvestorFocusU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allInvestorFocus = [fundSlashETF, SMA, insurance, bankPortfolio, esgGreenBonds, highYield, other, privateSlashCommercialLoans, commercialLinesOfCredit, financialServices] as const;
export type InvestorFocusMap<A> = { [K in InvestorFocusName]: A };


