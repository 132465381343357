import { type ReactNode } from "react";

import { NotificationPortal } from "@scripts/react/components/layout/Notifications";

import { useBootstrap } from "@scripts-ssr/client/bootstrap";
import { SitesAlerts } from "@scripts-ssr/components/Alert";

import { usePagesDispatch, usePagesSelector } from "../state/store";

type PageProps = {
  children: ReactNode;
};

export const Page = (props: PageProps) => {
  const dispatch = usePagesDispatch();
  const notifications = usePagesSelector("notifications").notifications;
  const flash = usePagesSelector("flash");

  useBootstrap([], dispatch, "Portals", "User");

  return <>
    <SitesAlerts flash={flash} dispatch={dispatch} />
    {props.children}
    <NotificationPortal notifications={notifications} dispatch={dispatch} />
  </>;
};
