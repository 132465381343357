import { type JSX, type ReactElement } from "react";

import type { CommonOrganizations } from "@scripts/api/commonOrganizations";
import { E, O, Ord, pipe, RA } from "@scripts/fp-ts";
import { useConfig } from "@scripts/react/context/Config";
import { type DataCodec, getValErrViaCodec } from "@scripts/react/form/form";
import type { NonOption } from "@scripts/util/nonOption";
import { organization } from "@scripts/util/organization";
import { caseInsensitiveOrd } from "@scripts/util/string";

import { SelectCreatable, type SelectOption, type SelectProps } from "./Select";

type OrganizationAutocompleteProps<PC extends DataCodec, KV, InnerKV = KV> = Pick<SelectProps<PC, KV, InnerKV>, "disabled"
  | "lens"
  | "placeholder"
  | "state"
  | "setState"
  | "codec"> & {
    organizations: CommonOrganizations;
  };

const formatCreateLabel = (value: string) => `Use “${value}”`;

export function OrganizationAutocomplete<PC extends DataCodec, InnerKV>(props: OrganizationAutocompleteProps<PC, O.Option<InnerKV>, InnerKV>): JSX.Element;
export function OrganizationAutocomplete<PC extends DataCodec, KV>(props: OrganizationAutocompleteProps<PC, NonOption<KV>>): JSX.Element;
export function OrganizationAutocomplete<PC extends DataCodec, KV>(props: OrganizationAutocompleteProps<PC, KV>): ReactElement {
  const config = useConfig();
  const ve = getValErrViaCodec(config)(props.state, props.lens, props.codec);

  const commonOrganizationOptions: ReadonlyArray<SelectOption<string>> = pipe(
    props.organizations,
    RA.map(org => ({
      label: org,
      value: org,
    }))
  );

  const allOrganizationOptions = pipe(
    ve.val,
    O.map((existingOrg) => pipe(
      props.organizations,
      RA.findFirst(org => org === existingOrg),
      O.fold(
        () => RA.concat([{ label: existingOrg, value: existingOrg }])(commonOrganizationOptions),
        () => commonOrganizationOptions,
      )
    )),
    O.getOrElse(() => commonOrganizationOptions),
    RA.sort(Ord.contramap((option: SelectOption<string>) => option.value)(caseInsensitiveOrd)),
  );

  /* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions */
  return <SelectCreatable
    codec={props.codec as any}
    disabled={props.disabled}
    formatCreateLabel={formatCreateLabel}
    isClearable={true}
    isSearchable={true}
    labelOrAriaLabel={E.left(organization)}
    lens={props.lens as any}
    maxOptionsShowing={20}
    options={allOrganizationOptions}
    placeholder={props.placeholder}
    requireInputToOpen
    resetOptions={commonOrganizationOptions}
    setState={props.setState}
    state={props.state}
  />;
  /* eslint-enable @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions */
}
