import type { PropsWithChildren, ReactElement, ReactNode, Ref, RefObject } from "react";
import { useEffect, useRef, useState } from "react";
import * as b from "fp-ts/lib/boolean";
import * as E from "fp-ts/lib/Either";
import * as Eq from "fp-ts/lib/Eq";
import { constFalse, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as Th from "fp-ts/lib/These";
import { useStableEffect } from "fp-ts-react-stable-hooks";
import type { Dispatch } from "redux";

import type { ApiData, ErrorHandlerApiReq } from "@scripts/api/methods";
import type { RespOrErrors } from "@scripts/fetch";
import { useConfig } from "@scripts/react/context/Config";
import type { NotificationAction } from "@scripts/react/state/notifications";
import { notificationAdd } from "@scripts/react/state/notifications";
import { isNonNullableRef } from "@scripts/react/syntax/react";
import type { ReloadFn, TimeStamp } from "@scripts/react/util/useReload";
import type { Joda } from "@scripts/syntax/date/joda";

import { dataEitherEq, useDataLoader } from "../../util/useDataLoader";
import { Empty } from "../Empty";
import { defaultErrorMsgEl, DefaultInlineError, error403MsgEl, Inline403Error, inline403ErrorTitle, inlineDefaultErrorTitle } from "../error/errorMessages";
import { LoadingWrapper } from "../LoadingWrapper";
import type { ResponseStatus } from "./ResponseStatus";
import { failed, loading, success } from "./ResponseStatus";

export type AjaxDispatch = Dispatch<NotificationAction>;

const DefaultPortalError = (props: { title: string, errEl: ReactNode, dispatch: AjaxDispatch }) => {
  useEffect(() => {
    props.dispatch(notificationAdd({
      title: props.title,
      id: "DefaultPortalError",
      type: "danger",
      children: props.errEl,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Empty />;
};

type NotificationErrorProps = { dispatch: AjaxDispatch, errorType: "notification" };

type InlineErrorProps = { errorType: "inline" };

type ErrorProps = NotificationErrorProps | InlineErrorProps;

export type ApiLoaderErrorElementProps = ErrorProps & { show403: boolean };
export const ApiLoaderErrorElement = (props: ApiLoaderErrorElementProps) => {
  const config = useConfig();
  switch (props.errorType) {
    case "inline":
      return props.show403 ? Inline403Error : DefaultInlineError;
    case "notification":
      return <DefaultPortalError title={props.show403 ? inline403ErrorTitle : inlineDefaultErrorTitle} dispatch={props.dispatch} errEl={props.show403 ? error403MsgEl : defaultErrorMsgEl} />;
  }
  return config.exhaustive(props);
};

export const parseEither = <E, A, S>(
  de: O.Option<E.Either<E, ApiData<A>>>,
  onSome: (t: ApiData<A>) => S,
  onNone: () => S
) => pipe(
  de,
  O.fold(
    onNone,
    E.fold(
      onNone,
      onSome
    )
  )
);

const parseEither403 = <E, A, S>(
  de: O.Option<E.Either<E, ApiData<A>>>,
  onNone: () => S,
  onLeft: (e: E) => S,
  onRight: (t: ApiData<A>) => S,
) => pipe(
  de,
  O.fold(
    onNone,
    E.fold(
      onLeft,
      onRight
    )
  )
);

const respIs403 = (re: O.Option<Response>) => O.fold(
  () => false,
  (r: Response) => r.status === 403
)(re);

export const show403Error = (e: RespOrErrors) => Th.fold(
  respIs403,
  constFalse,
  respIs403
)(e);

export type ApiMethods<A> = {
  apiMethod: ErrorHandlerApiReq<A>;
  timestamp?: TimeStamp;
  expiration?: Joda.Duration;
};

type StaleWhileReloadWrapperProps = PropsWithChildren<{
  isLoading: boolean;
  hasMountedRef: RefObject<HTMLDivElement | null>;
  skeleton: ReactElement;
  // Similar to Stale While Revalidate; when true the children are not destroyed after the first render
  // (this can allow child components to retain state), they're shown with stale data and a loading state.
  staleWhileReload: boolean;
}>;

const StaleWhileReloadWrapper = (props: StaleWhileReloadWrapperProps) => {
  const hasMounted = isNonNullableRef(props.hasMountedRef);

  return props.isLoading && (props.staleWhileReload ? !hasMounted : true)
    ? props.skeleton
    // eslint-disable-next-line react/jsx-no-useless-fragment
    : <>{props.children}</>;
};

export type DataEither<A> = O.Option<E.Either<RespOrErrors, ApiData<A>>>;

type ApiLoaderRawBaseProps<A> = {
  isLoading: boolean;
  dataEither: DataEither<A>;
};

type TableApiLoaderRawProps<A> =
  ApiLoaderRawBaseProps<A>
  & Pick<StaleWhileReloadWrapperProps, "skeleton">
  & Partial<Pick<StaleWhileReloadWrapperProps, "staleWhileReload">>
  & ErrorProps & {
    children: (r: A) => ReactElement;
  };

export const TableApiLoaderRaw = <A,>(props: TableApiLoaderRawProps<A>) => {
  const ref = useRef<HTMLDivElement>(null);
  const staleWhileReload = props.staleWhileReload ?? true;

  return <StaleWhileReloadWrapper
    isLoading={props.isLoading}
    hasMountedRef={ref}
    skeleton={props.skeleton}
    staleWhileReload={staleWhileReload}
  >
    <LoadingWrapper loading={props.isLoading} ref={ref}>
      {parseEither403(
        props.dataEither,
        () => <ApiLoaderErrorElement {...props} show403={false} />,
        respOrErrors => <ApiLoaderErrorElement  {...props} show403={show403Error(respOrErrors)} />,
        _ => props.children(_.data)
      )}
    </LoadingWrapper>
  </StaleWhileReloadWrapper>;
};

export type ApiLoaderRawProps<A> =
  Pick<StaleWhileReloadWrapperProps, "skeleton">
  & Partial<Pick<StaleWhileReloadWrapperProps, "staleWhileReload">>
  & ApiLoaderRawBaseProps<A>
  & ErrorProps & {
    children: (r: A, isLoading: boolean, hasMountedRef: Ref<HTMLDivElement>) => ReactElement;
  };

export const ApiLoaderRaw = <A,>(props: ApiLoaderRawProps<A>) => {
  const ref = useRef<HTMLDivElement>(null);
  const staleWhileReload = props.staleWhileReload ?? false;

  return <StaleWhileReloadWrapper
    isLoading={props.isLoading}
    hasMountedRef={ref}
    skeleton={props.skeleton}
    staleWhileReload={staleWhileReload}
  >
    {parseEither403(
      props.dataEither,
      () => <ApiLoaderErrorElement {...props} show403={false} />,
      respOrErrors => <ApiLoaderErrorElement  {...props} show403={show403Error(respOrErrors)} />,
      _ => props.children(_.data, props.isLoading, ref)
    )}
  </StaleWhileReloadWrapper>;
};

type ApiLoaderProps<A> =
  Pick<StaleWhileReloadWrapperProps, "skeleton">
  & Partial<Pick<StaleWhileReloadWrapperProps, "staleWhileReload">>
  & ErrorProps & {
    children: (r: A, isLoading: boolean, hasMountedRef: Ref<HTMLDivElement>) => ReactElement;
  } & ApiMethods<A>;

export function ApiLoader<A>(props: ApiLoaderProps<A>): ReactElement {
  const [isLoading, dataEither] = useDataLoader(props.apiMethod, O.fromNullable(props.expiration), props.timestamp);
  return <ApiLoaderRaw
    {...props}
    dataEither={dataEither}
    isLoading={isLoading}
  >
    {props.children}
  </ApiLoaderRaw>;
}

// Loads data on render, rather than on modal open like PortalModalApiLoader. The trade off is that the button will be
// disabled until the data loads, which may be annoying on larger requests or data that changes often, but it also means
// the request will only be made once rather than every time the modal opens, and the modal opening will be smoother.
export function ApiLoaderWithInitiator<A>(props: Omit<ApiLoaderProps<A>, "errorType" | "skeleton"> & { initiator: (loading: boolean) => ReactElement, dispatch: AjaxDispatch }): ReactElement {
  const [isLoading, dataEither] = useDataLoader(props.apiMethod, O.fromNullable(props.expiration), props.timestamp);

  return <>
    {props.initiator(isLoading)}
    <ApiLoaderRaw
      dataEither={dataEither}
      isLoading={isLoading}
      skeleton={<Empty />}
      errorType="notification"
      dispatch={props.dispatch}
    >
      {props.children}
    </ApiLoaderRaw>
  </>;
}

const apiLoaderEq = Eq.tuple(b.Eq, dataEitherEq);

export function useApiLoader<A>(request: ErrorHandlerApiReq<A>, expiration: O.Option<Joda.Duration>, timeStamp?: TimeStamp): ResponseStatus<A> {
  const [isFetching, dataEither] = useDataLoader(request, expiration, timeStamp);
  const [data, setData] = useState<ResponseStatus<A>>(loading);

  useStableEffect(() => {
    const state = parseEither(
      dataEither,
      (r): ResponseStatus<A> => success(r.data),
      (): ResponseStatus<A> => (isFetching ? loading : failed)
    );
    setData(state);
  }, [isFetching, dataEither], apiLoaderEq);

  return data;
}

type TableApiLoaderProps<A> =
  Pick<StaleWhileReloadWrapperProps, "skeleton">
  & Partial<Pick<StaleWhileReloadWrapperProps, "staleWhileReload">>
  & ErrorProps & {
    children: (r: A) => ReactElement;
  } & ApiMethods<A>;

export function TableApiLoader<A>(props: TableApiLoaderProps<A>): ReactElement {
  const [isLoading, dataEither] = useDataLoader(props.apiMethod, O.none, props.timestamp);
  return <TableApiLoaderRaw
    {...props}
    isLoading={isLoading}
    dataEither={dataEither}
  />;
}

export type LoaderDataState<A> = { data: A, setData: (a: A) => void, reload: ReloadFn };

type TableApiDraggableLoaderProps<A> = {
  children: (data: A, setData: (newData: A) => void) => ReactElement;
} & Pick<StaleWhileReloadWrapperProps, "skeleton"> & ApiMethods<A>;

export function TableApiDraggableLoader<A>(props: TableApiDraggableLoaderProps<A>): ReactElement {
  const [isLoading, dataEither, setData] = useDataLoader(props.apiMethod, O.none, props.timestamp);

  const setDataState = (a: A) => setData(_ => pipe(
    _.data,
    O.map(E.map(r => ({ data: a, resp: r.resp }))),
    newData => ({ data: newData, isLoading: false })
  ));

  return <TableApiLoaderRaw
    isLoading={isLoading}
    dataEither={dataEither}
    skeleton={props.skeleton}
    errorType="inline"
  >
    {_ => props.children(_, setDataState)}
  </TableApiLoaderRaw>;
}
