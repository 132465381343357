import type { PropsWithChildren } from "react";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import * as RA from "fp-ts/lib/ReadonlyArray";
import * as t from "io-ts";
import { Lens } from "monocle-ts";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { LocalDateC, LocalDateTimeFromIsoStringC } from "@scripts/codecs/localDate";
import { Eq, flow, N, pipe } from "@scripts/fp-ts";
import type { Ongoing } from "@scripts/generated/domaintables/dateQualifiers";
import type { TimeZoneU } from "@scripts/generated/domaintables/timeZones";
import { type BidSubmissionAnswerPostC, type BidSubmissionData, type BidSubmissionDataC, type BidSubmissionDocumentPostC, type BidSubmissionPostC, bidSubmissionPostC, type BidSubmissionPostRelatedContent } from "@scripts/generated/models/bidSubmissions";
import type { BidSubmissionTemplateData } from "@scripts/generated/models/bidSubmissionTemplates";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { DataAndIdC } from "@scripts/generated/models/relatedContent";
import type { DateWithOptionalTime, Rfp } from "@scripts/generated/models/rfpBase";
import type { WithModInfo, WithModInfoC, WithStatusCU, WithStatusU } from "@scripts/generated/models/threadThrough";
import type { UserWithRoles } from "@scripts/generated/models/user";
import type { FormModalFormProps } from "@scripts/react/components/form/FormModal";
import { bigNumericFormatC } from "@scripts/react/form/codecs";
import { emptyFormState, formLens, formModifiedLens, formNonNullableLens, type UnsafeFormData } from "@scripts/react/form/form";
import type { RfpBidSubmissionState } from "@scripts/syntax/rfp";
import { localStorageSafeRemove, localStorageSafeSet, localStorageUnsafeGet } from "@scripts/syntax/sessionStorage";

import type { ContentItemCardSubscriptionPropsO } from "../../card/ContentItemCard";

const bidSubmissionTimestampsC = t.type({
  lastSaved: LocalDateTimeFromIsoStringC,
  expirationDate: LocalDateC,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const bidSubmissionWithTimestampsC = t.type({
  ...bidSubmissionPostC.props,
  ...bidSubmissionTimestampsC.props,
});

type BidSubmissionWithTimestampsC = typeof bidSubmissionWithTimestampsC;

export type BidSubmissionFormProps = FormModalFormProps<BidSubmissionPostC, WithStatusCU<WithModInfoC<BidSubmissionDataC>>>;

type BidSubmissionFormState = BidSubmissionFormProps["state"];
type BidSubmissionFormData = BidSubmissionFormState["data"];

export type SetBidSubmission = (submission: WithStatusU<WithModInfo<BidSubmissionData>>) => void;

export type BidSubmissionModalDataProps = {
  user: O.Option<UserWithRoles>;
  rfp: WithStatusU<Rfp>;
  bidSubmissionTemplate: O.Option<WithStatusU<WithModInfo<BidSubmissionTemplateData>>>;
  bidSubmission: O.Option<WithStatusU<WithModInfo<BidSubmissionData>>>;
  setBidSubmission: SetBidSubmission;
  issuer: Issuer;
  bidSubmissionState: RfpBidSubmissionState;
} & ContentItemCardSubscriptionPropsO;

export type BidSubmissionFormContentBaseProps = PropsWithChildren<Pick<BidSubmissionFormProps, "state" | "setState"> & {
  rfpName: string;
  introductionText: O.Option<string>;
  bidSubmission: O.Option<WithStatusU<WithModInfo<BidSubmissionData>>>;
  bidsDue: O.Option<E.Either<DateWithOptionalTime<TimeZoneU>, Ongoing>>;
  bidSubmissionState: RfpBidSubmissionState;
}>;

export const bidSubmissionPostL = formLens<BidSubmissionPostC>();

const attachedAnswerDataL = (id: number) =>
  formNonNullableLens<DataAndIdC<BidSubmissionAnswerPostC>>()("data", { bidSubmissionTemplateQuestionId: id, yesOrNo: O.none, text: O.none });

const attachedAnswerValueL = <K extends "yesOrNo" | "text">(key: K) => (id: number) => attachedAnswerDataL(id).compose(formLens<BidSubmissionAnswerPostC>()(key));

const isItem = (index: number) => <ItemC extends BidSubmissionAnswerPostC | BidSubmissionDocumentPostC>(item: UnsafeFormData<DataAndIdC<ItemC>>, ii: number) =>
  t.undefined.is(item)
    ? false
    : index === ii;

export const attachedAnswerL = <K extends "yesOrNo" | "text">(key: K) => (id: number, index: number) => new Lens<BidSubmissionFormData, UnsafeFormData<BidSubmissionAnswerPostC>[K]>(
  (x) => pipe(
    x.relatedContent?.answers?.find(isItem(index)) ?? {},
    attachedAnswerValueL(key)(id).get,
  ),
  (a) => (s) => pipe(
    s.relatedContent?.answers,
    O.fromNullable,
    O.chain(flow(
      RA.modifyAt(index, (ans) => t.undefined.is(ans) ? ({ data: { bidSubmissionTemplateQuestionId: id } }) : ({ ...ans, data: { ...ans.data, [key]: a } }))
    )),
    O.fold(
      () => s,
      (anss) => ({ ...s, relatedContent: { ...s.relatedContent, answers: anss } }),
    )
  ),
);

const attachedDocumentDataL = (id: number) =>
  formNonNullableLens<DataAndIdC<BidSubmissionDocumentPostC>>()("data", { media: O.none, bidSubmissionTemplateDocumentId: id });

const attachedDocumentValueL = (id: number) => attachedDocumentDataL(id).compose(formLens<BidSubmissionDocumentPostC>()("media"));

export const attachedDocumentL = (id: number, index: number) => new Lens<BidSubmissionFormData, UnsafeFormData<BidSubmissionDocumentPostC>["media"]>(
  (x) => pipe(
    x.relatedContent?.documents?.find(isItem(index)) ?? {},
    attachedDocumentValueL(id).get,
  ),
  (a) => (s) => pipe(
    s.relatedContent?.documents,
    O.fromNullable,
    O.chain(flow(
      RA.modifyAt(index, (doc) => t.undefined.is(doc) ? ({ data: { bidSubmissionTemplateDocumentId: id } }) : ({ ...doc, data: { ...doc.data, media: a } }))
    )),
    O.fold(
      () => s,
      (docs) => ({ ...s, relatedContent: { ...s.relatedContent, documents: docs } }),
    )
  ),
);

const makeEmptyRelatedContent = (templateData: BidSubmissionTemplateData) => ({
  relatedContent: {
    documents: templateData.documents.map((d) => ({
      data: {
        bidSubmissionTemplateDocumentId: d.data.id,
      },
    })),
    answers: templateData.questions.map((q) => ({
      data: {
        bidSubmissionTemplateQuestionId: q.data.id,
      },
    })),
  },
});

const insertData = <A>(emptyData: ReadonlyArray<A>, filledData: ReadonlyArray<A>, eq: Eq.Eq<A>) => pipe(
  pipe(
    filledData,
    RA.reduce(emptyData, (acc, resp) => pipe(
      acc,
      RA.findIndex(a => eq.equals(a, resp)),
      O.chain(idx => pipe(
        acc,
        RA.modifyAt(idx, () => resp)
      )),
      O.getOrElse(() => acc)
    ))
  )
);

type EmptyRelatedContent = ReturnType<typeof makeEmptyRelatedContent>["relatedContent"];
type EmptyRelatedAnswer = EmptyRelatedContent["answers"][number];
type EmptyRelatedDocument = EmptyRelatedContent["documents"][number];

const fillRelatedContent = (emptyRelatedContent: EmptyRelatedContent, savedRelatedContent: BidSubmissionPostRelatedContent) => {
  const answers = insertData(
    emptyRelatedContent.answers, savedRelatedContent.answers,
    Eq.contramap((a: EmptyRelatedAnswer) => a.data.bidSubmissionTemplateQuestionId)(N.Eq)
  );

  const documents = insertData(
    emptyRelatedContent.documents,
    savedRelatedContent.documents,
    Eq.contramap((d: EmptyRelatedDocument) => d.data.bidSubmissionTemplateDocumentId)(N.Eq)
  );
  return { answers, documents };
};

export const bidSubmissionStoragePrefix = "bl-bid-submission-progress";

const bidSubmissionStorageKey = (rfpId: number) => `${bidSubmissionStoragePrefix}-${rfpId}`;

export const saveBidSubmissionProgress = (rfpId: number, data: UnsafeFormData<BidSubmissionPostC> & {
  lastSaved: string;
  expirationDate: string;
}) =>
  localStorageSafeSet(bidSubmissionStorageKey(rfpId), data);

export const getBidSubmissionProgress = (rfpId: number): ReturnType<typeof localStorageUnsafeGet<BidSubmissionWithTimestampsC>> =>
  typeof localStorage === "undefined"
    ? E.left("NoLocalStorage")
    : localStorageUnsafeGet<BidSubmissionWithTimestampsC>(bidSubmissionStorageKey(rfpId));

export const clearBidSubmissionProgress = flow(bidSubmissionStorageKey, localStorageSafeRemove);

const decodeLocalStorageBigNum = (config: BLConfigWithLog, num?: O.Option<Readonly<Big.Big>>) => pipe(
  O.fromNullable(num),
  O.flatten,
  O.chain(b =>
    pipe(
      bigNumericFormatC.decode(b),
      E.fold(
        e => {
          config.log.error("Failed to decode Big from local storage", e);
          return O.none;
        },
        O.some
      )
    )
  ),
);

export const initializeRfpBidSubmissionState = (
  config: BLConfigWithLog,
  user: BidSubmissionModalDataProps["user"], rfpId: number,
  bidSubmission: BidSubmissionModalDataProps["bidSubmission"],
  bidSubmissionTemplate: BidSubmissionModalDataProps["bidSubmissionTemplate"]
): BidSubmissionFormState => {
  const savedBidSubmission = getBidSubmissionProgress(rfpId);

  return pipe(
    bidSubmission,
    O.fold(
      () => pipe(
        bidSubmissionTemplate,
        O.fold(
          () => emptyFormState<BidSubmissionPostC>({}),
          (template) => {
            const emptyRelatedContent = makeEmptyRelatedContent(template.data.record.data);
            return pipe(
              savedBidSubmission,
              E.fold(
                () => {
                  const emptyState = emptyFormState<BidSubmissionPostC>({
                    ...emptyRelatedContent,
                    rfpId,
                    ...pipe(
                      user,
                      O.fold(
                        () => ({}),
                        (u) => ({
                          userId: u.user.id,
                          firstName: u.user.firstName,
                          lastName: u.user.lastName,
                          email: u.user.email,
                          organization: pipe(u.user.company, O.toUndefined),
                        })
                      )
                    ),
                    bidSubmissionTemplateId: template.data.id,
                  });
                  return pipe(
                    user,
                    O.fold(
                      () => emptyState,
                      () => formModifiedLens().set(true)(emptyState)
                    )
                  );
                },
                savedData => emptyFormState<BidSubmissionPostC>({
                  ...savedData,
                  relatedContent: fillRelatedContent(emptyRelatedContent.relatedContent, savedData.relatedContent),
                  interestRate: decodeLocalStorageBigNum(config, savedData.interestRate),
                  fees: decodeLocalStorageBigNum(config, savedData.fees),
                })
              ),
            );
          }
        )
      ),
      bid => emptyFormState<BidSubmissionPostC>({
        ...bid.data.record.data.bidSubmission,
        id: O.some(bid.data.id),
        relatedContent: {
          documents: bid.data.record.data.documents.map((d) => ({
            id: O.some(d.data.id),
            data: {
              bidSubmissionTemplateDocumentId: d.data.record.bidSubmissionDocument.bidSubmissionTemplateDocumentId,
              media: pipe(d.data.record.media, O.map(_ => _.data.record.uploadResponse)),
            },
          })),
          answers: bid.data.record.data.answers.map((q) => ({
            id: O.some(q.data.id),
            data: q.data.record,
          })),
        },
      }),
    )
  );
};
