export * from "fp-ts/lib/struct";

import { pipe } from "fp-ts/lib/function";
import * as RA from "fp-ts/lib/ReadonlyArray";
import * as RR from "fp-ts/lib/ReadonlyRecord";
import { Ord } from "fp-ts/lib/string";

import type { PrefixWith, StripPrefix } from "./lib/_internal";
import * as I from "./lib/_internal";
import { isProtectedProp } from "./lib/_protectedProps";
import type { Describe, Match } from "./lib/types";

type Any = { [K in PropertyKey]: unknown };

export type Without<T, K> = {
  [L in Exclude<keyof T, K>]: T[L]
};
/** @category refinements */
export const is = I.refinementFor.struct;

/**
 * When a type parameter is used to constrain a function parameter,
 * the type parameter is said to be in "contravariant position".
 *
 * When this is the case, what we're trying to determine is whether a
 * type function, let's call it `f`, is a subtype of the function argument
 * that is (eventually) provided -- let's call that function `g`.
 *
 * Somewhat counter-intuitively, the subtype/supertype relationship
 * between `f` and `g` in this case is inverted. That is to say:
 *
 * In a given context, for all `f`, `f` extends `g` if `f`:
 *
 *   1. accepts a more _general_ type of argument than `g`; and,
 *   2. returns a more _specific_ type than `g`
 *
 * In other words, a type parameter must be _wider_ than the concrete
 * argument that we give it later, and is why here we intersect `A`
 * with the widest possible struct type.
 */
export type Contravariant<A extends Match.AnyStruct> = A & Match.AnyStruct;

/** @internal */
const reduceWithIndex = RR.reduceWithIndex(Ord);

/** @category constructors */
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
const emptyOf = <S extends Match.AnyStruct = never>() =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  RR.empty as S;

/**
 * @category constructors
 *
 * `Struct.unit` is similar to `RR.singleton` in that it creates a record containing
 * a single key/value pair, but unlike `singleton`, `unit` returns a record whose
 * key is the string literal that is passed in (rather than widening to `string`).
 *
 * @example
 * ```typescript
 *  import { Struct, RR } from "@scripts/fp-ts/ReadonlyRecord"
 *
 *  const hasFoo = Struct.unit("foo")(1)  //=> ExpectType: { foo: number; }
 *  const b = RR.singleton("foo", 1) // => ExpectType: { [key: string]: number; }
 * ```
 */
export const unit = <K extends PropertyKey>(key: K) =>
  <V>(value: V): { [P in K]: V } =>
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    ({ [key]: value } as { [P in K]: V });

export const keys = <S extends Match.AnyStruct, K extends keyof S>(struct: S): K[] =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  Object.keys(struct).filter(k => Object.hasOwn(struct, k)) as K[];

export const pick = <T extends Match.AnyStruct, P extends keyof T>(...ks: ReadonlyArray<P>) =>
  <S extends T, K extends P>(s: S): Pick<S, K> =>
    pipe(
      ks,
      RA.filter(k => !isProtectedProp(k)),
      RA.reduce(
        emptyOf<Pick<S, K>>(),
        (acc, k) => ({ ...acc, ...(k in s ? { [k]: s[k] } : {}) }),
      )
    );

const omit_ = <K extends keyof S, S extends Any>(s: S, ...ks: ReadonlyArray<K>): Describe<Omit<S, K>> => pipe(
  keys<S, K>(s),
  RA.reduce(
    emptyOf<Omit<S, K>>(),
    (acc, k: K) => ({
      ...acc,
      ...(ks.includes(k) ? {} : { [k]: s[k] }),
    })
  )
);

export const omit = <K extends PropertyKey>(...ks: ReadonlyArray<K>) =>
  <S extends { [k in K]: unknown }>(s: S): Describe<Omit<S, K>> => omit_(s, ...ks);

/** @internal */
const mapKeys =
  <
    A extends O extends { readonly [K in keyof O]: O[K] }
    ? { readonly [K in keyof O]: O[K] }
    : never,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
    K extends keyof A & string,
    O extends object,
  >(
    // TODO: see if it's possible to infer the return type as a string literal
    fn: (k: K) => string
  ) =>
    (obj: A) =>
      pipe(
        obj,
        reduceWithIndex({}, (k: K, acc, curr: A[keyof A]) => {
          return { ...acc, ...{ [fn(k)]: curr } };
        })
      );

type StripPrefixKeys<Pre extends string, O> = Describe<{
  readonly [K in keyof O as StripPrefix<Pre, string & K>]: O[K]
}>;
export const stripPrefixFromKeys =
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  <Pre extends string>(pre: Pre) =>
    <O extends object>(obj: O): StripPrefixKeys<Pre, O> =>
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      pipe(obj, mapKeys(I.stripPrefix(pre))) as StripPrefixKeys<Pre, O>;

type PrefixKeys<Pre extends string, A> = Describe<{
  readonly [K in keyof A as PrefixWith<Pre, K & string>]: A[K]
}>;
export const prefixKeys =
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  <Pre extends string>(pre: Pre) =>
    <O extends object>(obj: O): PrefixKeys<Pre, O> =>
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      pipe(obj, mapKeys(I.prefix(pre))) as PrefixKeys<Pre, O>;
