import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const success = {
  _tag: `Success`
} as const;

export type SuccessTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Success`>
}>;
export const successTaggedC: SuccessTaggedC = t.type({
  _tag: t.literal(`Success`)
});
export type SuccessTagged = t.TypeOf<SuccessTaggedC>;
export type Success = SuccessTagged & typeof success;
export type SuccessC = t.Type<Success, SuccessTagged>;
export const successC: SuccessC = pipe(successTaggedC, c => new t.Type<Success, SuccessTagged>(
  `Success`,
  (u: unknown): u is Success => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Success> => pipe(c.decode(u), E.map(x => ({ ...x, ...success }))),
  (x: Success): SuccessTagged => ({ ...x, _tag: `Success`}),
)) satisfies t.Type<Success, unknown>;


export const info = {
  _tag: `Info`
} as const;

export type InfoTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Info`>
}>;
export const infoTaggedC: InfoTaggedC = t.type({
  _tag: t.literal(`Info`)
});
export type InfoTagged = t.TypeOf<InfoTaggedC>;
export type Info = InfoTagged & typeof info;
export type InfoC = t.Type<Info, InfoTagged>;
export const infoC: InfoC = pipe(infoTaggedC, c => new t.Type<Info, InfoTagged>(
  `Info`,
  (u: unknown): u is Info => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Info> => pipe(c.decode(u), E.map(x => ({ ...x, ...info }))),
  (x: Info): InfoTagged => ({ ...x, _tag: `Info`}),
)) satisfies t.Type<Info, unknown>;


export const warning = {
  _tag: `Warning`
} as const;

export type WarningTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Warning`>
}>;
export const warningTaggedC: WarningTaggedC = t.type({
  _tag: t.literal(`Warning`)
});
export type WarningTagged = t.TypeOf<WarningTaggedC>;
export type Warning = WarningTagged & typeof warning;
export type WarningC = t.Type<Warning, WarningTagged>;
export const warningC: WarningC = pipe(warningTaggedC, c => new t.Type<Warning, WarningTagged>(
  `Warning`,
  (u: unknown): u is Warning => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Warning> => pipe(c.decode(u), E.map(x => ({ ...x, ...warning }))),
  (x: Warning): WarningTagged => ({ ...x, _tag: `Warning`}),
)) satisfies t.Type<Warning, unknown>;


export const danger = {
  _tag: `Danger`
} as const;

export type DangerTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Danger`>
}>;
export const dangerTaggedC: DangerTaggedC = t.type({
  _tag: t.literal(`Danger`)
});
export type DangerTagged = t.TypeOf<DangerTaggedC>;
export type Danger = DangerTagged & typeof danger;
export type DangerC = t.Type<Danger, DangerTagged>;
export const dangerC: DangerC = pipe(dangerTaggedC, c => new t.Type<Danger, DangerTagged>(
  `Danger`,
  (u: unknown): u is Danger => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Danger> => pipe(c.decode(u), E.map(x => ({ ...x, ...danger }))),
  (x: Danger): DangerTagged => ({ ...x, _tag: `Danger`}),
)) satisfies t.Type<Danger, unknown>;


export const allAlertTypeC = [successC, infoC, warningC, dangerC] as const;
export const allAlertTypeNames = [`Success`, `Info`, `Warning`, `Danger`] as const;
export type AlertTypeName = (typeof allAlertTypeNames)[number];

export type AlertTypeCU = t.UnionC<[SuccessC, InfoC, WarningC, DangerC]>;
export type AlertTypeU = Success | Info | Warning | Danger;
export const AlertTypeCU: AlertTypeCU = t.union([successC, infoC, warningC, dangerC]) satisfies t.Type<AlertTypeU, unknown>;

export const alertTypeOrd: Ord.Ord<AlertTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allAlertType = [success, info, warning, danger] as const;
export type AlertTypeMap<A> = { [K in AlertTypeName]: A };


