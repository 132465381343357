import { Fragment } from "react";
import * as O from "fp-ts/lib/Option";

import { danger } from "@scripts/generated/domaintables/alertTypes";
import { BondLinkHelpPageAnchor, ContactBondLinkAnchor } from "@scripts/react/components/Anchor";

import { AlertSubtle } from "../Alert";

export const defaultErrorMsgEl =
  <Fragment>
    Apologies, we encountered an error on our end. You can try again later,
    or <BondLinkHelpPageAnchor title="visit our help page" /> to
    contact us if the problem persists.
  </Fragment>;

export const error403MsgEl =
  <Fragment>
    Your account settings do not grant you permission to complete this action (error 403).
    Contact an admin or <ContactBondLinkAnchor title="BondLink support" /> for help.
  </Fragment>;


export const defaultErrorMessage = "Apologies, we encountered an error while furnishing your request. Please try again later or contact BondLink for help.";

export const inlineDefaultErrorTitle = "Loading Error";

export const DefaultInlineError =
  <AlertSubtle type={danger} title={O.some(inlineDefaultErrorTitle)} klasses={"my-1"}>
    {defaultErrorMsgEl}
  </AlertSubtle>;

export const inline403ErrorTitle = "Permissions Error";

export const Inline403Error =
  <AlertSubtle type={danger} title={O.some(inline403ErrorTitle)} klasses={"my-1"}>
    {error403MsgEl}
  </AlertSubtle>;
