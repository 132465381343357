import { Route, query, lit, str, end, type, Match } from "fp-ts-routing/lib";
import * as O from "fp-ts/lib/Option";
import * as t from "io-ts";
import { EitherC, either } from "io-ts-types/lib/either";
import { NumberFromString } from "io-ts-types/lib/NumberFromString";

import { HtmlC, htmlC } from "../../codecs/html";
import { literalNumberFromString } from "../../codecs/literalNumberFromString";
import { NoKeyOverlapC } from "../../codecs/noKeyOverlap";
import { unsafeFromRelativeUrlString } from "../../codecs/relativeUrl";
import { Describe } from "../../fp-ts/lib/types/describe";
import { NullableToOptionalFields } from "../../fp-ts/Record";
import { issuerIdC } from "../../routes/routing/params/issuerId";
import { RelativeUrlInterfaceIO, relativeUrlInterfaceIO, RelativeUrlInterface, relativeUrlInterface } from "../../routes/urlInterface";
import { ApiRedirectC as imported7_ApiRedirectC, apiRedirectC as imported7_apiRedirectC } from "../models/apiRedirect";
import { DirectOfferingDataC as imported0_DirectOfferingDataC, BankOfferingDataC as imported2_BankOfferingDataC, directOfferingDataC as imported0_directOfferingDataC, bankOfferingDataC as imported2_bankOfferingDataC } from "../models/bondOffering";
import { BondProgramWithRelatedContentC as imported7_BondProgramWithRelatedContentC, bondProgramWithRelatedContentC as imported7_bondProgramWithRelatedContentC, BondProgramWithRelatedContentC as imported8_BondProgramWithRelatedContentC, bondProgramWithRelatedContentC as imported8_bondProgramWithRelatedContentC } from "../models/bondProgram";
import { BondsSsrDataC as imported0_BondsSsrDataC, bondsSsrDataC as imported0_bondsSsrDataC, ArchivedBondsSsrDataC as imported0_ArchivedBondsSsrDataC, archivedBondsSsrDataC as imported0_archivedBondsSsrDataC } from "../models/bondsSsrData";
import { BrowserRedirectC as imported26_BrowserRedirectC, BrowserRedirectC as imported25_BrowserRedirectC, BrowserRedirectC as imported27_BrowserRedirectC, browserRedirectC as imported26_browserRedirectC, browserRedirectC as imported25_browserRedirectC, browserRedirectC as imported27_browserRedirectC } from "../models/browserRedirect";
import { ContactPostC as imported0_ContactPostC, contactPostC as imported0_contactPostC } from "../models/contact";
import { EmmaLinksPageDataC as imported0_EmmaLinksPageDataC, emmaLinksPageDataC as imported0_emmaLinksPageDataC } from "../models/cusip";
import { SitesCustomPageDataC as imported0_SitesCustomPageDataC, sitesCustomPageDataC as imported0_sitesCustomPageDataC } from "../models/customPages";
import { AcceptDisclaimerPostC as imported0_AcceptDisclaimerPostC, acceptDisclaimerPostC as imported0_acceptDisclaimerPostC } from "../models/disclaimer";
import { DocumentWithCategoryC as imported24_DocumentWithCategoryC, documentWithCategoryC as imported24_documentWithCategoryC } from "../models/document";
import { EmptyObjectC as imported0_EmptyObjectC, emptyObjectC as imported0_emptyObjectC } from "../models/emptyObject";
import { IssuerFaqsWithSectionsC as imported1_IssuerFaqsWithSectionsC, issuerFaqsWithSectionsC as imported1_issuerFaqsWithSectionsC } from "../models/faq";
import { IssuerLinkC as imported9_IssuerLinkC, IssuerLinkSectionC as imported5_IssuerLinkSectionC, issuerLinkC as imported7_issuerLinkC, issuerLinkSectionC as imported5_issuerLinkSectionC } from "../models/issuerLinks";
import { NewsAndEventsDataC as imported0_NewsAndEventsDataC, newsAndEventsDataC as imported0_newsAndEventsDataC, IssuerNewsWithRelatedContentC as imported5_IssuerNewsWithRelatedContentC, issuerNewsWithRelatedContentC as imported5_issuerNewsWithRelatedContentC } from "../models/issuerNews";
import { SitesErrorPageDataC as imported1_SitesErrorPageDataC, issuerHomepageDataC as imported0_issuerHomepageDataC, issuerDocumentCategoriesPageDataC as imported0_issuerDocumentCategoriesPageDataC, IssuerPartnerLanderPageDataC as imported0_IssuerPartnerLanderPageDataC, sitesErrorPageDataC as imported2_sitesErrorPageDataC, IssuerAboutPageDataC as imported0_IssuerAboutPageDataC, SitesErrorPageDataC as imported0_SitesErrorPageDataC, IssuerEsgPageDataC as imported0_IssuerEsgPageDataC, sitesErrorPageDataC as imported1_sitesErrorPageDataC, SitesErrorPageDataC as imported2_SitesErrorPageDataC, bLPDealUnavailablePageDataC as imported0_bLPDealUnavailablePageDataC, issuerAboutPageDataC as imported0_issuerAboutPageDataC, IssuerDocumentCategoriesPageDataC as imported0_IssuerDocumentCategoriesPageDataC, issuerEsgPageDataC as imported0_issuerEsgPageDataC, IssuerResourcesPageDataC as imported0_IssuerResourcesPageDataC, issuerResourcesPageDataC as imported0_issuerResourcesPageDataC, RoadShowSsrDataC as imported0_RoadShowSsrDataC, issuerDownloadsPageDataC as imported0_issuerDownloadsPageDataC, issuerDownloadsPageDataC as imported1_issuerDownloadsPageDataC, sitesErrorPageDataC as imported0_sitesErrorPageDataC, IssuerHomepageDataC as imported0_IssuerHomepageDataC, IssuerDownloadsPageDataC as imported1_IssuerDownloadsPageDataC, roadShowSsrDataC as imported0_roadShowSsrDataC, issuerPartnerLanderPageDataC as imported0_issuerPartnerLanderPageDataC, IssuerDownloadsPageDataC as imported0_IssuerDownloadsPageDataC, BLPDealUnavailablePageDataC as imported0_BLPDealUnavailablePageDataC } from "../models/issuerPageData";
import { MediaC as imported101_MediaC, mediaC as imported97_mediaC } from "../models/media";
import { OfficerC as imported19_OfficerC, officerC as imported19_officerC } from "../models/officer";
import { ConfirmSitePasswordC as imported0_ConfirmSitePasswordC, confirmSitePasswordC as imported0_confirmSitePasswordC } from "../models/privateSite";
import { ProjectWithRelatedContentC as imported5_ProjectWithRelatedContentC, projectWithRelatedContentC as imported5_projectWithRelatedContentC, ProjectWithPhotosC as imported6_ProjectWithPhotosC, projectWithPhotosC as imported6_projectWithPhotosC } from "../models/project";
import { IssuerAndProgramRatingsByAgencyC as imported1_IssuerAndProgramRatingsByAgencyC, issuerAndProgramRatingsByAgencyC as imported1_issuerAndProgramRatingsByAgencyC } from "../models/rating";
import { rfpsSitesListC as imported0_rfpsSitesListC, BankRfpSitesDataC as imported2_BankRfpSitesDataC, bankRfpSitesDataC as imported2_bankRfpSitesDataC, directRfpSitesDataC as imported0_directRfpSitesDataC, RfpsSitesListC as imported0_RfpsSitesListC, DirectRfpSitesDataC as imported0_DirectRfpSitesDataC } from "../models/rfp";
import { RoadShowDataC as imported36_RoadShowDataC, roadShowDataC as imported36_roadShowDataC, RoadShowProgressPostC as imported0_RoadShowProgressPostC, roadShowProgressPostC as imported0_roadShowProgressPostC } from "../models/roadshow";
import { taggedContentC as imported245_taggedContentC, TaggedContentC as imported245_TaggedContentC, TaggedContentC as imported243_TaggedContentC, taggedContentC as imported244_taggedContentC, taggedContentC as imported246_taggedContentC, TaggedContentC as imported246_TaggedContentC, taggedContentC as imported242_taggedContentC, TaggedContentC as imported242_TaggedContentC, taggedContentC as imported243_taggedContentC, TaggedContentC as imported244_TaggedContentC } from "../models/taggedContent";
import { WithStatusCU as imported973_WithStatusCU, WithStatusCU as imported972_WithStatusCU, toManyC as imported0_toManyC, WithStatusCU as imported971_WithStatusCU, ToManyC as imported0_ToManyC, WithStatusCU as imported964_WithStatusCU, WithIdC as imported342_WithIdC, WithStatusCU as imported959_WithStatusCU, WithStatusCU as imported967_WithStatusCU, WithStatusCU as imported963_WithStatusCU, WithStatusCU as imported961_WithStatusCU, WithStatusCU as imported960_WithStatusCU, WithIdC as imported343_WithIdC, withIdC as imported343_withIdC, withIdC as imported344_withIdC, WithStatusCU as imported970_WithStatusCU, WithStatusCU as imported958_WithStatusCU, withIdC as imported342_withIdC, WithStatusCU as imported968_WithStatusCU, WithStatusCU as imported965_WithStatusCU, WithStatusCU as imported966_WithStatusCU, WithStatusCU as imported962_WithStatusCU, WithStatusCU as imported969_WithStatusCU, WithIdC as imported344_WithIdC } from "../models/threadThrough";

const issuersitesBondOfferingsControllerIndexPath = str("issuerSlug").then(lit("bonds")).then(type("issuerId", issuerIdC));
const issuersitesBondOfferingsControllerIndexPathParts = ["issuerSlug", "bonds", "issuerId"] as const;
export type IssuersitesBondOfferingsControllerIndexPathParts = typeof issuersitesBondOfferingsControllerIndexPathParts;

const issuersitesBondOfferingsControllerIndexQuery = t.strict({});
export type IssuersitesBondOfferingsControllerIndexParams = Describe<typeof issuersitesBondOfferingsControllerIndexPath._A & typeof issuersitesBondOfferingsControllerIndexQuery._A>;

export type IssuersitesBondOfferingsControllerIndexRoute<A = {}> = { match: Match<IssuersitesBondOfferingsControllerIndexParams & A>; pathParts: IssuersitesBondOfferingsControllerIndexPathParts };

export function issuersitesBondOfferingsControllerIndexRoute(): IssuersitesBondOfferingsControllerIndexRoute;
export function issuersitesBondOfferingsControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesBondOfferingsControllerIndexParams>): IssuersitesBondOfferingsControllerIndexRoute<t.TypeOf<A>>;
export function issuersitesBondOfferingsControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesBondOfferingsControllerIndexParams>) {
  return {
    match: (q ? issuersitesBondOfferingsControllerIndexPath.then(query(t.intersection([issuersitesBondOfferingsControllerIndexQuery, q]))) : issuersitesBondOfferingsControllerIndexPath.then(query(issuersitesBondOfferingsControllerIndexQuery))).then(end),
    pathParts: issuersitesBondOfferingsControllerIndexPathParts,
  };
}

export const issuersitesBondOfferingsControllerIndexCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported0_BondsSsrDataC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported0_bondsSsrDataC };

export const issuersitesBondOfferingsControllerIndex = (p: IssuersitesBondOfferingsControllerIndexParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesBondOfferingsControllerIndexRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesBondOfferingsControllerIndexCodecs);

const issuersitesBondOfferingsControllerOfferingPath = str("issuerSlug").then(lit("bonds")).then(lit("bond-offering")).then(type("issuerId", issuerIdC));
const issuersitesBondOfferingsControllerOfferingPathParts = [
  "issuerSlug", "bonds", "bond-offering", "issuerId"
] as const;
export type IssuersitesBondOfferingsControllerOfferingPathParts = typeof issuersitesBondOfferingsControllerOfferingPathParts;

const issuersitesBondOfferingsControllerOfferingQuery = t.exact(t.type({
  "offeringId": NumberFromString
}));
export type IssuersitesBondOfferingsControllerOfferingParams = Describe<typeof issuersitesBondOfferingsControllerOfferingPath._A & typeof issuersitesBondOfferingsControllerOfferingQuery._A>;

export type IssuersitesBondOfferingsControllerOfferingRoute<A = {}> = { match: Match<IssuersitesBondOfferingsControllerOfferingParams & A>; pathParts: IssuersitesBondOfferingsControllerOfferingPathParts };

export function issuersitesBondOfferingsControllerOfferingRoute(): IssuersitesBondOfferingsControllerOfferingRoute;
export function issuersitesBondOfferingsControllerOfferingRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesBondOfferingsControllerOfferingParams>): IssuersitesBondOfferingsControllerOfferingRoute<t.TypeOf<A>>;
export function issuersitesBondOfferingsControllerOfferingRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesBondOfferingsControllerOfferingParams>) {
  return {
    match: (q ? issuersitesBondOfferingsControllerOfferingPath.then(query(t.intersection([issuersitesBondOfferingsControllerOfferingQuery, q]))) : issuersitesBondOfferingsControllerOfferingPath.then(query(issuersitesBondOfferingsControllerOfferingQuery))).then(end),
    pathParts: issuersitesBondOfferingsControllerOfferingPathParts,
  };
}

export const issuersitesBondOfferingsControllerOfferingCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: EitherC<imported2_BankOfferingDataC, imported0_DirectOfferingDataC>
} = {
  input: imported0_emptyObjectC,
  output: htmlC,
  ssrInput: either(imported2_bankOfferingDataC, imported0_directOfferingDataC)
};

export const issuersitesBondOfferingsControllerOffering = (p: IssuersitesBondOfferingsControllerOfferingParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesBondOfferingsControllerOfferingRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesBondOfferingsControllerOfferingCodecs);

const issuersitesBondOfferingsControllerArchivedBondsPath = str("issuerSlug").then(lit("bond-offerings")).then(lit("archive")).then(type("issuerId", issuerIdC));
const issuersitesBondOfferingsControllerArchivedBondsPathParts = [
  "issuerSlug", "bond-offerings", "archive", "issuerId"
] as const;
export type IssuersitesBondOfferingsControllerArchivedBondsPathParts = typeof issuersitesBondOfferingsControllerArchivedBondsPathParts;

const issuersitesBondOfferingsControllerArchivedBondsQuery = t.strict({});
export type IssuersitesBondOfferingsControllerArchivedBondsParams = Describe<typeof issuersitesBondOfferingsControllerArchivedBondsPath._A & typeof issuersitesBondOfferingsControllerArchivedBondsQuery._A>;

export type IssuersitesBondOfferingsControllerArchivedBondsRoute<A = {}> = { match: Match<IssuersitesBondOfferingsControllerArchivedBondsParams & A>; pathParts: IssuersitesBondOfferingsControllerArchivedBondsPathParts };

export function issuersitesBondOfferingsControllerArchivedBondsRoute(): IssuersitesBondOfferingsControllerArchivedBondsRoute;
export function issuersitesBondOfferingsControllerArchivedBondsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesBondOfferingsControllerArchivedBondsParams>): IssuersitesBondOfferingsControllerArchivedBondsRoute<t.TypeOf<A>>;
export function issuersitesBondOfferingsControllerArchivedBondsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesBondOfferingsControllerArchivedBondsParams>) {
  return {
    match: (q ? issuersitesBondOfferingsControllerArchivedBondsPath.then(query(t.intersection([issuersitesBondOfferingsControllerArchivedBondsQuery, q]))) : issuersitesBondOfferingsControllerArchivedBondsPath.then(query(issuersitesBondOfferingsControllerArchivedBondsQuery))).then(end),
    pathParts: issuersitesBondOfferingsControllerArchivedBondsPathParts,
  };
}

export const issuersitesBondOfferingsControllerArchivedBondsCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported0_ArchivedBondsSsrDataC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported0_archivedBondsSsrDataC };

export const issuersitesBondOfferingsControllerArchivedBonds = (p: IssuersitesBondOfferingsControllerArchivedBondsParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesBondOfferingsControllerArchivedBondsRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesBondOfferingsControllerArchivedBondsCodecs);

const issuersitesBondOfferingsControllerRatingsPath = str("issuerSlug").then(lit("ratings")).then(type("issuerId", issuerIdC));
const issuersitesBondOfferingsControllerRatingsPathParts = ["issuerSlug", "ratings", "issuerId"] as const;
export type IssuersitesBondOfferingsControllerRatingsPathParts = typeof issuersitesBondOfferingsControllerRatingsPathParts;

const issuersitesBondOfferingsControllerRatingsQuery = t.strict({});
export type IssuersitesBondOfferingsControllerRatingsParams = Describe<typeof issuersitesBondOfferingsControllerRatingsPath._A & typeof issuersitesBondOfferingsControllerRatingsQuery._A>;

export type IssuersitesBondOfferingsControllerRatingsRoute<A = {}> = { match: Match<IssuersitesBondOfferingsControllerRatingsParams & A>; pathParts: IssuersitesBondOfferingsControllerRatingsPathParts };

export function issuersitesBondOfferingsControllerRatingsRoute(): IssuersitesBondOfferingsControllerRatingsRoute;
export function issuersitesBondOfferingsControllerRatingsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesBondOfferingsControllerRatingsParams>): IssuersitesBondOfferingsControllerRatingsRoute<t.TypeOf<A>>;
export function issuersitesBondOfferingsControllerRatingsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesBondOfferingsControllerRatingsParams>) {
  return {
    match: (q ? issuersitesBondOfferingsControllerRatingsPath.then(query(t.intersection([issuersitesBondOfferingsControllerRatingsQuery, q]))) : issuersitesBondOfferingsControllerRatingsPath.then(query(issuersitesBondOfferingsControllerRatingsQuery))).then(end),
    pathParts: issuersitesBondOfferingsControllerRatingsPathParts,
  };
}

export const issuersitesBondOfferingsControllerRatingsCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported1_IssuerAndProgramRatingsByAgencyC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported1_issuerAndProgramRatingsByAgencyC };

export const issuersitesBondOfferingsControllerRatings = (p: IssuersitesBondOfferingsControllerRatingsParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesBondOfferingsControllerRatingsRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesBondOfferingsControllerRatingsCodecs);

const issuersitesIssuerControllerIndexPath = str("issuerSlug").then(type("issuerId", issuerIdC));
const issuersitesIssuerControllerIndexPathParts = ["issuerSlug", "issuerId"] as const;
export type IssuersitesIssuerControllerIndexPathParts = typeof issuersitesIssuerControllerIndexPathParts;

const issuersitesIssuerControllerIndexQuery = t.strict({});
export type IssuersitesIssuerControllerIndexParams = Describe<typeof issuersitesIssuerControllerIndexPath._A & typeof issuersitesIssuerControllerIndexQuery._A>;

export type IssuersitesIssuerControllerIndexRoute<A = {}> = { match: Match<IssuersitesIssuerControllerIndexParams & A>; pathParts: IssuersitesIssuerControllerIndexPathParts };

export function issuersitesIssuerControllerIndexRoute(): IssuersitesIssuerControllerIndexRoute;
export function issuersitesIssuerControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesIssuerControllerIndexParams>): IssuersitesIssuerControllerIndexRoute<t.TypeOf<A>>;
export function issuersitesIssuerControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesIssuerControllerIndexParams>) {
  return {
    match: (q ? issuersitesIssuerControllerIndexPath.then(query(t.intersection([issuersitesIssuerControllerIndexQuery, q]))) : issuersitesIssuerControllerIndexPath.then(query(issuersitesIssuerControllerIndexQuery))).then(end),
    pathParts: issuersitesIssuerControllerIndexPathParts,
  };
}

export const issuersitesIssuerControllerIndexCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported0_IssuerHomepageDataC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported0_issuerHomepageDataC };

export const issuersitesIssuerControllerIndex = (p: IssuersitesIssuerControllerIndexParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesIssuerControllerIndexRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesIssuerControllerIndexCodecs);

const issuersitesAboutControllerIndexPath = str("issuerSlug").then(lit("about")).then(type("issuerId", issuerIdC));
const issuersitesAboutControllerIndexPathParts = ["issuerSlug", "about", "issuerId"] as const;
export type IssuersitesAboutControllerIndexPathParts = typeof issuersitesAboutControllerIndexPathParts;

const issuersitesAboutControllerIndexQuery = t.strict({});
export type IssuersitesAboutControllerIndexParams = Describe<typeof issuersitesAboutControllerIndexPath._A & typeof issuersitesAboutControllerIndexQuery._A>;

export type IssuersitesAboutControllerIndexRoute<A = {}> = { match: Match<IssuersitesAboutControllerIndexParams & A>; pathParts: IssuersitesAboutControllerIndexPathParts };

export function issuersitesAboutControllerIndexRoute(): IssuersitesAboutControllerIndexRoute;
export function issuersitesAboutControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesAboutControllerIndexParams>): IssuersitesAboutControllerIndexRoute<t.TypeOf<A>>;
export function issuersitesAboutControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesAboutControllerIndexParams>) {
  return {
    match: (q ? issuersitesAboutControllerIndexPath.then(query(t.intersection([issuersitesAboutControllerIndexQuery, q]))) : issuersitesAboutControllerIndexPath.then(query(issuersitesAboutControllerIndexQuery))).then(end),
    pathParts: issuersitesAboutControllerIndexPathParts,
  };
}

export const issuersitesAboutControllerIndexCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported0_IssuerAboutPageDataC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported0_issuerAboutPageDataC };

export const issuersitesAboutControllerIndex = (p: IssuersitesAboutControllerIndexParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesAboutControllerIndexRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesAboutControllerIndexCodecs);

const issuersitesAboutControllerEsgProgramPath = str("issuerSlug").then(lit("about")).then(lit("esg-program")).then(type("issuerId", issuerIdC));
const issuersitesAboutControllerEsgProgramPathParts = ["issuerSlug", "about", "esg-program", "issuerId"] as const;
export type IssuersitesAboutControllerEsgProgramPathParts = typeof issuersitesAboutControllerEsgProgramPathParts;

const issuersitesAboutControllerEsgProgramQuery = t.strict({});
export type IssuersitesAboutControllerEsgProgramParams = Describe<typeof issuersitesAboutControllerEsgProgramPath._A & typeof issuersitesAboutControllerEsgProgramQuery._A>;

export type IssuersitesAboutControllerEsgProgramRoute<A = {}> = { match: Match<IssuersitesAboutControllerEsgProgramParams & A>; pathParts: IssuersitesAboutControllerEsgProgramPathParts };

export function issuersitesAboutControllerEsgProgramRoute(): IssuersitesAboutControllerEsgProgramRoute;
export function issuersitesAboutControllerEsgProgramRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesAboutControllerEsgProgramParams>): IssuersitesAboutControllerEsgProgramRoute<t.TypeOf<A>>;
export function issuersitesAboutControllerEsgProgramRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesAboutControllerEsgProgramParams>) {
  return {
    match: (q ? issuersitesAboutControllerEsgProgramPath.then(query(t.intersection([issuersitesAboutControllerEsgProgramQuery, q]))) : issuersitesAboutControllerEsgProgramPath.then(query(issuersitesAboutControllerEsgProgramQuery))).then(end),
    pathParts: issuersitesAboutControllerEsgProgramPathParts,
  };
}

export const issuersitesAboutControllerEsgProgramCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported0_IssuerEsgPageDataC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported0_issuerEsgPageDataC };

export const issuersitesAboutControllerEsgProgram = (p: IssuersitesAboutControllerEsgProgramParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesAboutControllerEsgProgramRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesAboutControllerEsgProgramCodecs);

const issuersitesAboutControllerNewsEventsPath = str("issuerSlug").then(lit("about")).then(lit("news-events")).then(type("issuerId", issuerIdC));
const issuersitesAboutControllerNewsEventsPathParts = ["issuerSlug", "about", "news-events", "issuerId"] as const;
export type IssuersitesAboutControllerNewsEventsPathParts = typeof issuersitesAboutControllerNewsEventsPathParts;

const issuersitesAboutControllerNewsEventsQuery = t.strict({});
export type IssuersitesAboutControllerNewsEventsParams = Describe<typeof issuersitesAboutControllerNewsEventsPath._A & typeof issuersitesAboutControllerNewsEventsQuery._A>;

export type IssuersitesAboutControllerNewsEventsRoute<A = {}> = { match: Match<IssuersitesAboutControllerNewsEventsParams & A>; pathParts: IssuersitesAboutControllerNewsEventsPathParts };

export function issuersitesAboutControllerNewsEventsRoute(): IssuersitesAboutControllerNewsEventsRoute;
export function issuersitesAboutControllerNewsEventsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesAboutControllerNewsEventsParams>): IssuersitesAboutControllerNewsEventsRoute<t.TypeOf<A>>;
export function issuersitesAboutControllerNewsEventsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesAboutControllerNewsEventsParams>) {
  return {
    match: (q ? issuersitesAboutControllerNewsEventsPath.then(query(t.intersection([issuersitesAboutControllerNewsEventsQuery, q]))) : issuersitesAboutControllerNewsEventsPath.then(query(issuersitesAboutControllerNewsEventsQuery))).then(end),
    pathParts: issuersitesAboutControllerNewsEventsPathParts,
  };
}

export const issuersitesAboutControllerNewsEventsCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported0_NewsAndEventsDataC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported0_newsAndEventsDataC };

export const issuersitesAboutControllerNewsEvents = (p: IssuersitesAboutControllerNewsEventsParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesAboutControllerNewsEventsRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesAboutControllerNewsEventsCodecs);

const issuersitesAboutControllerProjectItemPath = str("issuerSlug").then(lit("about")).then(lit("project")).then(type("issuerId", issuerIdC));
const issuersitesAboutControllerProjectItemPathParts = ["issuerSlug", "about", "project", "issuerId"] as const;
export type IssuersitesAboutControllerProjectItemPathParts = typeof issuersitesAboutControllerProjectItemPathParts;

const issuersitesAboutControllerProjectItemQuery = t.exact(t.type({
  "projectId": NumberFromString
}));
export type IssuersitesAboutControllerProjectItemParams = Describe<typeof issuersitesAboutControllerProjectItemPath._A & typeof issuersitesAboutControllerProjectItemQuery._A>;

export type IssuersitesAboutControllerProjectItemRoute<A = {}> = { match: Match<IssuersitesAboutControllerProjectItemParams & A>; pathParts: IssuersitesAboutControllerProjectItemPathParts };

export function issuersitesAboutControllerProjectItemRoute(): IssuersitesAboutControllerProjectItemRoute;
export function issuersitesAboutControllerProjectItemRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesAboutControllerProjectItemParams>): IssuersitesAboutControllerProjectItemRoute<t.TypeOf<A>>;
export function issuersitesAboutControllerProjectItemRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesAboutControllerProjectItemParams>) {
  return {
    match: (q ? issuersitesAboutControllerProjectItemPath.then(query(t.intersection([issuersitesAboutControllerProjectItemQuery, q]))) : issuersitesAboutControllerProjectItemPath.then(query(issuersitesAboutControllerProjectItemQuery))).then(end),
    pathParts: issuersitesAboutControllerProjectItemPathParts,
  };
}

export const issuersitesAboutControllerProjectItemCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported958_WithStatusCU<imported242_TaggedContentC<imported5_ProjectWithRelatedContentC>>
} = {
  input: imported0_emptyObjectC,
  output: htmlC,
  ssrInput: imported959_WithStatusCU(imported242_taggedContentC(imported5_projectWithRelatedContentC))
};

export const issuersitesAboutControllerProjectItem = (p: IssuersitesAboutControllerProjectItemParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesAboutControllerProjectItemRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesAboutControllerProjectItemCodecs);

const issuersitesAboutControllerProjectsPath = str("issuerSlug").then(lit("about")).then(lit("projects")).then(type("issuerId", issuerIdC));
const issuersitesAboutControllerProjectsPathParts = ["issuerSlug", "about", "projects", "issuerId"] as const;
export type IssuersitesAboutControllerProjectsPathParts = typeof issuersitesAboutControllerProjectsPathParts;

const issuersitesAboutControllerProjectsQuery = t.strict({});
export type IssuersitesAboutControllerProjectsParams = Describe<typeof issuersitesAboutControllerProjectsPath._A & typeof issuersitesAboutControllerProjectsQuery._A>;

export type IssuersitesAboutControllerProjectsRoute<A = {}> = { match: Match<IssuersitesAboutControllerProjectsParams & A>; pathParts: IssuersitesAboutControllerProjectsPathParts };

export function issuersitesAboutControllerProjectsRoute(): IssuersitesAboutControllerProjectsRoute;
export function issuersitesAboutControllerProjectsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesAboutControllerProjectsParams>): IssuersitesAboutControllerProjectsRoute<t.TypeOf<A>>;
export function issuersitesAboutControllerProjectsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesAboutControllerProjectsParams>) {
  return {
    match: (q ? issuersitesAboutControllerProjectsPath.then(query(t.intersection([issuersitesAboutControllerProjectsQuery, q]))) : issuersitesAboutControllerProjectsPath.then(query(issuersitesAboutControllerProjectsQuery))).then(end),
    pathParts: issuersitesAboutControllerProjectsPathParts,
  };
}

export const issuersitesAboutControllerProjectsCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: t.ReadonlyArrayC<imported960_WithStatusCU<imported243_TaggedContentC<imported6_ProjectWithPhotosC>>>
} = {
  input: imported0_emptyObjectC,
  output: htmlC,
  ssrInput: t.readonlyArray(imported961_WithStatusCU(imported243_taggedContentC(imported6_projectWithPhotosC)))
};

export const issuersitesAboutControllerProjects = (p: IssuersitesAboutControllerProjectsParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesAboutControllerProjectsRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesAboutControllerProjectsCodecs);

const issuersitesAboutControllerTeamPath = str("issuerSlug").then(lit("about")).then(lit("our-team")).then(type("issuerId", issuerIdC));
const issuersitesAboutControllerTeamPathParts = ["issuerSlug", "about", "our-team", "issuerId"] as const;
export type IssuersitesAboutControllerTeamPathParts = typeof issuersitesAboutControllerTeamPathParts;

const issuersitesAboutControllerTeamQuery = t.strict({});
export type IssuersitesAboutControllerTeamParams = Describe<typeof issuersitesAboutControllerTeamPath._A & typeof issuersitesAboutControllerTeamQuery._A>;

export type IssuersitesAboutControllerTeamRoute<A = {}> = { match: Match<IssuersitesAboutControllerTeamParams & A>; pathParts: IssuersitesAboutControllerTeamPathParts };

export function issuersitesAboutControllerTeamRoute(): IssuersitesAboutControllerTeamRoute;
export function issuersitesAboutControllerTeamRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesAboutControllerTeamParams>): IssuersitesAboutControllerTeamRoute<t.TypeOf<A>>;
export function issuersitesAboutControllerTeamRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesAboutControllerTeamParams>) {
  return {
    match: (q ? issuersitesAboutControllerTeamPath.then(query(t.intersection([issuersitesAboutControllerTeamQuery, q]))) : issuersitesAboutControllerTeamPath.then(query(issuersitesAboutControllerTeamQuery))).then(end),
    pathParts: issuersitesAboutControllerTeamPathParts,
  };
}

export const issuersitesAboutControllerTeamCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: t.ReadonlyArrayC<imported962_WithStatusCU<imported19_OfficerC>>
} = {
  input: imported0_emptyObjectC,
  output: htmlC,
  ssrInput: t.readonlyArray(imported963_WithStatusCU(imported19_officerC))
};

export const issuersitesAboutControllerTeam = (p: IssuersitesAboutControllerTeamParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesAboutControllerTeamRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesAboutControllerTeamCodecs);

const issuersitesBondProgramsControllerBondProgramsPath = str("issuerSlug").then(lit("bond-programs")).then(type("issuerId", issuerIdC));
const issuersitesBondProgramsControllerBondProgramsPathParts = ["issuerSlug", "bond-programs", "issuerId"] as const;
export type IssuersitesBondProgramsControllerBondProgramsPathParts = typeof issuersitesBondProgramsControllerBondProgramsPathParts;

const issuersitesBondProgramsControllerBondProgramsQuery = t.strict({});
export type IssuersitesBondProgramsControllerBondProgramsParams = Describe<typeof issuersitesBondProgramsControllerBondProgramsPath._A & typeof issuersitesBondProgramsControllerBondProgramsQuery._A>;

export type IssuersitesBondProgramsControllerBondProgramsRoute<A = {}> = { match: Match<IssuersitesBondProgramsControllerBondProgramsParams & A>; pathParts: IssuersitesBondProgramsControllerBondProgramsPathParts };

export function issuersitesBondProgramsControllerBondProgramsRoute(): IssuersitesBondProgramsControllerBondProgramsRoute;
export function issuersitesBondProgramsControllerBondProgramsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesBondProgramsControllerBondProgramsParams>): IssuersitesBondProgramsControllerBondProgramsRoute<t.TypeOf<A>>;
export function issuersitesBondProgramsControllerBondProgramsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesBondProgramsControllerBondProgramsParams>) {
  return {
    match: (q ? issuersitesBondProgramsControllerBondProgramsPath.then(query(t.intersection([issuersitesBondProgramsControllerBondProgramsQuery, q]))) : issuersitesBondProgramsControllerBondProgramsPath.then(query(issuersitesBondProgramsControllerBondProgramsQuery))).then(end),
    pathParts: issuersitesBondProgramsControllerBondProgramsPathParts,
  };
}

export const issuersitesBondProgramsControllerBondProgramsCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: t.ReadonlyArrayC<imported964_WithStatusCU<imported7_BondProgramWithRelatedContentC>>
} = {
  input: imported0_emptyObjectC,
  output: htmlC,
  ssrInput: t.readonlyArray(imported965_WithStatusCU(imported7_bondProgramWithRelatedContentC))
};

export const issuersitesBondProgramsControllerBondPrograms = (p: IssuersitesBondProgramsControllerBondProgramsParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesBondProgramsControllerBondProgramsRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesBondProgramsControllerBondProgramsCodecs);

const issuersitesBondProgramsControllerBondProgramPath = str("issuerSlug").then(lit("bond-programs")).then(type("issuerId", issuerIdC));
const issuersitesBondProgramsControllerBondProgramPathParts = ["issuerSlug", "bond-programs", "issuerId"] as const;
export type IssuersitesBondProgramsControllerBondProgramPathParts = typeof issuersitesBondProgramsControllerBondProgramPathParts;

const issuersitesBondProgramsControllerBondProgramQuery = t.exact(t.type({
  "programId": NumberFromString
}));
export type IssuersitesBondProgramsControllerBondProgramParams = Describe<typeof issuersitesBondProgramsControllerBondProgramPath._A & typeof issuersitesBondProgramsControllerBondProgramQuery._A>;

export type IssuersitesBondProgramsControllerBondProgramRoute<A = {}> = { match: Match<IssuersitesBondProgramsControllerBondProgramParams & A>; pathParts: IssuersitesBondProgramsControllerBondProgramPathParts };

export function issuersitesBondProgramsControllerBondProgramRoute(): IssuersitesBondProgramsControllerBondProgramRoute;
export function issuersitesBondProgramsControllerBondProgramRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesBondProgramsControllerBondProgramParams>): IssuersitesBondProgramsControllerBondProgramRoute<t.TypeOf<A>>;
export function issuersitesBondProgramsControllerBondProgramRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesBondProgramsControllerBondProgramParams>) {
  return {
    match: (q ? issuersitesBondProgramsControllerBondProgramPath.then(query(t.intersection([issuersitesBondProgramsControllerBondProgramQuery, q]))) : issuersitesBondProgramsControllerBondProgramPath.then(query(issuersitesBondProgramsControllerBondProgramQuery))).then(end),
    pathParts: issuersitesBondProgramsControllerBondProgramPathParts,
  };
}

export const issuersitesBondProgramsControllerBondProgramCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported966_WithStatusCU<imported8_BondProgramWithRelatedContentC>
} = {
  input: imported0_emptyObjectC,
  output: htmlC,
  ssrInput: imported967_WithStatusCU(imported8_bondProgramWithRelatedContentC)
};

export const issuersitesBondProgramsControllerBondProgram = (p: IssuersitesBondProgramsControllerBondProgramParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesBondProgramsControllerBondProgramRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesBondProgramsControllerBondProgramCodecs);

const issuersitesResourcesControllerFaqPath = str("issuerSlug").then(lit("resources")).then(lit("faq")).then(type("issuerId", issuerIdC));
const issuersitesResourcesControllerFaqPathParts = ["issuerSlug", "resources", "faq", "issuerId"] as const;
export type IssuersitesResourcesControllerFaqPathParts = typeof issuersitesResourcesControllerFaqPathParts;

const issuersitesResourcesControllerFaqQuery = t.strict({});
export type IssuersitesResourcesControllerFaqParams = Describe<typeof issuersitesResourcesControllerFaqPath._A & typeof issuersitesResourcesControllerFaqQuery._A>;

export type IssuersitesResourcesControllerFaqRoute<A = {}> = { match: Match<IssuersitesResourcesControllerFaqParams & A>; pathParts: IssuersitesResourcesControllerFaqPathParts };

export function issuersitesResourcesControllerFaqRoute(): IssuersitesResourcesControllerFaqRoute;
export function issuersitesResourcesControllerFaqRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesResourcesControllerFaqParams>): IssuersitesResourcesControllerFaqRoute<t.TypeOf<A>>;
export function issuersitesResourcesControllerFaqRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesResourcesControllerFaqParams>) {
  return {
    match: (q ? issuersitesResourcesControllerFaqPath.then(query(t.intersection([issuersitesResourcesControllerFaqQuery, q]))) : issuersitesResourcesControllerFaqPath.then(query(issuersitesResourcesControllerFaqQuery))).then(end),
    pathParts: issuersitesResourcesControllerFaqPathParts,
  };
}

export const issuersitesResourcesControllerFaqCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported1_IssuerFaqsWithSectionsC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported1_issuerFaqsWithSectionsC };

export const issuersitesResourcesControllerFaq = (p: IssuersitesResourcesControllerFaqParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesResourcesControllerFaqRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesResourcesControllerFaqCodecs);

const issuersitesEmmaControllerLinksPath = str("issuerSlug").then(lit("resources")).then(lit("msrb-emma-links")).then(type("issuerId", issuerIdC));
const issuersitesEmmaControllerLinksPathParts = ["issuerSlug", "resources", "msrb-emma-links", "issuerId"] as const;
export type IssuersitesEmmaControllerLinksPathParts = typeof issuersitesEmmaControllerLinksPathParts;

const issuersitesEmmaControllerLinksQuery = t.exact(t.partial({
  "cusip9": t.string
}));
export type IssuersitesEmmaControllerLinksParamsRaw = Describe<typeof issuersitesEmmaControllerLinksPath._A & typeof issuersitesEmmaControllerLinksQuery._A>;
export type IssuersitesEmmaControllerLinksParams = Describe<NullableToOptionalFields<IssuersitesEmmaControllerLinksParamsRaw>>;

export type IssuersitesEmmaControllerLinksRoute<A = {}> = { match: Match<IssuersitesEmmaControllerLinksParams & A>; pathParts: IssuersitesEmmaControllerLinksPathParts };

export function issuersitesEmmaControllerLinksRoute(): IssuersitesEmmaControllerLinksRoute;
export function issuersitesEmmaControllerLinksRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesEmmaControllerLinksParamsRaw>): IssuersitesEmmaControllerLinksRoute<t.TypeOf<A>>;
export function issuersitesEmmaControllerLinksRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesEmmaControllerLinksParamsRaw>) {
  return {
    match: (q ? issuersitesEmmaControllerLinksPath.then(query(t.intersection([issuersitesEmmaControllerLinksQuery, q]))) : issuersitesEmmaControllerLinksPath.then(query(issuersitesEmmaControllerLinksQuery))).then(end).imap(
      (x: IssuersitesEmmaControllerLinksParamsRaw & t.TypeOf<A>): IssuersitesEmmaControllerLinksParams & t.TypeOf<A> => ({ ...x, cusip9: O.fromNullable(x.cusip9) }),
      (x: IssuersitesEmmaControllerLinksParams & t.TypeOf<A>): IssuersitesEmmaControllerLinksParamsRaw & t.TypeOf<A> => ({ ...x, cusip9: O.toUndefined(x.cusip9) })
    ),
    pathParts: issuersitesEmmaControllerLinksPathParts,
  };
}

export const issuersitesEmmaControllerLinksCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported0_EmmaLinksPageDataC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported0_emmaLinksPageDataC };

export const issuersitesEmmaControllerLinks = (p: IssuersitesEmmaControllerLinksParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesEmmaControllerLinksRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesEmmaControllerLinksCodecs);

const issuersitesReportsControllerDownloadRedirectPath = str("issuerSlug").then(lit("documents")).then(lit("download-report-direct")).then(type("issuerId", issuerIdC));
const issuersitesReportsControllerDownloadRedirectPathParts = [
  "issuerSlug", "documents", "download-report-direct", "issuerId"
] as const;
export type IssuersitesReportsControllerDownloadRedirectPathParts = typeof issuersitesReportsControllerDownloadRedirectPathParts;

const issuersitesReportsControllerDownloadRedirectQuery = t.exact(t.type({
  "mediaId": NumberFromString
}));
export type IssuersitesReportsControllerDownloadRedirectParams = Describe<typeof issuersitesReportsControllerDownloadRedirectPath._A & typeof issuersitesReportsControllerDownloadRedirectQuery._A>;

export type IssuersitesReportsControllerDownloadRedirectRoute<A = {}> = { match: Match<IssuersitesReportsControllerDownloadRedirectParams & A>; pathParts: IssuersitesReportsControllerDownloadRedirectPathParts };

export function issuersitesReportsControllerDownloadRedirectRoute(): IssuersitesReportsControllerDownloadRedirectRoute;
export function issuersitesReportsControllerDownloadRedirectRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesReportsControllerDownloadRedirectParams>): IssuersitesReportsControllerDownloadRedirectRoute<t.TypeOf<A>>;
export function issuersitesReportsControllerDownloadRedirectRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesReportsControllerDownloadRedirectParams>) {
  return {
    match: (q ? issuersitesReportsControllerDownloadRedirectPath.then(query(t.intersection([issuersitesReportsControllerDownloadRedirectQuery, q]))) : issuersitesReportsControllerDownloadRedirectPath.then(query(issuersitesReportsControllerDownloadRedirectQuery))).then(end),
    pathParts: issuersitesReportsControllerDownloadRedirectPathParts,
  };
}

export const issuersitesReportsControllerDownloadRedirectCodecs: {
  input: imported0_EmptyObjectC,
  output: imported25_BrowserRedirectC
} = { input: imported0_emptyObjectC, output: imported25_browserRedirectC };

export const issuersitesReportsControllerDownloadRedirect = (p: IssuersitesReportsControllerDownloadRedirectParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, imported25_BrowserRedirectC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesReportsControllerDownloadRedirectRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesReportsControllerDownloadRedirectCodecs);

const issuersitesRoadShowControllerRoadShowPath = str("issuerSlug").then(lit("bonds")).then(lit("roadshow")).then(type("issuerId", issuerIdC));
const issuersitesRoadShowControllerRoadShowPathParts = ["issuerSlug", "bonds", "roadshow", "issuerId"] as const;
export type IssuersitesRoadShowControllerRoadShowPathParts = typeof issuersitesRoadShowControllerRoadShowPathParts;

const issuersitesRoadShowControllerRoadShowQuery = t.exact(t.type({
  "roadShowId": NumberFromString
}));
export type IssuersitesRoadShowControllerRoadShowParams = Describe<typeof issuersitesRoadShowControllerRoadShowPath._A & typeof issuersitesRoadShowControllerRoadShowQuery._A>;

export type IssuersitesRoadShowControllerRoadShowRoute<A = {}> = { match: Match<IssuersitesRoadShowControllerRoadShowParams & A>; pathParts: IssuersitesRoadShowControllerRoadShowPathParts };

export function issuersitesRoadShowControllerRoadShowRoute(): IssuersitesRoadShowControllerRoadShowRoute;
export function issuersitesRoadShowControllerRoadShowRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesRoadShowControllerRoadShowParams>): IssuersitesRoadShowControllerRoadShowRoute<t.TypeOf<A>>;
export function issuersitesRoadShowControllerRoadShowRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesRoadShowControllerRoadShowParams>) {
  return {
    match: (q ? issuersitesRoadShowControllerRoadShowPath.then(query(t.intersection([issuersitesRoadShowControllerRoadShowQuery, q]))) : issuersitesRoadShowControllerRoadShowPath.then(query(issuersitesRoadShowControllerRoadShowQuery))).then(end),
    pathParts: issuersitesRoadShowControllerRoadShowPathParts,
  };
}

export const issuersitesRoadShowControllerRoadShowCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported342_WithIdC<imported244_TaggedContentC<imported36_RoadShowDataC>>
} = {
  input: imported0_emptyObjectC,
  output: htmlC,
  ssrInput: imported342_withIdC(imported244_taggedContentC(imported36_roadShowDataC))
};

export const issuersitesRoadShowControllerRoadShow = (p: IssuersitesRoadShowControllerRoadShowParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesRoadShowControllerRoadShowRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesRoadShowControllerRoadShowCodecs);

const issuersitesRoadShowControllerRoadShowPlayerPath = str("issuerSlug").then(lit("bonds")).then(lit("roadshow-player")).then(type("issuerId", issuerIdC));
const issuersitesRoadShowControllerRoadShowPlayerPathParts = [
  "issuerSlug", "bonds", "roadshow-player", "issuerId"
] as const;
export type IssuersitesRoadShowControllerRoadShowPlayerPathParts = typeof issuersitesRoadShowControllerRoadShowPlayerPathParts;

const issuersitesRoadShowControllerRoadShowPlayerQuery = t.exact(t.type({
  "roadShowId": NumberFromString
}));
export type IssuersitesRoadShowControllerRoadShowPlayerParams = Describe<typeof issuersitesRoadShowControllerRoadShowPlayerPath._A & typeof issuersitesRoadShowControllerRoadShowPlayerQuery._A>;

export type IssuersitesRoadShowControllerRoadShowPlayerRoute<A = {}> = { match: Match<IssuersitesRoadShowControllerRoadShowPlayerParams & A>; pathParts: IssuersitesRoadShowControllerRoadShowPlayerPathParts };

export function issuersitesRoadShowControllerRoadShowPlayerRoute(): IssuersitesRoadShowControllerRoadShowPlayerRoute;
export function issuersitesRoadShowControllerRoadShowPlayerRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesRoadShowControllerRoadShowPlayerParams>): IssuersitesRoadShowControllerRoadShowPlayerRoute<t.TypeOf<A>>;
export function issuersitesRoadShowControllerRoadShowPlayerRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesRoadShowControllerRoadShowPlayerParams>) {
  return {
    match: (q ? issuersitesRoadShowControllerRoadShowPlayerPath.then(query(t.intersection([issuersitesRoadShowControllerRoadShowPlayerQuery, q]))) : issuersitesRoadShowControllerRoadShowPlayerPath.then(query(issuersitesRoadShowControllerRoadShowPlayerQuery))).then(end),
    pathParts: issuersitesRoadShowControllerRoadShowPlayerPathParts,
  };
}

export const issuersitesRoadShowControllerRoadShowPlayerCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported0_RoadShowSsrDataC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported0_roadShowSsrDataC };

export const issuersitesRoadShowControllerRoadShowPlayer = (p: IssuersitesRoadShowControllerRoadShowPlayerParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesRoadShowControllerRoadShowPlayerRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesRoadShowControllerRoadShowPlayerCodecs);

const issuersitesRoadShowControllerReportProgressPath = lit("roadshow").then(lit("report"));
const issuersitesRoadShowControllerReportProgressPathParts = ["roadshow", "report"] as const;
export type IssuersitesRoadShowControllerReportProgressPathParts = typeof issuersitesRoadShowControllerReportProgressPathParts;

const issuersitesRoadShowControllerReportProgressQuery = t.strict({});
export type IssuersitesRoadShowControllerReportProgressParams = Describe<typeof issuersitesRoadShowControllerReportProgressPath._A & typeof issuersitesRoadShowControllerReportProgressQuery._A>;

export type IssuersitesRoadShowControllerReportProgressRoute<A = {}> = { match: Match<IssuersitesRoadShowControllerReportProgressParams & A>; pathParts: IssuersitesRoadShowControllerReportProgressPathParts };

export function issuersitesRoadShowControllerReportProgressRoute(): IssuersitesRoadShowControllerReportProgressRoute;
export function issuersitesRoadShowControllerReportProgressRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesRoadShowControllerReportProgressParams>): IssuersitesRoadShowControllerReportProgressRoute<t.TypeOf<A>>;
export function issuersitesRoadShowControllerReportProgressRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesRoadShowControllerReportProgressParams>) {
  return {
    match: (q ? issuersitesRoadShowControllerReportProgressPath.then(query(t.intersection([issuersitesRoadShowControllerReportProgressQuery, q]))) : issuersitesRoadShowControllerReportProgressPath.then(query(issuersitesRoadShowControllerReportProgressQuery))).then(end),
    pathParts: issuersitesRoadShowControllerReportProgressPathParts,
  };
}

export const issuersitesRoadShowControllerReportProgressCodecs: {
  input: imported0_RoadShowProgressPostC,
  output: imported0_EmptyObjectC
} = { input: imported0_roadShowProgressPostC, output: imported0_emptyObjectC };

export const issuersitesRoadShowControllerReportProgress = (): RelativeUrlInterfaceIO<"POST", imported0_RoadShowProgressPostC, imported0_EmptyObjectC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(issuersitesRoadShowControllerReportProgressRoute().match.formatter.run(Route.empty, {}).toString(true)), issuersitesRoadShowControllerReportProgressCodecs);

const issuersitesReportsControllerIndexPath = str("issuerSlug").then(lit("documents")).then(type("issuerId", issuerIdC));
const issuersitesReportsControllerIndexPathParts = ["issuerSlug", "documents", "issuerId"] as const;
export type IssuersitesReportsControllerIndexPathParts = typeof issuersitesReportsControllerIndexPathParts;

const issuersitesReportsControllerIndexQuery = t.strict({});
export type IssuersitesReportsControllerIndexParams = Describe<typeof issuersitesReportsControllerIndexPath._A & typeof issuersitesReportsControllerIndexQuery._A>;

export type IssuersitesReportsControllerIndexRoute<A = {}> = { match: Match<IssuersitesReportsControllerIndexParams & A>; pathParts: IssuersitesReportsControllerIndexPathParts };

export function issuersitesReportsControllerIndexRoute(): IssuersitesReportsControllerIndexRoute;
export function issuersitesReportsControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesReportsControllerIndexParams>): IssuersitesReportsControllerIndexRoute<t.TypeOf<A>>;
export function issuersitesReportsControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesReportsControllerIndexParams>) {
  return {
    match: (q ? issuersitesReportsControllerIndexPath.then(query(t.intersection([issuersitesReportsControllerIndexQuery, q]))) : issuersitesReportsControllerIndexPath.then(query(issuersitesReportsControllerIndexQuery))).then(end),
    pathParts: issuersitesReportsControllerIndexPathParts,
  };
}

export const issuersitesReportsControllerIndexCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported0_IssuerDocumentCategoriesPageDataC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported0_issuerDocumentCategoriesPageDataC };

export const issuersitesReportsControllerIndex = (p: IssuersitesReportsControllerIndexParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesReportsControllerIndexRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesReportsControllerIndexCodecs);

const issuersitesReportsControllerDownloadsPath = str("issuerSlug").then(lit("documents")).then(lit("downloads")).then(type("issuerId", issuerIdC));
const issuersitesReportsControllerDownloadsPathParts = ["issuerSlug", "documents", "downloads", "issuerId"] as const;
export type IssuersitesReportsControllerDownloadsPathParts = typeof issuersitesReportsControllerDownloadsPathParts;

const issuersitesReportsControllerDownloadsQuery = t.exact(t.partial({
  "mediaCategoryId": NumberFromString,
  "docTypeId": NumberFromString
}));
export type IssuersitesReportsControllerDownloadsParamsRaw = Describe<typeof issuersitesReportsControllerDownloadsPath._A & typeof issuersitesReportsControllerDownloadsQuery._A>;
export type IssuersitesReportsControllerDownloadsParams = Describe<NullableToOptionalFields<IssuersitesReportsControllerDownloadsParamsRaw>>;

export type IssuersitesReportsControllerDownloadsRoute<A = {}> = { match: Match<IssuersitesReportsControllerDownloadsParams & A>; pathParts: IssuersitesReportsControllerDownloadsPathParts };

export function issuersitesReportsControllerDownloadsRoute(): IssuersitesReportsControllerDownloadsRoute;
export function issuersitesReportsControllerDownloadsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesReportsControllerDownloadsParamsRaw>): IssuersitesReportsControllerDownloadsRoute<t.TypeOf<A>>;
export function issuersitesReportsControllerDownloadsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesReportsControllerDownloadsParamsRaw>) {
  return {
    match: (q ? issuersitesReportsControllerDownloadsPath.then(query(t.intersection([issuersitesReportsControllerDownloadsQuery, q]))) : issuersitesReportsControllerDownloadsPath.then(query(issuersitesReportsControllerDownloadsQuery))).then(end).imap(
      (x: IssuersitesReportsControllerDownloadsParamsRaw & t.TypeOf<A>): IssuersitesReportsControllerDownloadsParams & t.TypeOf<A> => ({ ...x, mediaCategoryId: O.fromNullable(x.mediaCategoryId), docTypeId: O.fromNullable(x.docTypeId) }),
      (x: IssuersitesReportsControllerDownloadsParams & t.TypeOf<A>): IssuersitesReportsControllerDownloadsParamsRaw & t.TypeOf<A> => ({ ...x, mediaCategoryId: O.toUndefined(x.mediaCategoryId), docTypeId: O.toUndefined(x.docTypeId) })
    ),
    pathParts: issuersitesReportsControllerDownloadsPathParts,
  };
}

export const issuersitesReportsControllerDownloadsCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported0_IssuerDownloadsPageDataC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported0_issuerDownloadsPageDataC };

export const issuersitesReportsControllerDownloads = (p: IssuersitesReportsControllerDownloadsParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesReportsControllerDownloadsRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesReportsControllerDownloadsCodecs);

const issuersitesReportsControllerArchiveDownloadsPath = str("issuerSlug").then(lit("documents")).then(lit("archive")).then(type("issuerId", issuerIdC));
const issuersitesReportsControllerArchiveDownloadsPathParts = [
  "issuerSlug", "documents", "archive", "issuerId"
] as const;
export type IssuersitesReportsControllerArchiveDownloadsPathParts = typeof issuersitesReportsControllerArchiveDownloadsPathParts;

const issuersitesReportsControllerArchiveDownloadsQuery = t.exact(t.partial({
  "mediaCategoryId": NumberFromString,
  "docTypeId": NumberFromString
}));
export type IssuersitesReportsControllerArchiveDownloadsParamsRaw = Describe<typeof issuersitesReportsControllerArchiveDownloadsPath._A & typeof issuersitesReportsControllerArchiveDownloadsQuery._A>;
export type IssuersitesReportsControllerArchiveDownloadsParams = Describe<NullableToOptionalFields<IssuersitesReportsControllerArchiveDownloadsParamsRaw>>;

export type IssuersitesReportsControllerArchiveDownloadsRoute<A = {}> = { match: Match<IssuersitesReportsControllerArchiveDownloadsParams & A>; pathParts: IssuersitesReportsControllerArchiveDownloadsPathParts };

export function issuersitesReportsControllerArchiveDownloadsRoute(): IssuersitesReportsControllerArchiveDownloadsRoute;
export function issuersitesReportsControllerArchiveDownloadsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesReportsControllerArchiveDownloadsParamsRaw>): IssuersitesReportsControllerArchiveDownloadsRoute<t.TypeOf<A>>;
export function issuersitesReportsControllerArchiveDownloadsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesReportsControllerArchiveDownloadsParamsRaw>) {
  return {
    match: (q ? issuersitesReportsControllerArchiveDownloadsPath.then(query(t.intersection([issuersitesReportsControllerArchiveDownloadsQuery, q]))) : issuersitesReportsControllerArchiveDownloadsPath.then(query(issuersitesReportsControllerArchiveDownloadsQuery))).then(end).imap(
      (x: IssuersitesReportsControllerArchiveDownloadsParamsRaw & t.TypeOf<A>): IssuersitesReportsControllerArchiveDownloadsParams & t.TypeOf<A> => ({ ...x, mediaCategoryId: O.fromNullable(x.mediaCategoryId), docTypeId: O.fromNullable(x.docTypeId) }),
      (x: IssuersitesReportsControllerArchiveDownloadsParams & t.TypeOf<A>): IssuersitesReportsControllerArchiveDownloadsParamsRaw & t.TypeOf<A> => ({ ...x, mediaCategoryId: O.toUndefined(x.mediaCategoryId), docTypeId: O.toUndefined(x.docTypeId) })
    ),
    pathParts: issuersitesReportsControllerArchiveDownloadsPathParts,
  };
}

export const issuersitesReportsControllerArchiveDownloadsCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported1_IssuerDownloadsPageDataC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported1_issuerDownloadsPageDataC };

export const issuersitesReportsControllerArchiveDownloads = (p: IssuersitesReportsControllerArchiveDownloadsParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesReportsControllerArchiveDownloadsRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesReportsControllerArchiveDownloadsCodecs);

const issuersitesReportsControllerViewFilePath = str("issuerSlug").then(lit("documents")).then(lit("view-file")).then(type("issuerId", issuerIdC));
const issuersitesReportsControllerViewFilePathParts = ["issuerSlug", "documents", "view-file", "issuerId"] as const;
export type IssuersitesReportsControllerViewFilePathParts = typeof issuersitesReportsControllerViewFilePathParts;

const issuersitesReportsControllerViewFileQuery = t.exact(t.type({
  "mediaId": NumberFromString
}));
export type IssuersitesReportsControllerViewFileParams = Describe<typeof issuersitesReportsControllerViewFilePath._A & typeof issuersitesReportsControllerViewFileQuery._A>;

export type IssuersitesReportsControllerViewFileRoute<A = {}> = { match: Match<IssuersitesReportsControllerViewFileParams & A>; pathParts: IssuersitesReportsControllerViewFilePathParts };

export function issuersitesReportsControllerViewFileRoute(): IssuersitesReportsControllerViewFileRoute;
export function issuersitesReportsControllerViewFileRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesReportsControllerViewFileParams>): IssuersitesReportsControllerViewFileRoute<t.TypeOf<A>>;
export function issuersitesReportsControllerViewFileRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesReportsControllerViewFileParams>) {
  return {
    match: (q ? issuersitesReportsControllerViewFilePath.then(query(t.intersection([issuersitesReportsControllerViewFileQuery, q]))) : issuersitesReportsControllerViewFilePath.then(query(issuersitesReportsControllerViewFileQuery))).then(end),
    pathParts: issuersitesReportsControllerViewFilePathParts,
  };
}

export const issuersitesReportsControllerViewFileCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported968_WithStatusCU<imported245_TaggedContentC<imported24_DocumentWithCategoryC>>
} = {
  input: imported0_emptyObjectC,
  output: htmlC,
  ssrInput: imported969_WithStatusCU(imported245_taggedContentC(imported24_documentWithCategoryC))
};

export const issuersitesReportsControllerViewFile = (p: IssuersitesReportsControllerViewFileParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesReportsControllerViewFileRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesReportsControllerViewFileCodecs);

const issuersitesReportsControllerIrmaLetterPath = str("issuerSlug").then(lit("documents")).then(lit("irma-letter")).then(type("issuerId", issuerIdC));
const issuersitesReportsControllerIrmaLetterPathParts = ["issuerSlug", "documents", "irma-letter", "issuerId"] as const;
export type IssuersitesReportsControllerIrmaLetterPathParts = typeof issuersitesReportsControllerIrmaLetterPathParts;

const issuersitesReportsControllerIrmaLetterQuery = t.strict({});
export type IssuersitesReportsControllerIrmaLetterParams = Describe<typeof issuersitesReportsControllerIrmaLetterPath._A & typeof issuersitesReportsControllerIrmaLetterQuery._A>;

export type IssuersitesReportsControllerIrmaLetterRoute<A = {}> = { match: Match<IssuersitesReportsControllerIrmaLetterParams & A>; pathParts: IssuersitesReportsControllerIrmaLetterPathParts };

export function issuersitesReportsControllerIrmaLetterRoute(): IssuersitesReportsControllerIrmaLetterRoute;
export function issuersitesReportsControllerIrmaLetterRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesReportsControllerIrmaLetterParams>): IssuersitesReportsControllerIrmaLetterRoute<t.TypeOf<A>>;
export function issuersitesReportsControllerIrmaLetterRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesReportsControllerIrmaLetterParams>) {
  return {
    match: (q ? issuersitesReportsControllerIrmaLetterPath.then(query(t.intersection([issuersitesReportsControllerIrmaLetterQuery, q]))) : issuersitesReportsControllerIrmaLetterPath.then(query(issuersitesReportsControllerIrmaLetterQuery))).then(end),
    pathParts: issuersitesReportsControllerIrmaLetterPathParts,
  };
}

export const issuersitesReportsControllerIrmaLetterCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported970_WithStatusCU<imported101_MediaC>
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported971_WithStatusCU(imported97_mediaC) };

export const issuersitesReportsControllerIrmaLetter = (p: IssuersitesReportsControllerIrmaLetterParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesReportsControllerIrmaLetterRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesReportsControllerIrmaLetterCodecs);

const issuersitesResourcesControllerIndexPath = str("issuerSlug").then(lit("resources")).then(type("issuerId", issuerIdC));
const issuersitesResourcesControllerIndexPathParts = ["issuerSlug", "resources", "issuerId"] as const;
export type IssuersitesResourcesControllerIndexPathParts = typeof issuersitesResourcesControllerIndexPathParts;

const issuersitesResourcesControllerIndexQuery = t.strict({});
export type IssuersitesResourcesControllerIndexParams = Describe<typeof issuersitesResourcesControllerIndexPath._A & typeof issuersitesResourcesControllerIndexQuery._A>;

export type IssuersitesResourcesControllerIndexRoute<A = {}> = { match: Match<IssuersitesResourcesControllerIndexParams & A>; pathParts: IssuersitesResourcesControllerIndexPathParts };

export function issuersitesResourcesControllerIndexRoute(): IssuersitesResourcesControllerIndexRoute;
export function issuersitesResourcesControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesResourcesControllerIndexParams>): IssuersitesResourcesControllerIndexRoute<t.TypeOf<A>>;
export function issuersitesResourcesControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesResourcesControllerIndexParams>) {
  return {
    match: (q ? issuersitesResourcesControllerIndexPath.then(query(t.intersection([issuersitesResourcesControllerIndexQuery, q]))) : issuersitesResourcesControllerIndexPath.then(query(issuersitesResourcesControllerIndexQuery))).then(end),
    pathParts: issuersitesResourcesControllerIndexPathParts,
  };
}

export const issuersitesResourcesControllerIndexCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported0_IssuerResourcesPageDataC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported0_issuerResourcesPageDataC };

export const issuersitesResourcesControllerIndex = (p: IssuersitesResourcesControllerIndexParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesResourcesControllerIndexRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesResourcesControllerIndexCodecs);

const issuersitesResourcesControllerLinksPath = str("issuerSlug").then(lit("resources")).then(lit("links")).then(type("issuerId", issuerIdC));
const issuersitesResourcesControllerLinksPathParts = ["issuerSlug", "resources", "links", "issuerId"] as const;
export type IssuersitesResourcesControllerLinksPathParts = typeof issuersitesResourcesControllerLinksPathParts;

const issuersitesResourcesControllerLinksQuery = t.strict({});
export type IssuersitesResourcesControllerLinksParams = Describe<typeof issuersitesResourcesControllerLinksPath._A & typeof issuersitesResourcesControllerLinksQuery._A>;

export type IssuersitesResourcesControllerLinksRoute<A = {}> = { match: Match<IssuersitesResourcesControllerLinksParams & A>; pathParts: IssuersitesResourcesControllerLinksPathParts };

export function issuersitesResourcesControllerLinksRoute(): IssuersitesResourcesControllerLinksRoute;
export function issuersitesResourcesControllerLinksRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesResourcesControllerLinksParams>): IssuersitesResourcesControllerLinksRoute<t.TypeOf<A>>;
export function issuersitesResourcesControllerLinksRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesResourcesControllerLinksParams>) {
  return {
    match: (q ? issuersitesResourcesControllerLinksPath.then(query(t.intersection([issuersitesResourcesControllerLinksQuery, q]))) : issuersitesResourcesControllerLinksPath.then(query(issuersitesResourcesControllerLinksQuery))).then(end),
    pathParts: issuersitesResourcesControllerLinksPathParts,
  };
}

export const issuersitesResourcesControllerLinksCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: t.ReadonlyArrayC<imported0_ToManyC<imported343_WithIdC<imported5_IssuerLinkSectionC>, imported344_WithIdC<imported9_IssuerLinkC>>>
} = {
  input: imported0_emptyObjectC,
  output: htmlC,
  ssrInput: t.readonlyArray(imported0_toManyC(imported343_withIdC(imported5_issuerLinkSectionC), imported344_withIdC(imported7_issuerLinkC)))
};

export const issuersitesResourcesControllerLinks = (p: IssuersitesResourcesControllerLinksParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesResourcesControllerLinksRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesResourcesControllerLinksCodecs);

const issuersitesResourcesControllerLinkPath = str("issuerSlug").then(lit("resources")).then(lit("view-link")).then(type("issuerId", issuerIdC));
const issuersitesResourcesControllerLinkPathParts = ["issuerSlug", "resources", "view-link", "issuerId"] as const;
export type IssuersitesResourcesControllerLinkPathParts = typeof issuersitesResourcesControllerLinkPathParts;

const issuersitesResourcesControllerLinkQuery = t.exact(t.type({
  "linkId": NumberFromString
}));
export type IssuersitesResourcesControllerLinkParams = Describe<typeof issuersitesResourcesControllerLinkPath._A & typeof issuersitesResourcesControllerLinkQuery._A>;

export type IssuersitesResourcesControllerLinkRoute<A = {}> = { match: Match<IssuersitesResourcesControllerLinkParams & A>; pathParts: IssuersitesResourcesControllerLinkPathParts };

export function issuersitesResourcesControllerLinkRoute(): IssuersitesResourcesControllerLinkRoute;
export function issuersitesResourcesControllerLinkRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesResourcesControllerLinkParams>): IssuersitesResourcesControllerLinkRoute<t.TypeOf<A>>;
export function issuersitesResourcesControllerLinkRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesResourcesControllerLinkParams>) {
  return {
    match: (q ? issuersitesResourcesControllerLinkPath.then(query(t.intersection([issuersitesResourcesControllerLinkQuery, q]))) : issuersitesResourcesControllerLinkPath.then(query(issuersitesResourcesControllerLinkQuery))).then(end),
    pathParts: issuersitesResourcesControllerLinkPathParts,
  };
}

export const issuersitesResourcesControllerLinkCodecs: {
  input: imported0_EmptyObjectC,
  output: imported26_BrowserRedirectC
} = { input: imported0_emptyObjectC, output: imported26_browserRedirectC };

export const issuersitesResourcesControllerLink = (p: IssuersitesResourcesControllerLinkParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, imported26_BrowserRedirectC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesResourcesControllerLinkRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesResourcesControllerLinkCodecs);

const issuersitesAboutControllerNewsItemPath = str("issuerSlug").then(lit("about")).then(lit("news")).then(type("issuerId", issuerIdC));
const issuersitesAboutControllerNewsItemPathParts = ["issuerSlug", "about", "news", "issuerId"] as const;
export type IssuersitesAboutControllerNewsItemPathParts = typeof issuersitesAboutControllerNewsItemPathParts;

const issuersitesAboutControllerNewsItemQuery = t.exact(t.type({
  "newsId": NumberFromString
}));
export type IssuersitesAboutControllerNewsItemParams = Describe<typeof issuersitesAboutControllerNewsItemPath._A & typeof issuersitesAboutControllerNewsItemQuery._A>;

export type IssuersitesAboutControllerNewsItemRoute<A = {}> = { match: Match<IssuersitesAboutControllerNewsItemParams & A>; pathParts: IssuersitesAboutControllerNewsItemPathParts };

export function issuersitesAboutControllerNewsItemRoute(): IssuersitesAboutControllerNewsItemRoute;
export function issuersitesAboutControllerNewsItemRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesAboutControllerNewsItemParams>): IssuersitesAboutControllerNewsItemRoute<t.TypeOf<A>>;
export function issuersitesAboutControllerNewsItemRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesAboutControllerNewsItemParams>) {
  return {
    match: (q ? issuersitesAboutControllerNewsItemPath.then(query(t.intersection([issuersitesAboutControllerNewsItemQuery, q]))) : issuersitesAboutControllerNewsItemPath.then(query(issuersitesAboutControllerNewsItemQuery))).then(end),
    pathParts: issuersitesAboutControllerNewsItemPathParts,
  };
}

export const issuersitesAboutControllerNewsItemCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported972_WithStatusCU<imported246_TaggedContentC<imported5_IssuerNewsWithRelatedContentC>>
} = {
  input: imported0_emptyObjectC,
  output: htmlC,
  ssrInput: imported973_WithStatusCU(imported246_taggedContentC(imported5_issuerNewsWithRelatedContentC))
};

export const issuersitesAboutControllerNewsItem = (p: IssuersitesAboutControllerNewsItemParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesAboutControllerNewsItemRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesAboutControllerNewsItemCodecs);

const issuersitesIssuerControllerCustomPath = str("issuerSlug").then(lit("additional-info")).then(type("issuerId", issuerIdC));
const issuersitesIssuerControllerCustomPathParts = ["issuerSlug", "additional-info", "issuerId"] as const;
export type IssuersitesIssuerControllerCustomPathParts = typeof issuersitesIssuerControllerCustomPathParts;

const issuersitesIssuerControllerCustomQuery = t.exact(t.type({
  "i": t.union([
    literalNumberFromString(1), literalNumberFromString(2), literalNumberFromString(3), literalNumberFromString(4), literalNumberFromString(5)
  ])
}));
export type IssuersitesIssuerControllerCustomParams = Describe<typeof issuersitesIssuerControllerCustomPath._A & typeof issuersitesIssuerControllerCustomQuery._A>;

export type IssuersitesIssuerControllerCustomRoute<A = {}> = { match: Match<IssuersitesIssuerControllerCustomParams & A>; pathParts: IssuersitesIssuerControllerCustomPathParts };

export function issuersitesIssuerControllerCustomRoute(): IssuersitesIssuerControllerCustomRoute;
export function issuersitesIssuerControllerCustomRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesIssuerControllerCustomParams>): IssuersitesIssuerControllerCustomRoute<t.TypeOf<A>>;
export function issuersitesIssuerControllerCustomRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesIssuerControllerCustomParams>) {
  return {
    match: (q ? issuersitesIssuerControllerCustomPath.then(query(t.intersection([issuersitesIssuerControllerCustomQuery, q]))) : issuersitesIssuerControllerCustomPath.then(query(issuersitesIssuerControllerCustomQuery))).then(end),
    pathParts: issuersitesIssuerControllerCustomPathParts,
  };
}

export const issuersitesIssuerControllerCustomCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported0_SitesCustomPageDataC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported0_sitesCustomPageDataC };

export const issuersitesIssuerControllerCustom = (p: IssuersitesIssuerControllerCustomParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesIssuerControllerCustomRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesIssuerControllerCustomCodecs);

const issuersitesDisclaimerControllerAcceptPath = lit("v2").then(str("issuerSlug")).then(lit("accept-disclaimer")).then(type("issuerId", issuerIdC));
const issuersitesDisclaimerControllerAcceptPathParts = ["v2", "issuerSlug", "accept-disclaimer", "issuerId"] as const;
export type IssuersitesDisclaimerControllerAcceptPathParts = typeof issuersitesDisclaimerControllerAcceptPathParts;

const issuersitesDisclaimerControllerAcceptQuery = t.strict({});
export type IssuersitesDisclaimerControllerAcceptParams = Describe<typeof issuersitesDisclaimerControllerAcceptPath._A & typeof issuersitesDisclaimerControllerAcceptQuery._A>;

export type IssuersitesDisclaimerControllerAcceptRoute<A = {}> = { match: Match<IssuersitesDisclaimerControllerAcceptParams & A>; pathParts: IssuersitesDisclaimerControllerAcceptPathParts };

export function issuersitesDisclaimerControllerAcceptRoute(): IssuersitesDisclaimerControllerAcceptRoute;
export function issuersitesDisclaimerControllerAcceptRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesDisclaimerControllerAcceptParams>): IssuersitesDisclaimerControllerAcceptRoute<t.TypeOf<A>>;
export function issuersitesDisclaimerControllerAcceptRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesDisclaimerControllerAcceptParams>) {
  return {
    match: (q ? issuersitesDisclaimerControllerAcceptPath.then(query(t.intersection([issuersitesDisclaimerControllerAcceptQuery, q]))) : issuersitesDisclaimerControllerAcceptPath.then(query(issuersitesDisclaimerControllerAcceptQuery))).then(end),
    pathParts: issuersitesDisclaimerControllerAcceptPathParts,
  };
}

export const issuersitesDisclaimerControllerAcceptCodecs: {
  input: imported0_AcceptDisclaimerPostC,
  output: imported0_EmptyObjectC
} = { input: imported0_acceptDisclaimerPostC, output: imported0_emptyObjectC };

export const issuersitesDisclaimerControllerAccept = (p: IssuersitesDisclaimerControllerAcceptParams): RelativeUrlInterfaceIO<"POST", imported0_AcceptDisclaimerPostC, imported0_EmptyObjectC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(issuersitesDisclaimerControllerAcceptRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesDisclaimerControllerAcceptCodecs);

const issuersitesIssuerControllerContactPostPath = str("issuerSlug").then(lit("contact")).then(type("issuerId", issuerIdC));
const issuersitesIssuerControllerContactPostPathParts = ["issuerSlug", "contact", "issuerId"] as const;
export type IssuersitesIssuerControllerContactPostPathParts = typeof issuersitesIssuerControllerContactPostPathParts;

const issuersitesIssuerControllerContactPostQuery = t.strict({});
export type IssuersitesIssuerControllerContactPostParams = Describe<typeof issuersitesIssuerControllerContactPostPath._A & typeof issuersitesIssuerControllerContactPostQuery._A>;

export type IssuersitesIssuerControllerContactPostRoute<A = {}> = { match: Match<IssuersitesIssuerControllerContactPostParams & A>; pathParts: IssuersitesIssuerControllerContactPostPathParts };

export function issuersitesIssuerControllerContactPostRoute(): IssuersitesIssuerControllerContactPostRoute;
export function issuersitesIssuerControllerContactPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesIssuerControllerContactPostParams>): IssuersitesIssuerControllerContactPostRoute<t.TypeOf<A>>;
export function issuersitesIssuerControllerContactPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesIssuerControllerContactPostParams>) {
  return {
    match: (q ? issuersitesIssuerControllerContactPostPath.then(query(t.intersection([issuersitesIssuerControllerContactPostQuery, q]))) : issuersitesIssuerControllerContactPostPath.then(query(issuersitesIssuerControllerContactPostQuery))).then(end),
    pathParts: issuersitesIssuerControllerContactPostPathParts,
  };
}

export const issuersitesIssuerControllerContactPostCodecs: {
  input: imported0_ContactPostC,
  output: imported0_EmptyObjectC
} = { input: imported0_contactPostC, output: imported0_emptyObjectC };

export const issuersitesIssuerControllerContactPost = (p: IssuersitesIssuerControllerContactPostParams): RelativeUrlInterfaceIO<"POST", imported0_ContactPostC, imported0_EmptyObjectC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(issuersitesIssuerControllerContactPostRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesIssuerControllerContactPostCodecs);

const issuersitesIssuerControllerConfirmSitePasswordPath = lit("v2").then(lit("confirm-site-password"));
const issuersitesIssuerControllerConfirmSitePasswordPathParts = ["v2", "confirm-site-password"] as const;
export type IssuersitesIssuerControllerConfirmSitePasswordPathParts = typeof issuersitesIssuerControllerConfirmSitePasswordPathParts;

const issuersitesIssuerControllerConfirmSitePasswordQuery = t.strict({});
export type IssuersitesIssuerControllerConfirmSitePasswordParams = Describe<typeof issuersitesIssuerControllerConfirmSitePasswordPath._A & typeof issuersitesIssuerControllerConfirmSitePasswordQuery._A>;

export type IssuersitesIssuerControllerConfirmSitePasswordRoute<A = {}> = { match: Match<IssuersitesIssuerControllerConfirmSitePasswordParams & A>; pathParts: IssuersitesIssuerControllerConfirmSitePasswordPathParts };

export function issuersitesIssuerControllerConfirmSitePasswordRoute(): IssuersitesIssuerControllerConfirmSitePasswordRoute;
export function issuersitesIssuerControllerConfirmSitePasswordRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesIssuerControllerConfirmSitePasswordParams>): IssuersitesIssuerControllerConfirmSitePasswordRoute<t.TypeOf<A>>;
export function issuersitesIssuerControllerConfirmSitePasswordRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesIssuerControllerConfirmSitePasswordParams>) {
  return {
    match: (q ? issuersitesIssuerControllerConfirmSitePasswordPath.then(query(t.intersection([issuersitesIssuerControllerConfirmSitePasswordQuery, q]))) : issuersitesIssuerControllerConfirmSitePasswordPath.then(query(issuersitesIssuerControllerConfirmSitePasswordQuery))).then(end),
    pathParts: issuersitesIssuerControllerConfirmSitePasswordPathParts,
  };
}

export const issuersitesIssuerControllerConfirmSitePasswordCodecs: {
  input: imported0_ConfirmSitePasswordC,
  output: imported7_ApiRedirectC
} = { input: imported0_confirmSitePasswordC, output: imported7_apiRedirectC };

export const issuersitesIssuerControllerConfirmSitePassword = (): RelativeUrlInterfaceIO<"POST", imported0_ConfirmSitePasswordC, imported7_ApiRedirectC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(issuersitesIssuerControllerConfirmSitePasswordRoute().match.formatter.run(Route.empty, {}).toString(true)), issuersitesIssuerControllerConfirmSitePasswordCodecs);

const issuersitesRfpsControllerRfpsPath = str("issuerSlug").then(lit("rfps")).then(type("issuerId", issuerIdC));
const issuersitesRfpsControllerRfpsPathParts = ["issuerSlug", "rfps", "issuerId"] as const;
export type IssuersitesRfpsControllerRfpsPathParts = typeof issuersitesRfpsControllerRfpsPathParts;

const issuersitesRfpsControllerRfpsQuery = t.strict({});
export type IssuersitesRfpsControllerRfpsParams = Describe<typeof issuersitesRfpsControllerRfpsPath._A & typeof issuersitesRfpsControllerRfpsQuery._A>;

export type IssuersitesRfpsControllerRfpsRoute<A = {}> = { match: Match<IssuersitesRfpsControllerRfpsParams & A>; pathParts: IssuersitesRfpsControllerRfpsPathParts };

export function issuersitesRfpsControllerRfpsRoute(): IssuersitesRfpsControllerRfpsRoute;
export function issuersitesRfpsControllerRfpsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesRfpsControllerRfpsParams>): IssuersitesRfpsControllerRfpsRoute<t.TypeOf<A>>;
export function issuersitesRfpsControllerRfpsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesRfpsControllerRfpsParams>) {
  return {
    match: (q ? issuersitesRfpsControllerRfpsPath.then(query(t.intersection([issuersitesRfpsControllerRfpsQuery, q]))) : issuersitesRfpsControllerRfpsPath.then(query(issuersitesRfpsControllerRfpsQuery))).then(end),
    pathParts: issuersitesRfpsControllerRfpsPathParts,
  };
}

export const issuersitesRfpsControllerRfpsCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported0_RfpsSitesListC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported0_rfpsSitesListC };

export const issuersitesRfpsControllerRfps = (p: IssuersitesRfpsControllerRfpsParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesRfpsControllerRfpsRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesRfpsControllerRfpsCodecs);

const issuersitesRfpsControllerRfpPath = str("issuerSlug").then(lit("rfp")).then(type("issuerId", issuerIdC));
const issuersitesRfpsControllerRfpPathParts = ["issuerSlug", "rfp", "issuerId"] as const;
export type IssuersitesRfpsControllerRfpPathParts = typeof issuersitesRfpsControllerRfpPathParts;

const issuersitesRfpsControllerRfpQuery = t.exact(t.type({
  "rfpId": NumberFromString
}));
export type IssuersitesRfpsControllerRfpParams = Describe<typeof issuersitesRfpsControllerRfpPath._A & typeof issuersitesRfpsControllerRfpQuery._A>;

export type IssuersitesRfpsControllerRfpRoute<A = {}> = { match: Match<IssuersitesRfpsControllerRfpParams & A>; pathParts: IssuersitesRfpsControllerRfpPathParts };

export function issuersitesRfpsControllerRfpRoute(): IssuersitesRfpsControllerRfpRoute;
export function issuersitesRfpsControllerRfpRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesRfpsControllerRfpParams>): IssuersitesRfpsControllerRfpRoute<t.TypeOf<A>>;
export function issuersitesRfpsControllerRfpRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesRfpsControllerRfpParams>) {
  return {
    match: (q ? issuersitesRfpsControllerRfpPath.then(query(t.intersection([issuersitesRfpsControllerRfpQuery, q]))) : issuersitesRfpsControllerRfpPath.then(query(issuersitesRfpsControllerRfpQuery))).then(end),
    pathParts: issuersitesRfpsControllerRfpPathParts,
  };
}

export const issuersitesRfpsControllerRfpCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: EitherC<imported2_BankRfpSitesDataC, imported0_DirectRfpSitesDataC>
} = {
  input: imported0_emptyObjectC,
  output: htmlC,
  ssrInput: either(imported2_bankRfpSitesDataC, imported0_directRfpSitesDataC)
};

export const issuersitesRfpsControllerRfp = (p: IssuersitesRfpsControllerRfpParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesRfpsControllerRfpRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesRfpsControllerRfpCodecs);

const issuersitesIssuerControllerEnterPreviewPath = lit("preview").then(lit("on"));
const issuersitesIssuerControllerEnterPreviewPathParts = ["preview", "on"] as const;
export type IssuersitesIssuerControllerEnterPreviewPathParts = typeof issuersitesIssuerControllerEnterPreviewPathParts;

const issuersitesIssuerControllerEnterPreviewQuery = t.strict({});
export type IssuersitesIssuerControllerEnterPreviewParams = Describe<typeof issuersitesIssuerControllerEnterPreviewPath._A & typeof issuersitesIssuerControllerEnterPreviewQuery._A>;

export type IssuersitesIssuerControllerEnterPreviewRoute<A = {}> = { match: Match<IssuersitesIssuerControllerEnterPreviewParams & A>; pathParts: IssuersitesIssuerControllerEnterPreviewPathParts };

export function issuersitesIssuerControllerEnterPreviewRoute(): IssuersitesIssuerControllerEnterPreviewRoute;
export function issuersitesIssuerControllerEnterPreviewRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesIssuerControllerEnterPreviewParams>): IssuersitesIssuerControllerEnterPreviewRoute<t.TypeOf<A>>;
export function issuersitesIssuerControllerEnterPreviewRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesIssuerControllerEnterPreviewParams>) {
  return {
    match: (q ? issuersitesIssuerControllerEnterPreviewPath.then(query(t.intersection([issuersitesIssuerControllerEnterPreviewQuery, q]))) : issuersitesIssuerControllerEnterPreviewPath.then(query(issuersitesIssuerControllerEnterPreviewQuery))).then(end),
    pathParts: issuersitesIssuerControllerEnterPreviewPathParts,
  };
}

export const issuersitesIssuerControllerEnterPreviewCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_EmptyObjectC
} = { input: imported0_emptyObjectC, output: imported0_emptyObjectC };

export const issuersitesIssuerControllerEnterPreview = (): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, imported0_EmptyObjectC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesIssuerControllerEnterPreviewRoute().match.formatter.run(Route.empty, {}).toString(true)), issuersitesIssuerControllerEnterPreviewCodecs);

const issuersitesIssuerControllerExitPreviewPath = lit("preview").then(lit("off"));
const issuersitesIssuerControllerExitPreviewPathParts = ["preview", "off"] as const;
export type IssuersitesIssuerControllerExitPreviewPathParts = typeof issuersitesIssuerControllerExitPreviewPathParts;

const issuersitesIssuerControllerExitPreviewQuery = t.strict({});
export type IssuersitesIssuerControllerExitPreviewParams = Describe<typeof issuersitesIssuerControllerExitPreviewPath._A & typeof issuersitesIssuerControllerExitPreviewQuery._A>;

export type IssuersitesIssuerControllerExitPreviewRoute<A = {}> = { match: Match<IssuersitesIssuerControllerExitPreviewParams & A>; pathParts: IssuersitesIssuerControllerExitPreviewPathParts };

export function issuersitesIssuerControllerExitPreviewRoute(): IssuersitesIssuerControllerExitPreviewRoute;
export function issuersitesIssuerControllerExitPreviewRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesIssuerControllerExitPreviewParams>): IssuersitesIssuerControllerExitPreviewRoute<t.TypeOf<A>>;
export function issuersitesIssuerControllerExitPreviewRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesIssuerControllerExitPreviewParams>) {
  return {
    match: (q ? issuersitesIssuerControllerExitPreviewPath.then(query(t.intersection([issuersitesIssuerControllerExitPreviewQuery, q]))) : issuersitesIssuerControllerExitPreviewPath.then(query(issuersitesIssuerControllerExitPreviewQuery))).then(end),
    pathParts: issuersitesIssuerControllerExitPreviewPathParts,
  };
}

export const issuersitesIssuerControllerExitPreviewCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_EmptyObjectC
} = { input: imported0_emptyObjectC, output: imported0_emptyObjectC };

export const issuersitesIssuerControllerExitPreview = (): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, imported0_EmptyObjectC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesIssuerControllerExitPreviewRoute().match.formatter.run(Route.empty, {}).toString(true)), issuersitesIssuerControllerExitPreviewCodecs);

const issuersitesIssuerControllerExternalLinkRedirectPath = str("issuerSlug").then(lit("external-link")).then(lit("redirect")).then(type("issuerId", issuerIdC));
const issuersitesIssuerControllerExternalLinkRedirectPathParts = [
  "issuerSlug", "external-link", "redirect", "issuerId"
] as const;
export type IssuersitesIssuerControllerExternalLinkRedirectPathParts = typeof issuersitesIssuerControllerExternalLinkRedirectPathParts;

const issuersitesIssuerControllerExternalLinkRedirectQuery = t.exact(t.type({
  "linkId": NumberFromString
}));
export type IssuersitesIssuerControllerExternalLinkRedirectParams = Describe<typeof issuersitesIssuerControllerExternalLinkRedirectPath._A & typeof issuersitesIssuerControllerExternalLinkRedirectQuery._A>;

export type IssuersitesIssuerControllerExternalLinkRedirectRoute<A = {}> = { match: Match<IssuersitesIssuerControllerExternalLinkRedirectParams & A>; pathParts: IssuersitesIssuerControllerExternalLinkRedirectPathParts };

export function issuersitesIssuerControllerExternalLinkRedirectRoute(): IssuersitesIssuerControllerExternalLinkRedirectRoute;
export function issuersitesIssuerControllerExternalLinkRedirectRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesIssuerControllerExternalLinkRedirectParams>): IssuersitesIssuerControllerExternalLinkRedirectRoute<t.TypeOf<A>>;
export function issuersitesIssuerControllerExternalLinkRedirectRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesIssuerControllerExternalLinkRedirectParams>) {
  return {
    match: (q ? issuersitesIssuerControllerExternalLinkRedirectPath.then(query(t.intersection([issuersitesIssuerControllerExternalLinkRedirectQuery, q]))) : issuersitesIssuerControllerExternalLinkRedirectPath.then(query(issuersitesIssuerControllerExternalLinkRedirectQuery))).then(end),
    pathParts: issuersitesIssuerControllerExternalLinkRedirectPathParts,
  };
}

export const issuersitesIssuerControllerExternalLinkRedirectCodecs: {
  input: imported0_EmptyObjectC,
  output: imported27_BrowserRedirectC
} = { input: imported0_emptyObjectC, output: imported27_browserRedirectC };

export const issuersitesIssuerControllerExternalLinkRedirect = (p: IssuersitesIssuerControllerExternalLinkRedirectParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, imported27_BrowserRedirectC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesIssuerControllerExternalLinkRedirectRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesIssuerControllerExternalLinkRedirectCodecs);

const issuersitesLanderControllerIndexPath = str("issuerSlug").then(lit("lander")).then(type("partner", t.union([
  t.literal("bondnav"), t.literal("fidelity"), t.literal("ice"), t.literal("ipreo")
]))).then(type("issuerId", issuerIdC));
const issuersitesLanderControllerIndexPathParts = ["issuerSlug", "lander", "partner", "issuerId"] as const;
export type IssuersitesLanderControllerIndexPathParts = typeof issuersitesLanderControllerIndexPathParts;

const issuersitesLanderControllerIndexQuery = t.strict({});
export type IssuersitesLanderControllerIndexParams = Describe<typeof issuersitesLanderControllerIndexPath._A & typeof issuersitesLanderControllerIndexQuery._A>;

export type IssuersitesLanderControllerIndexRoute<A = {}> = { match: Match<IssuersitesLanderControllerIndexParams & A>; pathParts: IssuersitesLanderControllerIndexPathParts };

export function issuersitesLanderControllerIndexRoute(): IssuersitesLanderControllerIndexRoute;
export function issuersitesLanderControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesLanderControllerIndexParams>): IssuersitesLanderControllerIndexRoute<t.TypeOf<A>>;
export function issuersitesLanderControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesLanderControllerIndexParams>) {
  return {
    match: (q ? issuersitesLanderControllerIndexPath.then(query(t.intersection([issuersitesLanderControllerIndexQuery, q]))) : issuersitesLanderControllerIndexPath.then(query(issuersitesLanderControllerIndexQuery))).then(end),
    pathParts: issuersitesLanderControllerIndexPathParts,
  };
}

export const issuersitesLanderControllerIndexCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported0_IssuerPartnerLanderPageDataC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported0_issuerPartnerLanderPageDataC };

export const issuersitesLanderControllerIndex = (p: IssuersitesLanderControllerIndexParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(issuersitesLanderControllerIndexRoute().match.formatter.run(Route.empty, p).toString(true)), issuersitesLanderControllerIndexCodecs);

const issuersitesSsrShimBLPDealUnavailablePath = lit("issuersites").then(lit("blp-deal-not-available"));
const issuersitesSsrShimBLPDealUnavailablePathParts = ["issuersites", "blp-deal-not-available"] as const;
export type IssuersitesSsrShimBLPDealUnavailablePathParts = typeof issuersitesSsrShimBLPDealUnavailablePathParts;

const issuersitesSsrShimBLPDealUnavailableQuery = t.strict({});
export type IssuersitesSsrShimBLPDealUnavailableParams = Describe<typeof issuersitesSsrShimBLPDealUnavailablePath._A & typeof issuersitesSsrShimBLPDealUnavailableQuery._A>;

export type IssuersitesSsrShimBLPDealUnavailableRoute<A = {}> = { match: Match<IssuersitesSsrShimBLPDealUnavailableParams & A>; pathParts: IssuersitesSsrShimBLPDealUnavailablePathParts };

export function issuersitesSsrShimBLPDealUnavailableRoute(): IssuersitesSsrShimBLPDealUnavailableRoute;
export function issuersitesSsrShimBLPDealUnavailableRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesSsrShimBLPDealUnavailableParams>): IssuersitesSsrShimBLPDealUnavailableRoute<t.TypeOf<A>>;
export function issuersitesSsrShimBLPDealUnavailableRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesSsrShimBLPDealUnavailableParams>) {
  return {
    match: (q ? issuersitesSsrShimBLPDealUnavailablePath.then(query(t.intersection([issuersitesSsrShimBLPDealUnavailableQuery, q]))) : issuersitesSsrShimBLPDealUnavailablePath.then(query(issuersitesSsrShimBLPDealUnavailableQuery))).then(end),
    pathParts: issuersitesSsrShimBLPDealUnavailablePathParts,
  };
}

export const issuersitesSsrShimBLPDealUnavailableCodecs: { ssrInput: imported0_BLPDealUnavailablePageDataC } = {
  ssrInput: imported0_bLPDealUnavailablePageDataC
};

export const issuersitesSsrShimBLPDealUnavailable = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(issuersitesSsrShimBLPDealUnavailableRoute().match.formatter.run(Route.empty, {}).toString(true)));

const issuersitesSsrShimNotFoundPath = lit("issuersites").then(lit("not-found"));
const issuersitesSsrShimNotFoundPathParts = ["issuersites", "not-found"] as const;
export type IssuersitesSsrShimNotFoundPathParts = typeof issuersitesSsrShimNotFoundPathParts;

const issuersitesSsrShimNotFoundQuery = t.strict({});
export type IssuersitesSsrShimNotFoundParams = Describe<typeof issuersitesSsrShimNotFoundPath._A & typeof issuersitesSsrShimNotFoundQuery._A>;

export type IssuersitesSsrShimNotFoundRoute<A = {}> = { match: Match<IssuersitesSsrShimNotFoundParams & A>; pathParts: IssuersitesSsrShimNotFoundPathParts };

export function issuersitesSsrShimNotFoundRoute(): IssuersitesSsrShimNotFoundRoute;
export function issuersitesSsrShimNotFoundRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesSsrShimNotFoundParams>): IssuersitesSsrShimNotFoundRoute<t.TypeOf<A>>;
export function issuersitesSsrShimNotFoundRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesSsrShimNotFoundParams>) {
  return {
    match: (q ? issuersitesSsrShimNotFoundPath.then(query(t.intersection([issuersitesSsrShimNotFoundQuery, q]))) : issuersitesSsrShimNotFoundPath.then(query(issuersitesSsrShimNotFoundQuery))).then(end),
    pathParts: issuersitesSsrShimNotFoundPathParts,
  };
}

export const issuersitesSsrShimNotFoundCodecs: { ssrInput: imported0_SitesErrorPageDataC } = {
  ssrInput: imported0_sitesErrorPageDataC
};

export const issuersitesSsrShimNotFound = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(issuersitesSsrShimNotFoundRoute().match.formatter.run(Route.empty, {}).toString(true)));

const issuersitesSsrShimForbiddenPath = lit("issuersites").then(lit("forbidden"));
const issuersitesSsrShimForbiddenPathParts = ["issuersites", "forbidden"] as const;
export type IssuersitesSsrShimForbiddenPathParts = typeof issuersitesSsrShimForbiddenPathParts;

const issuersitesSsrShimForbiddenQuery = t.strict({});
export type IssuersitesSsrShimForbiddenParams = Describe<typeof issuersitesSsrShimForbiddenPath._A & typeof issuersitesSsrShimForbiddenQuery._A>;

export type IssuersitesSsrShimForbiddenRoute<A = {}> = { match: Match<IssuersitesSsrShimForbiddenParams & A>; pathParts: IssuersitesSsrShimForbiddenPathParts };

export function issuersitesSsrShimForbiddenRoute(): IssuersitesSsrShimForbiddenRoute;
export function issuersitesSsrShimForbiddenRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesSsrShimForbiddenParams>): IssuersitesSsrShimForbiddenRoute<t.TypeOf<A>>;
export function issuersitesSsrShimForbiddenRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesSsrShimForbiddenParams>) {
  return {
    match: (q ? issuersitesSsrShimForbiddenPath.then(query(t.intersection([issuersitesSsrShimForbiddenQuery, q]))) : issuersitesSsrShimForbiddenPath.then(query(issuersitesSsrShimForbiddenQuery))).then(end),
    pathParts: issuersitesSsrShimForbiddenPathParts,
  };
}

export const issuersitesSsrShimForbiddenCodecs: { ssrInput: imported1_SitesErrorPageDataC } = {
  ssrInput: imported1_sitesErrorPageDataC
};

export const issuersitesSsrShimForbidden = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(issuersitesSsrShimForbiddenRoute().match.formatter.run(Route.empty, {}).toString(true)));

const issuersitesSsrShimServerErrorPath = lit("issuersites").then(lit("server-error"));
const issuersitesSsrShimServerErrorPathParts = ["issuersites", "server-error"] as const;
export type IssuersitesSsrShimServerErrorPathParts = typeof issuersitesSsrShimServerErrorPathParts;

const issuersitesSsrShimServerErrorQuery = t.strict({});
export type IssuersitesSsrShimServerErrorParams = Describe<typeof issuersitesSsrShimServerErrorPath._A & typeof issuersitesSsrShimServerErrorQuery._A>;

export type IssuersitesSsrShimServerErrorRoute<A = {}> = { match: Match<IssuersitesSsrShimServerErrorParams & A>; pathParts: IssuersitesSsrShimServerErrorPathParts };

export function issuersitesSsrShimServerErrorRoute(): IssuersitesSsrShimServerErrorRoute;
export function issuersitesSsrShimServerErrorRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersitesSsrShimServerErrorParams>): IssuersitesSsrShimServerErrorRoute<t.TypeOf<A>>;
export function issuersitesSsrShimServerErrorRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersitesSsrShimServerErrorParams>) {
  return {
    match: (q ? issuersitesSsrShimServerErrorPath.then(query(t.intersection([issuersitesSsrShimServerErrorQuery, q]))) : issuersitesSsrShimServerErrorPath.then(query(issuersitesSsrShimServerErrorQuery))).then(end),
    pathParts: issuersitesSsrShimServerErrorPathParts,
  };
}

export const issuersitesSsrShimServerErrorCodecs: { ssrInput: imported2_SitesErrorPageDataC } = {
  ssrInput: imported2_sitesErrorPageDataC
};

export const issuersitesSsrShimServerError = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(issuersitesSsrShimServerErrorRoute().match.formatter.run(Route.empty, {}).toString(true)));