import { flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import type { Predicate } from "fp-ts/lib/Predicate";
import { and, not } from "fp-ts/lib/Predicate";
import * as t from "io-ts";

const isNotNaN: Predicate<number> = pipe(t.number.is, and(not(isNaN)));

const isFinite_: Predicate<number> = Number.isFinite;

// We could use a branded type or even a newtype here, if we wanted:
const isFinite: Predicate<number> = pipe(isNotNaN, and(isFinite_));

/**
 * `parseInt` is stricter than JavaScript's native `parseInt`. If you're
 * using the global `parseInt` for its coercive (lossy) behavior, you should
 * probably keep using the global version.
 *
 * Notice how TypeScript infers that this function optionally accepts a
 * second argument for specifying the radix.
 */

export const parseInt = flow(
  O.tryCatchK(globalThis.parseInt),
  O.filter(isFinite),
  O.chainEitherK(t.Int.decode),
);
