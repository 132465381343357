import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { cusip6Name as imported13_cusip6Name, cusip9MaturityDate as imported8_cusip9MaturityDate, cusip9Cusip as imported8_cusip9Cusip, cusip9DatedDate as imported8_cusip9DatedDate, cusip6Name as imported12_cusip6Name, cusip9IssueDate as imported8_cusip9IssueDate, cusip6Cusip6 as imported6_cusip6Cusip6, cusip9BondName as imported17_cusip9BondName, cusip9BondName as imported16_cusip9BondName } from "./sortColumns";
import * as O from "fp-ts/lib/Option";
import { ASC as imported16_ASC, ASC as imported18_ASC } from "./sortDir";
import { pipe } from "fp-ts/lib/function";
import { customPage5 as imported20_customPage5, customPage2 as imported20_customPage2, customPage4 as imported20_customPage4, customPage3 as imported20_customPage3, customPage1 as imported20_customPage1 } from "./clientTextTypes";
import { customPage3Photos as imported6_customPage3Photos, customPage5Photos as imported6_customPage5Photos, customPage1Photos as imported6_customPage1Photos, customPage2Photos as imported6_customPage2Photos, customPage4Photos as imported6_customPage4Photos } from "./mediaCategories";
import * as Ord from "fp-ts/lib/Ord";
import { customPage1 as imported19_customPage1, projects as imported6_projects, customPage2 as imported19_customPage2, showIRMALetter as imported2_showIRMALetter, bondPrograms as imported6_bondPrograms, financeTeam as imported6_financeTeam, esgProgram as imported10_esgProgram, news as imported4_news, emma as imported8_emma, bondRatings as imported4_bondRatings, contact as imported4_contact, customPage5 as imported18_customPage5, linksPage as imported6_linksPage, activeRfps as imported6_activeRfps, customPage3 as imported18_customPage3, relatedIssuers as imported4_relatedIssuers, customPage4 as imported18_customPage4, customPage2 as imported18_customPage2, customPage5 as imported19_customPage5, cusip6 as imported6_cusip6, showArchivedBonds as imported4_showArchivedBonds, faq as imported4_faq, customPage1 as imported18_customPage1, customPage4 as imported19_customPage4, customPage3 as imported19_customPage3 } from "./featureFlags";

export const emmaLinks = {
  _tag: `EmmaLinks`,
  defaultSortCol: imported16_cusip9BondName,
  defaultSortDir: imported16_ASC,
  enablingFlag: O.some(imported8_emma),
  id: 5,
  name: `MSRB EMMA® Links`,
  sortColumns: [imported17_cusip9BondName, imported8_cusip9Cusip, imported8_cusip9DatedDate, imported8_cusip9IssueDate, imported8_cusip9MaturityDate]
} as const;

export type EmmaLinksTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EmmaLinks`>
}>;
export const emmaLinksTaggedC: EmmaLinksTaggedC = t.type({
  _tag: t.literal(`EmmaLinks`)
});
export type EmmaLinksTagged = t.TypeOf<EmmaLinksTaggedC>;
export type EmmaLinks = EmmaLinksTagged & typeof emmaLinks;
export type EmmaLinksC = t.Type<EmmaLinks, EmmaLinksTagged>;
export const emmaLinksC: EmmaLinksC = pipe(emmaLinksTaggedC, c => new t.Type<EmmaLinks, EmmaLinksTagged>(
  `EmmaLinks`,
  (u: unknown): u is EmmaLinks => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EmmaLinks> => pipe(c.decode(u), E.map(x => ({ ...x, ...emmaLinks }))),
  (x: EmmaLinks): EmmaLinksTagged => ({ ...x, _tag: `EmmaLinks`}),
)) satisfies t.Type<EmmaLinks, unknown>;


export const cusip6 = {
  _tag: `Cusip6`,
  defaultSortCol: imported12_cusip6Name,
  defaultSortDir: imported18_ASC,
  enablingFlag: O.some(imported6_cusip6),
  id: 14,
  name: `CUSIP-6`,
  sortColumns: [imported6_cusip6Cusip6, imported13_cusip6Name]
} as const;

export type Cusip6TaggedC = t.TypeC<{
  _tag: t.LiteralC<`Cusip6`>
}>;
export const cusip6TaggedC: Cusip6TaggedC = t.type({
  _tag: t.literal(`Cusip6`)
});
export type Cusip6Tagged = t.TypeOf<Cusip6TaggedC>;
export type Cusip6 = Cusip6Tagged & typeof cusip6;
export type Cusip6C = t.Type<Cusip6, Cusip6Tagged>;
export const cusip6C: Cusip6C = pipe(cusip6TaggedC, c => new t.Type<Cusip6, Cusip6Tagged>(
  `Cusip6`,
  (u: unknown): u is Cusip6 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Cusip6> => pipe(c.decode(u), E.map(x => ({ ...x, ...cusip6 }))),
  (x: Cusip6): Cusip6Tagged => ({ ...x, _tag: `Cusip6`}),
)) satisfies t.Type<Cusip6, unknown>;


export const customPage1 = {
  _tag: `CustomPage1`,
  enablingFlag: O.some(imported18_customPage1),
  ff: imported19_customPage1,
  id: 9,
  index: 1,
  mc: imported6_customPage1Photos,
  name: `Custom Page 1`,
  tt: imported20_customPage1
} as const;

export type CustomPage1TaggedC = t.TypeC<{
  _tag: t.LiteralC<`CustomPage1`>
}>;
export const customPage1TaggedC: CustomPage1TaggedC = t.type({
  _tag: t.literal(`CustomPage1`)
});
export type CustomPage1Tagged = t.TypeOf<CustomPage1TaggedC>;
export type CustomPage1 = CustomPage1Tagged & typeof customPage1;
export type CustomPage1C = t.Type<CustomPage1, CustomPage1Tagged>;
export const customPage1C: CustomPage1C = pipe(customPage1TaggedC, c => new t.Type<CustomPage1, CustomPage1Tagged>(
  `CustomPage1`,
  (u: unknown): u is CustomPage1 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage1> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage1 }))),
  (x: CustomPage1): CustomPage1Tagged => ({ ...x, _tag: `CustomPage1`}),
)) satisfies t.Type<CustomPage1, unknown>;


export const customPage2 = {
  _tag: `CustomPage2`,
  enablingFlag: O.some(imported18_customPage2),
  ff: imported19_customPage2,
  id: 10,
  index: 2,
  mc: imported6_customPage2Photos,
  name: `Custom Page 2`,
  tt: imported20_customPage2
} as const;

export type CustomPage2TaggedC = t.TypeC<{
  _tag: t.LiteralC<`CustomPage2`>
}>;
export const customPage2TaggedC: CustomPage2TaggedC = t.type({
  _tag: t.literal(`CustomPage2`)
});
export type CustomPage2Tagged = t.TypeOf<CustomPage2TaggedC>;
export type CustomPage2 = CustomPage2Tagged & typeof customPage2;
export type CustomPage2C = t.Type<CustomPage2, CustomPage2Tagged>;
export const customPage2C: CustomPage2C = pipe(customPage2TaggedC, c => new t.Type<CustomPage2, CustomPage2Tagged>(
  `CustomPage2`,
  (u: unknown): u is CustomPage2 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage2> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage2 }))),
  (x: CustomPage2): CustomPage2Tagged => ({ ...x, _tag: `CustomPage2`}),
)) satisfies t.Type<CustomPage2, unknown>;


export const customPage3 = {
  _tag: `CustomPage3`,
  enablingFlag: O.some(imported18_customPage3),
  ff: imported19_customPage3,
  id: 19,
  index: 3,
  mc: imported6_customPage3Photos,
  name: `Custom Page 3`,
  tt: imported20_customPage3
} as const;

export type CustomPage3TaggedC = t.TypeC<{
  _tag: t.LiteralC<`CustomPage3`>
}>;
export const customPage3TaggedC: CustomPage3TaggedC = t.type({
  _tag: t.literal(`CustomPage3`)
});
export type CustomPage3Tagged = t.TypeOf<CustomPage3TaggedC>;
export type CustomPage3 = CustomPage3Tagged & typeof customPage3;
export type CustomPage3C = t.Type<CustomPage3, CustomPage3Tagged>;
export const customPage3C: CustomPage3C = pipe(customPage3TaggedC, c => new t.Type<CustomPage3, CustomPage3Tagged>(
  `CustomPage3`,
  (u: unknown): u is CustomPage3 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage3> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage3 }))),
  (x: CustomPage3): CustomPage3Tagged => ({ ...x, _tag: `CustomPage3`}),
)) satisfies t.Type<CustomPage3, unknown>;


export const customPage4 = {
  _tag: `CustomPage4`,
  enablingFlag: O.some(imported18_customPage4),
  ff: imported19_customPage4,
  id: 20,
  index: 4,
  mc: imported6_customPage4Photos,
  name: `Custom Page 4`,
  tt: imported20_customPage4
} as const;

export type CustomPage4TaggedC = t.TypeC<{
  _tag: t.LiteralC<`CustomPage4`>
}>;
export const customPage4TaggedC: CustomPage4TaggedC = t.type({
  _tag: t.literal(`CustomPage4`)
});
export type CustomPage4Tagged = t.TypeOf<CustomPage4TaggedC>;
export type CustomPage4 = CustomPage4Tagged & typeof customPage4;
export type CustomPage4C = t.Type<CustomPage4, CustomPage4Tagged>;
export const customPage4C: CustomPage4C = pipe(customPage4TaggedC, c => new t.Type<CustomPage4, CustomPage4Tagged>(
  `CustomPage4`,
  (u: unknown): u is CustomPage4 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage4> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage4 }))),
  (x: CustomPage4): CustomPage4Tagged => ({ ...x, _tag: `CustomPage4`}),
)) satisfies t.Type<CustomPage4, unknown>;


export const customPage5 = {
  _tag: `CustomPage5`,
  enablingFlag: O.some(imported18_customPage5),
  ff: imported19_customPage5,
  id: 21,
  index: 5,
  mc: imported6_customPage5Photos,
  name: `Custom Page 5`,
  tt: imported20_customPage5
} as const;

export type CustomPage5TaggedC = t.TypeC<{
  _tag: t.LiteralC<`CustomPage5`>
}>;
export const customPage5TaggedC: CustomPage5TaggedC = t.type({
  _tag: t.literal(`CustomPage5`)
});
export type CustomPage5Tagged = t.TypeOf<CustomPage5TaggedC>;
export type CustomPage5 = CustomPage5Tagged & typeof customPage5;
export type CustomPage5C = t.Type<CustomPage5, CustomPage5Tagged>;
export const customPage5C: CustomPage5C = pipe(customPage5TaggedC, c => new t.Type<CustomPage5, CustomPage5Tagged>(
  `CustomPage5`,
  (u: unknown): u is CustomPage5 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage5> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage5 }))),
  (x: CustomPage5): CustomPage5Tagged => ({ ...x, _tag: `CustomPage5`}),
)) satisfies t.Type<CustomPage5, unknown>;


export const financeTeam = {
  _tag: `FinanceTeam`,
  enablingFlag: O.some(imported6_financeTeam),
  id: 1,
  name: `Team`
} as const;

export type FinanceTeamTaggedC = t.TypeC<{
  _tag: t.LiteralC<`FinanceTeam`>
}>;
export const financeTeamTaggedC: FinanceTeamTaggedC = t.type({
  _tag: t.literal(`FinanceTeam`)
});
export type FinanceTeamTagged = t.TypeOf<FinanceTeamTaggedC>;
export type FinanceTeam = FinanceTeamTagged & typeof financeTeam;
export type FinanceTeamC = t.Type<FinanceTeam, FinanceTeamTagged>;
export const financeTeamC: FinanceTeamC = pipe(financeTeamTaggedC, c => new t.Type<FinanceTeam, FinanceTeamTagged>(
  `FinanceTeam`,
  (u: unknown): u is FinanceTeam => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FinanceTeam> => pipe(c.decode(u), E.map(x => ({ ...x, ...financeTeam }))),
  (x: FinanceTeam): FinanceTeamTagged => ({ ...x, _tag: `FinanceTeam`}),
)) satisfies t.Type<FinanceTeam, unknown>;


export const infrastructureProjects = {
  _tag: `InfrastructureProjects`,
  enablingFlag: O.some(imported6_projects),
  id: 6,
  name: `Projects`
} as const;

export type InfrastructureProjectsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`InfrastructureProjects`>
}>;
export const infrastructureProjectsTaggedC: InfrastructureProjectsTaggedC = t.type({
  _tag: t.literal(`InfrastructureProjects`)
});
export type InfrastructureProjectsTagged = t.TypeOf<InfrastructureProjectsTaggedC>;
export type InfrastructureProjects = InfrastructureProjectsTagged & typeof infrastructureProjects;
export type InfrastructureProjectsC = t.Type<InfrastructureProjects, InfrastructureProjectsTagged>;
export const infrastructureProjectsC: InfrastructureProjectsC = pipe(infrastructureProjectsTaggedC, c => new t.Type<InfrastructureProjects, InfrastructureProjectsTagged>(
  `InfrastructureProjects`,
  (u: unknown): u is InfrastructureProjects => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InfrastructureProjects> => pipe(c.decode(u), E.map(x => ({ ...x, ...infrastructureProjects }))),
  (x: InfrastructureProjects): InfrastructureProjectsTagged => ({ ...x, _tag: `InfrastructureProjects`}),
)) satisfies t.Type<InfrastructureProjects, unknown>;


export const news = {
  _tag: `News`,
  enablingFlag: O.some(imported4_news),
  id: 7,
  name: `News & Press Releases`
} as const;

export type NewsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`News`>
}>;
export const newsTaggedC: NewsTaggedC = t.type({
  _tag: t.literal(`News`)
});
export type NewsTagged = t.TypeOf<NewsTaggedC>;
export type News = NewsTagged & typeof news;
export type NewsC = t.Type<News, NewsTagged>;
export const newsC: NewsC = pipe(newsTaggedC, c => new t.Type<News, NewsTagged>(
  `News`,
  (u: unknown): u is News => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, News> => pipe(c.decode(u), E.map(x => ({ ...x, ...news }))),
  (x: News): NewsTagged => ({ ...x, _tag: `News`}),
)) satisfies t.Type<News, unknown>;


export const relatedIssuers = {
  _tag: `RelatedIssuers`,
  enablingFlag: O.some(imported4_relatedIssuers),
  id: 8,
  name: `Related Issuers`
} as const;

export type RelatedIssuersTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RelatedIssuers`>
}>;
export const relatedIssuersTaggedC: RelatedIssuersTaggedC = t.type({
  _tag: t.literal(`RelatedIssuers`)
});
export type RelatedIssuersTagged = t.TypeOf<RelatedIssuersTaggedC>;
export type RelatedIssuers = RelatedIssuersTagged & typeof relatedIssuers;
export type RelatedIssuersC = t.Type<RelatedIssuers, RelatedIssuersTagged>;
export const relatedIssuersC: RelatedIssuersC = pipe(relatedIssuersTaggedC, c => new t.Type<RelatedIssuers, RelatedIssuersTagged>(
  `RelatedIssuers`,
  (u: unknown): u is RelatedIssuers => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RelatedIssuers> => pipe(c.decode(u), E.map(x => ({ ...x, ...relatedIssuers }))),
  (x: RelatedIssuers): RelatedIssuersTagged => ({ ...x, _tag: `RelatedIssuers`}),
)) satisfies t.Type<RelatedIssuers, unknown>;


export const contact = {
  _tag: `Contact`,
  enablingFlag: O.some(imported4_contact),
  id: 11,
  name: `Contact Us`
} as const;

export type ContactTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Contact`>
}>;
export const contactTaggedC: ContactTaggedC = t.type({
  _tag: t.literal(`Contact`)
});
export type ContactTagged = t.TypeOf<ContactTaggedC>;
export type Contact = ContactTagged & typeof contact;
export type ContactC = t.Type<Contact, ContactTagged>;
export const contactC: ContactC = pipe(contactTaggedC, c => new t.Type<Contact, ContactTagged>(
  `Contact`,
  (u: unknown): u is Contact => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Contact> => pipe(c.decode(u), E.map(x => ({ ...x, ...contact }))),
  (x: Contact): ContactTagged => ({ ...x, _tag: `Contact`}),
)) satisfies t.Type<Contact, unknown>;


export const homepage = {
  _tag: `Homepage`,
  enablingFlag: O.none,
  id: 18,
  name: `Homepage`
} as const;

export type HomepageTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Homepage`>
}>;
export const homepageTaggedC: HomepageTaggedC = t.type({
  _tag: t.literal(`Homepage`)
});
export type HomepageTagged = t.TypeOf<HomepageTaggedC>;
export type Homepage = HomepageTagged & typeof homepage;
export type HomepageC = t.Type<Homepage, HomepageTagged>;
export const homepageC: HomepageC = pipe(homepageTaggedC, c => new t.Type<Homepage, HomepageTagged>(
  `Homepage`,
  (u: unknown): u is Homepage => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Homepage> => pipe(c.decode(u), E.map(x => ({ ...x, ...homepage }))),
  (x: Homepage): HomepageTagged => ({ ...x, _tag: `Homepage`}),
)) satisfies t.Type<Homepage, unknown>;


export const aboutPage = {
  _tag: `AboutPage`,
  enablingFlag: O.none,
  id: 22,
  name: `About`
} as const;

export type AboutPageTaggedC = t.TypeC<{
  _tag: t.LiteralC<`AboutPage`>
}>;
export const aboutPageTaggedC: AboutPageTaggedC = t.type({
  _tag: t.literal(`AboutPage`)
});
export type AboutPageTagged = t.TypeOf<AboutPageTaggedC>;
export type AboutPage = AboutPageTagged & typeof aboutPage;
export type AboutPageC = t.Type<AboutPage, AboutPageTagged>;
export const aboutPageC: AboutPageC = pipe(aboutPageTaggedC, c => new t.Type<AboutPage, AboutPageTagged>(
  `AboutPage`,
  (u: unknown): u is AboutPage => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AboutPage> => pipe(c.decode(u), E.map(x => ({ ...x, ...aboutPage }))),
  (x: AboutPage): AboutPageTagged => ({ ...x, _tag: `AboutPage`}),
)) satisfies t.Type<AboutPage, unknown>;


export const esgProgram = {
  _tag: `EsgProgram`,
  enablingFlag: O.some(imported10_esgProgram),
  id: 29,
  name: `ESG Program`
} as const;

export type EsgProgramTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EsgProgram`>
}>;
export const esgProgramTaggedC: EsgProgramTaggedC = t.type({
  _tag: t.literal(`EsgProgram`)
});
export type EsgProgramTagged = t.TypeOf<EsgProgramTaggedC>;
export type EsgProgram = EsgProgramTagged & typeof esgProgram;
export type EsgProgramC = t.Type<EsgProgram, EsgProgramTagged>;
export const esgProgramC: EsgProgramC = pipe(esgProgramTaggedC, c => new t.Type<EsgProgram, EsgProgramTagged>(
  `EsgProgram`,
  (u: unknown): u is EsgProgram => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EsgProgram> => pipe(c.decode(u), E.map(x => ({ ...x, ...esgProgram }))),
  (x: EsgProgram): EsgProgramTagged => ({ ...x, _tag: `EsgProgram`}),
)) satisfies t.Type<EsgProgram, unknown>;


export const linksPage = {
  _tag: `LinksPage`,
  enablingFlag: O.some(imported6_linksPage),
  id: 30,
  name: `Links`
} as const;

export type LinksPageTaggedC = t.TypeC<{
  _tag: t.LiteralC<`LinksPage`>
}>;
export const linksPageTaggedC: LinksPageTaggedC = t.type({
  _tag: t.literal(`LinksPage`)
});
export type LinksPageTagged = t.TypeOf<LinksPageTaggedC>;
export type LinksPage = LinksPageTagged & typeof linksPage;
export type LinksPageC = t.Type<LinksPage, LinksPageTagged>;
export const linksPageC: LinksPageC = pipe(linksPageTaggedC, c => new t.Type<LinksPage, LinksPageTagged>(
  `LinksPage`,
  (u: unknown): u is LinksPage => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, LinksPage> => pipe(c.decode(u), E.map(x => ({ ...x, ...linksPage }))),
  (x: LinksPage): LinksPageTagged => ({ ...x, _tag: `LinksPage`}),
)) satisfies t.Type<LinksPage, unknown>;


export const rfpsPage = {
  _tag: `RfpsPage`,
  enablingFlag: O.some(imported6_activeRfps),
  id: 32,
  name: `RFPs`
} as const;

export type RfpsPageTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RfpsPage`>
}>;
export const rfpsPageTaggedC: RfpsPageTaggedC = t.type({
  _tag: t.literal(`RfpsPage`)
});
export type RfpsPageTagged = t.TypeOf<RfpsPageTaggedC>;
export type RfpsPage = RfpsPageTagged & typeof rfpsPage;
export type RfpsPageC = t.Type<RfpsPage, RfpsPageTagged>;
export const rfpsPageC: RfpsPageC = pipe(rfpsPageTaggedC, c => new t.Type<RfpsPage, RfpsPageTagged>(
  `RfpsPage`,
  (u: unknown): u is RfpsPage => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RfpsPage> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfpsPage }))),
  (x: RfpsPage): RfpsPageTagged => ({ ...x, _tag: `RfpsPage`}),
)) satisfies t.Type<RfpsPage, unknown>;


export const bondProgramsPage = {
  _tag: `BondProgramsPage`,
  enablingFlag: O.some(imported6_bondPrograms),
  id: 33,
  name: `Bond Programs`
} as const;

export type BondProgramsPageTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondProgramsPage`>
}>;
export const bondProgramsPageTaggedC: BondProgramsPageTaggedC = t.type({
  _tag: t.literal(`BondProgramsPage`)
});
export type BondProgramsPageTagged = t.TypeOf<BondProgramsPageTaggedC>;
export type BondProgramsPage = BondProgramsPageTagged & typeof bondProgramsPage;
export type BondProgramsPageC = t.Type<BondProgramsPage, BondProgramsPageTagged>;
export const bondProgramsPageC: BondProgramsPageC = pipe(bondProgramsPageTaggedC, c => new t.Type<BondProgramsPage, BondProgramsPageTagged>(
  `BondProgramsPage`,
  (u: unknown): u is BondProgramsPage => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondProgramsPage> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondProgramsPage }))),
  (x: BondProgramsPage): BondProgramsPageTagged => ({ ...x, _tag: `BondProgramsPage`}),
)) satisfies t.Type<BondProgramsPage, unknown>;


export const issuerRatings = {
  _tag: `IssuerRatings`,
  enablingFlag: O.none,
  id: 34,
  name: `Issuer Ratings`
} as const;

export type IssuerRatingsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`IssuerRatings`>
}>;
export const issuerRatingsTaggedC: IssuerRatingsTaggedC = t.type({
  _tag: t.literal(`IssuerRatings`)
});
export type IssuerRatingsTagged = t.TypeOf<IssuerRatingsTaggedC>;
export type IssuerRatings = IssuerRatingsTagged & typeof issuerRatings;
export type IssuerRatingsC = t.Type<IssuerRatings, IssuerRatingsTagged>;
export const issuerRatingsC: IssuerRatingsC = pipe(issuerRatingsTaggedC, c => new t.Type<IssuerRatings, IssuerRatingsTagged>(
  `IssuerRatings`,
  (u: unknown): u is IssuerRatings => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerRatings> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerRatings }))),
  (x: IssuerRatings): IssuerRatingsTagged => ({ ...x, _tag: `IssuerRatings`}),
)) satisfies t.Type<IssuerRatings, unknown>;


export const requestMoreInfoButton = {
  _tag: `RequestMoreInfoButton`,
  enablingFlag: O.none,
  id: 35,
  name: `Request More Info`
} as const;

export type RequestMoreInfoButtonTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RequestMoreInfoButton`>
}>;
export const requestMoreInfoButtonTaggedC: RequestMoreInfoButtonTaggedC = t.type({
  _tag: t.literal(`RequestMoreInfoButton`)
});
export type RequestMoreInfoButtonTagged = t.TypeOf<RequestMoreInfoButtonTaggedC>;
export type RequestMoreInfoButton = RequestMoreInfoButtonTagged & typeof requestMoreInfoButton;
export type RequestMoreInfoButtonC = t.Type<RequestMoreInfoButton, RequestMoreInfoButtonTagged>;
export const requestMoreInfoButtonC: RequestMoreInfoButtonC = pipe(requestMoreInfoButtonTaggedC, c => new t.Type<RequestMoreInfoButton, RequestMoreInfoButtonTagged>(
  `RequestMoreInfoButton`,
  (u: unknown): u is RequestMoreInfoButton => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RequestMoreInfoButton> => pipe(c.decode(u), E.map(x => ({ ...x, ...requestMoreInfoButton }))),
  (x: RequestMoreInfoButton): RequestMoreInfoButtonTagged => ({ ...x, _tag: `RequestMoreInfoButton`}),
)) satisfies t.Type<RequestMoreInfoButton, unknown>;


export const otherIssuerDocuments = {
  _tag: `OtherIssuerDocuments`,
  enablingFlag: O.none,
  id: 36,
  name: `Other Issuer Documents`
} as const;

export type OtherIssuerDocumentsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`OtherIssuerDocuments`>
}>;
export const otherIssuerDocumentsTaggedC: OtherIssuerDocumentsTaggedC = t.type({
  _tag: t.literal(`OtherIssuerDocuments`)
});
export type OtherIssuerDocumentsTagged = t.TypeOf<OtherIssuerDocumentsTaggedC>;
export type OtherIssuerDocuments = OtherIssuerDocumentsTagged & typeof otherIssuerDocuments;
export type OtherIssuerDocumentsC = t.Type<OtherIssuerDocuments, OtherIssuerDocumentsTagged>;
export const otherIssuerDocumentsC: OtherIssuerDocumentsC = pipe(otherIssuerDocumentsTaggedC, c => new t.Type<OtherIssuerDocuments, OtherIssuerDocumentsTagged>(
  `OtherIssuerDocuments`,
  (u: unknown): u is OtherIssuerDocuments => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, OtherIssuerDocuments> => pipe(c.decode(u), E.map(x => ({ ...x, ...otherIssuerDocuments }))),
  (x: OtherIssuerDocuments): OtherIssuerDocumentsTagged => ({ ...x, _tag: `OtherIssuerDocuments`}),
)) satisfies t.Type<OtherIssuerDocuments, unknown>;


export const bondRatings = {
  _tag: `BondRatings`,
  enablingFlag: O.some(imported4_bondRatings),
  id: 4,
  name: `Bond Ratings`
} as const;

export type BondRatingsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondRatings`>
}>;
export const bondRatingsTaggedC: BondRatingsTaggedC = t.type({
  _tag: t.literal(`BondRatings`)
});
export type BondRatingsTagged = t.TypeOf<BondRatingsTaggedC>;
export type BondRatings = BondRatingsTagged & typeof bondRatings;
export type BondRatingsC = t.Type<BondRatings, BondRatingsTagged>;
export const bondRatingsC: BondRatingsC = pipe(bondRatingsTaggedC, c => new t.Type<BondRatings, BondRatingsTagged>(
  `BondRatings`,
  (u: unknown): u is BondRatings => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondRatings> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondRatings }))),
  (x: BondRatings): BondRatingsTagged => ({ ...x, _tag: `BondRatings`}),
)) satisfies t.Type<BondRatings, unknown>;


export const faq = {
  _tag: `Faq`,
  enablingFlag: O.some(imported4_faq),
  id: 12,
  name: `FAQ`
} as const;

export type FaqTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Faq`>
}>;
export const faqTaggedC: FaqTaggedC = t.type({
  _tag: t.literal(`Faq`)
});
export type FaqTagged = t.TypeOf<FaqTaggedC>;
export type Faq = FaqTagged & typeof faq;
export type FaqC = t.Type<Faq, FaqTagged>;
export const faqC: FaqC = pipe(faqTaggedC, c => new t.Type<Faq, FaqTagged>(
  `Faq`,
  (u: unknown): u is Faq => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Faq> => pipe(c.decode(u), E.map(x => ({ ...x, ...faq }))),
  (x: Faq): FaqTagged => ({ ...x, _tag: `Faq`}),
)) satisfies t.Type<Faq, unknown>;


export const documentsPage = {
  _tag: `DocumentsPage`,
  enablingFlag: O.none,
  id: 23,
  name: `Documents`
} as const;

export type DocumentsPageTaggedC = t.TypeC<{
  _tag: t.LiteralC<`DocumentsPage`>
}>;
export const documentsPageTaggedC: DocumentsPageTaggedC = t.type({
  _tag: t.literal(`DocumentsPage`)
});
export type DocumentsPageTagged = t.TypeOf<DocumentsPageTaggedC>;
export type DocumentsPage = DocumentsPageTagged & typeof documentsPage;
export type DocumentsPageC = t.Type<DocumentsPage, DocumentsPageTagged>;
export const documentsPageC: DocumentsPageC = pipe(documentsPageTaggedC, c => new t.Type<DocumentsPage, DocumentsPageTagged>(
  `DocumentsPage`,
  (u: unknown): u is DocumentsPage => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DocumentsPage> => pipe(c.decode(u), E.map(x => ({ ...x, ...documentsPage }))),
  (x: DocumentsPage): DocumentsPageTagged => ({ ...x, _tag: `DocumentsPage`}),
)) satisfies t.Type<DocumentsPage, unknown>;


export const resourcesPage = {
  _tag: `ResourcesPage`,
  enablingFlag: O.none,
  id: 24,
  name: `Resources`
} as const;

export type ResourcesPageTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ResourcesPage`>
}>;
export const resourcesPageTaggedC: ResourcesPageTaggedC = t.type({
  _tag: t.literal(`ResourcesPage`)
});
export type ResourcesPageTagged = t.TypeOf<ResourcesPageTaggedC>;
export type ResourcesPage = ResourcesPageTagged & typeof resourcesPage;
export type ResourcesPageC = t.Type<ResourcesPage, ResourcesPageTagged>;
export const resourcesPageC: ResourcesPageC = pipe(resourcesPageTaggedC, c => new t.Type<ResourcesPage, ResourcesPageTagged>(
  `ResourcesPage`,
  (u: unknown): u is ResourcesPage => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ResourcesPage> => pipe(c.decode(u), E.map(x => ({ ...x, ...resourcesPage }))),
  (x: ResourcesPage): ResourcesPageTagged => ({ ...x, _tag: `ResourcesPage`}),
)) satisfies t.Type<ResourcesPage, unknown>;


export const bondsPage = {
  _tag: `BondsPage`,
  enablingFlag: O.none,
  id: 25,
  name: `Bonds`
} as const;

export type BondsPageTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondsPage`>
}>;
export const bondsPageTaggedC: BondsPageTaggedC = t.type({
  _tag: t.literal(`BondsPage`)
});
export type BondsPageTagged = t.TypeOf<BondsPageTaggedC>;
export type BondsPage = BondsPageTagged & typeof bondsPage;
export type BondsPageC = t.Type<BondsPage, BondsPageTagged>;
export const bondsPageC: BondsPageC = pipe(bondsPageTaggedC, c => new t.Type<BondsPage, BondsPageTagged>(
  `BondsPage`,
  (u: unknown): u is BondsPage => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondsPage> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondsPage }))),
  (x: BondsPage): BondsPageTagged => ({ ...x, _tag: `BondsPage`}),
)) satisfies t.Type<BondsPage, unknown>;


export const newsEventsPage = {
  _tag: `NewsEventsPage`,
  enablingFlag: O.none,
  id: 28,
  name: `News & Events`
} as const;

export type NewsEventsPageTaggedC = t.TypeC<{
  _tag: t.LiteralC<`NewsEventsPage`>
}>;
export const newsEventsPageTaggedC: NewsEventsPageTaggedC = t.type({
  _tag: t.literal(`NewsEventsPage`)
});
export type NewsEventsPageTagged = t.TypeOf<NewsEventsPageTaggedC>;
export type NewsEventsPage = NewsEventsPageTagged & typeof newsEventsPage;
export type NewsEventsPageC = t.Type<NewsEventsPage, NewsEventsPageTagged>;
export const newsEventsPageC: NewsEventsPageC = pipe(newsEventsPageTaggedC, c => new t.Type<NewsEventsPage, NewsEventsPageTagged>(
  `NewsEventsPage`,
  (u: unknown): u is NewsEventsPage => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NewsEventsPage> => pipe(c.decode(u), E.map(x => ({ ...x, ...newsEventsPage }))),
  (x: NewsEventsPage): NewsEventsPageTagged => ({ ...x, _tag: `NewsEventsPage`}),
)) satisfies t.Type<NewsEventsPage, unknown>;


export const bondArchive = {
  _tag: `BondArchive`,
  enablingFlag: O.some(imported4_showArchivedBonds),
  id: 31,
  name: `Bond Archive`
} as const;

export type BondArchiveTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondArchive`>
}>;
export const bondArchiveTaggedC: BondArchiveTaggedC = t.type({
  _tag: t.literal(`BondArchive`)
});
export type BondArchiveTagged = t.TypeOf<BondArchiveTaggedC>;
export type BondArchive = BondArchiveTagged & typeof bondArchive;
export type BondArchiveC = t.Type<BondArchive, BondArchiveTagged>;
export const bondArchiveC: BondArchiveC = pipe(bondArchiveTaggedC, c => new t.Type<BondArchive, BondArchiveTagged>(
  `BondArchive`,
  (u: unknown): u is BondArchive => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondArchive> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondArchive }))),
  (x: BondArchive): BondArchiveTagged => ({ ...x, _tag: `BondArchive`}),
)) satisfies t.Type<BondArchive, unknown>;


export const irmaLetter = {
  _tag: `IrmaLetter`,
  enablingFlag: O.some(imported2_showIRMALetter),
  id: 27,
  name: `IRMA Letter`
} as const;

export type IrmaLetterTaggedC = t.TypeC<{
  _tag: t.LiteralC<`IrmaLetter`>
}>;
export const irmaLetterTaggedC: IrmaLetterTaggedC = t.type({
  _tag: t.literal(`IrmaLetter`)
});
export type IrmaLetterTagged = t.TypeOf<IrmaLetterTaggedC>;
export type IrmaLetter = IrmaLetterTagged & typeof irmaLetter;
export type IrmaLetterC = t.Type<IrmaLetter, IrmaLetterTagged>;
export const irmaLetterC: IrmaLetterC = pipe(irmaLetterTaggedC, c => new t.Type<IrmaLetter, IrmaLetterTagged>(
  `IrmaLetter`,
  (u: unknown): u is IrmaLetter => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IrmaLetter> => pipe(c.decode(u), E.map(x => ({ ...x, ...irmaLetter }))),
  (x: IrmaLetter): IrmaLetterTagged => ({ ...x, _tag: `IrmaLetter`}),
)) satisfies t.Type<IrmaLetter, unknown>;


export const allPageC = [emmaLinksC, cusip6C, customPage1C, customPage2C, customPage3C, customPage4C, customPage5C, financeTeamC, infrastructureProjectsC, newsC, relatedIssuersC, contactC, homepageC, aboutPageC, esgProgramC, linksPageC, rfpsPageC, bondProgramsPageC, issuerRatingsC, requestMoreInfoButtonC, otherIssuerDocumentsC, bondRatingsC, faqC, documentsPageC, resourcesPageC, bondsPageC, newsEventsPageC, bondArchiveC, irmaLetterC] as const;
export const allPageNames = [`EmmaLinks`, `Cusip6`, `CustomPage1`, `CustomPage2`, `CustomPage3`, `CustomPage4`, `CustomPage5`, `FinanceTeam`, `InfrastructureProjects`, `News`, `RelatedIssuers`, `Contact`, `Homepage`, `AboutPage`, `EsgProgram`, `LinksPage`, `RfpsPage`, `BondProgramsPage`, `IssuerRatings`, `RequestMoreInfoButton`, `OtherIssuerDocuments`, `BondRatings`, `Faq`, `DocumentsPage`, `ResourcesPage`, `BondsPage`, `NewsEventsPage`, `BondArchive`, `IrmaLetter`] as const;
export type PageName = (typeof allPageNames)[number];

export type PageCU = t.UnionC<[EmmaLinksC, Cusip6C, CustomPage1C, CustomPage2C, CustomPage3C, CustomPage4C, CustomPage5C, FinanceTeamC, InfrastructureProjectsC, NewsC, RelatedIssuersC, ContactC, HomepageC, AboutPageC, EsgProgramC, LinksPageC, RfpsPageC, BondProgramsPageC, IssuerRatingsC, RequestMoreInfoButtonC, OtherIssuerDocumentsC, BondRatingsC, FaqC, DocumentsPageC, ResourcesPageC, BondsPageC, NewsEventsPageC, BondArchiveC, IrmaLetterC]>;
export type PageU = EmmaLinks | Cusip6 | CustomPage1 | CustomPage2 | CustomPage3 | CustomPage4 | CustomPage5 | FinanceTeam | InfrastructureProjects | News | RelatedIssuers | Contact | Homepage | AboutPage | EsgProgram | LinksPage | RfpsPage | BondProgramsPage | IssuerRatings | RequestMoreInfoButton | OtherIssuerDocuments | BondRatings | Faq | DocumentsPage | ResourcesPage | BondsPage | NewsEventsPage | BondArchive | IrmaLetter;
export const PageCU: PageCU = t.union([emmaLinksC, cusip6C, customPage1C, customPage2C, customPage3C, customPage4C, customPage5C, financeTeamC, infrastructureProjectsC, newsC, relatedIssuersC, contactC, homepageC, aboutPageC, esgProgramC, linksPageC, rfpsPageC, bondProgramsPageC, issuerRatingsC, requestMoreInfoButtonC, otherIssuerDocumentsC, bondRatingsC, faqC, documentsPageC, resourcesPageC, bondsPageC, newsEventsPageC, bondArchiveC, irmaLetterC]) satisfies t.Type<PageU, unknown>;

export const pageOrd: Ord.Ord<PageU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allPage = [emmaLinks, cusip6, customPage1, customPage2, customPage3, customPage4, customPage5, financeTeam, infrastructureProjects, news, relatedIssuers, contact, homepage, aboutPage, esgProgram, linksPage, rfpsPage, bondProgramsPage, issuerRatings, requestMoreInfoButton, otherIssuerDocuments, bondRatings, faq, documentsPage, resourcesPage, bondsPage, newsEventsPage, bondArchive, irmaLetter] as const;
export type PageMap<A> = { [K in PageName]: A };


export const allSortableC = [emmaLinksC, cusip6C] as const;
export const allSortableNames = [`EmmaLinks`, `Cusip6`] as const;
export type SortableName = (typeof allSortableNames)[number];

export type SortableCU = t.UnionC<[EmmaLinksC, Cusip6C]>;
export type SortableU = EmmaLinks | Cusip6;
export const SortableCU: SortableCU = t.union([emmaLinksC, cusip6C]) satisfies t.Type<SortableU, unknown>;

export const sortableOrd: Ord.Ord<SortableU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allSortable = [emmaLinks, cusip6] as const;
export type SortableMap<A> = { [K in SortableName]: A };


export const allTitleEditableC = [customPage1C, customPage2C, customPage3C, customPage4C, customPage5C, financeTeamC, emmaLinksC, infrastructureProjectsC, newsC, relatedIssuersC, contactC, cusip6C, homepageC, aboutPageC, esgProgramC, linksPageC, rfpsPageC, bondProgramsPageC, issuerRatingsC, requestMoreInfoButtonC, otherIssuerDocumentsC] as const;
export const allTitleEditableNames = [`CustomPage1`, `CustomPage2`, `CustomPage3`, `CustomPage4`, `CustomPage5`, `FinanceTeam`, `EmmaLinks`, `InfrastructureProjects`, `News`, `RelatedIssuers`, `Contact`, `Cusip6`, `Homepage`, `AboutPage`, `EsgProgram`, `LinksPage`, `RfpsPage`, `BondProgramsPage`, `IssuerRatings`, `RequestMoreInfoButton`, `OtherIssuerDocuments`] as const;
export type TitleEditableName = (typeof allTitleEditableNames)[number];

export type TitleEditableCU = t.UnionC<[CustomPage1C, CustomPage2C, CustomPage3C, CustomPage4C, CustomPage5C, FinanceTeamC, EmmaLinksC, InfrastructureProjectsC, NewsC, RelatedIssuersC, ContactC, Cusip6C, HomepageC, AboutPageC, EsgProgramC, LinksPageC, RfpsPageC, BondProgramsPageC, IssuerRatingsC, RequestMoreInfoButtonC, OtherIssuerDocumentsC]>;
export type TitleEditableU = CustomPage1 | CustomPage2 | CustomPage3 | CustomPage4 | CustomPage5 | FinanceTeam | EmmaLinks | InfrastructureProjects | News | RelatedIssuers | Contact | Cusip6 | Homepage | AboutPage | EsgProgram | LinksPage | RfpsPage | BondProgramsPage | IssuerRatings | RequestMoreInfoButton | OtherIssuerDocuments;
export const TitleEditableCU: TitleEditableCU = t.union([customPage1C, customPage2C, customPage3C, customPage4C, customPage5C, financeTeamC, emmaLinksC, infrastructureProjectsC, newsC, relatedIssuersC, contactC, cusip6C, homepageC, aboutPageC, esgProgramC, linksPageC, rfpsPageC, bondProgramsPageC, issuerRatingsC, requestMoreInfoButtonC, otherIssuerDocumentsC]) satisfies t.Type<TitleEditableU, unknown>;

export const titleEditableOrd: Ord.Ord<TitleEditableU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allTitleEditable = [customPage1, customPage2, customPage3, customPage4, customPage5, financeTeam, emmaLinks, infrastructureProjects, news, relatedIssuers, contact, cusip6, homepage, aboutPage, esgProgram, linksPage, rfpsPage, bondProgramsPage, issuerRatings, requestMoreInfoButton, otherIssuerDocuments] as const;
export type TitleEditableMap<A> = { [K in TitleEditableName]: A };


export const allIntroSentenceEditableC = [financeTeamC, bondRatingsC, emmaLinksC, infrastructureProjectsC, faqC, aboutPageC, documentsPageC, resourcesPageC, bondsPageC, newsEventsPageC, esgProgramC, linksPageC, bondArchiveC, rfpsPageC, bondProgramsPageC] as const;
export const allIntroSentenceEditableNames = [`FinanceTeam`, `BondRatings`, `EmmaLinks`, `InfrastructureProjects`, `Faq`, `AboutPage`, `DocumentsPage`, `ResourcesPage`, `BondsPage`, `NewsEventsPage`, `EsgProgram`, `LinksPage`, `BondArchive`, `RfpsPage`, `BondProgramsPage`] as const;
export type IntroSentenceEditableName = (typeof allIntroSentenceEditableNames)[number];

export type IntroSentenceEditableCU = t.UnionC<[FinanceTeamC, BondRatingsC, EmmaLinksC, InfrastructureProjectsC, FaqC, AboutPageC, DocumentsPageC, ResourcesPageC, BondsPageC, NewsEventsPageC, EsgProgramC, LinksPageC, BondArchiveC, RfpsPageC, BondProgramsPageC]>;
export type IntroSentenceEditableU = FinanceTeam | BondRatings | EmmaLinks | InfrastructureProjects | Faq | AboutPage | DocumentsPage | ResourcesPage | BondsPage | NewsEventsPage | EsgProgram | LinksPage | BondArchive | RfpsPage | BondProgramsPage;
export const IntroSentenceEditableCU: IntroSentenceEditableCU = t.union([financeTeamC, bondRatingsC, emmaLinksC, infrastructureProjectsC, faqC, aboutPageC, documentsPageC, resourcesPageC, bondsPageC, newsEventsPageC, esgProgramC, linksPageC, bondArchiveC, rfpsPageC, bondProgramsPageC]) satisfies t.Type<IntroSentenceEditableU, unknown>;

export const introSentenceEditableOrd: Ord.Ord<IntroSentenceEditableU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allIntroSentenceEditable = [financeTeam, bondRatings, emmaLinks, infrastructureProjects, faq, aboutPage, documentsPage, resourcesPage, bondsPage, newsEventsPage, esgProgram, linksPage, bondArchive, rfpsPage, bondProgramsPage] as const;
export type IntroSentenceEditableMap<A> = { [K in IntroSentenceEditableName]: A };


export const allCustomPageC = [customPage1C, customPage2C, customPage3C, customPage4C, customPage5C] as const;
export const allCustomPageNames = [`CustomPage1`, `CustomPage2`, `CustomPage3`, `CustomPage4`, `CustomPage5`] as const;
export type CustomPageName = (typeof allCustomPageNames)[number];

export type CustomPageCU = t.UnionC<[CustomPage1C, CustomPage2C, CustomPage3C, CustomPage4C, CustomPage5C]>;
export type CustomPageU = CustomPage1 | CustomPage2 | CustomPage3 | CustomPage4 | CustomPage5;
export const CustomPageCU: CustomPageCU = t.union([customPage1C, customPage2C, customPage3C, customPage4C, customPage5C]) satisfies t.Type<CustomPageU, unknown>;

export const customPageOrd: Ord.Ord<CustomPageU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allCustomPage = [customPage1, customPage2, customPage3, customPage4, customPage5] as const;
export type CustomPageMap<A> = { [K in CustomPageName]: A };


export const allPhotoEditableC = [homepageC, aboutPageC, documentsPageC, resourcesPageC, bondsPageC] as const;
export const allPhotoEditableNames = [`Homepage`, `AboutPage`, `DocumentsPage`, `ResourcesPage`, `BondsPage`] as const;
export type PhotoEditableName = (typeof allPhotoEditableNames)[number];

export type PhotoEditableCU = t.UnionC<[HomepageC, AboutPageC, DocumentsPageC, ResourcesPageC, BondsPageC]>;
export type PhotoEditableU = Homepage | AboutPage | DocumentsPage | ResourcesPage | BondsPage;
export const PhotoEditableCU: PhotoEditableCU = t.union([homepageC, aboutPageC, documentsPageC, resourcesPageC, bondsPageC]) satisfies t.Type<PhotoEditableU, unknown>;

export const photoEditableOrd: Ord.Ord<PhotoEditableU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allPhotoEditable = [homepage, aboutPage, documentsPage, resourcesPage, bondsPage] as const;
export type PhotoEditableMap<A> = { [K in PhotoEditableName]: A };


export const allBucketC = [aboutPageC, documentsPageC, resourcesPageC, bondsPageC] as const;
export const allBucketNames = [`AboutPage`, `DocumentsPage`, `ResourcesPage`, `BondsPage`] as const;
export type BucketName = (typeof allBucketNames)[number];

export type BucketCU = t.UnionC<[AboutPageC, DocumentsPageC, ResourcesPageC, BondsPageC]>;
export type BucketU = AboutPage | DocumentsPage | ResourcesPage | BondsPage;
export const BucketCU: BucketCU = t.union([aboutPageC, documentsPageC, resourcesPageC, bondsPageC]) satisfies t.Type<BucketU, unknown>;

export const bucketOrd: Ord.Ord<BucketU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allBucket = [aboutPage, documentsPage, resourcesPage, bondsPage] as const;
export type BucketMap<A> = { [K in BucketName]: A };


export const allHiddenC = [requestMoreInfoButtonC, otherIssuerDocumentsC] as const;
export const allHiddenNames = [`RequestMoreInfoButton`, `OtherIssuerDocuments`] as const;
export type HiddenName = (typeof allHiddenNames)[number];

export type HiddenCU = t.UnionC<[RequestMoreInfoButtonC, OtherIssuerDocumentsC]>;
export type HiddenU = RequestMoreInfoButton | OtherIssuerDocuments;
export const HiddenCU: HiddenCU = t.union([requestMoreInfoButtonC, otherIssuerDocumentsC]) satisfies t.Type<HiddenU, unknown>;

export const hiddenOrd: Ord.Ord<HiddenU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allHidden = [requestMoreInfoButton, otherIssuerDocuments] as const;
export type HiddenMap<A> = { [K in HiddenName]: A };


