
import * as A from "fp-ts/lib/Array";
import * as R from "fp-ts/lib/Record";
import type { Refinement } from "fp-ts/lib/Refinement";
import * as t from "io-ts";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";

import { arrayFromEntries } from "@scripts/codecs/arrayFromEntries";
import { nonEmptyArrayC } from "@scripts/codecs/nonEmptyArray";
import { readonlyNonEmptyArrayC } from "@scripts/codecs/readonlyNonEmptyArray";
import { s } from "@scripts/fp-ts";
import * as ec from "@scripts/generated/domaintables/errorCodes";
import * as rc from "@scripts/generated/domaintables/responseCodes";
import { apiErrorC as apiErrorBaseC } from "@scripts/generated/models/apiErrors";
import type { HasProps } from "@scripts/util/codecTypeGuards";
import { hasProps, isAnyArrayC, isArrayOrReadonlyArrayC, isIotsCodec, isNonEmptyArrayTypeC, isReadonlyNonEmptyArrayTypeC } from "@scripts/util/codecTypeGuards";

import type { FormValidationErrors } from "./form";

export * from "@scripts/generated/domaintables/errorCodes";

const CODEC_ERRORC = t.strict({
  _tag: t.literal(`CODEC_ERROR`),
});
export type CODEC_ERROR = t.TypeOf<typeof CODEC_ERRORC>;
export const CODEC_ERROR: CODEC_ERROR = { _tag: "CODEC_ERROR" };

const NETWORK_ERRORC = t.strict({
  _tag: t.literal(`NETWORK_ERROR`),
});
export type NETWORK_ERROR = t.TypeOf<typeof NETWORK_ERRORC>;
export const NETWORK_ERROR: NETWORK_ERROR = { _tag: "NETWORK_ERROR" };

const RESPONSE_TEXT_ERRORC = t.strict({
  _tag: t.literal(`RESPONSE_TEXT_ERROR`),
});
export type RESPONSE_TEXT_ERROR = t.TypeOf<typeof RESPONSE_TEXT_ERRORC>;
export const RESPONSE_TEXT_ERROR: RESPONSE_TEXT_ERROR = { _tag: "RESPONSE_TEXT_ERROR" };

const EXISTING_ERRORSC = t.strict({
  _tag: t.literal(`EXISTING_ERRORS`),
});
export type EXISTING_ERRORS = t.TypeOf<typeof EXISTING_ERRORSC>;
export const EXISTING_ERRORS: EXISTING_ERRORS = { _tag: "EXISTING_ERRORS" };

const RECAPTCHA_ERRORC = t.strict({
  _tag: t.literal(`RECAPTCHA_ERROR`),
});
export type RECAPTCHA_ERROR = t.TypeOf<typeof RECAPTCHA_ERRORC>;
export const RECAPTCHA_ERROR: RECAPTCHA_ERROR = { _tag: "RECAPTCHA_ERROR" };


const responseErrorsCU = t.union([rc.ResponseCodeCU, CODEC_ERRORC, NETWORK_ERRORC, RESPONSE_TEXT_ERRORC, EXISTING_ERRORSC, RECAPTCHA_ERRORC]);

const responseErrorC = t.type({
  ...apiErrorBaseC.props,
  error: responseErrorsCU,
});

export type ResponseError = t.TypeOf<typeof responseErrorC>;

export const responseErrorsC = t.type({
  error: responseErrorsCU,
  errors: t.readonlyArray(responseErrorC),
});

export type ResponseErrors = t.TypeOf<typeof responseErrorsC>;

export type FORM_CODEC_ERROR = t.TypeOf<typeof FORM_CODEC_ERRORC>;
const FORM_CODEC_ERROR_TAG = s.literal(`FORM_CODEC_ERROR`);
const FORM_CODEC_ERRORC = t.type({
  _tag: t.literal(FORM_CODEC_ERROR_TAG),
  val: optionFromNullable(t.unknown),
});
export const FORM_CODEC_ERROR = { _tag: FORM_CODEC_ERROR_TAG, error: FORM_CODEC_ERROR_TAG } as const;

export type FORM_CODEC_ERROR_NUMERIC = t.TypeOf<typeof FORM_CODEC_ERROR_NUMERICC>;
const FORM_CODEC_ERROR_NUMERIC_TAG = s.literal(`FORM_CODEC_ERROR_NUMERIC`);
const FORM_CODEC_ERROR_NUMERICC = t.strict({
  _tag: t.literal(FORM_CODEC_ERROR_NUMERIC_TAG),
  val: optionFromNullable(t.unknown),
});
export const FORM_CODEC_ERROR_NUMERIC = { _tag: FORM_CODEC_ERROR_NUMERIC_TAG, error: FORM_CODEC_ERROR_NUMERIC_TAG } as const;

const serverValidationErrorCU = t.union([FORM_CODEC_ERRORC, FORM_CODEC_ERROR_NUMERICC, ec.ErrorCodeCU]);

const serverValidationErrorC = t.type({
  error: serverValidationErrorCU,
  extra: optionFromNullable(t.string),
});
export type ServerValidationError = t.TypeOf<typeof serverValidationErrorC>;
export const serverValidationErrorsAC = t.readonlyArray(serverValidationErrorC);
export type ServerValidationErrorsA = t.TypeOf<typeof serverValidationErrorsAC>;

export const isServerValidationError: Refinement<unknown, ServerValidationError> = serverValidationErrorC.is;

const _formValidationErrorsC = (type: t.Mixed, excluded: t.Mixed[]) => {
  if (hasProps(type) && !excluded.includes(type)) {
    return formValidationErrorsC(type, excluded);
  } else {
    return serverValidationErrorsAC;
  }
};

export const formValidationErrorsC = <R extends t.Props, E extends t.Mixed>(type: HasProps<R>, excluded: E[]): FormValidationErrors<Exclude<t.TypeC<R>["props"], E>> =>
  t.partial(R.toEntries(type.props).reduce((acc, [k, c]) => {
    if (isAnyArrayC(c)) {
      return { ...acc, [k]: t.union([arrayFromEntries(_formValidationErrorsC(c.type, excluded)), serverValidationErrorsAC]) };
    }
    return { ...acc, [k]: t.union([_formValidationErrorsC(c, excluded), serverValidationErrorsAC]) };
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  }, {} as Exclude<t.TypeC<R>["props"], E>));

const _deepPartialC = <E extends t.Mixed>(type: t.Mixed, excluded: E[]): t.Mixed => {
  if (hasProps(type) && !A.exists((f: t.Mixed) => f.name === type.name)(excluded)) {
    return t.partial(Object.keys(type.props).reduce((acc, k) => {
      const c = type.props[k];
      if (isIotsCodec(c)) {
        if (hasProps(c)) {
          return { ...acc, [k]: _deepPartialC(c, excluded) };
        } else if (isArrayOrReadonlyArrayC(c)) {
          return { ...acc, [k]: t.readonlyArray(_deepPartialC(c.type, excluded)) };
        } else if (isReadonlyNonEmptyArrayTypeC(c)) {
          return { ...acc, [k]: readonlyNonEmptyArrayC(_deepPartialC(c.type, excluded)) };
        } else if (isNonEmptyArrayTypeC(c)) {
          return { ...acc, [k]: nonEmptyArrayC(_deepPartialC(c.type, excluded)) };
        } else {
          return { ...acc, [k]: c };
        }
      } else {
        return { ...acc, [k]: c };
      }
    }, {}));
  } else {
    if (isArrayOrReadonlyArrayC(type)) {
      return t.readonlyArray(_deepPartialC(type.type, excluded));
    } else {
      return type;
    }
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepPartialC = <C extends t.TypeC<any>, E extends t.Mixed>(type: C, excluded: E[]): t.PartialC<Exclude<C["props"], E>> => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return _deepPartialC(type, excluded) as unknown as t.PartialC<Exclude<C["props"], E>>;
};

// export const serverValidationRespC = <C extends t.TypeC<t.Props>>(type: C, excluded: t.Mixed[]) =>
//   t.intersection([
//     rc.ResponseCodeCU,
//     t.type({
//       validationErrors: formValidationErrorsC(type, excluded),
//     }),
//   ]);

export const existingErrors = () => ({ errors: [{ error: EXISTING_ERRORS, extra: "", field: "" }] });
