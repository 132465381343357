/* eslint-disable no-console */
import * as E from "fp-ts/lib/Either";
import type * as t from "io-ts";
import { formatValidationErrors } from "io-ts-reporters";

import type { Logger as ConfigLogger } from "@scripts/bondlink";

import { BLError, sentry } from "../sentry";
import { tap } from "./tap";

export type LogLevel = typeof LogLevel[keyof typeof LogLevel];
export const LogLevel = {
  debug: "debug",
  info: "info",
  warn: "warn",
  error: "error",
  fatal: "fatal",
} as const;

export type LogErrors = LogError[];
export type LogValidation<A> = E.Either<LogErrors, A>;
type LogError = { level: LogLevel, message: string, errors: t.Errors };

const Log: { [k in LogLevel]: (msg: string, ...args: unknown[]) => void } = {
  debug: (msg: string, ...args: unknown[]): void => console.debug(msg, ...args),
  info: (msg: string, ...args: unknown[]): void => console.log(msg, ...args),
  warn: (msg: string, ...args: unknown[]): void => console.warn(msg, ...args),
  error: (msg: string, ...args: unknown[]): void => {
    console.error(msg, ...args);
    sentry.capture(new BLError(msg, args, "BConsoleError"), "error");
  },
  fatal: (msg: string, ...args: unknown[]): void => {
    console.error(msg, ...args);
    sentry.capture(new BLError(msg, args, "BConsoleError"), "fatal");
  },
};

const trace = (l: (msg: string, ...data: unknown[]) => void) => (msg: string) => <A>(a: A) => {
  l(msg, a);
  return a;
};

export const traceError = trace(Log.error);

export const toLogErrors = (level: LogLevel, message: string, a: unknown) => (e?: unknown): LogErrors =>
  [{
    message,
    level,
    errors: [{
      value: e ? [a, e] : a,
      context: [],
    }],
  }];

export const errorsToLogErrors = (level: LogLevel, message: string) => (errors: t.Errors): LogErrors => [{ level, message, errors }];

export const logErrors = (les: LogErrors, ...args: unknown[]) => {
  les.forEach(le => {
    const ves = formatValidationErrors(le.errors);
    ves.length > 0
      ? Log[le.level](le.message, ves, ...args)
      : le.errors.forEach(e => Log[le.level](
        e.message || "Unknown Validation Error",
        E.tryCatch(() => JSON.stringify(e.value), () => e.value),
        E.tryCatch(() => JSON.stringify(e.context), () => e.context)
      ));
  });
  return les;
};

export const logValidation: <A>(v: LogValidation<A>) => LogValidation<A> = tap(E.mapLeft(logErrors));

export const makeConfigLogger = (): ConfigLogger => Log;
