import { useCallback, useEffect, useRef, useState } from "react";
import { useStable, useStableMemo } from "fp-ts-react-stable-hooks";

import { gtagModalEvent } from "@scripts/analytics";
import type { Lazy, Option } from "@scripts/fp-ts";
import { Eq, flow, O, pipe, tuple } from "@scripts/fp-ts";

function useAnalyticEffect(analyticEventLabel: string, modalOpen: boolean) {
  const isInitialized = useRef(false);
  useEffect(() => {
    if (isInitialized.current) {
      gtagModalEvent(analyticEventLabel, modalOpen);
    } else {
      isInitialized.current = true;
    }
  },
    [analyticEventLabel, modalOpen]
  );
}

export type ModalOpen = boolean & { readonly OMFTag: unique symbol };
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const modalOpen = (value: boolean): ModalOpen => value as ModalOpen;
export const mOpen = modalOpen(true);
const mClosed = modalOpen(false);

export type OpenModalFn = Lazy<void> & { readonly OpenModalFn: unique symbol };
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const openModalFn = (fn: Lazy<void>) => fn as OpenModalFn;

export type CloseModalFn = Lazy<void> & {
  readonly CloseModalFn: unique symbol;
};
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const closeModalFn = (fn: Lazy<void>) => fn as CloseModalFn;

export const closeModalFlow = (fn1: Lazy<void>, fn2: Lazy<void>) => closeModalFn(flow(fn1, fn2));

export type OpenModalO<T> = (value: T) => void;

export type UseModalProps = {
  modalOpen: ModalOpen;
  openModal: OpenModalFn;
  closeModal: CloseModalFn;
};

type StableModal = {
  isOpen: ModalOpen;
  open: OpenModalFn;
  close: CloseModalFn;
};
export const useStableModalAsNamespace = (analyticEventLabel: string): StableModal => {
  const [isOpen, setIsOpen] = useState(modalOpen(false));
  const open = openModalFn(useCallback(() => setIsOpen(mOpen), []));
  const close = closeModalFn(useCallback(() => setIsOpen(mClosed), []));
  useAnalyticEffect(analyticEventLabel, isOpen);
  return { isOpen, open, close };
};

type UseModal = [
  StableModal["isOpen"],
  StableModal["open"],
  StableModal["close"],
];
export const useModal: (analyticEventLabel: string) => UseModal =
  flow(
    useStableModalAsNamespace,
    ({ isOpen, open, close }) => tuple(isOpen, open, close)
  );

type StableModalO<A> = {
  isOpen: ModalOpen;
  value: Option<A>;
  open: OpenModalO<A>;
  close: CloseModalFn;
};
export const useStableModalOAsNamespace = <A>(
  analyticEventLabel: string,
  eq: Eq.Eq<Option<A>> = O.getEq(Eq.eqStrict),
): StableModalO<A> => {
  const [value, setValue] = useStable<Option<A>>(O.none, eq);
  const open: OpenModalO<A> = useCallback((v: A) => setValue(O.some(v)), [setValue]);
  const close = closeModalFn(useCallback(() => setValue(O.none), [setValue]));
  const isOpen = useStableMemo(
    () => modalOpen(O.isSome(value)),
    [value],
    // since there is only one dependency, you don't need to manually provide an `Eq` for the dependencies' array)
    Eq.tuple(eq)
  );

  useAnalyticEffect(analyticEventLabel, isOpen);
  return { isOpen, value, open, close };
};

type UseModalStableO<A> = [
  StableModalO<A>["isOpen"],
  StableModalO<A>["value"],
  StableModalO<A>["open"],
  StableModalO<A>["close"],
];
export const useModalStableO = <A>(analyticEventLabel: string): UseModalStableO<A> => pipe(
  useStableModalOAsNamespace<A>(analyticEventLabel),
  ({ isOpen, value, open, close }) => tuple(isOpen, value, open, close),
);
