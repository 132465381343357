import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const userSettingsModalId = {
  _tag: `UserSettingsModalId`,
  id: `user-settings`
} as const;

export type UserSettingsModalIdTaggedC = t.TypeC<{
  _tag: t.LiteralC<`UserSettingsModalId`>
}>;
export const userSettingsModalIdTaggedC: UserSettingsModalIdTaggedC = t.type({
  _tag: t.literal(`UserSettingsModalId`)
});
export type UserSettingsModalIdTagged = t.TypeOf<UserSettingsModalIdTaggedC>;
export type UserSettingsModalId = UserSettingsModalIdTagged & typeof userSettingsModalId;
export type UserSettingsModalIdC = t.Type<UserSettingsModalId, UserSettingsModalIdTagged>;
export const userSettingsModalIdC: UserSettingsModalIdC = pipe(userSettingsModalIdTaggedC, c => new t.Type<UserSettingsModalId, UserSettingsModalIdTagged>(
  `UserSettingsModalId`,
  (u: unknown): u is UserSettingsModalId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UserSettingsModalId> => pipe(c.decode(u), E.map(x => ({ ...x, ...userSettingsModalId }))),
  (x: UserSettingsModalId): UserSettingsModalIdTagged => ({ ...x, _tag: `UserSettingsModalId`}),
)) satisfies t.Type<UserSettingsModalId, unknown>;


export const addDocumentModalId = {
  _tag: `AddDocumentModalId`,
  id: `add-document`
} as const;

export type AddDocumentModalIdTaggedC = t.TypeC<{
  _tag: t.LiteralC<`AddDocumentModalId`>
}>;
export const addDocumentModalIdTaggedC: AddDocumentModalIdTaggedC = t.type({
  _tag: t.literal(`AddDocumentModalId`)
});
export type AddDocumentModalIdTagged = t.TypeOf<AddDocumentModalIdTaggedC>;
export type AddDocumentModalId = AddDocumentModalIdTagged & typeof addDocumentModalId;
export type AddDocumentModalIdC = t.Type<AddDocumentModalId, AddDocumentModalIdTagged>;
export const addDocumentModalIdC: AddDocumentModalIdC = pipe(addDocumentModalIdTaggedC, c => new t.Type<AddDocumentModalId, AddDocumentModalIdTagged>(
  `AddDocumentModalId`,
  (u: unknown): u is AddDocumentModalId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AddDocumentModalId> => pipe(c.decode(u), E.map(x => ({ ...x, ...addDocumentModalId }))),
  (x: AddDocumentModalId): AddDocumentModalIdTagged => ({ ...x, _tag: `AddDocumentModalId`}),
)) satisfies t.Type<AddDocumentModalId, unknown>;


export const addBondModalId = {
  _tag: `AddBondModalId`,
  id: `add-bond`
} as const;

export type AddBondModalIdTaggedC = t.TypeC<{
  _tag: t.LiteralC<`AddBondModalId`>
}>;
export const addBondModalIdTaggedC: AddBondModalIdTaggedC = t.type({
  _tag: t.literal(`AddBondModalId`)
});
export type AddBondModalIdTagged = t.TypeOf<AddBondModalIdTaggedC>;
export type AddBondModalId = AddBondModalIdTagged & typeof addBondModalId;
export type AddBondModalIdC = t.Type<AddBondModalId, AddBondModalIdTagged>;
export const addBondModalIdC: AddBondModalIdC = pipe(addBondModalIdTaggedC, c => new t.Type<AddBondModalId, AddBondModalIdTagged>(
  `AddBondModalId`,
  (u: unknown): u is AddBondModalId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AddBondModalId> => pipe(c.decode(u), E.map(x => ({ ...x, ...addBondModalId }))),
  (x: AddBondModalId): AddBondModalIdTagged => ({ ...x, _tag: `AddBondModalId`}),
)) satisfies t.Type<AddBondModalId, unknown>;


export const addBondRatingModalId = {
  _tag: `AddBondRatingModalId`,
  id: `add-bond-rating`
} as const;

export type AddBondRatingModalIdTaggedC = t.TypeC<{
  _tag: t.LiteralC<`AddBondRatingModalId`>
}>;
export const addBondRatingModalIdTaggedC: AddBondRatingModalIdTaggedC = t.type({
  _tag: t.literal(`AddBondRatingModalId`)
});
export type AddBondRatingModalIdTagged = t.TypeOf<AddBondRatingModalIdTaggedC>;
export type AddBondRatingModalId = AddBondRatingModalIdTagged & typeof addBondRatingModalId;
export type AddBondRatingModalIdC = t.Type<AddBondRatingModalId, AddBondRatingModalIdTagged>;
export const addBondRatingModalIdC: AddBondRatingModalIdC = pipe(addBondRatingModalIdTaggedC, c => new t.Type<AddBondRatingModalId, AddBondRatingModalIdTagged>(
  `AddBondRatingModalId`,
  (u: unknown): u is AddBondRatingModalId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AddBondRatingModalId> => pipe(c.decode(u), E.map(x => ({ ...x, ...addBondRatingModalId }))),
  (x: AddBondRatingModalId): AddBondRatingModalIdTagged => ({ ...x, _tag: `AddBondRatingModalId`}),
)) satisfies t.Type<AddBondRatingModalId, unknown>;


export const addCusip6ModalId = {
  _tag: `AddCusip6ModalId`,
  id: `add-cusip6`
} as const;

export type AddCusip6ModalIdTaggedC = t.TypeC<{
  _tag: t.LiteralC<`AddCusip6ModalId`>
}>;
export const addCusip6ModalIdTaggedC: AddCusip6ModalIdTaggedC = t.type({
  _tag: t.literal(`AddCusip6ModalId`)
});
export type AddCusip6ModalIdTagged = t.TypeOf<AddCusip6ModalIdTaggedC>;
export type AddCusip6ModalId = AddCusip6ModalIdTagged & typeof addCusip6ModalId;
export type AddCusip6ModalIdC = t.Type<AddCusip6ModalId, AddCusip6ModalIdTagged>;
export const addCusip6ModalIdC: AddCusip6ModalIdC = pipe(addCusip6ModalIdTaggedC, c => new t.Type<AddCusip6ModalId, AddCusip6ModalIdTagged>(
  `AddCusip6ModalId`,
  (u: unknown): u is AddCusip6ModalId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AddCusip6ModalId> => pipe(c.decode(u), E.map(x => ({ ...x, ...addCusip6ModalId }))),
  (x: AddCusip6ModalId): AddCusip6ModalIdTagged => ({ ...x, _tag: `AddCusip6ModalId`}),
)) satisfies t.Type<AddCusip6ModalId, unknown>;


export const addCusip9ModalId = {
  _tag: `AddCusip9ModalId`,
  id: `add-cusip9`
} as const;

export type AddCusip9ModalIdTaggedC = t.TypeC<{
  _tag: t.LiteralC<`AddCusip9ModalId`>
}>;
export const addCusip9ModalIdTaggedC: AddCusip9ModalIdTaggedC = t.type({
  _tag: t.literal(`AddCusip9ModalId`)
});
export type AddCusip9ModalIdTagged = t.TypeOf<AddCusip9ModalIdTaggedC>;
export type AddCusip9ModalId = AddCusip9ModalIdTagged & typeof addCusip9ModalId;
export type AddCusip9ModalIdC = t.Type<AddCusip9ModalId, AddCusip9ModalIdTagged>;
export const addCusip9ModalIdC: AddCusip9ModalIdC = pipe(addCusip9ModalIdTaggedC, c => new t.Type<AddCusip9ModalId, AddCusip9ModalIdTagged>(
  `AddCusip9ModalId`,
  (u: unknown): u is AddCusip9ModalId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AddCusip9ModalId> => pipe(c.decode(u), E.map(x => ({ ...x, ...addCusip9ModalId }))),
  (x: AddCusip9ModalId): AddCusip9ModalIdTagged => ({ ...x, _tag: `AddCusip9ModalId`}),
)) satisfies t.Type<AddCusip9ModalId, unknown>;


export const addNewsModalId = {
  _tag: `AddNewsModalId`,
  id: `add-news`
} as const;

export type AddNewsModalIdTaggedC = t.TypeC<{
  _tag: t.LiteralC<`AddNewsModalId`>
}>;
export const addNewsModalIdTaggedC: AddNewsModalIdTaggedC = t.type({
  _tag: t.literal(`AddNewsModalId`)
});
export type AddNewsModalIdTagged = t.TypeOf<AddNewsModalIdTaggedC>;
export type AddNewsModalId = AddNewsModalIdTagged & typeof addNewsModalId;
export type AddNewsModalIdC = t.Type<AddNewsModalId, AddNewsModalIdTagged>;
export const addNewsModalIdC: AddNewsModalIdC = pipe(addNewsModalIdTaggedC, c => new t.Type<AddNewsModalId, AddNewsModalIdTagged>(
  `AddNewsModalId`,
  (u: unknown): u is AddNewsModalId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AddNewsModalId> => pipe(c.decode(u), E.map(x => ({ ...x, ...addNewsModalId }))),
  (x: AddNewsModalId): AddNewsModalIdTagged => ({ ...x, _tag: `AddNewsModalId`}),
)) satisfies t.Type<AddNewsModalId, unknown>;


export const addProjectModalId = {
  _tag: `AddProjectModalId`,
  id: `add-project`
} as const;

export type AddProjectModalIdTaggedC = t.TypeC<{
  _tag: t.LiteralC<`AddProjectModalId`>
}>;
export const addProjectModalIdTaggedC: AddProjectModalIdTaggedC = t.type({
  _tag: t.literal(`AddProjectModalId`)
});
export type AddProjectModalIdTagged = t.TypeOf<AddProjectModalIdTaggedC>;
export type AddProjectModalId = AddProjectModalIdTagged & typeof addProjectModalId;
export type AddProjectModalIdC = t.Type<AddProjectModalId, AddProjectModalIdTagged>;
export const addProjectModalIdC: AddProjectModalIdC = pipe(addProjectModalIdTaggedC, c => new t.Type<AddProjectModalId, AddProjectModalIdTagged>(
  `AddProjectModalId`,
  (u: unknown): u is AddProjectModalId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AddProjectModalId> => pipe(c.decode(u), E.map(x => ({ ...x, ...addProjectModalId }))),
  (x: AddProjectModalId): AddProjectModalIdTagged => ({ ...x, _tag: `AddProjectModalId`}),
)) satisfies t.Type<AddProjectModalId, unknown>;


export const addQuickFactModalId = {
  _tag: `AddQuickFactModalId`,
  id: `add-quick-fact`
} as const;

export type AddQuickFactModalIdTaggedC = t.TypeC<{
  _tag: t.LiteralC<`AddQuickFactModalId`>
}>;
export const addQuickFactModalIdTaggedC: AddQuickFactModalIdTaggedC = t.type({
  _tag: t.literal(`AddQuickFactModalId`)
});
export type AddQuickFactModalIdTagged = t.TypeOf<AddQuickFactModalIdTaggedC>;
export type AddQuickFactModalId = AddQuickFactModalIdTagged & typeof addQuickFactModalId;
export type AddQuickFactModalIdC = t.Type<AddQuickFactModalId, AddQuickFactModalIdTagged>;
export const addQuickFactModalIdC: AddQuickFactModalIdC = pipe(addQuickFactModalIdTaggedC, c => new t.Type<AddQuickFactModalId, AddQuickFactModalIdTagged>(
  `AddQuickFactModalId`,
  (u: unknown): u is AddQuickFactModalId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AddQuickFactModalId> => pipe(c.decode(u), E.map(x => ({ ...x, ...addQuickFactModalId }))),
  (x: AddQuickFactModalId): AddQuickFactModalIdTagged => ({ ...x, _tag: `AddQuickFactModalId`}),
)) satisfies t.Type<AddQuickFactModalId, unknown>;


export const addEventModalId = {
  _tag: `AddEventModalId`,
  id: `add-event`
} as const;

export type AddEventModalIdTaggedC = t.TypeC<{
  _tag: t.LiteralC<`AddEventModalId`>
}>;
export const addEventModalIdTaggedC: AddEventModalIdTaggedC = t.type({
  _tag: t.literal(`AddEventModalId`)
});
export type AddEventModalIdTagged = t.TypeOf<AddEventModalIdTaggedC>;
export type AddEventModalId = AddEventModalIdTagged & typeof addEventModalId;
export type AddEventModalIdC = t.Type<AddEventModalId, AddEventModalIdTagged>;
export const addEventModalIdC: AddEventModalIdC = pipe(addEventModalIdTaggedC, c => new t.Type<AddEventModalId, AddEventModalIdTagged>(
  `AddEventModalId`,
  (u: unknown): u is AddEventModalId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AddEventModalId> => pipe(c.decode(u), E.map(x => ({ ...x, ...addEventModalId }))),
  (x: AddEventModalId): AddEventModalIdTagged => ({ ...x, _tag: `AddEventModalId`}),
)) satisfies t.Type<AddEventModalId, unknown>;


export const addRFPModalId = {
  _tag: `AddRFPModalId`,
  id: `add-rfp`
} as const;

export type AddRFPModalIdTaggedC = t.TypeC<{
  _tag: t.LiteralC<`AddRFPModalId`>
}>;
export const addRFPModalIdTaggedC: AddRFPModalIdTaggedC = t.type({
  _tag: t.literal(`AddRFPModalId`)
});
export type AddRFPModalIdTagged = t.TypeOf<AddRFPModalIdTaggedC>;
export type AddRFPModalId = AddRFPModalIdTagged & typeof addRFPModalId;
export type AddRFPModalIdC = t.Type<AddRFPModalId, AddRFPModalIdTagged>;
export const addRFPModalIdC: AddRFPModalIdC = pipe(addRFPModalIdTaggedC, c => new t.Type<AddRFPModalId, AddRFPModalIdTagged>(
  `AddRFPModalId`,
  (u: unknown): u is AddRFPModalId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AddRFPModalId> => pipe(c.decode(u), E.map(x => ({ ...x, ...addRFPModalId }))),
  (x: AddRFPModalId): AddRFPModalIdTagged => ({ ...x, _tag: `AddRFPModalId`}),
)) satisfies t.Type<AddRFPModalId, unknown>;


export const findIssuersModalId = {
  _tag: `FindIssuersModalId`,
  id: `find-issuers`
} as const;

export type FindIssuersModalIdTaggedC = t.TypeC<{
  _tag: t.LiteralC<`FindIssuersModalId`>
}>;
export const findIssuersModalIdTaggedC: FindIssuersModalIdTaggedC = t.type({
  _tag: t.literal(`FindIssuersModalId`)
});
export type FindIssuersModalIdTagged = t.TypeOf<FindIssuersModalIdTaggedC>;
export type FindIssuersModalId = FindIssuersModalIdTagged & typeof findIssuersModalId;
export type FindIssuersModalIdC = t.Type<FindIssuersModalId, FindIssuersModalIdTagged>;
export const findIssuersModalIdC: FindIssuersModalIdC = pipe(findIssuersModalIdTaggedC, c => new t.Type<FindIssuersModalId, FindIssuersModalIdTagged>(
  `FindIssuersModalId`,
  (u: unknown): u is FindIssuersModalId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FindIssuersModalId> => pipe(c.decode(u), E.map(x => ({ ...x, ...findIssuersModalId }))),
  (x: FindIssuersModalId): FindIssuersModalIdTagged => ({ ...x, _tag: `FindIssuersModalId`}),
)) satisfies t.Type<FindIssuersModalId, unknown>;


export const onboardingModalId = {
  _tag: `OnboardingModalId`,
  id: `account-setup`
} as const;

export type OnboardingModalIdTaggedC = t.TypeC<{
  _tag: t.LiteralC<`OnboardingModalId`>
}>;
export const onboardingModalIdTaggedC: OnboardingModalIdTaggedC = t.type({
  _tag: t.literal(`OnboardingModalId`)
});
export type OnboardingModalIdTagged = t.TypeOf<OnboardingModalIdTaggedC>;
export type OnboardingModalId = OnboardingModalIdTagged & typeof onboardingModalId;
export type OnboardingModalIdC = t.Type<OnboardingModalId, OnboardingModalIdTagged>;
export const onboardingModalIdC: OnboardingModalIdC = pipe(onboardingModalIdTaggedC, c => new t.Type<OnboardingModalId, OnboardingModalIdTagged>(
  `OnboardingModalId`,
  (u: unknown): u is OnboardingModalId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, OnboardingModalId> => pipe(c.decode(u), E.map(x => ({ ...x, ...onboardingModalId }))),
  (x: OnboardingModalId): OnboardingModalIdTagged => ({ ...x, _tag: `OnboardingModalId`}),
)) satisfies t.Type<OnboardingModalId, unknown>;


export const sitesEventModal = {
  _tag: `SitesEventModal`,
  id: `sites-event-modal`
} as const;

export type SitesEventModalTaggedC = t.TypeC<{
  _tag: t.LiteralC<`SitesEventModal`>
}>;
export const sitesEventModalTaggedC: SitesEventModalTaggedC = t.type({
  _tag: t.literal(`SitesEventModal`)
});
export type SitesEventModalTagged = t.TypeOf<SitesEventModalTaggedC>;
export type SitesEventModal = SitesEventModalTagged & typeof sitesEventModal;
export type SitesEventModalC = t.Type<SitesEventModal, SitesEventModalTagged>;
export const sitesEventModalC: SitesEventModalC = pipe(sitesEventModalTaggedC, c => new t.Type<SitesEventModal, SitesEventModalTagged>(
  `SitesEventModal`,
  (u: unknown): u is SitesEventModal => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SitesEventModal> => pipe(c.decode(u), E.map(x => ({ ...x, ...sitesEventModal }))),
  (x: SitesEventModal): SitesEventModalTagged => ({ ...x, _tag: `SitesEventModal`}),
)) satisfies t.Type<SitesEventModal, unknown>;


export const informationRequestModal = {
  _tag: `InformationRequestModal`,
  id: `information-request-modal`
} as const;

export type InformationRequestModalTaggedC = t.TypeC<{
  _tag: t.LiteralC<`InformationRequestModal`>
}>;
export const informationRequestModalTaggedC: InformationRequestModalTaggedC = t.type({
  _tag: t.literal(`InformationRequestModal`)
});
export type InformationRequestModalTagged = t.TypeOf<InformationRequestModalTaggedC>;
export type InformationRequestModal = InformationRequestModalTagged & typeof informationRequestModal;
export type InformationRequestModalC = t.Type<InformationRequestModal, InformationRequestModalTagged>;
export const informationRequestModalC: InformationRequestModalC = pipe(informationRequestModalTaggedC, c => new t.Type<InformationRequestModal, InformationRequestModalTagged>(
  `InformationRequestModal`,
  (u: unknown): u is InformationRequestModal => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InformationRequestModal> => pipe(c.decode(u), E.map(x => ({ ...x, ...informationRequestModal }))),
  (x: InformationRequestModal): InformationRequestModalTagged => ({ ...x, _tag: `InformationRequestModal`}),
)) satisfies t.Type<InformationRequestModal, unknown>;


export const allDeepLinkModalIdC = [userSettingsModalIdC, addDocumentModalIdC, addBondModalIdC, addBondRatingModalIdC, addCusip6ModalIdC, addCusip9ModalIdC, addNewsModalIdC, addProjectModalIdC, addQuickFactModalIdC, addEventModalIdC, addRFPModalIdC, findIssuersModalIdC, onboardingModalIdC, sitesEventModalC, informationRequestModalC] as const;
export const allDeepLinkModalIdNames = [`UserSettingsModalId`, `AddDocumentModalId`, `AddBondModalId`, `AddBondRatingModalId`, `AddCusip6ModalId`, `AddCusip9ModalId`, `AddNewsModalId`, `AddProjectModalId`, `AddQuickFactModalId`, `AddEventModalId`, `AddRFPModalId`, `FindIssuersModalId`, `OnboardingModalId`, `SitesEventModal`, `InformationRequestModal`] as const;
export type DeepLinkModalIdName = (typeof allDeepLinkModalIdNames)[number];

export type DeepLinkModalIdCU = t.UnionC<[UserSettingsModalIdC, AddDocumentModalIdC, AddBondModalIdC, AddBondRatingModalIdC, AddCusip6ModalIdC, AddCusip9ModalIdC, AddNewsModalIdC, AddProjectModalIdC, AddQuickFactModalIdC, AddEventModalIdC, AddRFPModalIdC, FindIssuersModalIdC, OnboardingModalIdC, SitesEventModalC, InformationRequestModalC]>;
export type DeepLinkModalIdU = UserSettingsModalId | AddDocumentModalId | AddBondModalId | AddBondRatingModalId | AddCusip6ModalId | AddCusip9ModalId | AddNewsModalId | AddProjectModalId | AddQuickFactModalId | AddEventModalId | AddRFPModalId | FindIssuersModalId | OnboardingModalId | SitesEventModal | InformationRequestModal;
export const DeepLinkModalIdCU: DeepLinkModalIdCU = t.union([userSettingsModalIdC, addDocumentModalIdC, addBondModalIdC, addBondRatingModalIdC, addCusip6ModalIdC, addCusip9ModalIdC, addNewsModalIdC, addProjectModalIdC, addQuickFactModalIdC, addEventModalIdC, addRFPModalIdC, findIssuersModalIdC, onboardingModalIdC, sitesEventModalC, informationRequestModalC]) satisfies t.Type<DeepLinkModalIdU, unknown>;

export const deepLinkModalIdOrd: Ord.Ord<DeepLinkModalIdU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allDeepLinkModalId = [userSettingsModalId, addDocumentModalId, addBondModalId, addBondRatingModalId, addCusip6ModalId, addCusip9ModalId, addNewsModalId, addProjectModalId, addQuickFactModalId, addEventModalId, addRFPModalId, findIssuersModalId, onboardingModalId, sitesEventModal, informationRequestModal] as const;
export type DeepLinkModalIdMap<A> = { [K in DeepLinkModalIdName]: A };


export const userSettingsTabId = {
  _tag: `UserSettingsTabId`,
  id: `settings`
} as const;

export type UserSettingsTabIdTaggedC = t.TypeC<{
  _tag: t.LiteralC<`UserSettingsTabId`>
}>;
export const userSettingsTabIdTaggedC: UserSettingsTabIdTaggedC = t.type({
  _tag: t.literal(`UserSettingsTabId`)
});
export type UserSettingsTabIdTagged = t.TypeOf<UserSettingsTabIdTaggedC>;
export type UserSettingsTabId = UserSettingsTabIdTagged & typeof userSettingsTabId;
export type UserSettingsTabIdC = t.Type<UserSettingsTabId, UserSettingsTabIdTagged>;
export const userSettingsTabIdC: UserSettingsTabIdC = pipe(userSettingsTabIdTaggedC, c => new t.Type<UserSettingsTabId, UserSettingsTabIdTagged>(
  `UserSettingsTabId`,
  (u: unknown): u is UserSettingsTabId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UserSettingsTabId> => pipe(c.decode(u), E.map(x => ({ ...x, ...userSettingsTabId }))),
  (x: UserSettingsTabId): UserSettingsTabIdTagged => ({ ...x, _tag: `UserSettingsTabId`}),
)) satisfies t.Type<UserSettingsTabId, unknown>;


export const allDeepLinkTabIdC = [userSettingsTabIdC] as const;
export const allDeepLinkTabIdNames = [`UserSettingsTabId`] as const;
export type DeepLinkTabIdName = (typeof allDeepLinkTabIdNames)[number];

export type DeepLinkTabIdCU = UserSettingsTabIdC;
export type DeepLinkTabIdU = UserSettingsTabId;
export const DeepLinkTabIdCU: DeepLinkTabIdCU = userSettingsTabIdC satisfies t.Type<DeepLinkTabIdU, unknown>;

export const deepLinkTabIdOrd: Ord.Ord<DeepLinkTabIdU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allDeepLinkTabId = [userSettingsTabId] as const;
export type DeepLinkTabIdMap<A> = { [K in DeepLinkTabIdName]: A };


