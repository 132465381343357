import type { HTMLAttributes, ReactElement } from "react";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import type { SVGString } from "*.svg";

import { headerBase as hbStyles } from "@scripts/generated/assets/stylesheets/components/_header-base";
import { subheaderStandard } from "@scripts/generated/assets/stylesheets/components/_subheader-standard";
import type { DataMetaBase, DataMetaSvg } from "@scripts/meta/dataMeta";
import { displayName, pluralDisplayName } from "@scripts/meta/dataMeta";
import type { AnchorProps } from "@scripts/react/components/Anchor";
import { Anchor } from "@scripts/react/components/Anchor";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { Svg } from "@scripts/react/components/Svg";
import type { ReactText } from "@scripts/react/syntax/react";
import type { KlassProp } from "@scripts/react/util/classnames";
import { klass, klassProp, mergeKlassPropO } from "@scripts/react/util/classnames";
import { uniqueAriaId } from "@scripts/util/labelOrAriaLabel";

const subheaderKlass = subheaderStandard[".subheader-standard"];
const headerContentFlexKlass = hbStyles[".header-content-flex"];

export type SubheaderType = "default" | "warning" | "danger" | "success" | "disabled";

const getTypeKlass = (type: SubheaderType) => type === "default" ? "accent-2-700-bg" : type;

type SubHeaderBaseProps = {
  klasses: KlassProp;
  type: SubheaderType;
};

type PropsBase = {
  icon: E.Either<ReactText, SVGString>;
  title: string;
} & SubHeaderBaseProps;

export type SubheaderProps = Omit<PropsBase, "icon"> & {
  icon: O.Option<E.Either<ReactText, SVGString>>;
  link: O.Option<AnchorProps>;
};

const SubheaderTitle = (props: SubheaderProps) =>
  <div {...klass(subheaderKlass[".icon-title-link"])}>
    {pipe(
      props.icon,
      mapOrEmpty(
        icon => <div {...klass(subheaderKlass[".icon-circle-title"], getTypeKlass(props.type))}>
          {pipe(icon, E.fold((t) => <h3>{t}</h3>, (i) => <Svg src={i} />))}
        </div>
      )
    )}
    {O.fold(() => <h2 id={uniqueAriaId(props.title)} >{props.title}</h2>, (ap: AnchorProps) =>
      <div id={uniqueAriaId(props.title)} {...klass(subheaderKlass[".title-link"])}>
        <h5>{props.title}</h5>
        <Anchor {...ap} />
      </div>)(props.link)}
  </div>;

const SubheaderBase = (props: React.PropsWithChildren<SubHeaderBaseProps>) =>
  <div {...klassProp([subheaderKlass, headerContentFlexKlass])(props.klasses)}>
    {props.children}
  </div>;

export const SubHeaderH3 = (props: React.PropsWithChildren<SubHeaderBaseProps & { title: string }>) =>
  <SubheaderBase klasses={mergeKlassPropO(["subheader-h3", "rg-1"])(props.klasses)} type={props.type}>
    <h3>{props.title}</h3>
    {props.children}
  </SubheaderBase>;

export const Subheader = (props: React.PropsWithChildren<SubheaderProps>) =>
  <SubheaderBase {...props}>
    <SubheaderTitle {...props} />
    {props.children}
  </SubheaderBase>;

export const SubheaderSmall = (props: PropsBase) => {
  const klasses = klassProp(subheaderKlass)(props.klasses);
  const containerAttrs: HTMLAttributes<HTMLDivElement> = { ...klasses };

  return (
    <div {...containerAttrs}>
      <div {...klass(subheaderKlass[".icon-circle-title"], "small", getTypeKlass(props.type))}>
        {pipe(props.icon, E.fold((text) => <p>{text}</p>, (icon) => <Svg src={icon} />))}
      </div>
      <h5>{props.title}</h5>
    </div>
  );
};

type SubheaderCardProps = PropsBase & { subtitle: string };

export const SubheaderCard = (props: SubheaderCardProps) => (
  <div {...klassProp(subheaderKlass)(props.klasses)}>
    <div {...klass(subheaderKlass[".card-header"])}>
      <div {...klass(subheaderKlass[".icon-circle-title"], getTypeKlass(props.type))}>
        {pipe(props.icon, E.fold((text) => <h3>{text}</h3>, (icon) => <Svg src={icon} />))}
      </div>
      <div {...klass(subheaderKlass[".card-header"][".title-container"])}>
        <h3>{props.title}</h3>
        <h4 {...klass(getTypeKlass(props.type))}>{props.subtitle}</h4>
      </div>
    </div>
  </div>
);

export const SubheaderMeta = (props: DataMetaBase<string> & DataMetaSvg & { plural?: true }): ReactElement<SubheaderProps> =>
  <Subheader
    title={props.plural ? pluralDisplayName(props) : displayName(props)}
    klasses={O.none}
    link={O.none}
    type="default"
    icon={pipe(
      props.svg,
      O.fromNullable,
      O.map(E.right)
    )}
  />;
