import type { ReactElement } from "react";
import { StrictMode } from "react";
import { Provider } from "react-redux";
import type { Action, Store } from "redux";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { ErrorBoundary } from "@scripts/react/components/error/ErrorBoundary";
import { ConfigProvider } from "@scripts/react/context/Config";

import { Page } from "./Page";


type PageLoaderProps<State, Actions extends Action<string>> = {
  config: BLConfigWithLog;
  render: () => ReactElement;
  pageTitle: string;
  store: Store<State, Actions>;
};

export const PageLoader = <State, Actions extends Action<string>>(
  props: PageLoaderProps<State, Actions>
) => {
  return (
    <StrictMode>
      <ErrorBoundary config={props.config}>
        <ConfigProvider value={props.config}>
          <Provider store={props.store}>
            <Page>
              {props.render()}
            </Page>
          </Provider>
        </ConfigProvider>
      </ErrorBoundary>
    </StrictMode>
  );
};
