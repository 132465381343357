import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const disabled = {
  _tag: `Disabled`,
  id: 1,
  name: `Disabled`
} as const;

export type DisabledTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Disabled`>
}>;
export const disabledTaggedC: DisabledTaggedC = t.type({
  _tag: t.literal(`Disabled`)
});
export type DisabledTagged = t.TypeOf<DisabledTaggedC>;
export type Disabled = DisabledTagged & typeof disabled;
export type DisabledC = t.Type<Disabled, DisabledTagged>;
export const disabledC: DisabledC = pipe(disabledTaggedC, c => new t.Type<Disabled, DisabledTagged>(
  `Disabled`,
  (u: unknown): u is Disabled => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Disabled> => pipe(c.decode(u), E.map(x => ({ ...x, ...disabled }))),
  (x: Disabled): DisabledTagged => ({ ...x, _tag: `Disabled`}),
)) satisfies t.Type<Disabled, unknown>;


export const enabledRequired = {
  _tag: `EnabledRequired`,
  id: 2,
  name: `EnabledRequired`
} as const;

export type EnabledRequiredTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EnabledRequired`>
}>;
export const enabledRequiredTaggedC: EnabledRequiredTaggedC = t.type({
  _tag: t.literal(`EnabledRequired`)
});
export type EnabledRequiredTagged = t.TypeOf<EnabledRequiredTaggedC>;
export type EnabledRequired = EnabledRequiredTagged & typeof enabledRequired;
export type EnabledRequiredC = t.Type<EnabledRequired, EnabledRequiredTagged>;
export const enabledRequiredC: EnabledRequiredC = pipe(enabledRequiredTaggedC, c => new t.Type<EnabledRequired, EnabledRequiredTagged>(
  `EnabledRequired`,
  (u: unknown): u is EnabledRequired => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EnabledRequired> => pipe(c.decode(u), E.map(x => ({ ...x, ...enabledRequired }))),
  (x: EnabledRequired): EnabledRequiredTagged => ({ ...x, _tag: `EnabledRequired`}),
)) satisfies t.Type<EnabledRequired, unknown>;


export const enabledOptional = {
  _tag: `EnabledOptional`,
  id: 3,
  name: `EnabledOptional`
} as const;

export type EnabledOptionalTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EnabledOptional`>
}>;
export const enabledOptionalTaggedC: EnabledOptionalTaggedC = t.type({
  _tag: t.literal(`EnabledOptional`)
});
export type EnabledOptionalTagged = t.TypeOf<EnabledOptionalTaggedC>;
export type EnabledOptional = EnabledOptionalTagged & typeof enabledOptional;
export type EnabledOptionalC = t.Type<EnabledOptional, EnabledOptionalTagged>;
export const enabledOptionalC: EnabledOptionalC = pipe(enabledOptionalTaggedC, c => new t.Type<EnabledOptional, EnabledOptionalTagged>(
  `EnabledOptional`,
  (u: unknown): u is EnabledOptional => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EnabledOptional> => pipe(c.decode(u), E.map(x => ({ ...x, ...enabledOptional }))),
  (x: EnabledOptional): EnabledOptionalTagged => ({ ...x, _tag: `EnabledOptional`}),
)) satisfies t.Type<EnabledOptional, unknown>;


export const allBidSubmissionTemplateDetailStateC = [disabledC, enabledRequiredC, enabledOptionalC] as const;
export const allBidSubmissionTemplateDetailStateNames = [`Disabled`, `EnabledRequired`, `EnabledOptional`] as const;
export type BidSubmissionTemplateDetailStateName = (typeof allBidSubmissionTemplateDetailStateNames)[number];

export type BidSubmissionTemplateDetailStateCU = t.UnionC<[DisabledC, EnabledRequiredC, EnabledOptionalC]>;
export type BidSubmissionTemplateDetailStateU = Disabled | EnabledRequired | EnabledOptional;
export const BidSubmissionTemplateDetailStateCU: BidSubmissionTemplateDetailStateCU = t.union([disabledC, enabledRequiredC, enabledOptionalC]) satisfies t.Type<BidSubmissionTemplateDetailStateU, unknown>;

export const bidSubmissionTemplateDetailStateOrd: Ord.Ord<BidSubmissionTemplateDetailStateU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allBidSubmissionTemplateDetailState = [disabled, enabledRequired, enabledOptional] as const;
export type BidSubmissionTemplateDetailStateMap<A> = { [K in BidSubmissionTemplateDetailStateName]: A };


