import { pipe } from "fp-ts/lib/function";
import * as RA from "fp-ts/lib/ReadonlyArray";

import { O } from "@scripts/fp-ts";
import { registeredInvestmentAdvisorSlashWealthManagement } from "@scripts/generated/domaintables/investorTypes";
import type { UserPersonaU } from "@scripts/generated/domaintables/userPersonas";
import { allUserPersona, investorInstitutional, userPersonaOrd } from "@scripts/generated/domaintables/userPersonas";
import type { Actor } from "@scripts/generated/models/actor";

export const personaOptions = pipe(
  allUserPersona,
  RA.sort(userPersonaOrd),
  RA.map((persona: UserPersonaU) => ({
    id: `user-persona-${persona.id}`,
    value: persona.id,
    label: persona.personaName,
  })),
);
export const isInstitutionalPersona = O.fold(() => false, (id: number) => id === investorInstitutional.id);
export const isInstitutionalOrWealthManagement = (a: Actor) => isInstitutionalPersona(a.personaId) || pipe(a.investorType, O.fold(() => false, tpe => tpe.id === registeredInvestmentAdvisorSlashWealthManagement.id));
