export const encodeURIPair = ([k, v]: [string, string] | readonly [string, string]): string => encodeURIComponent(k) + "=" + encodeURIComponent(v);

const isNewPage = (url: string): boolean => globalThis.location.href !== globalThis.location.origin + url || globalThis.location.href !== url;
// eslint-disable-next-line no-restricted-syntax
export const pushState = (url: string): void => { if (isNewPage(url)) globalThis.history.pushState({}, "", url); };
export const setPageUrl = (r: { url: () => string }): void => pushState(r.url());
// eslint-disable-next-line no-restricted-syntax
export const replaceState = (url: string): void => { if (isNewPage(url)) globalThis.history.replaceState({}, "", url); };
export const replacePageUrl = (r: { url: () => string }): void => replaceState(r.url());

// eslint-disable-next-line no-restricted-syntax
export const openInSameTab = (href: string): void => { if (isNewPage(href)) globalThis.location.href = href; };

export const openInNewTab = (href: string): void => { if (isNewPage(href)) globalThis.open(href, "_blank"); };
