import { type ReactElement, type ReactNode, useCallback, useState } from "react";

import { constVoid, pipe, R, RA } from "@scripts/fp-ts";
import { useConfig } from "@scripts/react/context/Config";
import type { DataCodec, FormState, FormStateBase } from "@scripts/react/form/form";
import type { SetState } from "@scripts/react/syntax/react";
import { closeModalFlow, type CloseModalFn } from "@scripts/react/util/useModal";
import { delProp } from "@scripts/util/delProp";

import { ConfirmDiscardChanges, useDiscardConfirmModal } from "./Confirmation";
import { makeModalWithMetaProps, Modal, type ModalProps, type ModalWithMetaProps } from "./Modal";

const makeDismissAction = (dismissAction: CloseModalFn, clearAction?: () => void) => closeModalFlow(clearAction ?? constVoid, dismissAction);

const ModalWithDiscardBase = (props: Omit<ModalProps, "body" | "children"> & {
  states: ReadonlyArray<FormStateBase>;
  children: (handleDismiss: CloseModalFn) => ReactNode;
  clearAction: () => void;
  confirmDiscardBody?: ReactElement;
  suppressUnsavedChangesModal?: boolean;
}) => {
  const {
    closeConfirmModal,
    handleDiscard,
    handleDismiss,
    isConfirmModalOpen,
  } = useDiscardConfirmModal(props.states, makeDismissAction(props.dismissAction, props.clearAction), props.suppressUnsavedChangesModal);

  return <>
    <Modal
      {...delProp(props, "children")}
      body={props.children(handleDismiss)}
      dismissAction={handleDismiss}
    />
    <ConfirmDiscardChanges
      dismissAction={closeConfirmModal}
      open={isConfirmModalOpen}
      onDiscardChanges={handleDiscard}
    >
      {props.confirmDiscardBody}
    </ConfirmDiscardChanges>
  </>;
};

export const ModalWithMetaDiscardBase = (props: Omit<ModalWithMetaProps, "children"> & {
  states: ReadonlyArray<FormStateBase>;
  children: (handleDismiss: CloseModalFn) => ReactNode;
  clearAction: () => void;
  confirmDiscardBody?: ReactElement;
  suppressUnsavedChangesModal?: boolean;
}) => {
  const config = useConfig();
  return <ModalWithDiscardBase
    {...makeModalWithMetaProps(config)(props)}
    states={props.states}
    children={props.children}
    clearAction={props.clearAction}
    confirmDiscardBody={props.confirmDiscardBody}
    suppressUnsavedChangesModal={props.suppressUnsavedChangesModal}
  />;
};

export type ModalStateProps<A,> = { state: A, setState: SetState<A> };
export type StatePropsRecord<PC extends DataCodec> = { [k: string]: FormState<PC> };
export type ModalStatesArgs<PC extends DataCodec, S extends StatePropsRecord<PC> = { formState: FormState<PC> }> = { [K in keyof S]: ModalStateProps<S[K]> };

const useStateRecord = <PC extends DataCodec, S extends StatePropsRecord<PC>, K extends keyof S>(acc: Partial<ModalStatesArgs<PC, S>>, curr: [K, S[K]]) => {
  const [state, setState] = useState(curr[1]);
  return { ...acc, [curr[0]]: { state, setState } };
};
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const useFormStates = <PC extends DataCodec, S extends StatePropsRecord<PC>>(states: S): ModalStatesArgs<PC, S> => pipe(R.toArray<keyof Omit<S, number | symbol>, S[keyof S]>(states), RA.reduce<[keyof S, S[keyof S]], Partial<ModalStatesArgs<PC, S>>>({}, useStateRecord)) as ModalStatesArgs<PC, S>;

const clearAction = <PC extends DataCodec, S extends StatePropsRecord<PC>>(states: S, formStates: ModalStatesArgs<PC, S>) => () => pipe(
  states,
  R.mapWithIndex<keyof Omit<S, number | symbol>, S[keyof Omit<S, number | symbol>], void>(
    <K extends keyof Omit<S, number | symbol>>(k: K, initialState: S[K]) => formStates[k].setState(initialState)
  )
);

type DiscardBodyMulti<PC extends DataCodec, S extends StatePropsRecord<PC>> = (statesProps: ModalStatesArgs<PC, S>, handleDismiss: CloseModalFn, clearAction: () => void) => ReactNode;
export type DiscardMultiProps<PC extends DataCodec, S extends StatePropsRecord<PC>> = {
  states: S;
  children: DiscardBodyMulti<PC, S>;
  confirmDiscardBody?: ReactElement;
  suppressUnsavedChangesModal?: boolean;
};

export type DiscardBody<PC extends DataCodec> = (statesProps: ModalStateProps<FormState<PC>>, handleDismiss: CloseModalFn, clearAction: () => void) => ReactNode;
type DiscardProps<PC extends DataCodec> = {
  initialState: FormState<PC>;
  children: DiscardBody<PC>;
  confirmDiscardBody?: ReactElement;
  suppressUnsavedChangesModal?: boolean;
};
type ModalWithDiscardProps<PC extends DataCodec> = Omit<ModalProps, "body" | "children"> & DiscardProps<PC>;

export const ModalWithDiscard = <PC extends DataCodec>(props: ModalWithDiscardProps<PC>) => {
  const [state, setState] = useState(props.initialState);
  const states = [state];
  const modalClearAction = useCallback(() => setState(props.initialState), [props.initialState]);

  return <ModalWithDiscardBase
    {...props}
    states={states}
    clearAction={modalClearAction}
  >
    {_ => props.children({ state, setState }, _, modalClearAction)}
  </ModalWithDiscardBase>;
};

type ModalWithMetaDiscardMultiProps<PC extends DataCodec, S extends StatePropsRecord<PC>> = Omit<ModalWithMetaProps, "children"> & DiscardMultiProps<PC, S>;

export const ModalWithMetaDiscardMulti = <PC extends DataCodec, S extends StatePropsRecord<PC>>(props: ModalWithMetaDiscardMultiProps<PC, S>) => {
  const formStates: ModalStatesArgs<PC, S> = useFormStates(props.states);
  const states: ReadonlyArray<FormStateBase> = R.toArray(formStates).map(a => a[1].state);
  const modalClearAction = clearAction(props.states, formStates);

  return <ModalWithMetaDiscardBase
    {...props}
    states={states}
    clearAction={modalClearAction}
  >
    {_ => props.children(formStates, _, modalClearAction)}
  </ModalWithMetaDiscardBase>;
};

type ModalWithMetaDiscardProps<PC extends DataCodec> = Omit<ModalWithMetaProps, "children"> & DiscardProps<PC>;

export const ModalWithMetaDiscard = <PC extends DataCodec>(props: ModalWithMetaDiscardProps<PC>) => {
  const [state, setState] = useState(props.initialState);
  const states = [state];
  const modalClearAction = useCallback(() => setState(props.initialState), [props.initialState]);

  return <ModalWithMetaDiscardBase
    {...props}
    states={states}
    clearAction={modalClearAction}
  >
    {_ => props.children({ state, setState }, _, modalClearAction)}
  </ModalWithMetaDiscardBase>;
};
