import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const unscheduled = {
  _tag: `Unscheduled`,
  name: `Unscheduled`
} as const;

export type UnscheduledTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Unscheduled`>
}>;
export const unscheduledTaggedC: UnscheduledTaggedC = t.type({
  _tag: t.literal(`Unscheduled`)
});
export type UnscheduledTagged = t.TypeOf<UnscheduledTaggedC>;
export type Unscheduled = UnscheduledTagged & typeof unscheduled;
export type UnscheduledC = t.Type<Unscheduled, UnscheduledTagged>;
export const unscheduledC: UnscheduledC = pipe(unscheduledTaggedC, c => new t.Type<Unscheduled, UnscheduledTagged>(
  `Unscheduled`,
  (u: unknown): u is Unscheduled => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Unscheduled> => pipe(c.decode(u), E.map(x => ({ ...x, ...unscheduled }))),
  (x: Unscheduled): UnscheduledTagged => ({ ...x, _tag: `Unscheduled`}),
)) satisfies t.Type<Unscheduled, unknown>;


export const biddingOpen = {
  _tag: `BiddingOpen`,
  name: `BiddingOpen`
} as const;

export type BiddingOpenTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BiddingOpen`>
}>;
export const biddingOpenTaggedC: BiddingOpenTaggedC = t.type({
  _tag: t.literal(`BiddingOpen`)
});
export type BiddingOpenTagged = t.TypeOf<BiddingOpenTaggedC>;
export type BiddingOpen = BiddingOpenTagged & typeof biddingOpen;
export type BiddingOpenC = t.Type<BiddingOpen, BiddingOpenTagged>;
export const biddingOpenC: BiddingOpenC = pipe(biddingOpenTaggedC, c => new t.Type<BiddingOpen, BiddingOpenTagged>(
  `BiddingOpen`,
  (u: unknown): u is BiddingOpen => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BiddingOpen> => pipe(c.decode(u), E.map(x => ({ ...x, ...biddingOpen }))),
  (x: BiddingOpen): BiddingOpenTagged => ({ ...x, _tag: `BiddingOpen`}),
)) satisfies t.Type<BiddingOpen, unknown>;


export const biddingClosed = {
  _tag: `BiddingClosed`,
  name: `BiddingClosed`
} as const;

export type BiddingClosedTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BiddingClosed`>
}>;
export const biddingClosedTaggedC: BiddingClosedTaggedC = t.type({
  _tag: t.literal(`BiddingClosed`)
});
export type BiddingClosedTagged = t.TypeOf<BiddingClosedTaggedC>;
export type BiddingClosed = BiddingClosedTagged & typeof biddingClosed;
export type BiddingClosedC = t.Type<BiddingClosed, BiddingClosedTagged>;
export const biddingClosedC: BiddingClosedC = pipe(biddingClosedTaggedC, c => new t.Type<BiddingClosed, BiddingClosedTagged>(
  `BiddingClosed`,
  (u: unknown): u is BiddingClosed => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BiddingClosed> => pipe(c.decode(u), E.map(x => ({ ...x, ...biddingClosed }))),
  (x: BiddingClosed): BiddingClosedTagged => ({ ...x, _tag: `BiddingClosed`}),
)) satisfies t.Type<BiddingClosed, unknown>;


export const bidAwarded = {
  _tag: `BidAwarded`,
  name: `BidAwarded`
} as const;

export type BidAwardedTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BidAwarded`>
}>;
export const bidAwardedTaggedC: BidAwardedTaggedC = t.type({
  _tag: t.literal(`BidAwarded`)
});
export type BidAwardedTagged = t.TypeOf<BidAwardedTaggedC>;
export type BidAwarded = BidAwardedTagged & typeof bidAwarded;
export type BidAwardedC = t.Type<BidAwarded, BidAwardedTagged>;
export const bidAwardedC: BidAwardedC = pipe(bidAwardedTaggedC, c => new t.Type<BidAwarded, BidAwardedTagged>(
  `BidAwarded`,
  (u: unknown): u is BidAwarded => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BidAwarded> => pipe(c.decode(u), E.map(x => ({ ...x, ...bidAwarded }))),
  (x: BidAwarded): BidAwardedTagged => ({ ...x, _tag: `BidAwarded`}),
)) satisfies t.Type<BidAwarded, unknown>;


export const allBiddingStateC = [unscheduledC, biddingOpenC, biddingClosedC, bidAwardedC] as const;
export const allBiddingStateNames = [`Unscheduled`, `BiddingOpen`, `BiddingClosed`, `BidAwarded`] as const;
export type BiddingStateName = (typeof allBiddingStateNames)[number];

export type BiddingStateCU = t.UnionC<[UnscheduledC, BiddingOpenC, BiddingClosedC, BidAwardedC]>;
export type BiddingStateU = Unscheduled | BiddingOpen | BiddingClosed | BidAwarded;
export const BiddingStateCU: BiddingStateCU = t.union([unscheduledC, biddingOpenC, biddingClosedC, bidAwardedC]) satisfies t.Type<BiddingStateU, unknown>;

export const biddingStateOrd: Ord.Ord<BiddingStateU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allBiddingState = [unscheduled, biddingOpen, biddingClosed, bidAwarded] as const;
export type BiddingStateMap<A> = { [K in BiddingStateName]: A };


