export * from "fp-ts/lib/Eq";

import * as Eq from "fp-ts/lib/Eq";
import type { Predicate } from "fp-ts/lib/Predicate";

import type * as Match from "./lib/types/matchers";

/** @alias for `S.Eq` */
export { Eq as string } from "fp-ts/lib/string"; // eslint-disable-line id-blacklist
/** @alias for `N.Eq` */
export { Eq as number } from "fp-ts/lib/number"; // eslint-disable-line id-blacklist
/** @alias for `B.Eq` */
export { Eq as boolean } from "fp-ts/lib/boolean"; // eslint-disable-line id-blacklist

/** @alias for `Eq.eqStrict` */
export const strict = Eq.eqStrict;

/** @internal */
type Eq<A> = Eq.Eq<A>;
/** @internal */
type BinaryPredicate<A> = (x: A, y: A) => boolean;

type EqNullable<A> = Eq<Match.Nullable<A>>;

/** @internal */
const isNullOrUndefined = (u: unknown): u is Match.NullOrUndefined => u == null;
/** @internal */
const both = <A>(predicate: Predicate<A>): BinaryPredicate<A> => (x, y) => predicate(x) && predicate(y);
/** @internal, but could maybe be exported */
const union = <A, B>(eq: Eq<A>, predicate: Predicate<A | B>): Eq<A | B> => Eq.fromEquals((a, b) =>
  /** case: both pass */
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  both(predicate)(a, b) ? (eq as Eq<A | B>).equals(a, b)
    /**
     * case: only 1 passes
     * We already handled the `both pass` case above, which
     * means that if _one_ of them passes the predicate, the
     * values are by definition not equivalent.
     */
    : predicate(a) ? false
      : predicate(b) ? false
        /**
         * case: neither passes (depending on the predicate,
         * both failing might not automatically mean the
         * values aren't equivalent.
         */
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        : (eq as Eq<A | B>).equals(a, b));

/**
 * @category combinators
 *
 * `Eq.nullable` is a combinator that takes an `Eq<A>` and returns an
 * `Eq<A | undefined | null>`.
 *
 * See above `Eq.optional` comment for behavioral notes.
 * */
export const nullable = <A>(eq: Eq<A>): EqNullable<A> => union(eq, isNullOrUndefined);
/**
 * @category combinators
 * `Eq.nullableStrict` is a combinator that takes an `Eq<A>` and returns an
 * `Eq<A | undefined | null>`. Unlike `Eq.optional` and `Eq.nullable` above,
 * `Eq.nullableStrict` returns `false` if both values are `undefined | null`
 *
 * Consider using `Eq.nullable` in any situations that could trigger a re-render
 * due to a comparison of `undefined` values being false.
*/
export const nullableStrict = <A>(eq: Eq<A>): EqNullable<A> => ({
  equals: (a, b) => (isNullOrUndefined(a) || isNullOrUndefined(b)) ? false : eq.equals(a, b),
});
