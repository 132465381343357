import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as t from "io-ts";
import { TitleEditableU as imported5_TitleEditableU, TitleEditableU as imported4_TitleEditableU, TitleEditableCU as imported8_TitleEditableCU, TitleEditableCU as imported10_TitleEditableCU, TitleEditableCU as imported11_TitleEditableCU, TitleEditableCU as imported9_TitleEditableCU } from "../domaintables/pages";
import * as O from "fp-ts/lib/Option";

export type PageTitlesC = t.ReadonlyArrayC<t.TupleC<[imported9_TitleEditableCU, t.StringC]>>;
export type PageTitles = ReadonlyArray<[imported4_TitleEditableU, string]>;
export const pageTitlesC: PageTitlesC = t.readonlyArray(t.tuple([imported8_TitleEditableCU, t.string])) satisfies t.Type<PageTitles, unknown>;


export type PostTitleC = t.TypeC<{
  page: imported11_TitleEditableCU,
  title: OptionFromNullableC<t.StringC>
}>;
export type PostTitle = {
  page: imported5_TitleEditableU,
  title: O.Option<string>
};
export const postTitleC: PostTitleC = t.type({
  page: imported10_TitleEditableCU,
  title: optionFromNullable(t.string)
}) satisfies t.Type<PostTitle, unknown>;


