import { constVoid, identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { fromNullable, map } from "fp-ts/lib/Option";

import { type RelativeUrl, unsafeFromRelativeUrlString } from "@scripts/codecs/relativeUrl";
import { tap } from "@scripts/util/tap";

import { replaceState } from "./router";

export type Method = "GET" | "POST" | "OPTIONS" | "DELETE";

export type UrlInterface<M extends Method> = { method: M, url: string };

export const urlInterface = <M extends Method>(method: M, url: string): UrlInterface<M> =>
  ({ method, url });

interface UrlIO<I, O> {
  readonly input: I;
  readonly output: O;
}

export interface UrlInterfaceIO<M extends Method, I, O> extends UrlInterface<M>, UrlIO<I, O> { }

export type RelativeUrlInterface<M extends Method> = Omit<UrlInterface<M>, "url"> & { url: RelativeUrl };

export const relativeUrlInterface = <M extends Method>(method: M, url: RelativeUrl): RelativeUrlInterface<M> =>
  ({ method, url });

export type RelativeUrlInterfaceIO<M extends Method, I, O> = Omit<UrlInterfaceIO<M, I, O>, "url"> & { url: RelativeUrl };

export const relativeUrlInterfaceIO = <M extends Method, I, O>(method: M, url: RelativeUrl, io: UrlIO<I, O>): RelativeUrlInterfaceIO<M, I, O> =>
  ({ method, url, input: io.input, output: io.output });

const urlWithQuery = (url: string, query: URLSearchParams): string => {
  const queryStr = query.toString();
  return `${url}${queryStr === "" ? "" : `?${queryStr}`}`;
};

// The types say location isn't nullable but it returns null server side
const getLocation = (): O.Option<Location> => O.fromNullable(globalThis.location);

const modifyAbsoluteUrl = (
  modOrigin: (origin: string) => string,
  modPath: (path: string) => string,
  modQuery: (query: URLSearchParams) => URLSearchParams,
): O.Option<string> => pipe(
  getLocation(),
  O.map(l => urlWithQuery(
    `${modOrigin(l.origin)}${modPath(l.pathname)}`,
    modQuery(new URLSearchParams(l.search))
  )),
);

const getRelative = (f: (l: Location) => string) => {
  function go(): O.Option<RelativeUrl>;
  function go(l: Location): RelativeUrl;
  function go(l?: Location): O.Option<RelativeUrl> | RelativeUrl {
    return l ? unsafeFromRelativeUrlString(f(l)) : pipe(getLocation(), O.map(_ => unsafeFromRelativeUrlString(f(_))));
  }
  return go;
};

export const getRelativePath = getRelative(_ => _.pathname);
export const getRelativeUrl = getRelative(_ => urlWithQuery(_.pathname, new URLSearchParams(_.search)));

export const replaceUrlState = (
  modOrigin: (origin: string) => string,
  modPath: (path: string) => string,
  modQuery: (query: URLSearchParams) => URLSearchParams,
): void => pipe(modifyAbsoluteUrl(modOrigin, modPath, modQuery), O.fold(constVoid, replaceState));

export const removeUrlParam = (param: string): O.Option<void> => pipe(
  fromNullable(new URLSearchParams(window.location.search).get(param)),
  map(() => replaceUrlState(identity, identity, tap(q => q.delete(param)))),
);
