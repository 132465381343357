export const bondlinkPrimaryColor = "#007fad";
export const bondlinkSecondaryColor = "#15625e";

export const blue = {
  900: "#005270",
  700: bondlinkPrimaryColor,
  500: "#3399bd",
  300: "#aedaea",
  100: "#ebf6fa",
} as const;

export const teal = {
  900: "#0c3734",
  700: bondlinkSecondaryColor,
  500: "#428a86",
  300: "#bfd9d7",
  100: "#eff5f5",
} as const;

export const lime = {
  900: "#3c7000",
  700: "#5aa701",
  500: "#7bb934",
  300: "#cee9af",
  100: "#f3f9eb",
} as const;

export const magenta = {
  900: "#4d0027",
  700: "#80124a",
  500: "#99416e",
  300: "#e1b7cc",
  100: "#f7edf2",
} as const;

export const orange = {
  900: "#994200",
  700: "#cc6314",
  500: "#d68243",
  300: "#edc7ab",
  100: "#faf1ea",
} as const;

export const indigo = {
  900: "#162569",
  700: "#3f51a3",
  500: "#6574b5",
  300: "#bac1de",
  100: "#eeeff7",
} as const;

/** @knipignore - used in scss */
export const red = {
  900: "#8d1616",
  700: "#cf2020",
  500: "#ed0707",
  300: "#fc9c9c",
  100: "#fee6e6",
} as const;

/** @knipignore - used in scss */
export const green = {
  900: "#044409",
  700: "#066b0e",
  500: "#008a0b",
  300: "#badebd",
  100: "#eef7ef",
} as const;

export const yellow = {
  900: "#996600",
  700: "#c18815",
  500: "#e8ab30",
  300: "#f5daa3",
  100: "#fcf6e8",
} as const;

export const gray = {
  900: "#202020",
  800: "#333333",
  700: "#4a4a4a",
  600: "#727272",
  500: "#949494",
  400: "#b9b9b9",
  300: "#d8d8d8",
  200: "#e8e8e8",
  100: "#f8f8f8",
} as const;

export const black = "#000000";
export const white = "#ffffff";
