import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const EMPTY_RESULT = {
  _tag: `EMPTY_RESULT`,
  error: `EMPTY_RESULT`
} as const;

export type EMPTY_RESULTTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EMPTY_RESULT`>
}>;
export const EMPTY_RESULTTaggedC: EMPTY_RESULTTaggedC = t.type({
  _tag: t.literal(`EMPTY_RESULT`)
});
export type EMPTY_RESULTTagged = t.TypeOf<EMPTY_RESULTTaggedC>;
export type EMPTY_RESULT = EMPTY_RESULTTagged & typeof EMPTY_RESULT;
export type EMPTY_RESULTC = t.Type<EMPTY_RESULT, EMPTY_RESULTTagged>;
export const EMPTY_RESULTC: EMPTY_RESULTC = pipe(EMPTY_RESULTTaggedC, c => new t.Type<EMPTY_RESULT, EMPTY_RESULTTagged>(
  `EMPTY_RESULT`,
  (u: unknown): u is EMPTY_RESULT => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EMPTY_RESULT> => pipe(c.decode(u), E.map(x => ({ ...x, ...EMPTY_RESULT }))),
  (x: EMPTY_RESULT): EMPTY_RESULTTagged => ({ ...x, _tag: `EMPTY_RESULT`}),
)) satisfies t.Type<EMPTY_RESULT, unknown>;


export const RESOURCE_NOT_FOUND = {
  _tag: `RESOURCE_NOT_FOUND`,
  error: `RESOURCE_NOT_FOUND`
} as const;

export type RESOURCE_NOT_FOUNDTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RESOURCE_NOT_FOUND`>
}>;
export const RESOURCE_NOT_FOUNDTaggedC: RESOURCE_NOT_FOUNDTaggedC = t.type({
  _tag: t.literal(`RESOURCE_NOT_FOUND`)
});
export type RESOURCE_NOT_FOUNDTagged = t.TypeOf<RESOURCE_NOT_FOUNDTaggedC>;
export type RESOURCE_NOT_FOUND = RESOURCE_NOT_FOUNDTagged & typeof RESOURCE_NOT_FOUND;
export type RESOURCE_NOT_FOUNDC = t.Type<RESOURCE_NOT_FOUND, RESOURCE_NOT_FOUNDTagged>;
export const RESOURCE_NOT_FOUNDC: RESOURCE_NOT_FOUNDC = pipe(RESOURCE_NOT_FOUNDTaggedC, c => new t.Type<RESOURCE_NOT_FOUND, RESOURCE_NOT_FOUNDTagged>(
  `RESOURCE_NOT_FOUND`,
  (u: unknown): u is RESOURCE_NOT_FOUND => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RESOURCE_NOT_FOUND> => pipe(c.decode(u), E.map(x => ({ ...x, ...RESOURCE_NOT_FOUND }))),
  (x: RESOURCE_NOT_FOUND): RESOURCE_NOT_FOUNDTagged => ({ ...x, _tag: `RESOURCE_NOT_FOUND`}),
)) satisfies t.Type<RESOURCE_NOT_FOUND, unknown>;


export const ID_NOT_FOUND = {
  _tag: `ID_NOT_FOUND`,
  error: `ID_NOT_FOUND`
} as const;

export type ID_NOT_FOUNDTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ID_NOT_FOUND`>
}>;
export const ID_NOT_FOUNDTaggedC: ID_NOT_FOUNDTaggedC = t.type({
  _tag: t.literal(`ID_NOT_FOUND`)
});
export type ID_NOT_FOUNDTagged = t.TypeOf<ID_NOT_FOUNDTaggedC>;
export type ID_NOT_FOUND = ID_NOT_FOUNDTagged & typeof ID_NOT_FOUND;
export type ID_NOT_FOUNDC = t.Type<ID_NOT_FOUND, ID_NOT_FOUNDTagged>;
export const ID_NOT_FOUNDC: ID_NOT_FOUNDC = pipe(ID_NOT_FOUNDTaggedC, c => new t.Type<ID_NOT_FOUND, ID_NOT_FOUNDTagged>(
  `ID_NOT_FOUND`,
  (u: unknown): u is ID_NOT_FOUND => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ID_NOT_FOUND> => pipe(c.decode(u), E.map(x => ({ ...x, ...ID_NOT_FOUND }))),
  (x: ID_NOT_FOUND): ID_NOT_FOUNDTagged => ({ ...x, _tag: `ID_NOT_FOUND`}),
)) satisfies t.Type<ID_NOT_FOUND, unknown>;


export const MISSING_REQUIRED_FIELD = {
  _tag: `MISSING_REQUIRED_FIELD`,
  error: `MISSING_REQUIRED_FIELD`
} as const;

export type MISSING_REQUIRED_FIELDTaggedC = t.TypeC<{
  _tag: t.LiteralC<`MISSING_REQUIRED_FIELD`>
}>;
export const MISSING_REQUIRED_FIELDTaggedC: MISSING_REQUIRED_FIELDTaggedC = t.type({
  _tag: t.literal(`MISSING_REQUIRED_FIELD`)
});
export type MISSING_REQUIRED_FIELDTagged = t.TypeOf<MISSING_REQUIRED_FIELDTaggedC>;
export type MISSING_REQUIRED_FIELD = MISSING_REQUIRED_FIELDTagged & typeof MISSING_REQUIRED_FIELD;
export type MISSING_REQUIRED_FIELDC = t.Type<MISSING_REQUIRED_FIELD, MISSING_REQUIRED_FIELDTagged>;
export const MISSING_REQUIRED_FIELDC: MISSING_REQUIRED_FIELDC = pipe(MISSING_REQUIRED_FIELDTaggedC, c => new t.Type<MISSING_REQUIRED_FIELD, MISSING_REQUIRED_FIELDTagged>(
  `MISSING_REQUIRED_FIELD`,
  (u: unknown): u is MISSING_REQUIRED_FIELD => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MISSING_REQUIRED_FIELD> => pipe(c.decode(u), E.map(x => ({ ...x, ...MISSING_REQUIRED_FIELD }))),
  (x: MISSING_REQUIRED_FIELD): MISSING_REQUIRED_FIELDTagged => ({ ...x, _tag: `MISSING_REQUIRED_FIELD`}),
)) satisfies t.Type<MISSING_REQUIRED_FIELD, unknown>;


export const CUSTOM = {
  _tag: `CUSTOM`,
  error: `CUSTOM`
} as const;

export type CUSTOMTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CUSTOM`>
}>;
export const CUSTOMTaggedC: CUSTOMTaggedC = t.type({
  _tag: t.literal(`CUSTOM`)
});
export type CUSTOMTagged = t.TypeOf<CUSTOMTaggedC>;
export type CUSTOM = CUSTOMTagged & typeof CUSTOM;
export type CUSTOMC = t.Type<CUSTOM, CUSTOMTagged>;
export const CUSTOMC: CUSTOMC = pipe(CUSTOMTaggedC, c => new t.Type<CUSTOM, CUSTOMTagged>(
  `CUSTOM`,
  (u: unknown): u is CUSTOM => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CUSTOM> => pipe(c.decode(u), E.map(x => ({ ...x, ...CUSTOM }))),
  (x: CUSTOM): CUSTOMTagged => ({ ...x, _tag: `CUSTOM`}),
)) satisfies t.Type<CUSTOM, unknown>;


export const TOO_MANY_RESULTS = {
  _tag: `TOO_MANY_RESULTS`,
  error: `TOO_MANY_RESULTS`
} as const;

export type TOO_MANY_RESULTSTaggedC = t.TypeC<{
  _tag: t.LiteralC<`TOO_MANY_RESULTS`>
}>;
export const TOO_MANY_RESULTSTaggedC: TOO_MANY_RESULTSTaggedC = t.type({
  _tag: t.literal(`TOO_MANY_RESULTS`)
});
export type TOO_MANY_RESULTSTagged = t.TypeOf<TOO_MANY_RESULTSTaggedC>;
export type TOO_MANY_RESULTS = TOO_MANY_RESULTSTagged & typeof TOO_MANY_RESULTS;
export type TOO_MANY_RESULTSC = t.Type<TOO_MANY_RESULTS, TOO_MANY_RESULTSTagged>;
export const TOO_MANY_RESULTSC: TOO_MANY_RESULTSC = pipe(TOO_MANY_RESULTSTaggedC, c => new t.Type<TOO_MANY_RESULTS, TOO_MANY_RESULTSTagged>(
  `TOO_MANY_RESULTS`,
  (u: unknown): u is TOO_MANY_RESULTS => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TOO_MANY_RESULTS> => pipe(c.decode(u), E.map(x => ({ ...x, ...TOO_MANY_RESULTS }))),
  (x: TOO_MANY_RESULTS): TOO_MANY_RESULTSTagged => ({ ...x, _tag: `TOO_MANY_RESULTS`}),
)) satisfies t.Type<TOO_MANY_RESULTS, unknown>;


export const TOO_MANY_REQUESTS = {
  _tag: `TOO_MANY_REQUESTS`,
  error: `TOO_MANY_REQUESTS`
} as const;

export type TOO_MANY_REQUESTSTaggedC = t.TypeC<{
  _tag: t.LiteralC<`TOO_MANY_REQUESTS`>
}>;
export const TOO_MANY_REQUESTSTaggedC: TOO_MANY_REQUESTSTaggedC = t.type({
  _tag: t.literal(`TOO_MANY_REQUESTS`)
});
export type TOO_MANY_REQUESTSTagged = t.TypeOf<TOO_MANY_REQUESTSTaggedC>;
export type TOO_MANY_REQUESTS = TOO_MANY_REQUESTSTagged & typeof TOO_MANY_REQUESTS;
export type TOO_MANY_REQUESTSC = t.Type<TOO_MANY_REQUESTS, TOO_MANY_REQUESTSTagged>;
export const TOO_MANY_REQUESTSC: TOO_MANY_REQUESTSC = pipe(TOO_MANY_REQUESTSTaggedC, c => new t.Type<TOO_MANY_REQUESTS, TOO_MANY_REQUESTSTagged>(
  `TOO_MANY_REQUESTS`,
  (u: unknown): u is TOO_MANY_REQUESTS => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TOO_MANY_REQUESTS> => pipe(c.decode(u), E.map(x => ({ ...x, ...TOO_MANY_REQUESTS }))),
  (x: TOO_MANY_REQUESTS): TOO_MANY_REQUESTSTagged => ({ ...x, _tag: `TOO_MANY_REQUESTS`}),
)) satisfies t.Type<TOO_MANY_REQUESTS, unknown>;


export const FOREIGN_KEY_CONSTRAINT = {
  _tag: `FOREIGN_KEY_CONSTRAINT`,
  error: `FOREIGN_KEY_CONSTRAINT`
} as const;

export type FOREIGN_KEY_CONSTRAINTTaggedC = t.TypeC<{
  _tag: t.LiteralC<`FOREIGN_KEY_CONSTRAINT`>
}>;
export const FOREIGN_KEY_CONSTRAINTTaggedC: FOREIGN_KEY_CONSTRAINTTaggedC = t.type({
  _tag: t.literal(`FOREIGN_KEY_CONSTRAINT`)
});
export type FOREIGN_KEY_CONSTRAINTTagged = t.TypeOf<FOREIGN_KEY_CONSTRAINTTaggedC>;
export type FOREIGN_KEY_CONSTRAINT = FOREIGN_KEY_CONSTRAINTTagged & typeof FOREIGN_KEY_CONSTRAINT;
export type FOREIGN_KEY_CONSTRAINTC = t.Type<FOREIGN_KEY_CONSTRAINT, FOREIGN_KEY_CONSTRAINTTagged>;
export const FOREIGN_KEY_CONSTRAINTC: FOREIGN_KEY_CONSTRAINTC = pipe(FOREIGN_KEY_CONSTRAINTTaggedC, c => new t.Type<FOREIGN_KEY_CONSTRAINT, FOREIGN_KEY_CONSTRAINTTagged>(
  `FOREIGN_KEY_CONSTRAINT`,
  (u: unknown): u is FOREIGN_KEY_CONSTRAINT => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FOREIGN_KEY_CONSTRAINT> => pipe(c.decode(u), E.map(x => ({ ...x, ...FOREIGN_KEY_CONSTRAINT }))),
  (x: FOREIGN_KEY_CONSTRAINT): FOREIGN_KEY_CONSTRAINTTagged => ({ ...x, _tag: `FOREIGN_KEY_CONSTRAINT`}),
)) satisfies t.Type<FOREIGN_KEY_CONSTRAINT, unknown>;


export const UNIQUE_CONSTRAINT = {
  _tag: `UNIQUE_CONSTRAINT`,
  error: `UNIQUE_CONSTRAINT`
} as const;

export type UNIQUE_CONSTRAINTTaggedC = t.TypeC<{
  _tag: t.LiteralC<`UNIQUE_CONSTRAINT`>
}>;
export const UNIQUE_CONSTRAINTTaggedC: UNIQUE_CONSTRAINTTaggedC = t.type({
  _tag: t.literal(`UNIQUE_CONSTRAINT`)
});
export type UNIQUE_CONSTRAINTTagged = t.TypeOf<UNIQUE_CONSTRAINTTaggedC>;
export type UNIQUE_CONSTRAINT = UNIQUE_CONSTRAINTTagged & typeof UNIQUE_CONSTRAINT;
export type UNIQUE_CONSTRAINTC = t.Type<UNIQUE_CONSTRAINT, UNIQUE_CONSTRAINTTagged>;
export const UNIQUE_CONSTRAINTC: UNIQUE_CONSTRAINTC = pipe(UNIQUE_CONSTRAINTTaggedC, c => new t.Type<UNIQUE_CONSTRAINT, UNIQUE_CONSTRAINTTagged>(
  `UNIQUE_CONSTRAINT`,
  (u: unknown): u is UNIQUE_CONSTRAINT => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UNIQUE_CONSTRAINT> => pipe(c.decode(u), E.map(x => ({ ...x, ...UNIQUE_CONSTRAINT }))),
  (x: UNIQUE_CONSTRAINT): UNIQUE_CONSTRAINTTagged => ({ ...x, _tag: `UNIQUE_CONSTRAINT`}),
)) satisfies t.Type<UNIQUE_CONSTRAINT, unknown>;


export const INTERNAL_SERVER_ERROR = {
  _tag: `INTERNAL_SERVER_ERROR`,
  error: `INTERNAL_SERVER_ERROR`
} as const;

export type INTERNAL_SERVER_ERRORTaggedC = t.TypeC<{
  _tag: t.LiteralC<`INTERNAL_SERVER_ERROR`>
}>;
export const INTERNAL_SERVER_ERRORTaggedC: INTERNAL_SERVER_ERRORTaggedC = t.type({
  _tag: t.literal(`INTERNAL_SERVER_ERROR`)
});
export type INTERNAL_SERVER_ERRORTagged = t.TypeOf<INTERNAL_SERVER_ERRORTaggedC>;
export type INTERNAL_SERVER_ERROR = INTERNAL_SERVER_ERRORTagged & typeof INTERNAL_SERVER_ERROR;
export type INTERNAL_SERVER_ERRORC = t.Type<INTERNAL_SERVER_ERROR, INTERNAL_SERVER_ERRORTagged>;
export const INTERNAL_SERVER_ERRORC: INTERNAL_SERVER_ERRORC = pipe(INTERNAL_SERVER_ERRORTaggedC, c => new t.Type<INTERNAL_SERVER_ERROR, INTERNAL_SERVER_ERRORTagged>(
  `INTERNAL_SERVER_ERROR`,
  (u: unknown): u is INTERNAL_SERVER_ERROR => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, INTERNAL_SERVER_ERROR> => pipe(c.decode(u), E.map(x => ({ ...x, ...INTERNAL_SERVER_ERROR }))),
  (x: INTERNAL_SERVER_ERROR): INTERNAL_SERVER_ERRORTagged => ({ ...x, _tag: `INTERNAL_SERVER_ERROR`}),
)) satisfies t.Type<INTERNAL_SERVER_ERROR, unknown>;


export const SESSION_ERROR = {
  _tag: `SESSION_ERROR`,
  error: `SESSION_ERROR`
} as const;

export type SESSION_ERRORTaggedC = t.TypeC<{
  _tag: t.LiteralC<`SESSION_ERROR`>
}>;
export const SESSION_ERRORTaggedC: SESSION_ERRORTaggedC = t.type({
  _tag: t.literal(`SESSION_ERROR`)
});
export type SESSION_ERRORTagged = t.TypeOf<SESSION_ERRORTaggedC>;
export type SESSION_ERROR = SESSION_ERRORTagged & typeof SESSION_ERROR;
export type SESSION_ERRORC = t.Type<SESSION_ERROR, SESSION_ERRORTagged>;
export const SESSION_ERRORC: SESSION_ERRORC = pipe(SESSION_ERRORTaggedC, c => new t.Type<SESSION_ERROR, SESSION_ERRORTagged>(
  `SESSION_ERROR`,
  (u: unknown): u is SESSION_ERROR => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SESSION_ERROR> => pipe(c.decode(u), E.map(x => ({ ...x, ...SESSION_ERROR }))),
  (x: SESSION_ERROR): SESSION_ERRORTagged => ({ ...x, _tag: `SESSION_ERROR`}),
)) satisfies t.Type<SESSION_ERROR, unknown>;


export const CLEAR_SESSION = {
  _tag: `CLEAR_SESSION`,
  error: `CLEAR_SESSION`
} as const;

export type CLEAR_SESSIONTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CLEAR_SESSION`>
}>;
export const CLEAR_SESSIONTaggedC: CLEAR_SESSIONTaggedC = t.type({
  _tag: t.literal(`CLEAR_SESSION`)
});
export type CLEAR_SESSIONTagged = t.TypeOf<CLEAR_SESSIONTaggedC>;
export type CLEAR_SESSION = CLEAR_SESSIONTagged & typeof CLEAR_SESSION;
export type CLEAR_SESSIONC = t.Type<CLEAR_SESSION, CLEAR_SESSIONTagged>;
export const CLEAR_SESSIONC: CLEAR_SESSIONC = pipe(CLEAR_SESSIONTaggedC, c => new t.Type<CLEAR_SESSION, CLEAR_SESSIONTagged>(
  `CLEAR_SESSION`,
  (u: unknown): u is CLEAR_SESSION => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CLEAR_SESSION> => pipe(c.decode(u), E.map(x => ({ ...x, ...CLEAR_SESSION }))),
  (x: CLEAR_SESSION): CLEAR_SESSIONTagged => ({ ...x, _tag: `CLEAR_SESSION`}),
)) satisfies t.Type<CLEAR_SESSION, unknown>;


export const FIELD_ERRORS = {
  _tag: `FIELD_ERRORS`,
  error: `FIELD_ERRORS`
} as const;

export type FIELD_ERRORSTaggedC = t.TypeC<{
  _tag: t.LiteralC<`FIELD_ERRORS`>
}>;
export const FIELD_ERRORSTaggedC: FIELD_ERRORSTaggedC = t.type({
  _tag: t.literal(`FIELD_ERRORS`)
});
export type FIELD_ERRORSTagged = t.TypeOf<FIELD_ERRORSTaggedC>;
export type FIELD_ERRORS = FIELD_ERRORSTagged & typeof FIELD_ERRORS;
export type FIELD_ERRORSC = t.Type<FIELD_ERRORS, FIELD_ERRORSTagged>;
export const FIELD_ERRORSC: FIELD_ERRORSC = pipe(FIELD_ERRORSTaggedC, c => new t.Type<FIELD_ERRORS, FIELD_ERRORSTagged>(
  `FIELD_ERRORS`,
  (u: unknown): u is FIELD_ERRORS => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FIELD_ERRORS> => pipe(c.decode(u), E.map(x => ({ ...x, ...FIELD_ERRORS }))),
  (x: FIELD_ERRORS): FIELD_ERRORSTagged => ({ ...x, _tag: `FIELD_ERRORS`}),
)) satisfies t.Type<FIELD_ERRORS, unknown>;


export const PASSWORD_EXPIRED = {
  _tag: `PASSWORD_EXPIRED`,
  error: `PASSWORD_EXPIRED`
} as const;

export type PASSWORD_EXPIREDTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PASSWORD_EXPIRED`>
}>;
export const PASSWORD_EXPIREDTaggedC: PASSWORD_EXPIREDTaggedC = t.type({
  _tag: t.literal(`PASSWORD_EXPIRED`)
});
export type PASSWORD_EXPIREDTagged = t.TypeOf<PASSWORD_EXPIREDTaggedC>;
export type PASSWORD_EXPIRED = PASSWORD_EXPIREDTagged & typeof PASSWORD_EXPIRED;
export type PASSWORD_EXPIREDC = t.Type<PASSWORD_EXPIRED, PASSWORD_EXPIREDTagged>;
export const PASSWORD_EXPIREDC: PASSWORD_EXPIREDC = pipe(PASSWORD_EXPIREDTaggedC, c => new t.Type<PASSWORD_EXPIRED, PASSWORD_EXPIREDTagged>(
  `PASSWORD_EXPIRED`,
  (u: unknown): u is PASSWORD_EXPIRED => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PASSWORD_EXPIRED> => pipe(c.decode(u), E.map(x => ({ ...x, ...PASSWORD_EXPIRED }))),
  (x: PASSWORD_EXPIRED): PASSWORD_EXPIREDTagged => ({ ...x, _tag: `PASSWORD_EXPIRED`}),
)) satisfies t.Type<PASSWORD_EXPIRED, unknown>;


export const CREDENTIALS_INVALID = {
  _tag: `CREDENTIALS_INVALID`,
  error: `CREDENTIALS_INVALID`
} as const;

export type CREDENTIALS_INVALIDTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CREDENTIALS_INVALID`>
}>;
export const CREDENTIALS_INVALIDTaggedC: CREDENTIALS_INVALIDTaggedC = t.type({
  _tag: t.literal(`CREDENTIALS_INVALID`)
});
export type CREDENTIALS_INVALIDTagged = t.TypeOf<CREDENTIALS_INVALIDTaggedC>;
export type CREDENTIALS_INVALID = CREDENTIALS_INVALIDTagged & typeof CREDENTIALS_INVALID;
export type CREDENTIALS_INVALIDC = t.Type<CREDENTIALS_INVALID, CREDENTIALS_INVALIDTagged>;
export const CREDENTIALS_INVALIDC: CREDENTIALS_INVALIDC = pipe(CREDENTIALS_INVALIDTaggedC, c => new t.Type<CREDENTIALS_INVALID, CREDENTIALS_INVALIDTagged>(
  `CREDENTIALS_INVALID`,
  (u: unknown): u is CREDENTIALS_INVALID => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CREDENTIALS_INVALID> => pipe(c.decode(u), E.map(x => ({ ...x, ...CREDENTIALS_INVALID }))),
  (x: CREDENTIALS_INVALID): CREDENTIALS_INVALIDTagged => ({ ...x, _tag: `CREDENTIALS_INVALID`}),
)) satisfies t.Type<CREDENTIALS_INVALID, unknown>;


export const allResponseCodeC = [EMPTY_RESULTC, RESOURCE_NOT_FOUNDC, ID_NOT_FOUNDC, MISSING_REQUIRED_FIELDC, CUSTOMC, TOO_MANY_RESULTSC, TOO_MANY_REQUESTSC, FOREIGN_KEY_CONSTRAINTC, UNIQUE_CONSTRAINTC, INTERNAL_SERVER_ERRORC, SESSION_ERRORC, CLEAR_SESSIONC, FIELD_ERRORSC, PASSWORD_EXPIREDC, CREDENTIALS_INVALIDC] as const;
export const allResponseCodeNames = [`EMPTY_RESULT`, `RESOURCE_NOT_FOUND`, `ID_NOT_FOUND`, `MISSING_REQUIRED_FIELD`, `CUSTOM`, `TOO_MANY_RESULTS`, `TOO_MANY_REQUESTS`, `FOREIGN_KEY_CONSTRAINT`, `UNIQUE_CONSTRAINT`, `INTERNAL_SERVER_ERROR`, `SESSION_ERROR`, `CLEAR_SESSION`, `FIELD_ERRORS`, `PASSWORD_EXPIRED`, `CREDENTIALS_INVALID`] as const;
export type ResponseCodeName = (typeof allResponseCodeNames)[number];

export type ResponseCodeCU = t.UnionC<[EMPTY_RESULTC, RESOURCE_NOT_FOUNDC, ID_NOT_FOUNDC, MISSING_REQUIRED_FIELDC, CUSTOMC, TOO_MANY_RESULTSC, TOO_MANY_REQUESTSC, FOREIGN_KEY_CONSTRAINTC, UNIQUE_CONSTRAINTC, INTERNAL_SERVER_ERRORC, SESSION_ERRORC, CLEAR_SESSIONC, FIELD_ERRORSC, PASSWORD_EXPIREDC, CREDENTIALS_INVALIDC]>;
export type ResponseCodeU = EMPTY_RESULT | RESOURCE_NOT_FOUND | ID_NOT_FOUND | MISSING_REQUIRED_FIELD | CUSTOM | TOO_MANY_RESULTS | TOO_MANY_REQUESTS | FOREIGN_KEY_CONSTRAINT | UNIQUE_CONSTRAINT | INTERNAL_SERVER_ERROR | SESSION_ERROR | CLEAR_SESSION | FIELD_ERRORS | PASSWORD_EXPIRED | CREDENTIALS_INVALID;
export const ResponseCodeCU: ResponseCodeCU = t.union([EMPTY_RESULTC, RESOURCE_NOT_FOUNDC, ID_NOT_FOUNDC, MISSING_REQUIRED_FIELDC, CUSTOMC, TOO_MANY_RESULTSC, TOO_MANY_REQUESTSC, FOREIGN_KEY_CONSTRAINTC, UNIQUE_CONSTRAINTC, INTERNAL_SERVER_ERRORC, SESSION_ERRORC, CLEAR_SESSIONC, FIELD_ERRORSC, PASSWORD_EXPIREDC, CREDENTIALS_INVALIDC]) satisfies t.Type<ResponseCodeU, unknown>;

export const responseCodeOrd: Ord.Ord<ResponseCodeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allResponseCode = [EMPTY_RESULT, RESOURCE_NOT_FOUND, ID_NOT_FOUND, MISSING_REQUIRED_FIELD, CUSTOM, TOO_MANY_RESULTS, TOO_MANY_REQUESTS, FOREIGN_KEY_CONSTRAINT, UNIQUE_CONSTRAINT, INTERNAL_SERVER_ERROR, SESSION_ERROR, CLEAR_SESSION, FIELD_ERRORS, PASSWORD_EXPIRED, CREDENTIALS_INVALID] as const;
export type ResponseCodeMap<A> = { [K in ResponseCodeName]: A };


