import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const fixedRate = {
  _tag: `FixedRate`,
  id: 1,
  name: `Fixed Rate`
} as const;

export type FixedRateTaggedC = t.TypeC<{
  _tag: t.LiteralC<`FixedRate`>
}>;
export const fixedRateTaggedC: FixedRateTaggedC = t.type({
  _tag: t.literal(`FixedRate`)
});
export type FixedRateTagged = t.TypeOf<FixedRateTaggedC>;
export type FixedRate = FixedRateTagged & typeof fixedRate;
export type FixedRateC = t.Type<FixedRate, FixedRateTagged>;
export const fixedRateC: FixedRateC = pipe(fixedRateTaggedC, c => new t.Type<FixedRate, FixedRateTagged>(
  `FixedRate`,
  (u: unknown): u is FixedRate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FixedRate> => pipe(c.decode(u), E.map(x => ({ ...x, ...fixedRate }))),
  (x: FixedRate): FixedRateTagged => ({ ...x, _tag: `FixedRate`}),
)) satisfies t.Type<FixedRate, unknown>;


export const floatingRate = {
  _tag: `FloatingRate`,
  id: 2,
  name: `Floating Rate`
} as const;

export type FloatingRateTaggedC = t.TypeC<{
  _tag: t.LiteralC<`FloatingRate`>
}>;
export const floatingRateTaggedC: FloatingRateTaggedC = t.type({
  _tag: t.literal(`FloatingRate`)
});
export type FloatingRateTagged = t.TypeOf<FloatingRateTaggedC>;
export type FloatingRate = FloatingRateTagged & typeof floatingRate;
export type FloatingRateC = t.Type<FloatingRate, FloatingRateTagged>;
export const floatingRateC: FloatingRateC = pipe(floatingRateTaggedC, c => new t.Type<FloatingRate, FloatingRateTagged>(
  `FloatingRate`,
  (u: unknown): u is FloatingRate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FloatingRate> => pipe(c.decode(u), E.map(x => ({ ...x, ...floatingRate }))),
  (x: FloatingRate): FloatingRateTagged => ({ ...x, _tag: `FloatingRate`}),
)) satisfies t.Type<FloatingRate, unknown>;


export const variableRate = {
  _tag: `VariableRate`,
  id: 3,
  name: `Variable Rate`
} as const;

export type VariableRateTaggedC = t.TypeC<{
  _tag: t.LiteralC<`VariableRate`>
}>;
export const variableRateTaggedC: VariableRateTaggedC = t.type({
  _tag: t.literal(`VariableRate`)
});
export type VariableRateTagged = t.TypeOf<VariableRateTaggedC>;
export type VariableRate = VariableRateTagged & typeof variableRate;
export type VariableRateC = t.Type<VariableRate, VariableRateTagged>;
export const variableRateC: VariableRateC = pipe(variableRateTaggedC, c => new t.Type<VariableRate, VariableRateTagged>(
  `VariableRate`,
  (u: unknown): u is VariableRate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, VariableRate> => pipe(c.decode(u), E.map(x => ({ ...x, ...variableRate }))),
  (x: VariableRate): VariableRateTagged => ({ ...x, _tag: `VariableRate`}),
)) satisfies t.Type<VariableRate, unknown>;


export const allOfferingTypeC = [fixedRateC, floatingRateC, variableRateC] as const;
export const allOfferingTypeNames = [`FixedRate`, `FloatingRate`, `VariableRate`] as const;
export type OfferingTypeName = (typeof allOfferingTypeNames)[number];

export type OfferingTypeCU = t.UnionC<[FixedRateC, FloatingRateC, VariableRateC]>;
export type OfferingTypeU = FixedRate | FloatingRate | VariableRate;
export const OfferingTypeCU: OfferingTypeCU = t.union([fixedRateC, floatingRateC, variableRateC]) satisfies t.Type<OfferingTypeU, unknown>;

export const offeringTypeOrd: Ord.Ord<OfferingTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allOfferingType = [fixedRate, floatingRate, variableRate] as const;
export type OfferingTypeMap<A> = { [K in OfferingTypeName]: A };


