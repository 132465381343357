import { ReadonlyNonEmptyArrayType, readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray";
import { ReadonlyMapFromEntriesC, readonlyMapFromEntries } from "io-ts-types/lib/readonlyMapFromEntries";
import { Ord as numberOrd } from "fp-ts/lib/number";

export const s3PublicReadAcl = {
  _tag: `S3PublicReadAcl`,
  acl: `public-read`
} as const;

export type S3PublicReadAclTaggedC = t.TypeC<{
  _tag: t.LiteralC<`S3PublicReadAcl`>
}>;
export const s3PublicReadAclTaggedC: S3PublicReadAclTaggedC = t.type({
  _tag: t.literal(`S3PublicReadAcl`)
});
export type S3PublicReadAclTagged = t.TypeOf<S3PublicReadAclTaggedC>;
export type S3PublicReadAcl = S3PublicReadAclTagged & typeof s3PublicReadAcl;
export type S3PublicReadAclC = t.Type<S3PublicReadAcl, S3PublicReadAclTagged>;
export const s3PublicReadAclC: S3PublicReadAclC = pipe(s3PublicReadAclTaggedC, c => new t.Type<S3PublicReadAcl, S3PublicReadAclTagged>(
  `S3PublicReadAcl`,
  (u: unknown): u is S3PublicReadAcl => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, S3PublicReadAcl> => pipe(c.decode(u), E.map(x => ({ ...x, ...s3PublicReadAcl }))),
  (x: S3PublicReadAcl): S3PublicReadAclTagged => ({ ...x, _tag: `S3PublicReadAcl`}),
)) satisfies t.Type<S3PublicReadAcl, unknown>;


export const s3PrivateAcl = {
  _tag: `S3PrivateAcl`,
  acl: `private`
} as const;

export type S3PrivateAclTaggedC = t.TypeC<{
  _tag: t.LiteralC<`S3PrivateAcl`>
}>;
export const s3PrivateAclTaggedC: S3PrivateAclTaggedC = t.type({
  _tag: t.literal(`S3PrivateAcl`)
});
export type S3PrivateAclTagged = t.TypeOf<S3PrivateAclTaggedC>;
export type S3PrivateAcl = S3PrivateAclTagged & typeof s3PrivateAcl;
export type S3PrivateAclC = t.Type<S3PrivateAcl, S3PrivateAclTagged>;
export const s3PrivateAclC: S3PrivateAclC = pipe(s3PrivateAclTaggedC, c => new t.Type<S3PrivateAcl, S3PrivateAclTagged>(
  `S3PrivateAcl`,
  (u: unknown): u is S3PrivateAcl => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, S3PrivateAcl> => pipe(c.decode(u), E.map(x => ({ ...x, ...s3PrivateAcl }))),
  (x: S3PrivateAcl): S3PrivateAclTagged => ({ ...x, _tag: `S3PrivateAcl`}),
)) satisfies t.Type<S3PrivateAcl, unknown>;


export const allS3AclC = [s3PublicReadAclC, s3PrivateAclC] as const;
export const allS3AclNames = [`S3PublicReadAcl`, `S3PrivateAcl`] as const;
export type S3AclName = (typeof allS3AclNames)[number];

export type S3AclCU = t.UnionC<[S3PublicReadAclC, S3PrivateAclC]>;
export type S3AclU = S3PublicReadAcl | S3PrivateAcl;
export const S3AclCU: S3AclCU = t.union([s3PublicReadAclC, s3PrivateAclC]) satisfies t.Type<S3AclU, unknown>;

export const s3AclOrd: Ord.Ord<S3AclU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allS3Acl = [s3PublicReadAcl, s3PrivateAcl] as const;
export type S3AclMap<A> = { [K in S3AclName]: A };


export type S3MultipartUploadInfoC = t.TypeC<{
  bucket: t.StringC,
  key: t.StringC,
  uploadId: t.StringC
}>;
export type S3MultipartUploadInfo = {
  bucket: string,
  key: string,
  uploadId: string
};
export const s3MultipartUploadInfoC: S3MultipartUploadInfoC = t.type({
  bucket: t.string,
  key: t.string,
  uploadId: t.string
}) satisfies t.Type<S3MultipartUploadInfo, unknown>;


export type S3PresignMultipartUploadPartsPostC = t.TypeC<{
  uploadInfo: S3MultipartUploadInfoC,
  partNumbers: t.ReadonlyArrayC<t.NumberC>
}>;
export type S3PresignMultipartUploadPartsPost = {
  uploadInfo: S3MultipartUploadInfo,
  partNumbers: ReadonlyArray<number>
};
export const s3PresignMultipartUploadPartsPostC: S3PresignMultipartUploadPartsPostC = t.type({
  uploadInfo: s3MultipartUploadInfoC,
  partNumbers: t.readonlyArray(t.number)
}) satisfies t.Type<S3PresignMultipartUploadPartsPost, unknown>;


export type S3PresignedMultipartUploadPartsC = t.TypeC<{
  presignedUrls: ReadonlyMapFromEntriesC<t.NumberC, t.StringC>
}>;
export type S3PresignedMultipartUploadParts = {
  presignedUrls: ReadonlyMap<number, string>
};
export const s3PresignedMultipartUploadPartsC: S3PresignedMultipartUploadPartsC = t.type({
  presignedUrls: readonlyMapFromEntries(t.number, numberOrd, t.string)
}) satisfies t.Type<S3PresignedMultipartUploadParts, unknown>;


export type S3SuccessPostC = t.TypeC<{
  key: t.StringC,
  bucket: t.StringC,
  size: t.NumberC
}>;
export type S3SuccessPost = {
  key: string,
  bucket: string,
  size: number
};
export const s3SuccessPostC: S3SuccessPostC = t.type({
  key: t.string,
  bucket: t.string,
  size: t.number
}) satisfies t.Type<S3SuccessPost, unknown>;


export type S3MultipartUploadPartC = t.TypeC<{
  partNumber: t.NumberC,
  size: OptionFromNullableC<t.NumberC>,
  etag: t.StringC
}>;
export type S3MultipartUploadPart = {
  partNumber: number,
  size: O.Option<number>,
  etag: string
};
export const s3MultipartUploadPartC: S3MultipartUploadPartC = t.type({
  partNumber: t.number,
  size: optionFromNullable(t.number),
  etag: t.string
}) satisfies t.Type<S3MultipartUploadPart, unknown>;


export type S3CreateMultipartUploadPostC = t.TypeC<{
  bucket: t.StringC,
  key: t.StringC,
  acl: S3AclCU,
  contentType: t.StringC,
  fileName: t.StringC
}>;
export type S3CreateMultipartUploadPost = {
  bucket: string,
  key: string,
  acl: S3AclU,
  contentType: string,
  fileName: string
};
export const s3CreateMultipartUploadPostC: S3CreateMultipartUploadPostC = t.type({
  bucket: t.string,
  key: t.string,
  acl: S3AclCU,
  contentType: t.string,
  fileName: t.string
}) satisfies t.Type<S3CreateMultipartUploadPost, unknown>;


export type S3CompleteMultipartUploadPostC = t.TypeC<{
  uploadInfo: S3MultipartUploadInfoC,
  parts: ReadonlyNonEmptyArrayType<S3MultipartUploadPartC>
}>;
export type S3CompleteMultipartUploadPost = {
  uploadInfo: S3MultipartUploadInfo,
  parts: RNEA.ReadonlyNonEmptyArray<S3MultipartUploadPart>
};
export const s3CompleteMultipartUploadPostC: S3CompleteMultipartUploadPostC = t.type({
  uploadInfo: s3MultipartUploadInfoC,
  parts: readonlyNonEmptyArrayC(s3MultipartUploadPartC)
}) satisfies t.Type<S3CompleteMultipartUploadPost, unknown>;


export type S3CompleteMultipartUploadResponseC = t.TypeC<{
  location: t.StringC
}>;
export type S3CompleteMultipartUploadResponse = {
  location: string
};
export const s3CompleteMultipartUploadResponseC: S3CompleteMultipartUploadResponseC = t.type({
  location: t.string
}) satisfies t.Type<S3CompleteMultipartUploadResponse, unknown>;


export type S3ListMultipartUploadPartsPostC = t.TypeC<{
  uploadInfo: S3MultipartUploadInfoC
}>;
export type S3ListMultipartUploadPartsPost = {
  uploadInfo: S3MultipartUploadInfo
};
export const s3ListMultipartUploadPartsPostC: S3ListMultipartUploadPartsPostC = t.type({
  uploadInfo: s3MultipartUploadInfoC
}) satisfies t.Type<S3ListMultipartUploadPartsPost, unknown>;


export type S3ListMultipartUploadPartsResponseC = t.TypeC<{
  parts: t.ReadonlyArrayC<S3MultipartUploadPartC>
}>;
export type S3ListMultipartUploadPartsResponse = {
  parts: ReadonlyArray<S3MultipartUploadPart>
};
export const s3ListMultipartUploadPartsResponseC: S3ListMultipartUploadPartsResponseC = t.type({
  parts: t.readonlyArray(s3MultipartUploadPartC)
}) satisfies t.Type<S3ListMultipartUploadPartsResponse, unknown>;


