import type { ZxcvbnResult } from "@zxcvbn-ts/core";
import { sequenceS } from "fp-ts/lib/Apply";
import { identity, pipe } from "fp-ts/lib/function";
import * as NEA from "fp-ts/NonEmptyArray";
import * as O from "fp-ts/Option";

import type { BLConfigWithLog } from "./bondlink";
import { TE } from "./fp-ts";

type PasswordStrengthResult = {
  score: O.Option<number>;
  suggestions: O.Option<NEA.NonEmptyArray<string>>;
};
type CheckPasswordStrength = (password: O.Option<string>) => PasswordStrengthResult;

export const loadPasswordStrength = (config: BLConfigWithLog) =>
  (onError: (err: unknown) => void, onSuccess: (resp: CheckPasswordStrength) => void):
    TE.TaskEither<unknown, CheckPasswordStrength> =>
  pipe(
    sequenceS(TE.ApplicativePar)({
      core: TE.tryCatch(() =>
        import(
          /* webpackChunkName: "zxcvbn" */
          /* webpackMode: "lazy-once" */
          "@zxcvbn-ts/core"
        ),
        identity
      ),
      languageCommon: TE.tryCatch(() =>
        import(
          /* webpackChunkName: "zxcvbn" */
          /* webpackMode: "lazy-once" */
          "@zxcvbn-ts/language-common"
        ),
        identity
      ),
      languageEn: TE.tryCatch(() =>
        import(
          /* webpackChunkName: "zxcvbn" */
          /* webpackMode: "lazy-once" */
          "@zxcvbn-ts/language-en"
        ),
        identity
      ),
    }),
    TE.bimap(
      e => {
        config.log.fatal("Failed to lazy load zxcvbn libs", e);
        onError(e);
        return e;
      },
      _ => {
        const checkPasswordStrength = (password: O.Option<string>) => {
          _.core.zxcvbnOptions.setOptions({
            translations: _.languageEn.translations,
            graphs: _.languageCommon.adjacencyGraphs,
            dictionary: {
              ..._.languageCommon.dictionary,
              ..._.languageEn.dictionary,
              userInputs: ["bondlink"],
            },
          });
          const result = pipe(password, O.map(_.core.zxcvbn));

          // The previous implementation of the password strength meter treats everything 3+ as 3 and the styling depends on it.
          // We require a zxcvbn score of 3+ ("strong" or better in the UI)
          // we only have 4 levels in the UI, so 0 and 1 are rolled TEgether
          const score = O.map((r: ZxcvbnResult) => Math.max(r.score - 1, 0))(result);

          const suggestions: PasswordStrengthResult["suggestions"] = pipe(
            result,
            O.chain(r =>
              pipe(
                r.feedback.warning,
                O.fromNullable,
                O.fold(
                  () => [...r.feedback.suggestions],
                  warning => [warning, ...r.feedback.suggestions],
                ),
                NEA.fromArray
              )
            )
          );

          return {
            score,
            suggestions,
          };
        };
        onSuccess(checkPasswordStrength);
        return checkPasswordStrength;
      }
    )
  );
