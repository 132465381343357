export * from "fp-ts/lib/Record";
import * as R from "fp-ts/lib/Record";

import type * as O from "./Option";
import { type Ord, preserve } from "./Ord";

export type NonEmptyRecord<A, K extends keyof A = keyof A> =
  A extends Record<K, A[K]>
    ? K extends never
      ? never
      : A
    : never;

type NonNullableKeys<P> = Exclude<{
  [K in keyof P]: P[K] extends NonNullable<unknown>
    ? K
    : never;
}[keyof P], undefined>;

type NullableKeys<P> = Exclude<{
  [K in keyof P]: P[K] extends NonNullable<unknown>
    ? never
    : K
}[keyof P], undefined>;

export type NonNullableFields<P> = Pick<P, NonNullableKeys<P>> & Partial<Record<NullableKeys<P>, never>>;
export type NullableFields<P> = Pick<P, NullableKeys<P>>;

export type PartiallyPartialize<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type NullableToOptionalFields<P> = { [K in keyof P]-?: P[K] extends NonNullable<unknown> ? P[K] : O.Option<NonNullable<P[K]>>; };

const toEntriesWithOrd = (ord: Ord<string>): <K extends string, A>(r: Record<K, A>) => Array<[K, A]> => R.collect(ord)(
  (k, a) => [k, a]
);

export const toEntriesNoSort: <K extends string, A>(r: Record<K, A>) => Array<[K, A]> = toEntriesWithOrd(preserve());
