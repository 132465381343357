import type { ReactElement } from "react";
import { useCallback, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import { E } from "@scripts/fp-ts";
import { useConfig } from "@scripts/react/context/Config";
import { isRequiredC } from "@scripts/react/form/codecs";
import type { DataCodec } from "@scripts/react/form/form";
import { getValErrViaCodec } from "@scripts/react/form/form";
import { klass } from "@scripts/react/util/classnames";
import { idFromLabelOrAriaLabel, toLabelString } from "@scripts/util/labelOrAriaLabel";
import { pick } from "@scripts/util/pick";
import { loadPasswordStrength } from "@scripts/zxcvbn";

import eyeIcon from "@svgs/eye.svg";
import hiddenIcon from "@svgs/hidden.svg";

import { ButtonLinkIcon } from "../Button";
import { DataLoader } from "../DataLoader";
import { mapOrEmpty } from "../Empty";
import { PasswordStrength } from "../PasswordStrength";
import { Tooltip } from "../Tooltip";
import type { InputProps } from "./TextInput";
import { InputBase, labelToComponentO, TextInputLabelWithAction } from "./TextInput";

export type PasswordAutocompleteAttribute =
  | "new-password"
  | "current-password"
  | "one-time-code";

const PWSkeleton = () => <div {...klass("label-container")}>
  <Skeleton count={2} style={{ height: "4rem", marginBottom: "1rem" }} />
</div>;

const PasswordNote = () =>
  <div {...klass("small", "font-sans-italic-400", "gray-800", "mb-1")}>
    Note: We use an enhanced password validation tool to ensure security for your account.
    Making your password longer will usually increase its strength.
  </div>;

export const PasswordInput = <PC extends DataCodec, KV>(props: Omit<InputProps<PC, KV>, "type" | "autoComplete"> & { autoComplete: PasswordAutocompleteAttribute }) => {
  const [shown, setShown] = useState<boolean>(false);
  const toggleShown = useCallback(() => setShown(s => !s), []);

  const ariaLabelledById = idFromLabelOrAriaLabel(props.labelOrAriaLabel);

  const labelComponent = labelToComponentO(
    props.labelOrAriaLabel,
    <TextInputLabelWithAction
      {...props}
      id={ariaLabelledById}
      required={props.requiredOverride ?? isRequiredC(props.codec)}
      label={toLabelString(props.labelOrAriaLabel)}
    >
      <ButtonLinkIcon
        {...klass("small", "mb-025", "b-0")}
        onClick={toggleShown}
        icon={shown ? hiddenIcon : eyeIcon}
        textOrAriaLabel={E.left(`${shown ? "Hide" : "Show"} password`)}
      />
    </TextInputLabelWithAction>
  );

  return <InputBase {...props} ariaLabelledById={ariaLabelledById} type={shown ? "text" : "password"} autoComplete={props.autoComplete} labelComponent={labelComponent} />;
};

export const CurrentPasswordInput = <PC extends DataCodec, KV>(props: Omit<InputProps<PC, KV>, "type" | "autoComplete">) =>
  <PasswordInput {...props} autoComplete={"current-password"} />;

export const NewPasswordWithConfirmInput = <PC extends DataCodec, KV>(props: Omit<InputProps<PC, KV>, "type" | "autoComplete"> & {
  confirmProps: O.Option<Pick<InputProps<PC, KV>, "labelOrAriaLabel" | "lens">>;
}): ReactElement => {
  const config = useConfig();
  const password = getValErrViaCodec(config)(props.state, props.lens, props.codec).val;
  const passwordStrength = loadPasswordStrength(config);

  return (
    <>
      <PasswordNote />
      <DataLoader
        taskEither={passwordStrength}
        loading={PWSkeleton}
        errorType="inline"
        show403={false}
      >
        {checkPasswordStrength => {
          const pwStrength = checkPasswordStrength(password);
          return <>
            <Tooltip
              delay="default"
              headerBar={{
                type: "HeaderBarNoButton",
                title: "Password must be “strong” or better. Tips to improve it:",
              }}
              disableTabIndex={true}
              listItems={pipe(pwStrength.suggestions, O.fold(() => [], suggestions => suggestions.map(s => ({ text: s, link: O.none }))))}
              placement="left"
              popperOptions={{
                modifiers: [{
                  name: "flip",
                  options: {
                    fallbackPlacements: ["left", "right", "top", "bottom"],
                  },
                }],
              }}
              addKlassToTooltip="password-input"
              controlOptions={{
                type: "TooltipControlUser",
                visible: O.isSome(pwStrength.suggestions),
              }}
            >
              <PasswordInput {...props}
                state={props.state}
                autoComplete={"new-password"}
              />
            </Tooltip>
            <PasswordStrength score={pwStrength.score} />
            {pipe(
              props.confirmProps,
              mapOrEmpty(cp =>
                <PasswordInput
                  {...pick("state", "setState", "codec", "placeholder", "disabled")(props)}
                  {...cp}
                  autoComplete={"new-password"}
                />
              )
            )}
          </>;
        }}
      </DataLoader>
    </>
  );
};
