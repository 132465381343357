import { Route, query, lit, int, end, type, Match } from "fp-ts-routing/lib";
import * as t from "io-ts";
import { BooleanFromString } from "io-ts-types/lib/BooleanFromString";
import { EitherC, either } from "io-ts-types/lib/either";
import { NumberFromString } from "io-ts-types/lib/NumberFromString";

import { literalNumberFromString } from "../../codecs/literalNumberFromString";
import { NoKeyOverlapC } from "../../codecs/noKeyOverlap";
import { ReadonlyNonEmptyArrayType, readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import { unsafeFromRelativeUrlString, relativeUrlC } from "../../codecs/relativeUrl";
import { Describe } from "../../fp-ts/lib/types/describe";
import { relativeUrlInterface, RelativeUrlInterfaceIO, relativeUrlInterfaceIO } from "../../routes/urlInterface";
import * as investorPortalPages from "../domaintables/investorPortalPages";
import { apiRedirectC as imported5_apiRedirectC, ApiRedirectC as imported6_ApiRedirectC, ApiRedirectC as imported4_ApiRedirectC, apiRedirectC as imported3_apiRedirectC, apiRedirectC as imported4_apiRedirectC, apiRedirectC as imported6_apiRedirectC, ApiRedirectC as imported5_ApiRedirectC, ApiRedirectC as imported3_ApiRedirectC } from "../models/apiRedirect";
import { BidSubmissionPostC as imported0_BidSubmissionPostC, BidSubmissionDataC as imported14_BidSubmissionDataC, bidSubmissionPostC as imported0_bidSubmissionPostC, bidSubmissionDataC as imported14_bidSubmissionDataC } from "../models/bidSubmissions";
import { browserRedirectC as imported24_browserRedirectC, browserRedirectC as imported18_browserRedirectC, BrowserRedirectC as imported24_BrowserRedirectC, BrowserRedirectC as imported21_BrowserRedirectC, browserRedirectC as imported19_browserRedirectC, BrowserRedirectC as imported20_BrowserRedirectC, browserRedirectC as imported22_browserRedirectC, browserRedirectC as imported21_browserRedirectC, BrowserRedirectC as imported18_BrowserRedirectC, BrowserRedirectC as imported19_BrowserRedirectC, BrowserRedirectC as imported23_BrowserRedirectC, BrowserRedirectC as imported22_BrowserRedirectC, browserRedirectC as imported23_browserRedirectC, browserRedirectC as imported20_browserRedirectC } from "../models/browserRedirect";
import { EmptyObjectC as imported0_EmptyObjectC, emptyObjectC as imported0_emptyObjectC } from "../models/emptyObject";
import { InstitutionalInvestorInfoPostC as imported0_InstitutionalInvestorInfoPostC, institutionalInvestorInfoPostC as imported0_institutionalInvestorInfoPostC, institutionalInvestorInfoC as imported1_institutionalInvestorInfoC, InstitutionalInvestorInfoC as imported1_InstitutionalInvestorInfoC, institutionalInvestorInfoC as imported0_institutionalInvestorInfoC, InstitutionalInvestorInfoC as imported0_InstitutionalInvestorInfoC } from "../models/institutionalInvestorInfo";
import { investorPortalOfferingC as imported4_investorPortalOfferingC, InvestorPortalOfferingC as imported8_InvestorPortalOfferingC, investorPortalOfferingC as imported8_investorPortalOfferingC, InvestorPortalOfferingC as imported3_InvestorPortalOfferingC, investorPortalOfferingC as imported7_investorPortalOfferingC, investorPortalOfferingC as imported2_investorPortalOfferingC, InvestorPortalOfferingC as imported7_InvestorPortalOfferingC, InvestorPortalOfferingC as imported6_InvestorPortalOfferingC, investorPortalOfferingC as imported5_investorPortalOfferingC, InvestorPortalOfferingC as imported2_InvestorPortalOfferingC, investorPortalOfferingC as imported6_investorPortalOfferingC, InvestorPortalOfferingC as imported5_InvestorPortalOfferingC, investorPortalOfferingC as imported3_investorPortalOfferingC, InvestorPortalOfferingC as imported4_InvestorPortalOfferingC } from "../models/investorPortalOffering";
import { IssuerWithAddressC as imported10_IssuerWithAddressC, issuerWithAddressC as imported10_issuerWithAddressC, IssuerWithAddressC as imported11_IssuerWithAddressC, issuerWithAddressC as imported11_issuerWithAddressC } from "../models/issuer";
import { IssuerActivityTypeCU as imported10_IssuerActivityTypeCU, IssuerActivityTypeCU as imported11_IssuerActivityTypeCU, IssuerActivityTypeCU as imported12_IssuerActivityTypeCU, IssuerActivityTypeCU as imported13_IssuerActivityTypeCU } from "../models/issuerActivityTypes";
import { IssuerOverviewCardC as imported2_IssuerOverviewCardC, issuerOverviewCardC as imported2_issuerOverviewCardC, IssuerOverviewCardsC as imported0_IssuerOverviewCardsC, issuerOverviewCardsC as imported0_issuerOverviewCardsC } from "../models/issuerOverviewCard";
import { issuersCusip6SearchResultsC as imported0_issuersCusip6SearchResultsC, IssuersCusip6SearchResultsC as imported0_IssuersCusip6SearchResultsC, issuersBySectorPostC as imported0_issuersBySectorPostC, IssuersCusip6SearchPostC as imported0_IssuersCusip6SearchPostC, IssuersBySectorPostC as imported0_IssuersBySectorPostC, issuersCusip6SearchPostC as imported0_issuersCusip6SearchPostC } from "../models/issuerSearch";
import { NotificationSubscriptionsStatusC as imported0_NotificationSubscriptionsStatusC, notificationSubscriptionsStatusC as imported0_notificationSubscriptionsStatusC, UserIssuerSubscriptionsPostC as imported0_UserIssuerSubscriptionsPostC, userIssuerSubscriptionsPostC as imported0_userIssuerSubscriptionsPostC } from "../models/notificationSubscription";
import { OnboardingStepStatusC as imported0_OnboardingStepStatusC, onboardingStepStatusC as imported0_onboardingStepStatusC } from "../models/onboardingStepStatus";
import { InvestorPortalRfpC as imported2_InvestorPortalRfpC, investorPortalRfpC as imported2_investorPortalRfpC, InvestorPortalRfpC as imported3_InvestorPortalRfpC, InvestorPortalRfpC as imported0_InvestorPortalRfpC, investorPortalRfpC as imported1_investorPortalRfpC, investorPortalRfpC as imported0_investorPortalRfpC, InvestorPortalRfpC as imported1_InvestorPortalRfpC, investorPortalRfpC as imported3_investorPortalRfpC } from "../models/rfp";
import { SectorDataC as imported0_SectorDataC, sectorDataC as imported0_sectorDataC } from "../models/sector";
import { subscribedC as imported44_subscribedC, SubscribedC as imported45_SubscribedC, SubscribedC as imported44_SubscribedC, subscribedC as imported42_subscribedC, SubscribedC as imported43_SubscribedC, subscribedC as imported45_subscribedC, SubscribedC as imported42_SubscribedC, subscribedC as imported43_subscribedC } from "../models/subscribed";
import { WithModInfoC as imported255_WithModInfoC, WithStatusCU as imported956_WithStatusCU, withModInfoC as imported255_withModInfoC, WithStatusCU as imported957_WithStatusCU } from "../models/threadThrough";
import { UserC as imported32_UserC, userC as imported32_userC, UserC as imported33_UserC, userC as imported33_userC } from "../models/user";
import { UserContentSubscriptionsC as imported0_UserContentSubscriptionsC, userContentSubscriptionsC as imported0_userContentSubscriptionsC } from "../models/userContentSubscriptions";
import { MfaSettingsResponseC as imported0_MfaSettingsResponseC, updatePasswordPostC as imported0_updatePasswordPostC, verifyMfaPostC as imported0_verifyMfaPostC, MfaSettingsPostC as imported0_MfaSettingsPostC, userSettingsPostC as imported0_userSettingsPostC, VerifyMfaPostC as imported0_VerifyMfaPostC, UserSettingsResponseC as imported0_UserSettingsResponseC, mfaSettingsResponseC as imported0_mfaSettingsResponseC, mfaSettingsPostC as imported0_mfaSettingsPostC, userSettingsResponseC as imported0_userSettingsResponseC, UserSettingsPostC as imported0_UserSettingsPostC, UpdatePasswordPostC as imported0_UpdatePasswordPostC } from "../models/userSettings";
import { WatchlistStateDataC as imported0_WatchlistStateDataC, watchlistStateDataC as imported0_watchlistStateDataC, WatchlistIssuerC as imported0_WatchlistIssuerC, watchlistIssuerC as imported0_watchlistIssuerC } from "../models/watchlist";

export const investorPortalInvestorControllerBootstrap = (p: { page: (routes: typeof investorPortalPages) => Match<Record<string, never>> }) =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(p.page(investorPortalPages).formatter.run(Route.empty, {}).toString(true)));

const investorPortalInvestorControllerTrackPageviewPath = lit("v2").then(lit("investor-portal")).then(lit("track-pageview"));
const investorPortalInvestorControllerTrackPageviewPathParts = ["v2", "investor-portal", "track-pageview"] as const;
export type InvestorPortalInvestorControllerTrackPageviewPathParts = typeof investorPortalInvestorControllerTrackPageviewPathParts;

const investorPortalInvestorControllerTrackPageviewQuery = t.exact(t.type({
  "url": t.string
}));
export type InvestorPortalInvestorControllerTrackPageviewParams = Describe<typeof investorPortalInvestorControllerTrackPageviewPath._A & typeof investorPortalInvestorControllerTrackPageviewQuery._A>;

export type InvestorPortalInvestorControllerTrackPageviewRoute<A = {}> = { match: Match<InvestorPortalInvestorControllerTrackPageviewParams & A>; pathParts: InvestorPortalInvestorControllerTrackPageviewPathParts };

export function investorPortalInvestorControllerTrackPageviewRoute(): InvestorPortalInvestorControllerTrackPageviewRoute;
export function investorPortalInvestorControllerTrackPageviewRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalInvestorControllerTrackPageviewParams>): InvestorPortalInvestorControllerTrackPageviewRoute<t.TypeOf<A>>;
export function investorPortalInvestorControllerTrackPageviewRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalInvestorControllerTrackPageviewParams>) {
  return {
    match: (q ? investorPortalInvestorControllerTrackPageviewPath.then(query(t.intersection([investorPortalInvestorControllerTrackPageviewQuery, q]))) : investorPortalInvestorControllerTrackPageviewPath.then(query(investorPortalInvestorControllerTrackPageviewQuery))).then(end),
    pathParts: investorPortalInvestorControllerTrackPageviewPathParts,
  };
}

export const investorPortalInvestorControllerTrackPageviewCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_EmptyObjectC
} = { input: imported0_emptyObjectC, output: imported0_emptyObjectC };

export const investorPortalInvestorControllerTrackPageview = (p: InvestorPortalInvestorControllerTrackPageviewParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, imported0_EmptyObjectC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalInvestorControllerTrackPageviewRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalInvestorControllerTrackPageviewCodecs);

const investorPortalInvestorControllerGetInvestorSectorsPath = lit("v2").then(lit("investor-portal")).then(lit("sectors"));
const investorPortalInvestorControllerGetInvestorSectorsPathParts = ["v2", "investor-portal", "sectors"] as const;
export type InvestorPortalInvestorControllerGetInvestorSectorsPathParts = typeof investorPortalInvestorControllerGetInvestorSectorsPathParts;

const investorPortalInvestorControllerGetInvestorSectorsQuery = t.strict({});
export type InvestorPortalInvestorControllerGetInvestorSectorsParams = Describe<typeof investorPortalInvestorControllerGetInvestorSectorsPath._A & typeof investorPortalInvestorControllerGetInvestorSectorsQuery._A>;

export type InvestorPortalInvestorControllerGetInvestorSectorsRoute<A = {}> = { match: Match<InvestorPortalInvestorControllerGetInvestorSectorsParams & A>; pathParts: InvestorPortalInvestorControllerGetInvestorSectorsPathParts };

export function investorPortalInvestorControllerGetInvestorSectorsRoute(): InvestorPortalInvestorControllerGetInvestorSectorsRoute;
export function investorPortalInvestorControllerGetInvestorSectorsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalInvestorControllerGetInvestorSectorsParams>): InvestorPortalInvestorControllerGetInvestorSectorsRoute<t.TypeOf<A>>;
export function investorPortalInvestorControllerGetInvestorSectorsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalInvestorControllerGetInvestorSectorsParams>) {
  return {
    match: (q ? investorPortalInvestorControllerGetInvestorSectorsPath.then(query(t.intersection([investorPortalInvestorControllerGetInvestorSectorsQuery, q]))) : investorPortalInvestorControllerGetInvestorSectorsPath.then(query(investorPortalInvestorControllerGetInvestorSectorsQuery))).then(end),
    pathParts: investorPortalInvestorControllerGetInvestorSectorsPathParts,
  };
}

export const investorPortalInvestorControllerGetInvestorSectorsCodecs: {
  input: imported0_EmptyObjectC,
  output: ReadonlyNonEmptyArrayType<imported42_SubscribedC<imported0_SectorDataC>>
} = { input: imported0_emptyObjectC, output: readonlyNonEmptyArrayC(imported42_subscribedC(imported0_sectorDataC)) };

export const investorPortalInvestorControllerGetInvestorSectors = (): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, ReadonlyNonEmptyArrayType<imported42_SubscribedC<imported0_SectorDataC>>> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalInvestorControllerGetInvestorSectorsRoute().match.formatter.run(Route.empty, {}).toString(true)), investorPortalInvestorControllerGetInvestorSectorsCodecs);

const investorPortalInvestorControllerGetInvestorStatesPath = lit("v2").then(lit("investor-portal")).then(lit("states"));
const investorPortalInvestorControllerGetInvestorStatesPathParts = ["v2", "investor-portal", "states"] as const;
export type InvestorPortalInvestorControllerGetInvestorStatesPathParts = typeof investorPortalInvestorControllerGetInvestorStatesPathParts;

const investorPortalInvestorControllerGetInvestorStatesQuery = t.strict({});
export type InvestorPortalInvestorControllerGetInvestorStatesParams = Describe<typeof investorPortalInvestorControllerGetInvestorStatesPath._A & typeof investorPortalInvestorControllerGetInvestorStatesQuery._A>;

export type InvestorPortalInvestorControllerGetInvestorStatesRoute<A = {}> = { match: Match<InvestorPortalInvestorControllerGetInvestorStatesParams & A>; pathParts: InvestorPortalInvestorControllerGetInvestorStatesPathParts };

export function investorPortalInvestorControllerGetInvestorStatesRoute(): InvestorPortalInvestorControllerGetInvestorStatesRoute;
export function investorPortalInvestorControllerGetInvestorStatesRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalInvestorControllerGetInvestorStatesParams>): InvestorPortalInvestorControllerGetInvestorStatesRoute<t.TypeOf<A>>;
export function investorPortalInvestorControllerGetInvestorStatesRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalInvestorControllerGetInvestorStatesParams>) {
  return {
    match: (q ? investorPortalInvestorControllerGetInvestorStatesPath.then(query(t.intersection([investorPortalInvestorControllerGetInvestorStatesQuery, q]))) : investorPortalInvestorControllerGetInvestorStatesPath.then(query(investorPortalInvestorControllerGetInvestorStatesQuery))).then(end),
    pathParts: investorPortalInvestorControllerGetInvestorStatesPathParts,
  };
}

export const investorPortalInvestorControllerGetInvestorStatesCodecs: {
  input: imported0_EmptyObjectC,
  output: ReadonlyNonEmptyArrayType<imported43_SubscribedC<imported0_WatchlistStateDataC>>
} = {
  input: imported0_emptyObjectC,
  output: readonlyNonEmptyArrayC(imported43_subscribedC(imported0_watchlistStateDataC))
};

export const investorPortalInvestorControllerGetInvestorStates = (): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, ReadonlyNonEmptyArrayType<imported43_SubscribedC<imported0_WatchlistStateDataC>>> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalInvestorControllerGetInvestorStatesRoute().match.formatter.run(Route.empty, {}).toString(true)), investorPortalInvestorControllerGetInvestorStatesCodecs);

const investorPortalInvestorControllerRecentlyViewedIssuersPath = lit("v2").then(lit("investor-portal")).then(lit("dashboard")).then(lit("recent-issuers"));
const investorPortalInvestorControllerRecentlyViewedIssuersPathParts = [
  "v2", "investor-portal", "dashboard", "recent-issuers"
] as const;
export type InvestorPortalInvestorControllerRecentlyViewedIssuersPathParts = typeof investorPortalInvestorControllerRecentlyViewedIssuersPathParts;

const investorPortalInvestorControllerRecentlyViewedIssuersQuery = t.strict({});
export type InvestorPortalInvestorControllerRecentlyViewedIssuersParams = Describe<typeof investorPortalInvestorControllerRecentlyViewedIssuersPath._A & typeof investorPortalInvestorControllerRecentlyViewedIssuersQuery._A>;

export type InvestorPortalInvestorControllerRecentlyViewedIssuersRoute<A = {}> = { match: Match<InvestorPortalInvestorControllerRecentlyViewedIssuersParams & A>; pathParts: InvestorPortalInvestorControllerRecentlyViewedIssuersPathParts };

export function investorPortalInvestorControllerRecentlyViewedIssuersRoute(): InvestorPortalInvestorControllerRecentlyViewedIssuersRoute;
export function investorPortalInvestorControllerRecentlyViewedIssuersRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalInvestorControllerRecentlyViewedIssuersParams>): InvestorPortalInvestorControllerRecentlyViewedIssuersRoute<t.TypeOf<A>>;
export function investorPortalInvestorControllerRecentlyViewedIssuersRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalInvestorControllerRecentlyViewedIssuersParams>) {
  return {
    match: (q ? investorPortalInvestorControllerRecentlyViewedIssuersPath.then(query(t.intersection([investorPortalInvestorControllerRecentlyViewedIssuersQuery, q]))) : investorPortalInvestorControllerRecentlyViewedIssuersPath.then(query(investorPortalInvestorControllerRecentlyViewedIssuersQuery))).then(end),
    pathParts: investorPortalInvestorControllerRecentlyViewedIssuersPathParts,
  };
}

export const investorPortalInvestorControllerRecentlyViewedIssuersCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported2_IssuerOverviewCardC>
} = { input: imported0_emptyObjectC, output: t.readonlyArray(imported2_issuerOverviewCardC) };

export const investorPortalInvestorControllerRecentlyViewedIssuers = (): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported2_IssuerOverviewCardC>> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalInvestorControllerRecentlyViewedIssuersRoute().match.formatter.run(Route.empty, {}).toString(true)), investorPortalInvestorControllerRecentlyViewedIssuersCodecs);

const investorPortalInvestorControllerSubscribedIssuersPath = lit("v2").then(lit("investor-portal")).then(lit("dashboard")).then(lit("subscribed-issuers"));
const investorPortalInvestorControllerSubscribedIssuersPathParts = [
  "v2", "investor-portal", "dashboard", "subscribed-issuers"
] as const;
export type InvestorPortalInvestorControllerSubscribedIssuersPathParts = typeof investorPortalInvestorControllerSubscribedIssuersPathParts;

const investorPortalInvestorControllerSubscribedIssuersQuery = t.exact(t.type({
  "page": NumberFromString
}));
export type InvestorPortalInvestorControllerSubscribedIssuersParams = Describe<typeof investorPortalInvestorControllerSubscribedIssuersPath._A & typeof investorPortalInvestorControllerSubscribedIssuersQuery._A>;

export type InvestorPortalInvestorControllerSubscribedIssuersRoute<A = {}> = { match: Match<InvestorPortalInvestorControllerSubscribedIssuersParams & A>; pathParts: InvestorPortalInvestorControllerSubscribedIssuersPathParts };

export function investorPortalInvestorControllerSubscribedIssuersRoute(): InvestorPortalInvestorControllerSubscribedIssuersRoute;
export function investorPortalInvestorControllerSubscribedIssuersRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalInvestorControllerSubscribedIssuersParams>): InvestorPortalInvestorControllerSubscribedIssuersRoute<t.TypeOf<A>>;
export function investorPortalInvestorControllerSubscribedIssuersRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalInvestorControllerSubscribedIssuersParams>) {
  return {
    match: (q ? investorPortalInvestorControllerSubscribedIssuersPath.then(query(t.intersection([investorPortalInvestorControllerSubscribedIssuersQuery, q]))) : investorPortalInvestorControllerSubscribedIssuersPath.then(query(investorPortalInvestorControllerSubscribedIssuersQuery))).then(end),
    pathParts: investorPortalInvestorControllerSubscribedIssuersPathParts,
  };
}

export const investorPortalInvestorControllerSubscribedIssuersCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_IssuerOverviewCardsC
} = { input: imported0_emptyObjectC, output: imported0_issuerOverviewCardsC };

export const investorPortalInvestorControllerSubscribedIssuers = (p: InvestorPortalInvestorControllerSubscribedIssuersParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, imported0_IssuerOverviewCardsC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalInvestorControllerSubscribedIssuersRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalInvestorControllerSubscribedIssuersCodecs);

const investorPortalInvestorControllerGetOnboardingStepsStatusPath = lit("v2").then(lit("investor-portal")).then(lit("onboarding-steps"));
const investorPortalInvestorControllerGetOnboardingStepsStatusPathParts = [
  "v2", "investor-portal", "onboarding-steps"
] as const;
export type InvestorPortalInvestorControllerGetOnboardingStepsStatusPathParts = typeof investorPortalInvestorControllerGetOnboardingStepsStatusPathParts;

const investorPortalInvestorControllerGetOnboardingStepsStatusQuery = t.strict({});
export type InvestorPortalInvestorControllerGetOnboardingStepsStatusParams = Describe<typeof investorPortalInvestorControllerGetOnboardingStepsStatusPath._A & typeof investorPortalInvestorControllerGetOnboardingStepsStatusQuery._A>;

export type InvestorPortalInvestorControllerGetOnboardingStepsStatusRoute<A = {}> = { match: Match<InvestorPortalInvestorControllerGetOnboardingStepsStatusParams & A>; pathParts: InvestorPortalInvestorControllerGetOnboardingStepsStatusPathParts };

export function investorPortalInvestorControllerGetOnboardingStepsStatusRoute(): InvestorPortalInvestorControllerGetOnboardingStepsStatusRoute;
export function investorPortalInvestorControllerGetOnboardingStepsStatusRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalInvestorControllerGetOnboardingStepsStatusParams>): InvestorPortalInvestorControllerGetOnboardingStepsStatusRoute<t.TypeOf<A>>;
export function investorPortalInvestorControllerGetOnboardingStepsStatusRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalInvestorControllerGetOnboardingStepsStatusParams>) {
  return {
    match: (q ? investorPortalInvestorControllerGetOnboardingStepsStatusPath.then(query(t.intersection([investorPortalInvestorControllerGetOnboardingStepsStatusQuery, q]))) : investorPortalInvestorControllerGetOnboardingStepsStatusPath.then(query(investorPortalInvestorControllerGetOnboardingStepsStatusQuery))).then(end),
    pathParts: investorPortalInvestorControllerGetOnboardingStepsStatusPathParts,
  };
}

export const investorPortalInvestorControllerGetOnboardingStepsStatusCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported0_OnboardingStepStatusC>
} = { input: imported0_emptyObjectC, output: t.readonlyArray(imported0_onboardingStepStatusC) };

export const investorPortalInvestorControllerGetOnboardingStepsStatus = (): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported0_OnboardingStepStatusC>> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalInvestorControllerGetOnboardingStepsStatusRoute().match.formatter.run(Route.empty, {}).toString(true)), investorPortalInvestorControllerGetOnboardingStepsStatusCodecs);

const investorPortalInvestorControllerCompleteOnboardingStepPath = lit("v2").then(lit("investor-portal")).then(lit("onboarding-steps")).then(lit("complete")).then(type("onboardingStepId", t.union([
  literalNumberFromString(1), literalNumberFromString(2)
])));
const investorPortalInvestorControllerCompleteOnboardingStepPathParts = [
  "v2", "investor-portal", "onboarding-steps", "complete", "onboardingStepId"
] as const;
export type InvestorPortalInvestorControllerCompleteOnboardingStepPathParts = typeof investorPortalInvestorControllerCompleteOnboardingStepPathParts;

const investorPortalInvestorControllerCompleteOnboardingStepQuery = t.strict({});
export type InvestorPortalInvestorControllerCompleteOnboardingStepParams = Describe<typeof investorPortalInvestorControllerCompleteOnboardingStepPath._A & typeof investorPortalInvestorControllerCompleteOnboardingStepQuery._A>;

export type InvestorPortalInvestorControllerCompleteOnboardingStepRoute<A = {}> = { match: Match<InvestorPortalInvestorControllerCompleteOnboardingStepParams & A>; pathParts: InvestorPortalInvestorControllerCompleteOnboardingStepPathParts };

export function investorPortalInvestorControllerCompleteOnboardingStepRoute(): InvestorPortalInvestorControllerCompleteOnboardingStepRoute;
export function investorPortalInvestorControllerCompleteOnboardingStepRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalInvestorControllerCompleteOnboardingStepParams>): InvestorPortalInvestorControllerCompleteOnboardingStepRoute<t.TypeOf<A>>;
export function investorPortalInvestorControllerCompleteOnboardingStepRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalInvestorControllerCompleteOnboardingStepParams>) {
  return {
    match: (q ? investorPortalInvestorControllerCompleteOnboardingStepPath.then(query(t.intersection([investorPortalInvestorControllerCompleteOnboardingStepQuery, q]))) : investorPortalInvestorControllerCompleteOnboardingStepPath.then(query(investorPortalInvestorControllerCompleteOnboardingStepQuery))).then(end),
    pathParts: investorPortalInvestorControllerCompleteOnboardingStepPathParts,
  };
}

export const investorPortalInvestorControllerCompleteOnboardingStepCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_EmptyObjectC
} = { input: imported0_emptyObjectC, output: imported0_emptyObjectC };

export const investorPortalInvestorControllerCompleteOnboardingStep = (p: InvestorPortalInvestorControllerCompleteOnboardingStepParams): RelativeUrlInterfaceIO<"POST", imported0_EmptyObjectC, imported0_EmptyObjectC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(investorPortalInvestorControllerCompleteOnboardingStepRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalInvestorControllerCompleteOnboardingStepCodecs);

const investorPortalInvestorControllerIssuersCusip6SearchPath = lit("v2").then(lit("investor-portal")).then(lit("issuer-cusip-search"));
const investorPortalInvestorControllerIssuersCusip6SearchPathParts = [
  "v2", "investor-portal", "issuer-cusip-search"
] as const;
export type InvestorPortalInvestorControllerIssuersCusip6SearchPathParts = typeof investorPortalInvestorControllerIssuersCusip6SearchPathParts;

const investorPortalInvestorControllerIssuersCusip6SearchQuery = t.strict({});
export type InvestorPortalInvestorControllerIssuersCusip6SearchParams = Describe<typeof investorPortalInvestorControllerIssuersCusip6SearchPath._A & typeof investorPortalInvestorControllerIssuersCusip6SearchQuery._A>;

export type InvestorPortalInvestorControllerIssuersCusip6SearchRoute<A = {}> = { match: Match<InvestorPortalInvestorControllerIssuersCusip6SearchParams & A>; pathParts: InvestorPortalInvestorControllerIssuersCusip6SearchPathParts };

export function investorPortalInvestorControllerIssuersCusip6SearchRoute(): InvestorPortalInvestorControllerIssuersCusip6SearchRoute;
export function investorPortalInvestorControllerIssuersCusip6SearchRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalInvestorControllerIssuersCusip6SearchParams>): InvestorPortalInvestorControllerIssuersCusip6SearchRoute<t.TypeOf<A>>;
export function investorPortalInvestorControllerIssuersCusip6SearchRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalInvestorControllerIssuersCusip6SearchParams>) {
  return {
    match: (q ? investorPortalInvestorControllerIssuersCusip6SearchPath.then(query(t.intersection([investorPortalInvestorControllerIssuersCusip6SearchQuery, q]))) : investorPortalInvestorControllerIssuersCusip6SearchPath.then(query(investorPortalInvestorControllerIssuersCusip6SearchQuery))).then(end),
    pathParts: investorPortalInvestorControllerIssuersCusip6SearchPathParts,
  };
}

export const investorPortalInvestorControllerIssuersCusip6SearchCodecs: {
  input: imported0_IssuersCusip6SearchPostC,
  output: imported0_IssuersCusip6SearchResultsC
} = { input: imported0_issuersCusip6SearchPostC, output: imported0_issuersCusip6SearchResultsC };

export const investorPortalInvestorControllerIssuersCusip6Search = (): RelativeUrlInterfaceIO<"POST", imported0_IssuersCusip6SearchPostC, imported0_IssuersCusip6SearchResultsC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(investorPortalInvestorControllerIssuersCusip6SearchRoute().match.formatter.run(Route.empty, {}).toString(true)), investorPortalInvestorControllerIssuersCusip6SearchCodecs);

const investorPortalInvestorControllerIssuersBySectorPath = lit("v2").then(lit("investor-portal")).then(lit("issuers-by-sector"));
const investorPortalInvestorControllerIssuersBySectorPathParts = [
  "v2", "investor-portal", "issuers-by-sector"
] as const;
export type InvestorPortalInvestorControllerIssuersBySectorPathParts = typeof investorPortalInvestorControllerIssuersBySectorPathParts;

const investorPortalInvestorControllerIssuersBySectorQuery = t.strict({});
export type InvestorPortalInvestorControllerIssuersBySectorParams = Describe<typeof investorPortalInvestorControllerIssuersBySectorPath._A & typeof investorPortalInvestorControllerIssuersBySectorQuery._A>;

export type InvestorPortalInvestorControllerIssuersBySectorRoute<A = {}> = { match: Match<InvestorPortalInvestorControllerIssuersBySectorParams & A>; pathParts: InvestorPortalInvestorControllerIssuersBySectorPathParts };

export function investorPortalInvestorControllerIssuersBySectorRoute(): InvestorPortalInvestorControllerIssuersBySectorRoute;
export function investorPortalInvestorControllerIssuersBySectorRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalInvestorControllerIssuersBySectorParams>): InvestorPortalInvestorControllerIssuersBySectorRoute<t.TypeOf<A>>;
export function investorPortalInvestorControllerIssuersBySectorRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalInvestorControllerIssuersBySectorParams>) {
  return {
    match: (q ? investorPortalInvestorControllerIssuersBySectorPath.then(query(t.intersection([investorPortalInvestorControllerIssuersBySectorQuery, q]))) : investorPortalInvestorControllerIssuersBySectorPath.then(query(investorPortalInvestorControllerIssuersBySectorQuery))).then(end),
    pathParts: investorPortalInvestorControllerIssuersBySectorPathParts,
  };
}

export const investorPortalInvestorControllerIssuersBySectorCodecs: {
  input: imported0_IssuersBySectorPostC,
  output: t.ReadonlyArrayC<imported44_SubscribedC<imported10_IssuerWithAddressC>>
} = {
  input: imported0_issuersBySectorPostC,
  output: t.readonlyArray(imported44_subscribedC(imported10_issuerWithAddressC))
};

export const investorPortalInvestorControllerIssuersBySector = (): RelativeUrlInterfaceIO<"POST", imported0_IssuersBySectorPostC, t.ReadonlyArrayC<imported44_SubscribedC<imported10_IssuerWithAddressC>>> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(investorPortalInvestorControllerIssuersBySectorRoute().match.formatter.run(Route.empty, {}).toString(true)), investorPortalInvestorControllerIssuersBySectorCodecs);

const investorPortalInvestorControllerConfirmEmailChangePath = lit("portal").then(lit("confirm-email"));
const investorPortalInvestorControllerConfirmEmailChangePathParts = ["portal", "confirm-email"] as const;
export type InvestorPortalInvestorControllerConfirmEmailChangePathParts = typeof investorPortalInvestorControllerConfirmEmailChangePathParts;

const investorPortalInvestorControllerConfirmEmailChangeQuery = t.exact(t.type({
  "oldEmail": t.string,
  "newEmail": t.string,
  "hash": t.string
}));
export type InvestorPortalInvestorControllerConfirmEmailChangeParams = Describe<typeof investorPortalInvestorControllerConfirmEmailChangePath._A & typeof investorPortalInvestorControllerConfirmEmailChangeQuery._A>;

export type InvestorPortalInvestorControllerConfirmEmailChangeRoute<A = {}> = { match: Match<InvestorPortalInvestorControllerConfirmEmailChangeParams & A>; pathParts: InvestorPortalInvestorControllerConfirmEmailChangePathParts };

export function investorPortalInvestorControllerConfirmEmailChangeRoute(): InvestorPortalInvestorControllerConfirmEmailChangeRoute;
export function investorPortalInvestorControllerConfirmEmailChangeRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalInvestorControllerConfirmEmailChangeParams>): InvestorPortalInvestorControllerConfirmEmailChangeRoute<t.TypeOf<A>>;
export function investorPortalInvestorControllerConfirmEmailChangeRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalInvestorControllerConfirmEmailChangeParams>) {
  return {
    match: (q ? investorPortalInvestorControllerConfirmEmailChangePath.then(query(t.intersection([investorPortalInvestorControllerConfirmEmailChangeQuery, q]))) : investorPortalInvestorControllerConfirmEmailChangePath.then(query(investorPortalInvestorControllerConfirmEmailChangeQuery))).then(end),
    pathParts: investorPortalInvestorControllerConfirmEmailChangePathParts,
  };
}

export const investorPortalInvestorControllerConfirmEmailChangeCodecs: {
  input: imported0_EmptyObjectC,
  output: imported18_BrowserRedirectC
} = { input: imported0_emptyObjectC, output: imported18_browserRedirectC };

export const investorPortalInvestorControllerConfirmEmailChange = (p: InvestorPortalInvestorControllerConfirmEmailChangeParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, imported18_BrowserRedirectC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalInvestorControllerConfirmEmailChangeRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalInvestorControllerConfirmEmailChangeCodecs);

const investorPortalInvestorControllerAcceptTermsPath = lit("v2").then(lit("investor-portal")).then(lit("accept-user-terms"));
const investorPortalInvestorControllerAcceptTermsPathParts = ["v2", "investor-portal", "accept-user-terms"] as const;
export type InvestorPortalInvestorControllerAcceptTermsPathParts = typeof investorPortalInvestorControllerAcceptTermsPathParts;

const investorPortalInvestorControllerAcceptTermsQuery = t.exact(t.type({
  "redirect": relativeUrlC
}));
export type InvestorPortalInvestorControllerAcceptTermsParams = Describe<typeof investorPortalInvestorControllerAcceptTermsPath._A & typeof investorPortalInvestorControllerAcceptTermsQuery._A>;

export type InvestorPortalInvestorControllerAcceptTermsRoute<A = {}> = { match: Match<InvestorPortalInvestorControllerAcceptTermsParams & A>; pathParts: InvestorPortalInvestorControllerAcceptTermsPathParts };

export function investorPortalInvestorControllerAcceptTermsRoute(): InvestorPortalInvestorControllerAcceptTermsRoute;
export function investorPortalInvestorControllerAcceptTermsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalInvestorControllerAcceptTermsParams>): InvestorPortalInvestorControllerAcceptTermsRoute<t.TypeOf<A>>;
export function investorPortalInvestorControllerAcceptTermsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalInvestorControllerAcceptTermsParams>) {
  return {
    match: (q ? investorPortalInvestorControllerAcceptTermsPath.then(query(t.intersection([investorPortalInvestorControllerAcceptTermsQuery, q]))) : investorPortalInvestorControllerAcceptTermsPath.then(query(investorPortalInvestorControllerAcceptTermsQuery))).then(end),
    pathParts: investorPortalInvestorControllerAcceptTermsPathParts,
  };
}

export const investorPortalInvestorControllerAcceptTermsCodecs: {
  input: imported0_EmptyObjectC,
  output: imported3_ApiRedirectC
} = { input: imported0_emptyObjectC, output: imported3_apiRedirectC };

export const investorPortalInvestorControllerAcceptTerms = (p: InvestorPortalInvestorControllerAcceptTermsParams): RelativeUrlInterfaceIO<"POST", imported0_EmptyObjectC, imported3_ApiRedirectC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(investorPortalInvestorControllerAcceptTermsRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalInvestorControllerAcceptTermsCodecs);

const investorPortalInvestorControllerAcceptBLPTermsPath = lit("v2").then(lit("investor-portal")).then(lit("accept-blp-terms"));
const investorPortalInvestorControllerAcceptBLPTermsPathParts = ["v2", "investor-portal", "accept-blp-terms"] as const;
export type InvestorPortalInvestorControllerAcceptBLPTermsPathParts = typeof investorPortalInvestorControllerAcceptBLPTermsPathParts;

const investorPortalInvestorControllerAcceptBLPTermsQuery = t.exact(t.type({
  "redirect": relativeUrlC
}));
export type InvestorPortalInvestorControllerAcceptBLPTermsParams = Describe<typeof investorPortalInvestorControllerAcceptBLPTermsPath._A & typeof investorPortalInvestorControllerAcceptBLPTermsQuery._A>;

export type InvestorPortalInvestorControllerAcceptBLPTermsRoute<A = {}> = { match: Match<InvestorPortalInvestorControllerAcceptBLPTermsParams & A>; pathParts: InvestorPortalInvestorControllerAcceptBLPTermsPathParts };

export function investorPortalInvestorControllerAcceptBLPTermsRoute(): InvestorPortalInvestorControllerAcceptBLPTermsRoute;
export function investorPortalInvestorControllerAcceptBLPTermsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalInvestorControllerAcceptBLPTermsParams>): InvestorPortalInvestorControllerAcceptBLPTermsRoute<t.TypeOf<A>>;
export function investorPortalInvestorControllerAcceptBLPTermsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalInvestorControllerAcceptBLPTermsParams>) {
  return {
    match: (q ? investorPortalInvestorControllerAcceptBLPTermsPath.then(query(t.intersection([investorPortalInvestorControllerAcceptBLPTermsQuery, q]))) : investorPortalInvestorControllerAcceptBLPTermsPath.then(query(investorPortalInvestorControllerAcceptBLPTermsQuery))).then(end),
    pathParts: investorPortalInvestorControllerAcceptBLPTermsPathParts,
  };
}

export const investorPortalInvestorControllerAcceptBLPTermsCodecs: {
  input: imported0_EmptyObjectC,
  output: imported4_ApiRedirectC
} = { input: imported0_emptyObjectC, output: imported4_apiRedirectC };

export const investorPortalInvestorControllerAcceptBLPTerms = (p: InvestorPortalInvestorControllerAcceptBLPTermsParams): RelativeUrlInterfaceIO<"POST", imported0_EmptyObjectC, imported4_ApiRedirectC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(investorPortalInvestorControllerAcceptBLPTermsRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalInvestorControllerAcceptBLPTermsCodecs);

const investorPortalInvestorControllerPublishedIssuersByDateRangePath = lit("v2").then(lit("investor-portal")).then(lit("published-by-date-range"));
const investorPortalInvestorControllerPublishedIssuersByDateRangePathParts = [
  "v2", "investor-portal", "published-by-date-range"
] as const;
export type InvestorPortalInvestorControllerPublishedIssuersByDateRangePathParts = typeof investorPortalInvestorControllerPublishedIssuersByDateRangePathParts;

const investorPortalInvestorControllerPublishedIssuersByDateRangeQuery = t.exact(t.type({
  "dateRange": t.union([
    literalNumberFromString(1), literalNumberFromString(7), literalNumberFromString(30), literalNumberFromString(90), literalNumberFromString(365)
  ])
}));
export type InvestorPortalInvestorControllerPublishedIssuersByDateRangeParams = Describe<typeof investorPortalInvestorControllerPublishedIssuersByDateRangePath._A & typeof investorPortalInvestorControllerPublishedIssuersByDateRangeQuery._A>;

export type InvestorPortalInvestorControllerPublishedIssuersByDateRangeRoute<A = {}> = { match: Match<InvestorPortalInvestorControllerPublishedIssuersByDateRangeParams & A>; pathParts: InvestorPortalInvestorControllerPublishedIssuersByDateRangePathParts };

export function investorPortalInvestorControllerPublishedIssuersByDateRangeRoute(): InvestorPortalInvestorControllerPublishedIssuersByDateRangeRoute;
export function investorPortalInvestorControllerPublishedIssuersByDateRangeRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalInvestorControllerPublishedIssuersByDateRangeParams>): InvestorPortalInvestorControllerPublishedIssuersByDateRangeRoute<t.TypeOf<A>>;
export function investorPortalInvestorControllerPublishedIssuersByDateRangeRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalInvestorControllerPublishedIssuersByDateRangeParams>) {
  return {
    match: (q ? investorPortalInvestorControllerPublishedIssuersByDateRangePath.then(query(t.intersection([investorPortalInvestorControllerPublishedIssuersByDateRangeQuery, q]))) : investorPortalInvestorControllerPublishedIssuersByDateRangePath.then(query(investorPortalInvestorControllerPublishedIssuersByDateRangeQuery))).then(end),
    pathParts: investorPortalInvestorControllerPublishedIssuersByDateRangePathParts,
  };
}

export const investorPortalInvestorControllerPublishedIssuersByDateRangeCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported45_SubscribedC<imported11_IssuerWithAddressC>>
} = { input: imported0_emptyObjectC, output: t.readonlyArray(imported45_subscribedC(imported11_issuerWithAddressC)) };

export const investorPortalInvestorControllerPublishedIssuersByDateRange = (p: InvestorPortalInvestorControllerPublishedIssuersByDateRangeParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported45_SubscribedC<imported11_IssuerWithAddressC>>> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalInvestorControllerPublishedIssuersByDateRangeRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalInvestorControllerPublishedIssuersByDateRangeCodecs);

const investorPortalUserControllerPostSettingsPath = lit("v2").then(lit("investor-portal")).then(lit("user-settings"));
const investorPortalUserControllerPostSettingsPathParts = ["v2", "investor-portal", "user-settings"] as const;
export type InvestorPortalUserControllerPostSettingsPathParts = typeof investorPortalUserControllerPostSettingsPathParts;

const investorPortalUserControllerPostSettingsQuery = t.strict({});
export type InvestorPortalUserControllerPostSettingsParams = Describe<typeof investorPortalUserControllerPostSettingsPath._A & typeof investorPortalUserControllerPostSettingsQuery._A>;

export type InvestorPortalUserControllerPostSettingsRoute<A = {}> = { match: Match<InvestorPortalUserControllerPostSettingsParams & A>; pathParts: InvestorPortalUserControllerPostSettingsPathParts };

export function investorPortalUserControllerPostSettingsRoute(): InvestorPortalUserControllerPostSettingsRoute;
export function investorPortalUserControllerPostSettingsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalUserControllerPostSettingsParams>): InvestorPortalUserControllerPostSettingsRoute<t.TypeOf<A>>;
export function investorPortalUserControllerPostSettingsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalUserControllerPostSettingsParams>) {
  return {
    match: (q ? investorPortalUserControllerPostSettingsPath.then(query(t.intersection([investorPortalUserControllerPostSettingsQuery, q]))) : investorPortalUserControllerPostSettingsPath.then(query(investorPortalUserControllerPostSettingsQuery))).then(end),
    pathParts: investorPortalUserControllerPostSettingsPathParts,
  };
}

export const investorPortalUserControllerPostSettingsCodecs: {
  input: imported0_UserSettingsPostC,
  output: imported0_UserSettingsResponseC
} = { input: imported0_userSettingsPostC, output: imported0_userSettingsResponseC };

export const investorPortalUserControllerPostSettings = (): RelativeUrlInterfaceIO<"POST", imported0_UserSettingsPostC, imported0_UserSettingsResponseC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(investorPortalUserControllerPostSettingsRoute().match.formatter.run(Route.empty, {}).toString(true)), investorPortalUserControllerPostSettingsCodecs);

const investorPortalUserControllerPostMfaSettingsPath = lit("v2").then(lit("investor-portal")).then(lit("user-settings")).then(lit("mfa"));
const investorPortalUserControllerPostMfaSettingsPathParts = ["v2", "investor-portal", "user-settings", "mfa"] as const;
export type InvestorPortalUserControllerPostMfaSettingsPathParts = typeof investorPortalUserControllerPostMfaSettingsPathParts;

const investorPortalUserControllerPostMfaSettingsQuery = t.strict({});
export type InvestorPortalUserControllerPostMfaSettingsParams = Describe<typeof investorPortalUserControllerPostMfaSettingsPath._A & typeof investorPortalUserControllerPostMfaSettingsQuery._A>;

export type InvestorPortalUserControllerPostMfaSettingsRoute<A = {}> = { match: Match<InvestorPortalUserControllerPostMfaSettingsParams & A>; pathParts: InvestorPortalUserControllerPostMfaSettingsPathParts };

export function investorPortalUserControllerPostMfaSettingsRoute(): InvestorPortalUserControllerPostMfaSettingsRoute;
export function investorPortalUserControllerPostMfaSettingsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalUserControllerPostMfaSettingsParams>): InvestorPortalUserControllerPostMfaSettingsRoute<t.TypeOf<A>>;
export function investorPortalUserControllerPostMfaSettingsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalUserControllerPostMfaSettingsParams>) {
  return {
    match: (q ? investorPortalUserControllerPostMfaSettingsPath.then(query(t.intersection([investorPortalUserControllerPostMfaSettingsQuery, q]))) : investorPortalUserControllerPostMfaSettingsPath.then(query(investorPortalUserControllerPostMfaSettingsQuery))).then(end),
    pathParts: investorPortalUserControllerPostMfaSettingsPathParts,
  };
}

export const investorPortalUserControllerPostMfaSettingsCodecs: {
  input: imported0_MfaSettingsPostC,
  output: imported0_MfaSettingsResponseC
} = { input: imported0_mfaSettingsPostC, output: imported0_mfaSettingsResponseC };

export const investorPortalUserControllerPostMfaSettings = (): RelativeUrlInterfaceIO<"POST", imported0_MfaSettingsPostC, imported0_MfaSettingsResponseC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(investorPortalUserControllerPostMfaSettingsRoute().match.formatter.run(Route.empty, {}).toString(true)), investorPortalUserControllerPostMfaSettingsCodecs);

const investorPortalUserControllerPostVerifyMfaPath = lit("v2").then(lit("investor-portal")).then(lit("user-settings")).then(lit("mfa")).then(lit("verify"));
const investorPortalUserControllerPostVerifyMfaPathParts = [
  "v2", "investor-portal", "user-settings", "mfa", "verify"
] as const;
export type InvestorPortalUserControllerPostVerifyMfaPathParts = typeof investorPortalUserControllerPostVerifyMfaPathParts;

const investorPortalUserControllerPostVerifyMfaQuery = t.strict({});
export type InvestorPortalUserControllerPostVerifyMfaParams = Describe<typeof investorPortalUserControllerPostVerifyMfaPath._A & typeof investorPortalUserControllerPostVerifyMfaQuery._A>;

export type InvestorPortalUserControllerPostVerifyMfaRoute<A = {}> = { match: Match<InvestorPortalUserControllerPostVerifyMfaParams & A>; pathParts: InvestorPortalUserControllerPostVerifyMfaPathParts };

export function investorPortalUserControllerPostVerifyMfaRoute(): InvestorPortalUserControllerPostVerifyMfaRoute;
export function investorPortalUserControllerPostVerifyMfaRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalUserControllerPostVerifyMfaParams>): InvestorPortalUserControllerPostVerifyMfaRoute<t.TypeOf<A>>;
export function investorPortalUserControllerPostVerifyMfaRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalUserControllerPostVerifyMfaParams>) {
  return {
    match: (q ? investorPortalUserControllerPostVerifyMfaPath.then(query(t.intersection([investorPortalUserControllerPostVerifyMfaQuery, q]))) : investorPortalUserControllerPostVerifyMfaPath.then(query(investorPortalUserControllerPostVerifyMfaQuery))).then(end),
    pathParts: investorPortalUserControllerPostVerifyMfaPathParts,
  };
}

export const investorPortalUserControllerPostVerifyMfaCodecs: {
  input: imported0_VerifyMfaPostC,
  output: imported32_UserC
} = { input: imported0_verifyMfaPostC, output: imported32_userC };

export const investorPortalUserControllerPostVerifyMfa = (): RelativeUrlInterfaceIO<"POST", imported0_VerifyMfaPostC, imported32_UserC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(investorPortalUserControllerPostVerifyMfaRoute().match.formatter.run(Route.empty, {}).toString(true)), investorPortalUserControllerPostVerifyMfaCodecs);

const investorPortalUserControllerDisableMfaPath = lit("v2").then(lit("investor-portal")).then(lit("user-settings")).then(lit("mfa")).then(lit("disable"));
const investorPortalUserControllerDisableMfaPathParts = [
  "v2", "investor-portal", "user-settings", "mfa", "disable"
] as const;
export type InvestorPortalUserControllerDisableMfaPathParts = typeof investorPortalUserControllerDisableMfaPathParts;

const investorPortalUserControllerDisableMfaQuery = t.strict({});
export type InvestorPortalUserControllerDisableMfaParams = Describe<typeof investorPortalUserControllerDisableMfaPath._A & typeof investorPortalUserControllerDisableMfaQuery._A>;

export type InvestorPortalUserControllerDisableMfaRoute<A = {}> = { match: Match<InvestorPortalUserControllerDisableMfaParams & A>; pathParts: InvestorPortalUserControllerDisableMfaPathParts };

export function investorPortalUserControllerDisableMfaRoute(): InvestorPortalUserControllerDisableMfaRoute;
export function investorPortalUserControllerDisableMfaRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalUserControllerDisableMfaParams>): InvestorPortalUserControllerDisableMfaRoute<t.TypeOf<A>>;
export function investorPortalUserControllerDisableMfaRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalUserControllerDisableMfaParams>) {
  return {
    match: (q ? investorPortalUserControllerDisableMfaPath.then(query(t.intersection([investorPortalUserControllerDisableMfaQuery, q]))) : investorPortalUserControllerDisableMfaPath.then(query(investorPortalUserControllerDisableMfaQuery))).then(end),
    pathParts: investorPortalUserControllerDisableMfaPathParts,
  };
}

export const investorPortalUserControllerDisableMfaCodecs: {
  input: imported0_EmptyObjectC,
  output: imported33_UserC
} = { input: imported0_emptyObjectC, output: imported33_userC };

export const investorPortalUserControllerDisableMfa = (): RelativeUrlInterfaceIO<"POST", imported0_EmptyObjectC, imported33_UserC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(investorPortalUserControllerDisableMfaRoute().match.formatter.run(Route.empty, {}).toString(true)), investorPortalUserControllerDisableMfaCodecs);

const investorPortalUserControllerUpdatePasswordPath = lit("v2").then(lit("investor-portal")).then(lit("user-settings")).then(lit("update-password"));
const investorPortalUserControllerUpdatePasswordPathParts = [
  "v2", "investor-portal", "user-settings", "update-password"
] as const;
export type InvestorPortalUserControllerUpdatePasswordPathParts = typeof investorPortalUserControllerUpdatePasswordPathParts;

const investorPortalUserControllerUpdatePasswordQuery = t.strict({});
export type InvestorPortalUserControllerUpdatePasswordParams = Describe<typeof investorPortalUserControllerUpdatePasswordPath._A & typeof investorPortalUserControllerUpdatePasswordQuery._A>;

export type InvestorPortalUserControllerUpdatePasswordRoute<A = {}> = { match: Match<InvestorPortalUserControllerUpdatePasswordParams & A>; pathParts: InvestorPortalUserControllerUpdatePasswordPathParts };

export function investorPortalUserControllerUpdatePasswordRoute(): InvestorPortalUserControllerUpdatePasswordRoute;
export function investorPortalUserControllerUpdatePasswordRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalUserControllerUpdatePasswordParams>): InvestorPortalUserControllerUpdatePasswordRoute<t.TypeOf<A>>;
export function investorPortalUserControllerUpdatePasswordRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalUserControllerUpdatePasswordParams>) {
  return {
    match: (q ? investorPortalUserControllerUpdatePasswordPath.then(query(t.intersection([investorPortalUserControllerUpdatePasswordQuery, q]))) : investorPortalUserControllerUpdatePasswordPath.then(query(investorPortalUserControllerUpdatePasswordQuery))).then(end),
    pathParts: investorPortalUserControllerUpdatePasswordPathParts,
  };
}

export const investorPortalUserControllerUpdatePasswordCodecs: {
  input: imported0_UpdatePasswordPostC,
  output: imported0_EmptyObjectC
} = { input: imported0_updatePasswordPostC, output: imported0_emptyObjectC };

export const investorPortalUserControllerUpdatePassword = (): RelativeUrlInterfaceIO<"POST", imported0_UpdatePasswordPostC, imported0_EmptyObjectC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(investorPortalUserControllerUpdatePasswordRoute().match.formatter.run(Route.empty, {}).toString(true)), investorPortalUserControllerUpdatePasswordCodecs);

const investorPortalUserControllerPostInstitutionalInvestorInfoPath = lit("v2").then(lit("investor-portal")).then(lit("user-settings")).then(lit("investor-info"));
const investorPortalUserControllerPostInstitutionalInvestorInfoPathParts = [
  "v2", "investor-portal", "user-settings", "investor-info"
] as const;
export type InvestorPortalUserControllerPostInstitutionalInvestorInfoPathParts = typeof investorPortalUserControllerPostInstitutionalInvestorInfoPathParts;

const investorPortalUserControllerPostInstitutionalInvestorInfoQuery = t.strict({});
export type InvestorPortalUserControllerPostInstitutionalInvestorInfoParams = Describe<typeof investorPortalUserControllerPostInstitutionalInvestorInfoPath._A & typeof investorPortalUserControllerPostInstitutionalInvestorInfoQuery._A>;

export type InvestorPortalUserControllerPostInstitutionalInvestorInfoRoute<A = {}> = { match: Match<InvestorPortalUserControllerPostInstitutionalInvestorInfoParams & A>; pathParts: InvestorPortalUserControllerPostInstitutionalInvestorInfoPathParts };

export function investorPortalUserControllerPostInstitutionalInvestorInfoRoute(): InvestorPortalUserControllerPostInstitutionalInvestorInfoRoute;
export function investorPortalUserControllerPostInstitutionalInvestorInfoRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalUserControllerPostInstitutionalInvestorInfoParams>): InvestorPortalUserControllerPostInstitutionalInvestorInfoRoute<t.TypeOf<A>>;
export function investorPortalUserControllerPostInstitutionalInvestorInfoRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalUserControllerPostInstitutionalInvestorInfoParams>) {
  return {
    match: (q ? investorPortalUserControllerPostInstitutionalInvestorInfoPath.then(query(t.intersection([investorPortalUserControllerPostInstitutionalInvestorInfoQuery, q]))) : investorPortalUserControllerPostInstitutionalInvestorInfoPath.then(query(investorPortalUserControllerPostInstitutionalInvestorInfoQuery))).then(end),
    pathParts: investorPortalUserControllerPostInstitutionalInvestorInfoPathParts,
  };
}

export const investorPortalUserControllerPostInstitutionalInvestorInfoCodecs: {
  input: imported0_InstitutionalInvestorInfoPostC,
  output: imported0_InstitutionalInvestorInfoC
} = { input: imported0_institutionalInvestorInfoPostC, output: imported0_institutionalInvestorInfoC };

export const investorPortalUserControllerPostInstitutionalInvestorInfo = (): RelativeUrlInterfaceIO<"POST", imported0_InstitutionalInvestorInfoPostC, imported0_InstitutionalInvestorInfoC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(investorPortalUserControllerPostInstitutionalInvestorInfoRoute().match.formatter.run(Route.empty, {}).toString(true)), investorPortalUserControllerPostInstitutionalInvestorInfoCodecs);

const investorPortalUserControllerGetInstitutionalInvestorInfoPath = lit("v2").then(lit("investor-portal")).then(lit("user-settings")).then(lit("investor-info"));
const investorPortalUserControllerGetInstitutionalInvestorInfoPathParts = [
  "v2", "investor-portal", "user-settings", "investor-info"
] as const;
export type InvestorPortalUserControllerGetInstitutionalInvestorInfoPathParts = typeof investorPortalUserControllerGetInstitutionalInvestorInfoPathParts;

const investorPortalUserControllerGetInstitutionalInvestorInfoQuery = t.strict({});
export type InvestorPortalUserControllerGetInstitutionalInvestorInfoParams = Describe<typeof investorPortalUserControllerGetInstitutionalInvestorInfoPath._A & typeof investorPortalUserControllerGetInstitutionalInvestorInfoQuery._A>;

export type InvestorPortalUserControllerGetInstitutionalInvestorInfoRoute<A = {}> = { match: Match<InvestorPortalUserControllerGetInstitutionalInvestorInfoParams & A>; pathParts: InvestorPortalUserControllerGetInstitutionalInvestorInfoPathParts };

export function investorPortalUserControllerGetInstitutionalInvestorInfoRoute(): InvestorPortalUserControllerGetInstitutionalInvestorInfoRoute;
export function investorPortalUserControllerGetInstitutionalInvestorInfoRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalUserControllerGetInstitutionalInvestorInfoParams>): InvestorPortalUserControllerGetInstitutionalInvestorInfoRoute<t.TypeOf<A>>;
export function investorPortalUserControllerGetInstitutionalInvestorInfoRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalUserControllerGetInstitutionalInvestorInfoParams>) {
  return {
    match: (q ? investorPortalUserControllerGetInstitutionalInvestorInfoPath.then(query(t.intersection([investorPortalUserControllerGetInstitutionalInvestorInfoQuery, q]))) : investorPortalUserControllerGetInstitutionalInvestorInfoPath.then(query(investorPortalUserControllerGetInstitutionalInvestorInfoQuery))).then(end),
    pathParts: investorPortalUserControllerGetInstitutionalInvestorInfoPathParts,
  };
}

export const investorPortalUserControllerGetInstitutionalInvestorInfoCodecs: {
  input: imported0_EmptyObjectC,
  output: imported1_InstitutionalInvestorInfoC
} = { input: imported0_emptyObjectC, output: imported1_institutionalInvestorInfoC };

export const investorPortalUserControllerGetInstitutionalInvestorInfo = (): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, imported1_InstitutionalInvestorInfoC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalUserControllerGetInstitutionalInvestorInfoRoute().match.formatter.run(Route.empty, {}).toString(true)), investorPortalUserControllerGetInstitutionalInvestorInfoCodecs);

const investorPortalRfpsControllerCalendarPath = lit("v2").then(lit("investor-portal")).then(lit("rfps")).then(lit("calendar"));
const investorPortalRfpsControllerCalendarPathParts = ["v2", "investor-portal", "rfps", "calendar"] as const;
export type InvestorPortalRfpsControllerCalendarPathParts = typeof investorPortalRfpsControllerCalendarPathParts;

const investorPortalRfpsControllerCalendarQuery = t.strict({});
export type InvestorPortalRfpsControllerCalendarParams = Describe<typeof investorPortalRfpsControllerCalendarPath._A & typeof investorPortalRfpsControllerCalendarQuery._A>;

export type InvestorPortalRfpsControllerCalendarRoute<A = {}> = { match: Match<InvestorPortalRfpsControllerCalendarParams & A>; pathParts: InvestorPortalRfpsControllerCalendarPathParts };

export function investorPortalRfpsControllerCalendarRoute(): InvestorPortalRfpsControllerCalendarRoute;
export function investorPortalRfpsControllerCalendarRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalRfpsControllerCalendarParams>): InvestorPortalRfpsControllerCalendarRoute<t.TypeOf<A>>;
export function investorPortalRfpsControllerCalendarRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalRfpsControllerCalendarParams>) {
  return {
    match: (q ? investorPortalRfpsControllerCalendarPath.then(query(t.intersection([investorPortalRfpsControllerCalendarQuery, q]))) : investorPortalRfpsControllerCalendarPath.then(query(investorPortalRfpsControllerCalendarQuery))).then(end),
    pathParts: investorPortalRfpsControllerCalendarPathParts,
  };
}

export const investorPortalRfpsControllerCalendarCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported0_InvestorPortalRfpC>
} = { input: imported0_emptyObjectC, output: t.readonlyArray(imported0_investorPortalRfpC) };

export const investorPortalRfpsControllerCalendar = (): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported0_InvestorPortalRfpC>> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalRfpsControllerCalendarRoute().match.formatter.run(Route.empty, {}).toString(true)), investorPortalRfpsControllerCalendarCodecs);

const investorPortalRfpsControllerActiveAndUnscheduledPath = lit("v2").then(lit("investor-portal")).then(lit("rfps")).then(lit("active"));
const investorPortalRfpsControllerActiveAndUnscheduledPathParts = ["v2", "investor-portal", "rfps", "active"] as const;
export type InvestorPortalRfpsControllerActiveAndUnscheduledPathParts = typeof investorPortalRfpsControllerActiveAndUnscheduledPathParts;

const investorPortalRfpsControllerActiveAndUnscheduledQuery = t.exact(t.type({
  "page": NumberFromString
}));
export type InvestorPortalRfpsControllerActiveAndUnscheduledParams = Describe<typeof investorPortalRfpsControllerActiveAndUnscheduledPath._A & typeof investorPortalRfpsControllerActiveAndUnscheduledQuery._A>;

export type InvestorPortalRfpsControllerActiveAndUnscheduledRoute<A = {}> = { match: Match<InvestorPortalRfpsControllerActiveAndUnscheduledParams & A>; pathParts: InvestorPortalRfpsControllerActiveAndUnscheduledPathParts };

export function investorPortalRfpsControllerActiveAndUnscheduledRoute(): InvestorPortalRfpsControllerActiveAndUnscheduledRoute;
export function investorPortalRfpsControllerActiveAndUnscheduledRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalRfpsControllerActiveAndUnscheduledParams>): InvestorPortalRfpsControllerActiveAndUnscheduledRoute<t.TypeOf<A>>;
export function investorPortalRfpsControllerActiveAndUnscheduledRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalRfpsControllerActiveAndUnscheduledParams>) {
  return {
    match: (q ? investorPortalRfpsControllerActiveAndUnscheduledPath.then(query(t.intersection([investorPortalRfpsControllerActiveAndUnscheduledQuery, q]))) : investorPortalRfpsControllerActiveAndUnscheduledPath.then(query(investorPortalRfpsControllerActiveAndUnscheduledQuery))).then(end),
    pathParts: investorPortalRfpsControllerActiveAndUnscheduledPathParts,
  };
}

export const investorPortalRfpsControllerActiveAndUnscheduledCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported1_InvestorPortalRfpC>
} = { input: imported0_emptyObjectC, output: t.readonlyArray(imported1_investorPortalRfpC) };

export const investorPortalRfpsControllerActiveAndUnscheduled = (p: InvestorPortalRfpsControllerActiveAndUnscheduledParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported1_InvestorPortalRfpC>> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalRfpsControllerActiveAndUnscheduledRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalRfpsControllerActiveAndUnscheduledCodecs);

const investorPortalRfpsControllerRecentPath = lit("v2").then(lit("investor-portal")).then(lit("rfps")).then(lit("recent"));
const investorPortalRfpsControllerRecentPathParts = ["v2", "investor-portal", "rfps", "recent"] as const;
export type InvestorPortalRfpsControllerRecentPathParts = typeof investorPortalRfpsControllerRecentPathParts;

const investorPortalRfpsControllerRecentQuery = t.exact(t.type({
  "page": NumberFromString
}));
export type InvestorPortalRfpsControllerRecentParams = Describe<typeof investorPortalRfpsControllerRecentPath._A & typeof investorPortalRfpsControllerRecentQuery._A>;

export type InvestorPortalRfpsControllerRecentRoute<A = {}> = { match: Match<InvestorPortalRfpsControllerRecentParams & A>; pathParts: InvestorPortalRfpsControllerRecentPathParts };

export function investorPortalRfpsControllerRecentRoute(): InvestorPortalRfpsControllerRecentRoute;
export function investorPortalRfpsControllerRecentRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalRfpsControllerRecentParams>): InvestorPortalRfpsControllerRecentRoute<t.TypeOf<A>>;
export function investorPortalRfpsControllerRecentRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalRfpsControllerRecentParams>) {
  return {
    match: (q ? investorPortalRfpsControllerRecentPath.then(query(t.intersection([investorPortalRfpsControllerRecentQuery, q]))) : investorPortalRfpsControllerRecentPath.then(query(investorPortalRfpsControllerRecentQuery))).then(end),
    pathParts: investorPortalRfpsControllerRecentPathParts,
  };
}

export const investorPortalRfpsControllerRecentCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported2_InvestorPortalRfpC>
} = { input: imported0_emptyObjectC, output: t.readonlyArray(imported2_investorPortalRfpC) };

export const investorPortalRfpsControllerRecent = (p: InvestorPortalRfpsControllerRecentParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported2_InvestorPortalRfpC>> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalRfpsControllerRecentRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalRfpsControllerRecentCodecs);

const investorPortalRfpsControllerUpdateSubscriptionPath = lit("v2").then(lit("investor-portal")).then(lit("rfps")).then(lit("update-subscription")).then(int("rfpId")).then(type("enabled", BooleanFromString));
const investorPortalRfpsControllerUpdateSubscriptionPathParts = [
  "v2", "investor-portal", "rfps", "update-subscription", "rfpId", "enabled"
] as const;
export type InvestorPortalRfpsControllerUpdateSubscriptionPathParts = typeof investorPortalRfpsControllerUpdateSubscriptionPathParts;

const investorPortalRfpsControllerUpdateSubscriptionQuery = t.strict({});
export type InvestorPortalRfpsControllerUpdateSubscriptionParams = Describe<typeof investorPortalRfpsControllerUpdateSubscriptionPath._A & typeof investorPortalRfpsControllerUpdateSubscriptionQuery._A>;

export type InvestorPortalRfpsControllerUpdateSubscriptionRoute<A = {}> = { match: Match<InvestorPortalRfpsControllerUpdateSubscriptionParams & A>; pathParts: InvestorPortalRfpsControllerUpdateSubscriptionPathParts };

export function investorPortalRfpsControllerUpdateSubscriptionRoute(): InvestorPortalRfpsControllerUpdateSubscriptionRoute;
export function investorPortalRfpsControllerUpdateSubscriptionRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalRfpsControllerUpdateSubscriptionParams>): InvestorPortalRfpsControllerUpdateSubscriptionRoute<t.TypeOf<A>>;
export function investorPortalRfpsControllerUpdateSubscriptionRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalRfpsControllerUpdateSubscriptionParams>) {
  return {
    match: (q ? investorPortalRfpsControllerUpdateSubscriptionPath.then(query(t.intersection([investorPortalRfpsControllerUpdateSubscriptionQuery, q]))) : investorPortalRfpsControllerUpdateSubscriptionPath.then(query(investorPortalRfpsControllerUpdateSubscriptionQuery))).then(end),
    pathParts: investorPortalRfpsControllerUpdateSubscriptionPathParts,
  };
}

export const investorPortalRfpsControllerUpdateSubscriptionCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_EmptyObjectC
} = { input: imported0_emptyObjectC, output: imported0_emptyObjectC };

export const investorPortalRfpsControllerUpdateSubscription = (p: InvestorPortalRfpsControllerUpdateSubscriptionParams): RelativeUrlInterfaceIO<"POST", imported0_EmptyObjectC, imported0_EmptyObjectC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(investorPortalRfpsControllerUpdateSubscriptionRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalRfpsControllerUpdateSubscriptionCodecs);

const investorPortalRfpsControllerSubscribeRedirectPath = lit("v2").then(lit("investor-portal")).then(lit("rfps")).then(lit("subscribe-redirect")).then(int("rfpId"));
const investorPortalRfpsControllerSubscribeRedirectPathParts = [
  "v2", "investor-portal", "rfps", "subscribe-redirect", "rfpId"
] as const;
export type InvestorPortalRfpsControllerSubscribeRedirectPathParts = typeof investorPortalRfpsControllerSubscribeRedirectPathParts;

const investorPortalRfpsControllerSubscribeRedirectQuery = t.exact(t.type({
  "redirect": relativeUrlC
}));
export type InvestorPortalRfpsControllerSubscribeRedirectParams = Describe<typeof investorPortalRfpsControllerSubscribeRedirectPath._A & typeof investorPortalRfpsControllerSubscribeRedirectQuery._A>;

export type InvestorPortalRfpsControllerSubscribeRedirectRoute<A = {}> = { match: Match<InvestorPortalRfpsControllerSubscribeRedirectParams & A>; pathParts: InvestorPortalRfpsControllerSubscribeRedirectPathParts };

export function investorPortalRfpsControllerSubscribeRedirectRoute(): InvestorPortalRfpsControllerSubscribeRedirectRoute;
export function investorPortalRfpsControllerSubscribeRedirectRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalRfpsControllerSubscribeRedirectParams>): InvestorPortalRfpsControllerSubscribeRedirectRoute<t.TypeOf<A>>;
export function investorPortalRfpsControllerSubscribeRedirectRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalRfpsControllerSubscribeRedirectParams>) {
  return {
    match: (q ? investorPortalRfpsControllerSubscribeRedirectPath.then(query(t.intersection([investorPortalRfpsControllerSubscribeRedirectQuery, q]))) : investorPortalRfpsControllerSubscribeRedirectPath.then(query(investorPortalRfpsControllerSubscribeRedirectQuery))).then(end),
    pathParts: investorPortalRfpsControllerSubscribeRedirectPathParts,
  };
}

export const investorPortalRfpsControllerSubscribeRedirectCodecs: {
  input: imported0_EmptyObjectC,
  output: imported19_BrowserRedirectC
} = { input: imported0_emptyObjectC, output: imported19_browserRedirectC };

export const investorPortalRfpsControllerSubscribeRedirect = (p: InvestorPortalRfpsControllerSubscribeRedirectParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, imported19_BrowserRedirectC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalRfpsControllerSubscribeRedirectRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalRfpsControllerSubscribeRedirectCodecs);

const investorPortalRfpsControllerDownloadDocumentPath = lit("v2").then(lit("investor-portal")).then(lit("rfps")).then(lit("documents")).then(lit("download")).then(int("issuerId")).then(int("rfpId")).then(int("mediaId"));
const investorPortalRfpsControllerDownloadDocumentPathParts = [
  "v2", "investor-portal", "rfps", "documents", "download", "issuerId", "rfpId", "mediaId"
] as const;
export type InvestorPortalRfpsControllerDownloadDocumentPathParts = typeof investorPortalRfpsControllerDownloadDocumentPathParts;

const investorPortalRfpsControllerDownloadDocumentQuery = t.strict({});
export type InvestorPortalRfpsControllerDownloadDocumentParams = Describe<typeof investorPortalRfpsControllerDownloadDocumentPath._A & typeof investorPortalRfpsControllerDownloadDocumentQuery._A>;

export type InvestorPortalRfpsControllerDownloadDocumentRoute<A = {}> = { match: Match<InvestorPortalRfpsControllerDownloadDocumentParams & A>; pathParts: InvestorPortalRfpsControllerDownloadDocumentPathParts };

export function investorPortalRfpsControllerDownloadDocumentRoute(): InvestorPortalRfpsControllerDownloadDocumentRoute;
export function investorPortalRfpsControllerDownloadDocumentRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalRfpsControllerDownloadDocumentParams>): InvestorPortalRfpsControllerDownloadDocumentRoute<t.TypeOf<A>>;
export function investorPortalRfpsControllerDownloadDocumentRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalRfpsControllerDownloadDocumentParams>) {
  return {
    match: (q ? investorPortalRfpsControllerDownloadDocumentPath.then(query(t.intersection([investorPortalRfpsControllerDownloadDocumentQuery, q]))) : investorPortalRfpsControllerDownloadDocumentPath.then(query(investorPortalRfpsControllerDownloadDocumentQuery))).then(end),
    pathParts: investorPortalRfpsControllerDownloadDocumentPathParts,
  };
}

export const investorPortalRfpsControllerDownloadDocumentCodecs: {
  input: imported0_EmptyObjectC,
  output: EitherC<imported20_BrowserRedirectC, imported5_ApiRedirectC>
} = { input: imported0_emptyObjectC, output: either(imported20_browserRedirectC, imported5_apiRedirectC) };

export const investorPortalRfpsControllerDownloadDocument = (p: InvestorPortalRfpsControllerDownloadDocumentParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, EitherC<imported20_BrowserRedirectC, imported5_ApiRedirectC>> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalRfpsControllerDownloadDocumentRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalRfpsControllerDownloadDocumentCodecs);

const investorPortalRfpsControllerSubmitBidPath = lit("v2").then(lit("investor-portal")).then(lit("rfps")).then(lit("submit-bid"));
const investorPortalRfpsControllerSubmitBidPathParts = ["v2", "investor-portal", "rfps", "submit-bid"] as const;
export type InvestorPortalRfpsControllerSubmitBidPathParts = typeof investorPortalRfpsControllerSubmitBidPathParts;

const investorPortalRfpsControllerSubmitBidQuery = t.strict({});
export type InvestorPortalRfpsControllerSubmitBidParams = Describe<typeof investorPortalRfpsControllerSubmitBidPath._A & typeof investorPortalRfpsControllerSubmitBidQuery._A>;

export type InvestorPortalRfpsControllerSubmitBidRoute<A = {}> = { match: Match<InvestorPortalRfpsControllerSubmitBidParams & A>; pathParts: InvestorPortalRfpsControllerSubmitBidPathParts };

export function investorPortalRfpsControllerSubmitBidRoute(): InvestorPortalRfpsControllerSubmitBidRoute;
export function investorPortalRfpsControllerSubmitBidRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalRfpsControllerSubmitBidParams>): InvestorPortalRfpsControllerSubmitBidRoute<t.TypeOf<A>>;
export function investorPortalRfpsControllerSubmitBidRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalRfpsControllerSubmitBidParams>) {
  return {
    match: (q ? investorPortalRfpsControllerSubmitBidPath.then(query(t.intersection([investorPortalRfpsControllerSubmitBidQuery, q]))) : investorPortalRfpsControllerSubmitBidPath.then(query(investorPortalRfpsControllerSubmitBidQuery))).then(end),
    pathParts: investorPortalRfpsControllerSubmitBidPathParts,
  };
}

export const investorPortalRfpsControllerSubmitBidCodecs: {
  input: imported0_BidSubmissionPostC,
  output: imported956_WithStatusCU<imported255_WithModInfoC<imported14_BidSubmissionDataC>>
} = {
  input: imported0_bidSubmissionPostC,
  output: imported957_WithStatusCU(imported255_withModInfoC(imported14_bidSubmissionDataC))
};

export const investorPortalRfpsControllerSubmitBid = (): RelativeUrlInterfaceIO<"POST", imported0_BidSubmissionPostC, imported956_WithStatusCU<imported255_WithModInfoC<imported14_BidSubmissionDataC>>> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(investorPortalRfpsControllerSubmitBidRoute().match.formatter.run(Route.empty, {}).toString(true)), investorPortalRfpsControllerSubmitBidCodecs);

const investorPortalRfpsControllerDownloadBidSubmissionDocumentPath = lit("v2").then(lit("investor-portal")).then(lit("rfps")).then(lit("bid-submission")).then(lit("documents")).then(lit("download"));
const investorPortalRfpsControllerDownloadBidSubmissionDocumentPathParts = [
  "v2", "investor-portal", "rfps", "bid-submission", "documents", "download"
] as const;
export type InvestorPortalRfpsControllerDownloadBidSubmissionDocumentPathParts = typeof investorPortalRfpsControllerDownloadBidSubmissionDocumentPathParts;

const investorPortalRfpsControllerDownloadBidSubmissionDocumentQuery = t.exact(t.type({
  "mediaUri": t.string
}));
export type InvestorPortalRfpsControllerDownloadBidSubmissionDocumentParams = Describe<typeof investorPortalRfpsControllerDownloadBidSubmissionDocumentPath._A & typeof investorPortalRfpsControllerDownloadBidSubmissionDocumentQuery._A>;

export type InvestorPortalRfpsControllerDownloadBidSubmissionDocumentRoute<A = {}> = { match: Match<InvestorPortalRfpsControllerDownloadBidSubmissionDocumentParams & A>; pathParts: InvestorPortalRfpsControllerDownloadBidSubmissionDocumentPathParts };

export function investorPortalRfpsControllerDownloadBidSubmissionDocumentRoute(): InvestorPortalRfpsControllerDownloadBidSubmissionDocumentRoute;
export function investorPortalRfpsControllerDownloadBidSubmissionDocumentRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalRfpsControllerDownloadBidSubmissionDocumentParams>): InvestorPortalRfpsControllerDownloadBidSubmissionDocumentRoute<t.TypeOf<A>>;
export function investorPortalRfpsControllerDownloadBidSubmissionDocumentRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalRfpsControllerDownloadBidSubmissionDocumentParams>) {
  return {
    match: (q ? investorPortalRfpsControllerDownloadBidSubmissionDocumentPath.then(query(t.intersection([investorPortalRfpsControllerDownloadBidSubmissionDocumentQuery, q]))) : investorPortalRfpsControllerDownloadBidSubmissionDocumentPath.then(query(investorPortalRfpsControllerDownloadBidSubmissionDocumentQuery))).then(end),
    pathParts: investorPortalRfpsControllerDownloadBidSubmissionDocumentPathParts,
  };
}

export const investorPortalRfpsControllerDownloadBidSubmissionDocumentCodecs: {
  input: imported0_EmptyObjectC,
  output: imported21_BrowserRedirectC
} = { input: imported0_emptyObjectC, output: imported21_browserRedirectC };

export const investorPortalRfpsControllerDownloadBidSubmissionDocument = (p: InvestorPortalRfpsControllerDownloadBidSubmissionDocumentParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, imported21_BrowserRedirectC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalRfpsControllerDownloadBidSubmissionDocumentRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalRfpsControllerDownloadBidSubmissionDocumentCodecs);

const investorPortalOfferingsControllerSubscribedUpcomingPath = lit("v2").then(lit("investor-portal")).then(lit("offerings")).then(lit("upcoming"));
const investorPortalOfferingsControllerSubscribedUpcomingPathParts = [
  "v2", "investor-portal", "offerings", "upcoming"
] as const;
export type InvestorPortalOfferingsControllerSubscribedUpcomingPathParts = typeof investorPortalOfferingsControllerSubscribedUpcomingPathParts;

const investorPortalOfferingsControllerSubscribedUpcomingQuery = t.exact(t.type({
  "page": NumberFromString
}));
export type InvestorPortalOfferingsControllerSubscribedUpcomingParams = Describe<typeof investorPortalOfferingsControllerSubscribedUpcomingPath._A & typeof investorPortalOfferingsControllerSubscribedUpcomingQuery._A>;

export type InvestorPortalOfferingsControllerSubscribedUpcomingRoute<A = {}> = { match: Match<InvestorPortalOfferingsControllerSubscribedUpcomingParams & A>; pathParts: InvestorPortalOfferingsControllerSubscribedUpcomingPathParts };

export function investorPortalOfferingsControllerSubscribedUpcomingRoute(): InvestorPortalOfferingsControllerSubscribedUpcomingRoute;
export function investorPortalOfferingsControllerSubscribedUpcomingRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalOfferingsControllerSubscribedUpcomingParams>): InvestorPortalOfferingsControllerSubscribedUpcomingRoute<t.TypeOf<A>>;
export function investorPortalOfferingsControllerSubscribedUpcomingRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalOfferingsControllerSubscribedUpcomingParams>) {
  return {
    match: (q ? investorPortalOfferingsControllerSubscribedUpcomingPath.then(query(t.intersection([investorPortalOfferingsControllerSubscribedUpcomingQuery, q]))) : investorPortalOfferingsControllerSubscribedUpcomingPath.then(query(investorPortalOfferingsControllerSubscribedUpcomingQuery))).then(end),
    pathParts: investorPortalOfferingsControllerSubscribedUpcomingPathParts,
  };
}

export const investorPortalOfferingsControllerSubscribedUpcomingCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported2_InvestorPortalOfferingC>
} = { input: imported0_emptyObjectC, output: t.readonlyArray(imported2_investorPortalOfferingC) };

export const investorPortalOfferingsControllerSubscribedUpcoming = (p: InvestorPortalOfferingsControllerSubscribedUpcomingParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported2_InvestorPortalOfferingC>> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalOfferingsControllerSubscribedUpcomingRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalOfferingsControllerSubscribedUpcomingCodecs);

const investorPortalOfferingsControllerSubscribedPastPath = lit("v2").then(lit("investor-portal")).then(lit("offerings")).then(lit("past"));
const investorPortalOfferingsControllerSubscribedPastPathParts = [
  "v2", "investor-portal", "offerings", "past"
] as const;
export type InvestorPortalOfferingsControllerSubscribedPastPathParts = typeof investorPortalOfferingsControllerSubscribedPastPathParts;

const investorPortalOfferingsControllerSubscribedPastQuery = t.exact(t.type({
  "page": NumberFromString
}));
export type InvestorPortalOfferingsControllerSubscribedPastParams = Describe<typeof investorPortalOfferingsControllerSubscribedPastPath._A & typeof investorPortalOfferingsControllerSubscribedPastQuery._A>;

export type InvestorPortalOfferingsControllerSubscribedPastRoute<A = {}> = { match: Match<InvestorPortalOfferingsControllerSubscribedPastParams & A>; pathParts: InvestorPortalOfferingsControllerSubscribedPastPathParts };

export function investorPortalOfferingsControllerSubscribedPastRoute(): InvestorPortalOfferingsControllerSubscribedPastRoute;
export function investorPortalOfferingsControllerSubscribedPastRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalOfferingsControllerSubscribedPastParams>): InvestorPortalOfferingsControllerSubscribedPastRoute<t.TypeOf<A>>;
export function investorPortalOfferingsControllerSubscribedPastRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalOfferingsControllerSubscribedPastParams>) {
  return {
    match: (q ? investorPortalOfferingsControllerSubscribedPastPath.then(query(t.intersection([investorPortalOfferingsControllerSubscribedPastQuery, q]))) : investorPortalOfferingsControllerSubscribedPastPath.then(query(investorPortalOfferingsControllerSubscribedPastQuery))).then(end),
    pathParts: investorPortalOfferingsControllerSubscribedPastPathParts,
  };
}

export const investorPortalOfferingsControllerSubscribedPastCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported3_InvestorPortalOfferingC>
} = { input: imported0_emptyObjectC, output: t.readonlyArray(imported3_investorPortalOfferingC) };

export const investorPortalOfferingsControllerSubscribedPast = (p: InvestorPortalOfferingsControllerSubscribedPastParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported3_InvestorPortalOfferingC>> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalOfferingsControllerSubscribedPastRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalOfferingsControllerSubscribedPastCodecs);

const investorPortalOfferingsControllerSubscribedUnscheduledPath = lit("v2").then(lit("investor-portal")).then(lit("offerings")).then(lit("unscheduled"));
const investorPortalOfferingsControllerSubscribedUnscheduledPathParts = [
  "v2", "investor-portal", "offerings", "unscheduled"
] as const;
export type InvestorPortalOfferingsControllerSubscribedUnscheduledPathParts = typeof investorPortalOfferingsControllerSubscribedUnscheduledPathParts;

const investorPortalOfferingsControllerSubscribedUnscheduledQuery = t.exact(t.type({
  "page": NumberFromString
}));
export type InvestorPortalOfferingsControllerSubscribedUnscheduledParams = Describe<typeof investorPortalOfferingsControllerSubscribedUnscheduledPath._A & typeof investorPortalOfferingsControllerSubscribedUnscheduledQuery._A>;

export type InvestorPortalOfferingsControllerSubscribedUnscheduledRoute<A = {}> = { match: Match<InvestorPortalOfferingsControllerSubscribedUnscheduledParams & A>; pathParts: InvestorPortalOfferingsControllerSubscribedUnscheduledPathParts };

export function investorPortalOfferingsControllerSubscribedUnscheduledRoute(): InvestorPortalOfferingsControllerSubscribedUnscheduledRoute;
export function investorPortalOfferingsControllerSubscribedUnscheduledRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalOfferingsControllerSubscribedUnscheduledParams>): InvestorPortalOfferingsControllerSubscribedUnscheduledRoute<t.TypeOf<A>>;
export function investorPortalOfferingsControllerSubscribedUnscheduledRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalOfferingsControllerSubscribedUnscheduledParams>) {
  return {
    match: (q ? investorPortalOfferingsControllerSubscribedUnscheduledPath.then(query(t.intersection([investorPortalOfferingsControllerSubscribedUnscheduledQuery, q]))) : investorPortalOfferingsControllerSubscribedUnscheduledPath.then(query(investorPortalOfferingsControllerSubscribedUnscheduledQuery))).then(end),
    pathParts: investorPortalOfferingsControllerSubscribedUnscheduledPathParts,
  };
}

export const investorPortalOfferingsControllerSubscribedUnscheduledCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported4_InvestorPortalOfferingC>
} = { input: imported0_emptyObjectC, output: t.readonlyArray(imported4_investorPortalOfferingC) };

export const investorPortalOfferingsControllerSubscribedUnscheduled = (p: InvestorPortalOfferingsControllerSubscribedUnscheduledParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported4_InvestorPortalOfferingC>> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalOfferingsControllerSubscribedUnscheduledRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalOfferingsControllerSubscribedUnscheduledCodecs);

const investorPortalOfferingsControllerCalendarPath = lit("v2").then(lit("investor-portal")).then(lit("offerings")).then(lit("calendar"));
const investorPortalOfferingsControllerCalendarPathParts = ["v2", "investor-portal", "offerings", "calendar"] as const;
export type InvestorPortalOfferingsControllerCalendarPathParts = typeof investorPortalOfferingsControllerCalendarPathParts;

const investorPortalOfferingsControllerCalendarQuery = t.strict({});
export type InvestorPortalOfferingsControllerCalendarParams = Describe<typeof investorPortalOfferingsControllerCalendarPath._A & typeof investorPortalOfferingsControllerCalendarQuery._A>;

export type InvestorPortalOfferingsControllerCalendarRoute<A = {}> = { match: Match<InvestorPortalOfferingsControllerCalendarParams & A>; pathParts: InvestorPortalOfferingsControllerCalendarPathParts };

export function investorPortalOfferingsControllerCalendarRoute(): InvestorPortalOfferingsControllerCalendarRoute;
export function investorPortalOfferingsControllerCalendarRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalOfferingsControllerCalendarParams>): InvestorPortalOfferingsControllerCalendarRoute<t.TypeOf<A>>;
export function investorPortalOfferingsControllerCalendarRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalOfferingsControllerCalendarParams>) {
  return {
    match: (q ? investorPortalOfferingsControllerCalendarPath.then(query(t.intersection([investorPortalOfferingsControllerCalendarQuery, q]))) : investorPortalOfferingsControllerCalendarPath.then(query(investorPortalOfferingsControllerCalendarQuery))).then(end),
    pathParts: investorPortalOfferingsControllerCalendarPathParts,
  };
}

export const investorPortalOfferingsControllerCalendarCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported5_InvestorPortalOfferingC>
} = { input: imported0_emptyObjectC, output: t.readonlyArray(imported5_investorPortalOfferingC) };

export const investorPortalOfferingsControllerCalendar = (): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported5_InvestorPortalOfferingC>> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalOfferingsControllerCalendarRoute().match.formatter.run(Route.empty, {}).toString(true)), investorPortalOfferingsControllerCalendarCodecs);

const investorPortalOfferingsControllerCalendarSortedPath = lit("v2").then(lit("investor-portal")).then(lit("offerings")).then(lit("calendar-sort"));
const investorPortalOfferingsControllerCalendarSortedPathParts = [
  "v2", "investor-portal", "offerings", "calendar-sort"
] as const;
export type InvestorPortalOfferingsControllerCalendarSortedPathParts = typeof investorPortalOfferingsControllerCalendarSortedPathParts;

const investorPortalOfferingsControllerCalendarSortedQuery = t.strict({});
export type InvestorPortalOfferingsControllerCalendarSortedParams = Describe<typeof investorPortalOfferingsControllerCalendarSortedPath._A & typeof investorPortalOfferingsControllerCalendarSortedQuery._A>;

export type InvestorPortalOfferingsControllerCalendarSortedRoute<A = {}> = { match: Match<InvestorPortalOfferingsControllerCalendarSortedParams & A>; pathParts: InvestorPortalOfferingsControllerCalendarSortedPathParts };

export function investorPortalOfferingsControllerCalendarSortedRoute(): InvestorPortalOfferingsControllerCalendarSortedRoute;
export function investorPortalOfferingsControllerCalendarSortedRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalOfferingsControllerCalendarSortedParams>): InvestorPortalOfferingsControllerCalendarSortedRoute<t.TypeOf<A>>;
export function investorPortalOfferingsControllerCalendarSortedRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalOfferingsControllerCalendarSortedParams>) {
  return {
    match: (q ? investorPortalOfferingsControllerCalendarSortedPath.then(query(t.intersection([investorPortalOfferingsControllerCalendarSortedQuery, q]))) : investorPortalOfferingsControllerCalendarSortedPath.then(query(investorPortalOfferingsControllerCalendarSortedQuery))).then(end),
    pathParts: investorPortalOfferingsControllerCalendarSortedPathParts,
  };
}

export const investorPortalOfferingsControllerCalendarSortedCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported6_InvestorPortalOfferingC>
} = { input: imported0_emptyObjectC, output: t.readonlyArray(imported6_investorPortalOfferingC) };

export const investorPortalOfferingsControllerCalendarSorted = (): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported6_InvestorPortalOfferingC>> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalOfferingsControllerCalendarSortedRoute().match.formatter.run(Route.empty, {}).toString(true)), investorPortalOfferingsControllerCalendarSortedCodecs);

const investorPortalOfferingsControllerBankCalendarPath = lit("v2").then(lit("investor-portal")).then(lit("offerings")).then(lit("calendar")).then(lit("bank")).then(int("bankId"));
const investorPortalOfferingsControllerBankCalendarPathParts = [
  "v2", "investor-portal", "offerings", "calendar", "bank", "bankId"
] as const;
export type InvestorPortalOfferingsControllerBankCalendarPathParts = typeof investorPortalOfferingsControllerBankCalendarPathParts;

const investorPortalOfferingsControllerBankCalendarQuery = t.strict({});
export type InvestorPortalOfferingsControllerBankCalendarParams = Describe<typeof investorPortalOfferingsControllerBankCalendarPath._A & typeof investorPortalOfferingsControllerBankCalendarQuery._A>;

export type InvestorPortalOfferingsControllerBankCalendarRoute<A = {}> = { match: Match<InvestorPortalOfferingsControllerBankCalendarParams & A>; pathParts: InvestorPortalOfferingsControllerBankCalendarPathParts };

export function investorPortalOfferingsControllerBankCalendarRoute(): InvestorPortalOfferingsControllerBankCalendarRoute;
export function investorPortalOfferingsControllerBankCalendarRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalOfferingsControllerBankCalendarParams>): InvestorPortalOfferingsControllerBankCalendarRoute<t.TypeOf<A>>;
export function investorPortalOfferingsControllerBankCalendarRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalOfferingsControllerBankCalendarParams>) {
  return {
    match: (q ? investorPortalOfferingsControllerBankCalendarPath.then(query(t.intersection([investorPortalOfferingsControllerBankCalendarQuery, q]))) : investorPortalOfferingsControllerBankCalendarPath.then(query(investorPortalOfferingsControllerBankCalendarQuery))).then(end),
    pathParts: investorPortalOfferingsControllerBankCalendarPathParts,
  };
}

export const investorPortalOfferingsControllerBankCalendarCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported7_InvestorPortalOfferingC>
} = { input: imported0_emptyObjectC, output: t.readonlyArray(imported7_investorPortalOfferingC) };

export const investorPortalOfferingsControllerBankCalendar = (p: InvestorPortalOfferingsControllerBankCalendarParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported7_InvestorPortalOfferingC>> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalOfferingsControllerBankCalendarRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalOfferingsControllerBankCalendarCodecs);

const investorPortalOfferingsControllerUpdateSubscriptionPath = lit("v2").then(lit("investor-portal")).then(lit("offerings")).then(lit("update-subscription")).then(int("offeringId")).then(type("enabled", BooleanFromString));
const investorPortalOfferingsControllerUpdateSubscriptionPathParts = [
  "v2", "investor-portal", "offerings", "update-subscription", "offeringId", "enabled"
] as const;
export type InvestorPortalOfferingsControllerUpdateSubscriptionPathParts = typeof investorPortalOfferingsControllerUpdateSubscriptionPathParts;

const investorPortalOfferingsControllerUpdateSubscriptionQuery = t.strict({});
export type InvestorPortalOfferingsControllerUpdateSubscriptionParams = Describe<typeof investorPortalOfferingsControllerUpdateSubscriptionPath._A & typeof investorPortalOfferingsControllerUpdateSubscriptionQuery._A>;

export type InvestorPortalOfferingsControllerUpdateSubscriptionRoute<A = {}> = { match: Match<InvestorPortalOfferingsControllerUpdateSubscriptionParams & A>; pathParts: InvestorPortalOfferingsControllerUpdateSubscriptionPathParts };

export function investorPortalOfferingsControllerUpdateSubscriptionRoute(): InvestorPortalOfferingsControllerUpdateSubscriptionRoute;
export function investorPortalOfferingsControllerUpdateSubscriptionRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalOfferingsControllerUpdateSubscriptionParams>): InvestorPortalOfferingsControllerUpdateSubscriptionRoute<t.TypeOf<A>>;
export function investorPortalOfferingsControllerUpdateSubscriptionRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalOfferingsControllerUpdateSubscriptionParams>) {
  return {
    match: (q ? investorPortalOfferingsControllerUpdateSubscriptionPath.then(query(t.intersection([investorPortalOfferingsControllerUpdateSubscriptionQuery, q]))) : investorPortalOfferingsControllerUpdateSubscriptionPath.then(query(investorPortalOfferingsControllerUpdateSubscriptionQuery))).then(end),
    pathParts: investorPortalOfferingsControllerUpdateSubscriptionPathParts,
  };
}

export const investorPortalOfferingsControllerUpdateSubscriptionCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_EmptyObjectC
} = { input: imported0_emptyObjectC, output: imported0_emptyObjectC };

export const investorPortalOfferingsControllerUpdateSubscription = (p: InvestorPortalOfferingsControllerUpdateSubscriptionParams): RelativeUrlInterfaceIO<"POST", imported0_EmptyObjectC, imported0_EmptyObjectC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(investorPortalOfferingsControllerUpdateSubscriptionRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalOfferingsControllerUpdateSubscriptionCodecs);

const investorPortalOfferingsControllerSubscribeRedirectPath = lit("v2").then(lit("investor-portal")).then(lit("offerings")).then(lit("subscribe-redirect")).then(int("offeringId"));
const investorPortalOfferingsControllerSubscribeRedirectPathParts = [
  "v2", "investor-portal", "offerings", "subscribe-redirect", "offeringId"
] as const;
export type InvestorPortalOfferingsControllerSubscribeRedirectPathParts = typeof investorPortalOfferingsControllerSubscribeRedirectPathParts;

const investorPortalOfferingsControllerSubscribeRedirectQuery = t.exact(t.type({
  "redirect": relativeUrlC
}));
export type InvestorPortalOfferingsControllerSubscribeRedirectParams = Describe<typeof investorPortalOfferingsControllerSubscribeRedirectPath._A & typeof investorPortalOfferingsControllerSubscribeRedirectQuery._A>;

export type InvestorPortalOfferingsControllerSubscribeRedirectRoute<A = {}> = { match: Match<InvestorPortalOfferingsControllerSubscribeRedirectParams & A>; pathParts: InvestorPortalOfferingsControllerSubscribeRedirectPathParts };

export function investorPortalOfferingsControllerSubscribeRedirectRoute(): InvestorPortalOfferingsControllerSubscribeRedirectRoute;
export function investorPortalOfferingsControllerSubscribeRedirectRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalOfferingsControllerSubscribeRedirectParams>): InvestorPortalOfferingsControllerSubscribeRedirectRoute<t.TypeOf<A>>;
export function investorPortalOfferingsControllerSubscribeRedirectRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalOfferingsControllerSubscribeRedirectParams>) {
  return {
    match: (q ? investorPortalOfferingsControllerSubscribeRedirectPath.then(query(t.intersection([investorPortalOfferingsControllerSubscribeRedirectQuery, q]))) : investorPortalOfferingsControllerSubscribeRedirectPath.then(query(investorPortalOfferingsControllerSubscribeRedirectQuery))).then(end),
    pathParts: investorPortalOfferingsControllerSubscribeRedirectPathParts,
  };
}

export const investorPortalOfferingsControllerSubscribeRedirectCodecs: {
  input: imported0_EmptyObjectC,
  output: imported22_BrowserRedirectC
} = { input: imported0_emptyObjectC, output: imported22_browserRedirectC };

export const investorPortalOfferingsControllerSubscribeRedirect = (p: InvestorPortalOfferingsControllerSubscribeRedirectParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, imported22_BrowserRedirectC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalOfferingsControllerSubscribeRedirectRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalOfferingsControllerSubscribeRedirectCodecs);

const investorPortalOfferingsControllerDownloadDocumentPath = lit("v2").then(lit("investor-portal")).then(lit("offerings")).then(lit("documents")).then(lit("download")).then(int("issuerId")).then(int("offeringId")).then(int("mediaId"));
const investorPortalOfferingsControllerDownloadDocumentPathParts = [
  "v2", "investor-portal", "offerings", "documents", "download", "issuerId", "offeringId", "mediaId"
] as const;
export type InvestorPortalOfferingsControllerDownloadDocumentPathParts = typeof investorPortalOfferingsControllerDownloadDocumentPathParts;

const investorPortalOfferingsControllerDownloadDocumentQuery = t.strict({});
export type InvestorPortalOfferingsControllerDownloadDocumentParams = Describe<typeof investorPortalOfferingsControllerDownloadDocumentPath._A & typeof investorPortalOfferingsControllerDownloadDocumentQuery._A>;

export type InvestorPortalOfferingsControllerDownloadDocumentRoute<A = {}> = { match: Match<InvestorPortalOfferingsControllerDownloadDocumentParams & A>; pathParts: InvestorPortalOfferingsControllerDownloadDocumentPathParts };

export function investorPortalOfferingsControllerDownloadDocumentRoute(): InvestorPortalOfferingsControllerDownloadDocumentRoute;
export function investorPortalOfferingsControllerDownloadDocumentRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalOfferingsControllerDownloadDocumentParams>): InvestorPortalOfferingsControllerDownloadDocumentRoute<t.TypeOf<A>>;
export function investorPortalOfferingsControllerDownloadDocumentRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalOfferingsControllerDownloadDocumentParams>) {
  return {
    match: (q ? investorPortalOfferingsControllerDownloadDocumentPath.then(query(t.intersection([investorPortalOfferingsControllerDownloadDocumentQuery, q]))) : investorPortalOfferingsControllerDownloadDocumentPath.then(query(investorPortalOfferingsControllerDownloadDocumentQuery))).then(end),
    pathParts: investorPortalOfferingsControllerDownloadDocumentPathParts,
  };
}

export const investorPortalOfferingsControllerDownloadDocumentCodecs: {
  input: imported0_EmptyObjectC,
  output: EitherC<imported23_BrowserRedirectC, imported6_ApiRedirectC>
} = { input: imported0_emptyObjectC, output: either(imported23_browserRedirectC, imported6_apiRedirectC) };

export const investorPortalOfferingsControllerDownloadDocument = (p: InvestorPortalOfferingsControllerDownloadDocumentParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, EitherC<imported23_BrowserRedirectC, imported6_ApiRedirectC>> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalOfferingsControllerDownloadDocumentRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalOfferingsControllerDownloadDocumentCodecs);

const investorPortalOfferingsControllerCalendarOptionsPath = lit("v2").then(lit("investor-portal")).then(lit("offerings")).then(lit("calendar"));
const investorPortalOfferingsControllerCalendarOptionsPathParts = [
  "v2", "investor-portal", "offerings", "calendar"
] as const;
export type InvestorPortalOfferingsControllerCalendarOptionsPathParts = typeof investorPortalOfferingsControllerCalendarOptionsPathParts;

const investorPortalOfferingsControllerCalendarOptionsQuery = t.strict({});
export type InvestorPortalOfferingsControllerCalendarOptionsParams = Describe<typeof investorPortalOfferingsControllerCalendarOptionsPath._A & typeof investorPortalOfferingsControllerCalendarOptionsQuery._A>;

export type InvestorPortalOfferingsControllerCalendarOptionsRoute<A = {}> = { match: Match<InvestorPortalOfferingsControllerCalendarOptionsParams & A>; pathParts: InvestorPortalOfferingsControllerCalendarOptionsPathParts };

export function investorPortalOfferingsControllerCalendarOptionsRoute(): InvestorPortalOfferingsControllerCalendarOptionsRoute;
export function investorPortalOfferingsControllerCalendarOptionsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalOfferingsControllerCalendarOptionsParams>): InvestorPortalOfferingsControllerCalendarOptionsRoute<t.TypeOf<A>>;
export function investorPortalOfferingsControllerCalendarOptionsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalOfferingsControllerCalendarOptionsParams>) {
  return {
    match: (q ? investorPortalOfferingsControllerCalendarOptionsPath.then(query(t.intersection([investorPortalOfferingsControllerCalendarOptionsQuery, q]))) : investorPortalOfferingsControllerCalendarOptionsPath.then(query(investorPortalOfferingsControllerCalendarOptionsQuery))).then(end),
    pathParts: investorPortalOfferingsControllerCalendarOptionsPathParts,
  };
}

export const investorPortalOfferingsControllerCalendarOptionsCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_EmptyObjectC
} = { input: imported0_emptyObjectC, output: imported0_emptyObjectC };

export const investorPortalOfferingsControllerCalendarOptions = (): RelativeUrlInterfaceIO<"OPTIONS", imported0_EmptyObjectC, imported0_EmptyObjectC> =>
  relativeUrlInterfaceIO("OPTIONS", unsafeFromRelativeUrlString(investorPortalOfferingsControllerCalendarOptionsRoute().match.formatter.run(Route.empty, {}).toString(true)), investorPortalOfferingsControllerCalendarOptionsCodecs);

const investorPortalIssuerActivityControllerAllPath = lit("v2").then(lit("investor-portal")).then(lit("issuer-activity")).then(lit("all")).then(int("days"));
const investorPortalIssuerActivityControllerAllPathParts = [
  "v2", "investor-portal", "issuer-activity", "all", "days"
] as const;
export type InvestorPortalIssuerActivityControllerAllPathParts = typeof investorPortalIssuerActivityControllerAllPathParts;

const investorPortalIssuerActivityControllerAllQuery = t.strict({});
export type InvestorPortalIssuerActivityControllerAllParams = Describe<typeof investorPortalIssuerActivityControllerAllPath._A & typeof investorPortalIssuerActivityControllerAllQuery._A>;

export type InvestorPortalIssuerActivityControllerAllRoute<A = {}> = { match: Match<InvestorPortalIssuerActivityControllerAllParams & A>; pathParts: InvestorPortalIssuerActivityControllerAllPathParts };

export function investorPortalIssuerActivityControllerAllRoute(): InvestorPortalIssuerActivityControllerAllRoute;
export function investorPortalIssuerActivityControllerAllRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalIssuerActivityControllerAllParams>): InvestorPortalIssuerActivityControllerAllRoute<t.TypeOf<A>>;
export function investorPortalIssuerActivityControllerAllRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalIssuerActivityControllerAllParams>) {
  return {
    match: (q ? investorPortalIssuerActivityControllerAllPath.then(query(t.intersection([investorPortalIssuerActivityControllerAllQuery, q]))) : investorPortalIssuerActivityControllerAllPath.then(query(investorPortalIssuerActivityControllerAllQuery))).then(end),
    pathParts: investorPortalIssuerActivityControllerAllPathParts,
  };
}

export const investorPortalIssuerActivityControllerAllCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported10_IssuerActivityTypeCU>
} = { input: imported0_emptyObjectC, output: t.readonlyArray(imported11_IssuerActivityTypeCU) };

export const investorPortalIssuerActivityControllerAll = (p: InvestorPortalIssuerActivityControllerAllParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported10_IssuerActivityTypeCU>> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalIssuerActivityControllerAllRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalIssuerActivityControllerAllCodecs);

const investorPortalIssuerActivityControllerSubscribedPath = lit("v2").then(lit("investor-portal")).then(lit("issuer-activity")).then(lit("subscribed")).then(int("days"));
const investorPortalIssuerActivityControllerSubscribedPathParts = [
  "v2", "investor-portal", "issuer-activity", "subscribed", "days"
] as const;
export type InvestorPortalIssuerActivityControllerSubscribedPathParts = typeof investorPortalIssuerActivityControllerSubscribedPathParts;

const investorPortalIssuerActivityControllerSubscribedQuery = t.strict({});
export type InvestorPortalIssuerActivityControllerSubscribedParams = Describe<typeof investorPortalIssuerActivityControllerSubscribedPath._A & typeof investorPortalIssuerActivityControllerSubscribedQuery._A>;

export type InvestorPortalIssuerActivityControllerSubscribedRoute<A = {}> = { match: Match<InvestorPortalIssuerActivityControllerSubscribedParams & A>; pathParts: InvestorPortalIssuerActivityControllerSubscribedPathParts };

export function investorPortalIssuerActivityControllerSubscribedRoute(): InvestorPortalIssuerActivityControllerSubscribedRoute;
export function investorPortalIssuerActivityControllerSubscribedRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalIssuerActivityControllerSubscribedParams>): InvestorPortalIssuerActivityControllerSubscribedRoute<t.TypeOf<A>>;
export function investorPortalIssuerActivityControllerSubscribedRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalIssuerActivityControllerSubscribedParams>) {
  return {
    match: (q ? investorPortalIssuerActivityControllerSubscribedPath.then(query(t.intersection([investorPortalIssuerActivityControllerSubscribedQuery, q]))) : investorPortalIssuerActivityControllerSubscribedPath.then(query(investorPortalIssuerActivityControllerSubscribedQuery))).then(end),
    pathParts: investorPortalIssuerActivityControllerSubscribedPathParts,
  };
}

export const investorPortalIssuerActivityControllerSubscribedCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported12_IssuerActivityTypeCU>
} = { input: imported0_emptyObjectC, output: t.readonlyArray(imported13_IssuerActivityTypeCU) };

export const investorPortalIssuerActivityControllerSubscribed = (p: InvestorPortalIssuerActivityControllerSubscribedParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported12_IssuerActivityTypeCU>> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalIssuerActivityControllerSubscribedRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalIssuerActivityControllerSubscribedCodecs);

const investorPortalMarketingEmailsControllerSubscribePath = lit("v2").then(lit("investor-portal")).then(lit("marketing-emails")).then(lit("subscribe"));
const investorPortalMarketingEmailsControllerSubscribePathParts = [
  "v2", "investor-portal", "marketing-emails", "subscribe"
] as const;
export type InvestorPortalMarketingEmailsControllerSubscribePathParts = typeof investorPortalMarketingEmailsControllerSubscribePathParts;

const investorPortalMarketingEmailsControllerSubscribeQuery = t.strict({});
export type InvestorPortalMarketingEmailsControllerSubscribeParams = Describe<typeof investorPortalMarketingEmailsControllerSubscribePath._A & typeof investorPortalMarketingEmailsControllerSubscribeQuery._A>;

export type InvestorPortalMarketingEmailsControllerSubscribeRoute<A = {}> = { match: Match<InvestorPortalMarketingEmailsControllerSubscribeParams & A>; pathParts: InvestorPortalMarketingEmailsControllerSubscribePathParts };

export function investorPortalMarketingEmailsControllerSubscribeRoute(): InvestorPortalMarketingEmailsControllerSubscribeRoute;
export function investorPortalMarketingEmailsControllerSubscribeRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalMarketingEmailsControllerSubscribeParams>): InvestorPortalMarketingEmailsControllerSubscribeRoute<t.TypeOf<A>>;
export function investorPortalMarketingEmailsControllerSubscribeRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalMarketingEmailsControllerSubscribeParams>) {
  return {
    match: (q ? investorPortalMarketingEmailsControllerSubscribePath.then(query(t.intersection([investorPortalMarketingEmailsControllerSubscribeQuery, q]))) : investorPortalMarketingEmailsControllerSubscribePath.then(query(investorPortalMarketingEmailsControllerSubscribeQuery))).then(end),
    pathParts: investorPortalMarketingEmailsControllerSubscribePathParts,
  };
}

export const investorPortalMarketingEmailsControllerSubscribeCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_EmptyObjectC
} = { input: imported0_emptyObjectC, output: imported0_emptyObjectC };

export const investorPortalMarketingEmailsControllerSubscribe = (): RelativeUrlInterfaceIO<"POST", imported0_EmptyObjectC, imported0_EmptyObjectC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(investorPortalMarketingEmailsControllerSubscribeRoute().match.formatter.run(Route.empty, {}).toString(true)), investorPortalMarketingEmailsControllerSubscribeCodecs);

const investorPortalMarketingEmailsControllerUnsubscribePath = lit("v2").then(lit("investor-portal")).then(lit("marketing-emails")).then(lit("unsubscribe"));
const investorPortalMarketingEmailsControllerUnsubscribePathParts = [
  "v2", "investor-portal", "marketing-emails", "unsubscribe"
] as const;
export type InvestorPortalMarketingEmailsControllerUnsubscribePathParts = typeof investorPortalMarketingEmailsControllerUnsubscribePathParts;

const investorPortalMarketingEmailsControllerUnsubscribeQuery = t.strict({});
export type InvestorPortalMarketingEmailsControllerUnsubscribeParams = Describe<typeof investorPortalMarketingEmailsControllerUnsubscribePath._A & typeof investorPortalMarketingEmailsControllerUnsubscribeQuery._A>;

export type InvestorPortalMarketingEmailsControllerUnsubscribeRoute<A = {}> = { match: Match<InvestorPortalMarketingEmailsControllerUnsubscribeParams & A>; pathParts: InvestorPortalMarketingEmailsControllerUnsubscribePathParts };

export function investorPortalMarketingEmailsControllerUnsubscribeRoute(): InvestorPortalMarketingEmailsControllerUnsubscribeRoute;
export function investorPortalMarketingEmailsControllerUnsubscribeRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalMarketingEmailsControllerUnsubscribeParams>): InvestorPortalMarketingEmailsControllerUnsubscribeRoute<t.TypeOf<A>>;
export function investorPortalMarketingEmailsControllerUnsubscribeRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalMarketingEmailsControllerUnsubscribeParams>) {
  return {
    match: (q ? investorPortalMarketingEmailsControllerUnsubscribePath.then(query(t.intersection([investorPortalMarketingEmailsControllerUnsubscribeQuery, q]))) : investorPortalMarketingEmailsControllerUnsubscribePath.then(query(investorPortalMarketingEmailsControllerUnsubscribeQuery))).then(end),
    pathParts: investorPortalMarketingEmailsControllerUnsubscribePathParts,
  };
}

export const investorPortalMarketingEmailsControllerUnsubscribeCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_EmptyObjectC
} = { input: imported0_emptyObjectC, output: imported0_emptyObjectC };

export const investorPortalMarketingEmailsControllerUnsubscribe = (): RelativeUrlInterfaceIO<"POST", imported0_EmptyObjectC, imported0_EmptyObjectC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(investorPortalMarketingEmailsControllerUnsubscribeRoute().match.formatter.run(Route.empty, {}).toString(true)), investorPortalMarketingEmailsControllerUnsubscribeCodecs);

const investorPortalNotificationSubscriptionsControllerGetSubscribedStatusPath = lit("v2").then(lit("investor-portal")).then(lit("notification-subscriptions")).then(lit("status"));
const investorPortalNotificationSubscriptionsControllerGetSubscribedStatusPathParts = [
  "v2", "investor-portal", "notification-subscriptions", "status"
] as const;
export type InvestorPortalNotificationSubscriptionsControllerGetSubscribedStatusPathParts = typeof investorPortalNotificationSubscriptionsControllerGetSubscribedStatusPathParts;

const investorPortalNotificationSubscriptionsControllerGetSubscribedStatusQuery = t.strict({});
export type InvestorPortalNotificationSubscriptionsControllerGetSubscribedStatusParams = Describe<typeof investorPortalNotificationSubscriptionsControllerGetSubscribedStatusPath._A & typeof investorPortalNotificationSubscriptionsControllerGetSubscribedStatusQuery._A>;

export type InvestorPortalNotificationSubscriptionsControllerGetSubscribedStatusRoute<A = {}> = { match: Match<InvestorPortalNotificationSubscriptionsControllerGetSubscribedStatusParams & A>; pathParts: InvestorPortalNotificationSubscriptionsControllerGetSubscribedStatusPathParts };

export function investorPortalNotificationSubscriptionsControllerGetSubscribedStatusRoute(): InvestorPortalNotificationSubscriptionsControllerGetSubscribedStatusRoute;
export function investorPortalNotificationSubscriptionsControllerGetSubscribedStatusRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalNotificationSubscriptionsControllerGetSubscribedStatusParams>): InvestorPortalNotificationSubscriptionsControllerGetSubscribedStatusRoute<t.TypeOf<A>>;
export function investorPortalNotificationSubscriptionsControllerGetSubscribedStatusRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalNotificationSubscriptionsControllerGetSubscribedStatusParams>) {
  return {
    match: (q ? investorPortalNotificationSubscriptionsControllerGetSubscribedStatusPath.then(query(t.intersection([investorPortalNotificationSubscriptionsControllerGetSubscribedStatusQuery, q]))) : investorPortalNotificationSubscriptionsControllerGetSubscribedStatusPath.then(query(investorPortalNotificationSubscriptionsControllerGetSubscribedStatusQuery))).then(end),
    pathParts: investorPortalNotificationSubscriptionsControllerGetSubscribedStatusPathParts,
  };
}

export const investorPortalNotificationSubscriptionsControllerGetSubscribedStatusCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_NotificationSubscriptionsStatusC
} = { input: imported0_emptyObjectC, output: imported0_notificationSubscriptionsStatusC };

export const investorPortalNotificationSubscriptionsControllerGetSubscribedStatus = (): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, imported0_NotificationSubscriptionsStatusC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalNotificationSubscriptionsControllerGetSubscribedStatusRoute().match.formatter.run(Route.empty, {}).toString(true)), investorPortalNotificationSubscriptionsControllerGetSubscribedStatusCodecs);

const investorPortalNotificationSubscriptionsControllerUpdateEnabledPath = lit("v2").then(lit("investor-portal")).then(lit("notification-subscriptions")).then(lit("update-enabled")).then(type("enabled", BooleanFromString));
const investorPortalNotificationSubscriptionsControllerUpdateEnabledPathParts = [
  "v2", "investor-portal", "notification-subscriptions", "update-enabled", "enabled"
] as const;
export type InvestorPortalNotificationSubscriptionsControllerUpdateEnabledPathParts = typeof investorPortalNotificationSubscriptionsControllerUpdateEnabledPathParts;

const investorPortalNotificationSubscriptionsControllerUpdateEnabledQuery = t.strict({});
export type InvestorPortalNotificationSubscriptionsControllerUpdateEnabledParams = Describe<typeof investorPortalNotificationSubscriptionsControllerUpdateEnabledPath._A & typeof investorPortalNotificationSubscriptionsControllerUpdateEnabledQuery._A>;

export type InvestorPortalNotificationSubscriptionsControllerUpdateEnabledRoute<A = {}> = { match: Match<InvestorPortalNotificationSubscriptionsControllerUpdateEnabledParams & A>; pathParts: InvestorPortalNotificationSubscriptionsControllerUpdateEnabledPathParts };

export function investorPortalNotificationSubscriptionsControllerUpdateEnabledRoute(): InvestorPortalNotificationSubscriptionsControllerUpdateEnabledRoute;
export function investorPortalNotificationSubscriptionsControllerUpdateEnabledRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalNotificationSubscriptionsControllerUpdateEnabledParams>): InvestorPortalNotificationSubscriptionsControllerUpdateEnabledRoute<t.TypeOf<A>>;
export function investorPortalNotificationSubscriptionsControllerUpdateEnabledRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalNotificationSubscriptionsControllerUpdateEnabledParams>) {
  return {
    match: (q ? investorPortalNotificationSubscriptionsControllerUpdateEnabledPath.then(query(t.intersection([investorPortalNotificationSubscriptionsControllerUpdateEnabledQuery, q]))) : investorPortalNotificationSubscriptionsControllerUpdateEnabledPath.then(query(investorPortalNotificationSubscriptionsControllerUpdateEnabledQuery))).then(end),
    pathParts: investorPortalNotificationSubscriptionsControllerUpdateEnabledPathParts,
  };
}

export const investorPortalNotificationSubscriptionsControllerUpdateEnabledCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_EmptyObjectC
} = { input: imported0_emptyObjectC, output: imported0_emptyObjectC };

export const investorPortalNotificationSubscriptionsControllerUpdateEnabled = (p: InvestorPortalNotificationSubscriptionsControllerUpdateEnabledParams): RelativeUrlInterfaceIO<"POST", imported0_EmptyObjectC, imported0_EmptyObjectC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(investorPortalNotificationSubscriptionsControllerUpdateEnabledRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalNotificationSubscriptionsControllerUpdateEnabledCodecs);

const investorPortalNotificationSubscriptionsControllerUpdateContentSubscriptionsPath = lit("v2").then(lit("investor-portal")).then(lit("notification-subscriptions")).then(lit("update-content-subscriptions"));
const investorPortalNotificationSubscriptionsControllerUpdateContentSubscriptionsPathParts = [
  "v2", "investor-portal", "notification-subscriptions", "update-content-subscriptions"
] as const;
export type InvestorPortalNotificationSubscriptionsControllerUpdateContentSubscriptionsPathParts = typeof investorPortalNotificationSubscriptionsControllerUpdateContentSubscriptionsPathParts;

const investorPortalNotificationSubscriptionsControllerUpdateContentSubscriptionsQuery = t.strict({});
export type InvestorPortalNotificationSubscriptionsControllerUpdateContentSubscriptionsParams = Describe<typeof investorPortalNotificationSubscriptionsControllerUpdateContentSubscriptionsPath._A & typeof investorPortalNotificationSubscriptionsControllerUpdateContentSubscriptionsQuery._A>;

export type InvestorPortalNotificationSubscriptionsControllerUpdateContentSubscriptionsRoute<A = {}> = { match: Match<InvestorPortalNotificationSubscriptionsControllerUpdateContentSubscriptionsParams & A>; pathParts: InvestorPortalNotificationSubscriptionsControllerUpdateContentSubscriptionsPathParts };

export function investorPortalNotificationSubscriptionsControllerUpdateContentSubscriptionsRoute(): InvestorPortalNotificationSubscriptionsControllerUpdateContentSubscriptionsRoute;
export function investorPortalNotificationSubscriptionsControllerUpdateContentSubscriptionsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalNotificationSubscriptionsControllerUpdateContentSubscriptionsParams>): InvestorPortalNotificationSubscriptionsControllerUpdateContentSubscriptionsRoute<t.TypeOf<A>>;
export function investorPortalNotificationSubscriptionsControllerUpdateContentSubscriptionsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalNotificationSubscriptionsControllerUpdateContentSubscriptionsParams>) {
  return {
    match: (q ? investorPortalNotificationSubscriptionsControllerUpdateContentSubscriptionsPath.then(query(t.intersection([investorPortalNotificationSubscriptionsControllerUpdateContentSubscriptionsQuery, q]))) : investorPortalNotificationSubscriptionsControllerUpdateContentSubscriptionsPath.then(query(investorPortalNotificationSubscriptionsControllerUpdateContentSubscriptionsQuery))).then(end),
    pathParts: investorPortalNotificationSubscriptionsControllerUpdateContentSubscriptionsPathParts,
  };
}

export const investorPortalNotificationSubscriptionsControllerUpdateContentSubscriptionsCodecs: {
  input: imported0_UserContentSubscriptionsC,
  output: imported0_EmptyObjectC
} = { input: imported0_userContentSubscriptionsC, output: imported0_emptyObjectC };

export const investorPortalNotificationSubscriptionsControllerUpdateContentSubscriptions = (): RelativeUrlInterfaceIO<"POST", imported0_UserContentSubscriptionsC, imported0_EmptyObjectC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(investorPortalNotificationSubscriptionsControllerUpdateContentSubscriptionsRoute().match.formatter.run(Route.empty, {}).toString(true)), investorPortalNotificationSubscriptionsControllerUpdateContentSubscriptionsCodecs);

const investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionPath = lit("v2").then(lit("investor-portal")).then(lit("notification-subscriptions")).then(lit("update-issuer-subscription")).then(int("issuerId")).then(type("enabled", BooleanFromString));
const investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionPathParts = [
  "v2", "investor-portal", "notification-subscriptions", "update-issuer-subscription", "issuerId", "enabled"
] as const;
export type InvestorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionPathParts = typeof investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionPathParts;

const investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionQuery = t.strict({});
export type InvestorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionParams = Describe<typeof investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionPath._A & typeof investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionQuery._A>;

export type InvestorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionRoute<A = {}> = { match: Match<InvestorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionParams & A>; pathParts: InvestorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionPathParts };

export function investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionRoute(): InvestorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionRoute;
export function investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionParams>): InvestorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionRoute<t.TypeOf<A>>;
export function investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionParams>) {
  return {
    match: (q ? investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionPath.then(query(t.intersection([investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionQuery, q]))) : investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionPath.then(query(investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionQuery))).then(end),
    pathParts: investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionPathParts,
  };
}

export const investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_EmptyObjectC
} = { input: imported0_emptyObjectC, output: imported0_emptyObjectC };

export const investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscription = (p: InvestorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionParams): RelativeUrlInterfaceIO<"POST", imported0_EmptyObjectC, imported0_EmptyObjectC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionCodecs);

const investorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionPath = lit("v2").then(lit("investor-portal")).then(lit("notification-subscriptions")).then(lit("update-sector-subscription")).then(type("sectorId", t.union([
  literalNumberFromString(1), literalNumberFromString(2), literalNumberFromString(3), literalNumberFromString(4), literalNumberFromString(5), literalNumberFromString(6), literalNumberFromString(7), literalNumberFromString(8), literalNumberFromString(9), literalNumberFromString(10), literalNumberFromString(11), literalNumberFromString(12), literalNumberFromString(13), literalNumberFromString(14), literalNumberFromString(15), literalNumberFromString(16), literalNumberFromString(17), literalNumberFromString(18), literalNumberFromString(19), literalNumberFromString(20), literalNumberFromString(21)
]))).then(type("enabled", BooleanFromString));
const investorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionPathParts = [
  "v2", "investor-portal", "notification-subscriptions", "update-sector-subscription", "sectorId", "enabled"
] as const;
export type InvestorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionPathParts = typeof investorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionPathParts;

const investorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionQuery = t.strict({});
export type InvestorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionParams = Describe<typeof investorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionPath._A & typeof investorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionQuery._A>;

export type InvestorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionRoute<A = {}> = { match: Match<InvestorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionParams & A>; pathParts: InvestorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionPathParts };

export function investorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionRoute(): InvestorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionRoute;
export function investorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionParams>): InvestorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionRoute<t.TypeOf<A>>;
export function investorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionParams>) {
  return {
    match: (q ? investorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionPath.then(query(t.intersection([investorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionQuery, q]))) : investorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionPath.then(query(investorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionQuery))).then(end),
    pathParts: investorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionPathParts,
  };
}

export const investorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_EmptyObjectC
} = { input: imported0_emptyObjectC, output: imported0_emptyObjectC };

export const investorPortalNotificationSubscriptionsControllerUpdateSectorSubscription = (p: InvestorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionParams): RelativeUrlInterfaceIO<"POST", imported0_EmptyObjectC, imported0_EmptyObjectC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(investorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalNotificationSubscriptionsControllerUpdateSectorSubscriptionCodecs);

const investorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionPath = lit("v2").then(lit("investor-portal")).then(lit("notification-subscriptions")).then(lit("update-state-subscription")).then(type("stateId", t.union([
  literalNumberFromString(1), literalNumberFromString(2), literalNumberFromString(3), literalNumberFromString(4), literalNumberFromString(5), literalNumberFromString(6), literalNumberFromString(7), literalNumberFromString(8), literalNumberFromString(9), literalNumberFromString(10), literalNumberFromString(11), literalNumberFromString(12), literalNumberFromString(13), literalNumberFromString(14), literalNumberFromString(15), literalNumberFromString(16), literalNumberFromString(17), literalNumberFromString(18), literalNumberFromString(19), literalNumberFromString(20), literalNumberFromString(21), literalNumberFromString(22), literalNumberFromString(23), literalNumberFromString(24), literalNumberFromString(25), literalNumberFromString(26), literalNumberFromString(27), literalNumberFromString(28), literalNumberFromString(29), literalNumberFromString(30), literalNumberFromString(31), literalNumberFromString(32), literalNumberFromString(33), literalNumberFromString(34), literalNumberFromString(35), literalNumberFromString(36), literalNumberFromString(37), literalNumberFromString(38), literalNumberFromString(39), literalNumberFromString(40), literalNumberFromString(41), literalNumberFromString(42), literalNumberFromString(43), literalNumberFromString(44), literalNumberFromString(45), literalNumberFromString(46), literalNumberFromString(47), literalNumberFromString(48), literalNumberFromString(49), literalNumberFromString(50), literalNumberFromString(51), literalNumberFromString(52), literalNumberFromString(53), literalNumberFromString(54), literalNumberFromString(55), literalNumberFromString(56), literalNumberFromString(57), literalNumberFromString(58), literalNumberFromString(59), literalNumberFromString(60), literalNumberFromString(61), literalNumberFromString(62), literalNumberFromString(63), literalNumberFromString(64), literalNumberFromString(65), literalNumberFromString(66), literalNumberFromString(67)
]))).then(type("enabled", BooleanFromString));
const investorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionPathParts = [
  "v2", "investor-portal", "notification-subscriptions", "update-state-subscription", "stateId", "enabled"
] as const;
export type InvestorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionPathParts = typeof investorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionPathParts;

const investorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionQuery = t.strict({});
export type InvestorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionParams = Describe<typeof investorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionPath._A & typeof investorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionQuery._A>;

export type InvestorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionRoute<A = {}> = { match: Match<InvestorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionParams & A>; pathParts: InvestorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionPathParts };

export function investorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionRoute(): InvestorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionRoute;
export function investorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionParams>): InvestorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionRoute<t.TypeOf<A>>;
export function investorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionParams>) {
  return {
    match: (q ? investorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionPath.then(query(t.intersection([investorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionQuery, q]))) : investorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionPath.then(query(investorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionQuery))).then(end),
    pathParts: investorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionPathParts,
  };
}

export const investorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_EmptyObjectC
} = { input: imported0_emptyObjectC, output: imported0_emptyObjectC };

export const investorPortalNotificationSubscriptionsControllerUpdateStateSubscription = (p: InvestorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionParams): RelativeUrlInterfaceIO<"POST", imported0_EmptyObjectC, imported0_EmptyObjectC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(investorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalNotificationSubscriptionsControllerUpdateStateSubscriptionCodecs);

const investorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectPath = lit("v2").then(lit("investor-portal")).then(lit("notification-subscriptions")).then(lit("enable-user-issuer-subscription-redirect")).then(int("issuerId"));
const investorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectPathParts = [
  "v2", "investor-portal", "notification-subscriptions", "enable-user-issuer-subscription-redirect", "issuerId"
] as const;
export type InvestorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectPathParts = typeof investorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectPathParts;

const investorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectQuery = t.exact(t.type({
  "redirect": relativeUrlC
}));
export type InvestorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectParams = Describe<typeof investorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectPath._A & typeof investorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectQuery._A>;

export type InvestorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectRoute<A = {}> = { match: Match<InvestorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectParams & A>; pathParts: InvestorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectPathParts };

export function investorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectRoute(): InvestorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectRoute;
export function investorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectParams>): InvestorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectRoute<t.TypeOf<A>>;
export function investorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectParams>) {
  return {
    match: (q ? investorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectPath.then(query(t.intersection([investorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectQuery, q]))) : investorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectPath.then(query(investorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectQuery))).then(end),
    pathParts: investorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectPathParts,
  };
}

export const investorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectCodecs: {
  input: imported0_EmptyObjectC,
  output: imported24_BrowserRedirectC
} = { input: imported0_emptyObjectC, output: imported24_browserRedirectC };

export const investorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirect = (p: InvestorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectParams): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, imported24_BrowserRedirectC> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectRoute().match.formatter.run(Route.empty, p).toString(true)), investorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirectCodecs);

const investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionsPath = lit("v2").then(lit("investor-portal")).then(lit("notification-subscriptions")).then(lit("update-issuer-subscriptions"));
const investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionsPathParts = [
  "v2", "investor-portal", "notification-subscriptions", "update-issuer-subscriptions"
] as const;
export type InvestorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionsPathParts = typeof investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionsPathParts;

const investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionsQuery = t.strict({});
export type InvestorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionsParams = Describe<typeof investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionsPath._A & typeof investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionsQuery._A>;

export type InvestorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionsRoute<A = {}> = { match: Match<InvestorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionsParams & A>; pathParts: InvestorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionsPathParts };

export function investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionsRoute(): InvestorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionsRoute;
export function investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionsParams>): InvestorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionsRoute<t.TypeOf<A>>;
export function investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionsParams>) {
  return {
    match: (q ? investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionsPath.then(query(t.intersection([investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionsQuery, q]))) : investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionsPath.then(query(investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionsQuery))).then(end),
    pathParts: investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionsPathParts,
  };
}

export const investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionsCodecs: {
  input: imported0_UserIssuerSubscriptionsPostC,
  output: imported0_EmptyObjectC
} = { input: imported0_userIssuerSubscriptionsPostC, output: imported0_emptyObjectC };

export const investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptions = (): RelativeUrlInterfaceIO<"POST", imported0_UserIssuerSubscriptionsPostC, imported0_EmptyObjectC> =>
  relativeUrlInterfaceIO("POST", unsafeFromRelativeUrlString(investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionsRoute().match.formatter.run(Route.empty, {}).toString(true)), investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionsCodecs);

const investorPortalWatchlistControllerGetIssuersPath = lit("v2").then(lit("investor-portal")).then(lit("watchlist")).then(lit("issuers"));
const investorPortalWatchlistControllerGetIssuersPathParts = ["v2", "investor-portal", "watchlist", "issuers"] as const;
export type InvestorPortalWatchlistControllerGetIssuersPathParts = typeof investorPortalWatchlistControllerGetIssuersPathParts;

const investorPortalWatchlistControllerGetIssuersQuery = t.strict({});
export type InvestorPortalWatchlistControllerGetIssuersParams = Describe<typeof investorPortalWatchlistControllerGetIssuersPath._A & typeof investorPortalWatchlistControllerGetIssuersQuery._A>;

export type InvestorPortalWatchlistControllerGetIssuersRoute<A = {}> = { match: Match<InvestorPortalWatchlistControllerGetIssuersParams & A>; pathParts: InvestorPortalWatchlistControllerGetIssuersPathParts };

export function investorPortalWatchlistControllerGetIssuersRoute(): InvestorPortalWatchlistControllerGetIssuersRoute;
export function investorPortalWatchlistControllerGetIssuersRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalWatchlistControllerGetIssuersParams>): InvestorPortalWatchlistControllerGetIssuersRoute<t.TypeOf<A>>;
export function investorPortalWatchlistControllerGetIssuersRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalWatchlistControllerGetIssuersParams>) {
  return {
    match: (q ? investorPortalWatchlistControllerGetIssuersPath.then(query(t.intersection([investorPortalWatchlistControllerGetIssuersQuery, q]))) : investorPortalWatchlistControllerGetIssuersPath.then(query(investorPortalWatchlistControllerGetIssuersQuery))).then(end),
    pathParts: investorPortalWatchlistControllerGetIssuersPathParts,
  };
}

export const investorPortalWatchlistControllerGetIssuersCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported0_WatchlistIssuerC>
} = { input: imported0_emptyObjectC, output: t.readonlyArray(imported0_watchlistIssuerC) };

export const investorPortalWatchlistControllerGetIssuers = (): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported0_WatchlistIssuerC>> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalWatchlistControllerGetIssuersRoute().match.formatter.run(Route.empty, {}).toString(true)), investorPortalWatchlistControllerGetIssuersCodecs);

const investorPortalWatchlistControllerGetOfferingsPath = lit("v2").then(lit("investor-portal")).then(lit("watchlist")).then(lit("offerings"));
const investorPortalWatchlistControllerGetOfferingsPathParts = [
  "v2", "investor-portal", "watchlist", "offerings"
] as const;
export type InvestorPortalWatchlistControllerGetOfferingsPathParts = typeof investorPortalWatchlistControllerGetOfferingsPathParts;

const investorPortalWatchlistControllerGetOfferingsQuery = t.strict({});
export type InvestorPortalWatchlistControllerGetOfferingsParams = Describe<typeof investorPortalWatchlistControllerGetOfferingsPath._A & typeof investorPortalWatchlistControllerGetOfferingsQuery._A>;

export type InvestorPortalWatchlistControllerGetOfferingsRoute<A = {}> = { match: Match<InvestorPortalWatchlistControllerGetOfferingsParams & A>; pathParts: InvestorPortalWatchlistControllerGetOfferingsPathParts };

export function investorPortalWatchlistControllerGetOfferingsRoute(): InvestorPortalWatchlistControllerGetOfferingsRoute;
export function investorPortalWatchlistControllerGetOfferingsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalWatchlistControllerGetOfferingsParams>): InvestorPortalWatchlistControllerGetOfferingsRoute<t.TypeOf<A>>;
export function investorPortalWatchlistControllerGetOfferingsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalWatchlistControllerGetOfferingsParams>) {
  return {
    match: (q ? investorPortalWatchlistControllerGetOfferingsPath.then(query(t.intersection([investorPortalWatchlistControllerGetOfferingsQuery, q]))) : investorPortalWatchlistControllerGetOfferingsPath.then(query(investorPortalWatchlistControllerGetOfferingsQuery))).then(end),
    pathParts: investorPortalWatchlistControllerGetOfferingsPathParts,
  };
}

export const investorPortalWatchlistControllerGetOfferingsCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported8_InvestorPortalOfferingC>
} = { input: imported0_emptyObjectC, output: t.readonlyArray(imported8_investorPortalOfferingC) };

export const investorPortalWatchlistControllerGetOfferings = (): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported8_InvestorPortalOfferingC>> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalWatchlistControllerGetOfferingsRoute().match.formatter.run(Route.empty, {}).toString(true)), investorPortalWatchlistControllerGetOfferingsCodecs);

const investorPortalWatchlistControllerGetRfpsPath = lit("v2").then(lit("investor-portal")).then(lit("watchlist")).then(lit("rfps"));
const investorPortalWatchlistControllerGetRfpsPathParts = ["v2", "investor-portal", "watchlist", "rfps"] as const;
export type InvestorPortalWatchlistControllerGetRfpsPathParts = typeof investorPortalWatchlistControllerGetRfpsPathParts;

const investorPortalWatchlistControllerGetRfpsQuery = t.strict({});
export type InvestorPortalWatchlistControllerGetRfpsParams = Describe<typeof investorPortalWatchlistControllerGetRfpsPath._A & typeof investorPortalWatchlistControllerGetRfpsQuery._A>;

export type InvestorPortalWatchlistControllerGetRfpsRoute<A = {}> = { match: Match<InvestorPortalWatchlistControllerGetRfpsParams & A>; pathParts: InvestorPortalWatchlistControllerGetRfpsPathParts };

export function investorPortalWatchlistControllerGetRfpsRoute(): InvestorPortalWatchlistControllerGetRfpsRoute;
export function investorPortalWatchlistControllerGetRfpsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorPortalWatchlistControllerGetRfpsParams>): InvestorPortalWatchlistControllerGetRfpsRoute<t.TypeOf<A>>;
export function investorPortalWatchlistControllerGetRfpsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorPortalWatchlistControllerGetRfpsParams>) {
  return {
    match: (q ? investorPortalWatchlistControllerGetRfpsPath.then(query(t.intersection([investorPortalWatchlistControllerGetRfpsQuery, q]))) : investorPortalWatchlistControllerGetRfpsPath.then(query(investorPortalWatchlistControllerGetRfpsQuery))).then(end),
    pathParts: investorPortalWatchlistControllerGetRfpsPathParts,
  };
}

export const investorPortalWatchlistControllerGetRfpsCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported3_InvestorPortalRfpC>
} = { input: imported0_emptyObjectC, output: t.readonlyArray(imported3_investorPortalRfpC) };

export const investorPortalWatchlistControllerGetRfps = (): RelativeUrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported3_InvestorPortalRfpC>> =>
  relativeUrlInterfaceIO("GET", unsafeFromRelativeUrlString(investorPortalWatchlistControllerGetRfpsRoute().match.formatter.run(Route.empty, {}).toString(true)), investorPortalWatchlistControllerGetRfpsCodecs);