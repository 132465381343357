import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const ET = {
  _tag: `ET`,
  id: 1,
  name: `ET`
} as const;

export type ETTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ET`>
}>;
export const ETTaggedC: ETTaggedC = t.type({
  _tag: t.literal(`ET`)
});
export type ETTagged = t.TypeOf<ETTaggedC>;
export type ET = ETTagged & typeof ET;
export type ETC = t.Type<ET, ETTagged>;
export const ETC: ETC = pipe(ETTaggedC, c => new t.Type<ET, ETTagged>(
  `ET`,
  (u: unknown): u is ET => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ET> => pipe(c.decode(u), E.map(x => ({ ...x, ...ET }))),
  (x: ET): ETTagged => ({ ...x, _tag: `ET`}),
)) satisfies t.Type<ET, unknown>;


export const CT = {
  _tag: `CT`,
  id: 2,
  name: `CT`
} as const;

export type CTTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CT`>
}>;
export const CTTaggedC: CTTaggedC = t.type({
  _tag: t.literal(`CT`)
});
export type CTTagged = t.TypeOf<CTTaggedC>;
export type CT = CTTagged & typeof CT;
export type CTC = t.Type<CT, CTTagged>;
export const CTC: CTC = pipe(CTTaggedC, c => new t.Type<CT, CTTagged>(
  `CT`,
  (u: unknown): u is CT => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CT> => pipe(c.decode(u), E.map(x => ({ ...x, ...CT }))),
  (x: CT): CTTagged => ({ ...x, _tag: `CT`}),
)) satisfies t.Type<CT, unknown>;


export const MT = {
  _tag: `MT`,
  id: 3,
  name: `MT`
} as const;

export type MTTaggedC = t.TypeC<{
  _tag: t.LiteralC<`MT`>
}>;
export const MTTaggedC: MTTaggedC = t.type({
  _tag: t.literal(`MT`)
});
export type MTTagged = t.TypeOf<MTTaggedC>;
export type MT = MTTagged & typeof MT;
export type MTC = t.Type<MT, MTTagged>;
export const MTC: MTC = pipe(MTTaggedC, c => new t.Type<MT, MTTagged>(
  `MT`,
  (u: unknown): u is MT => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MT> => pipe(c.decode(u), E.map(x => ({ ...x, ...MT }))),
  (x: MT): MTTagged => ({ ...x, _tag: `MT`}),
)) satisfies t.Type<MT, unknown>;


export const PT = {
  _tag: `PT`,
  id: 4,
  name: `PT`
} as const;

export type PTTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PT`>
}>;
export const PTTaggedC: PTTaggedC = t.type({
  _tag: t.literal(`PT`)
});
export type PTTagged = t.TypeOf<PTTaggedC>;
export type PT = PTTagged & typeof PT;
export type PTC = t.Type<PT, PTTagged>;
export const PTC: PTC = pipe(PTTaggedC, c => new t.Type<PT, PTTagged>(
  `PT`,
  (u: unknown): u is PT => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PT> => pipe(c.decode(u), E.map(x => ({ ...x, ...PT }))),
  (x: PT): PTTagged => ({ ...x, _tag: `PT`}),
)) satisfies t.Type<PT, unknown>;


export const AKT = {
  _tag: `AKT`,
  id: 5,
  name: `AKT`
} as const;

export type AKTTaggedC = t.TypeC<{
  _tag: t.LiteralC<`AKT`>
}>;
export const AKTTaggedC: AKTTaggedC = t.type({
  _tag: t.literal(`AKT`)
});
export type AKTTagged = t.TypeOf<AKTTaggedC>;
export type AKT = AKTTagged & typeof AKT;
export type AKTC = t.Type<AKT, AKTTagged>;
export const AKTC: AKTC = pipe(AKTTaggedC, c => new t.Type<AKT, AKTTagged>(
  `AKT`,
  (u: unknown): u is AKT => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AKT> => pipe(c.decode(u), E.map(x => ({ ...x, ...AKT }))),
  (x: AKT): AKTTagged => ({ ...x, _tag: `AKT`}),
)) satisfies t.Type<AKT, unknown>;


export const HT = {
  _tag: `HT`,
  id: 6,
  name: `HT`
} as const;

export type HTTaggedC = t.TypeC<{
  _tag: t.LiteralC<`HT`>
}>;
export const HTTaggedC: HTTaggedC = t.type({
  _tag: t.literal(`HT`)
});
export type HTTagged = t.TypeOf<HTTaggedC>;
export type HT = HTTagged & typeof HT;
export type HTC = t.Type<HT, HTTagged>;
export const HTC: HTC = pipe(HTTaggedC, c => new t.Type<HT, HTTagged>(
  `HT`,
  (u: unknown): u is HT => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, HT> => pipe(c.decode(u), E.map(x => ({ ...x, ...HT }))),
  (x: HT): HTTagged => ({ ...x, _tag: `HT`}),
)) satisfies t.Type<HT, unknown>;


export const AT = {
  _tag: `AT`,
  id: 7,
  name: `AT`
} as const;

export type ATTaggedC = t.TypeC<{
  _tag: t.LiteralC<`AT`>
}>;
export const ATTaggedC: ATTaggedC = t.type({
  _tag: t.literal(`AT`)
});
export type ATTagged = t.TypeOf<ATTaggedC>;
export type AT = ATTagged & typeof AT;
export type ATC = t.Type<AT, ATTagged>;
export const ATC: ATC = pipe(ATTaggedC, c => new t.Type<AT, ATTagged>(
  `AT`,
  (u: unknown): u is AT => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AT> => pipe(c.decode(u), E.map(x => ({ ...x, ...AT }))),
  (x: AT): ATTagged => ({ ...x, _tag: `AT`}),
)) satisfies t.Type<AT, unknown>;


export const allTimeZoneC = [ETC, CTC, MTC, PTC, AKTC, HTC, ATC] as const;
export const allTimeZoneNames = [`ET`, `CT`, `MT`, `PT`, `AKT`, `HT`, `AT`] as const;
export type TimeZoneName = (typeof allTimeZoneNames)[number];

export type TimeZoneCU = t.UnionC<[ETC, CTC, MTC, PTC, AKTC, HTC, ATC]>;
export type TimeZoneU = ET | CT | MT | PT | AKT | HT | AT;
export const TimeZoneCU: TimeZoneCU = t.union([ETC, CTC, MTC, PTC, AKTC, HTC, ATC]) satisfies t.Type<TimeZoneU, unknown>;

export const timeZoneOrd: Ord.Ord<TimeZoneU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allTimeZone = [ET, CT, MT, PT, AKT, HT, AT] as const;
export type TimeZoneMap<A> = { [K in TimeZoneName]: A };


