export * from "fp-ts/lib/ReadonlyArray";

import { flow } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as RA from "fp-ts/lib/ReadonlyArray";

import { prop } from "@scripts/util/prop";

import type { IntRangeExcl } from "./Array";
import { firstN as arrayFirstN } from "./Array";
import { refinementFor } from "./lib/_internal";
import type { Match } from "./lib/types";

/**
 * `pluck` maps over an array and "plucks" a property off of each element.
 */

export const pluck: <K extends string>(key: K) => <A extends Match.AnyStruct>(
  xs: ReadonlyArray<A>
) => ReadonlyArray<A[K]> = flow(prop, RA.map);

/** @category refinements */
export const is = refinementFor.readonlyArray;

type ExtraReadonlyArrayFields<A> = Omit<ReadonlyArray<A>, number | "length">;

export type ReadonlyArrayMaxN<A, N extends number> = { [i in IntRangeExcl<0, N>]?: A } & {
  length: IntRangeExcl<0, N> | N;
} & ExtraReadonlyArrayFields<A>;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const mapMaxN = RA.map as unknown as <A, B, N extends number>(fn: (item: A) => B) => (arr: ReadonlyArrayMaxN<A, N>) => ReadonlyArrayMaxN<B, N>;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const mapWithIndexMaxN = RA.mapWithIndex as unknown as <A, B, N extends number>(fn: (idx: IntRangeExcl<0, N>, item: A) => B) => (arr: ReadonlyArrayMaxN<A, N>) => ReadonlyArrayMaxN<B, N>;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const reduceWithIndexMaxN = RA.reduceWithIndex as unknown as <A, B, N extends number>(b: B, f: (idx: IntRangeExcl<0, N>, b: B, a: A) => B) => (fa: ReadonlyArrayMaxN<A, N>) => B;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const filterMapMaxN = RA.filterMap as unknown as <A, B, N extends number>(fn: (item: A) => O.Option<B>) => (arr: ReadonlyArrayMaxN<A, N>) => ReadonlyArrayMaxN<B, N>;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const firstN = arrayFirstN as unknown as <N extends number>(n: N) => <A>(arr: ReadonlyArray<A>) => ReadonlyArrayMaxN<A, N>;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const maxNToRA = <A, N extends number>(a: ReadonlyArrayMaxN<A, N>) => a as ReadonlyArray<A>;

export const appendPreserveType: <A>(end: A) => (array: ReadonlyArray<A>) => ReadonlyArray<A> = RA.append;

export const sum = RA.reduce(0, (total, value: number) => total + value);

export const findFirstMapWithIndex = <A, B>(f: (i: number, a: A) => O.Option<B>) => (as: ReadonlyArray<A>): O.Option<B> => {
  let i = 0;
  for (const a of as) {
    const out = f(i, a);
    if (O.isSome(out)) {
      return out;
    }
    i++;
  }
  return O.none;
};
