import { allSensitiveField } from "./generated/domaintables/sensitiveFields";
import { emToPx } from "./syntax/math";
import { transitionBaseMs } from "./ui/transitions";

export type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl" | "ul";
type BreakpointSizes = {
  [key in Breakpoint]: number;
};
type MatchMedia = {
  [key in Breakpoint]: string;
};

type ZIndexes = "belowAll" | "base" | "icon" | "contextMenu" | "componentLoader" | "dropdown" | "header" | "navMenuBackground" | "naMenu" | "navBondLinkBar" | "draftsPill" | "filtersBackground" | "filtersContainer" | "filters" | "modalBackground" | "modal" | "disclaimerModalBackground" | "disclaimerModal" | "tooltip" | "pageLoader" | "globalAlerts" | "aboveAll";

interface BondlinkConfigStatic {
  readonly baseTransitionDelay: number;
  readonly bondlinkUrl: string;
  readonly breakpoints: BreakpointSizes;
  readonly matchMedia: MatchMedia;
  readonly zindexes: { [key in ZIndexes]: number; };
  readonly issuerHelpUrl: string;
  readonly sensitiveFields: string[];
}

const makeBlConfigStatic = (breakpointSizes: BreakpointSizes): BondlinkConfigStatic => ({
  baseTransitionDelay: transitionBaseMs,
  bondlinkUrl: "https://www.bondlink.com",
  breakpoints: {
    xs: emToPx(breakpointSizes.xs),
    sm: emToPx(breakpointSizes.sm),
    md: emToPx(breakpointSizes.md),
    lg: emToPx(breakpointSizes.lg),
    xl: emToPx(breakpointSizes.xl),
    ul: emToPx(breakpointSizes.ul),
  },
  matchMedia: {
    xs: `(min-width: ${breakpointSizes.xs}em)`,
    sm: `(min-width: ${breakpointSizes.sm}em)`,
    md: `(min-width: ${breakpointSizes.md}em)`,
    lg: `(min-width: ${breakpointSizes.lg}em)`,
    xl: `(min-width: ${breakpointSizes.xl}em)`,
    ul: `(min-width: ${breakpointSizes.ul}em)`,
  },
  zindexes: {
    belowAll: - 1,
    base: 1,
    icon: 5,
    contextMenu: 100,
    componentLoader: 500,
    dropdown: 1000,
    header: 1010,
    navMenuBackground: 1018,
    naMenu: 1020,
    navBondLinkBar: 1022,
    draftsPill: 1025,
    filtersBackground: 1027,
    filtersContainer: 1028,
    filters: 1029,
    modalBackground: 1030,
    modal: 1040,
    disclaimerModalBackground: 1060,
    disclaimerModal: 1070,
    tooltip: 2000,
    pageLoader: 3000,
    globalAlerts: 4000,
    aboveAll: 9999,
  },
  issuerHelpUrl: "https://learn.bondlink.com/help/",
  sensitiveFields: allSensitiveField.map(f => f.field),
});

// Sizes are in rems
export const Static: BondlinkConfigStatic = makeBlConfigStatic({
  xs: 0,
  sm: 36,
  md: 48,
  lg: 62,
  xl: 75,
  ul: 92,
});
