import { Route, query, lit, end, Match } from "fp-ts-routing/lib";
import * as t from "io-ts";

import { NoKeyOverlapC } from "../../codecs/noKeyOverlap";
import { unsafeFromRelativeUrlString } from "../../codecs/relativeUrl";
import { Describe } from "../../fp-ts/lib/types/describe";
import { RelativeUrlInterface, relativeUrlInterface } from "../../routes/urlInterface";

const assetImgDealPortalDashboardHeaderJpgPath = lit("assets").then(lit("img")).then(lit("deal-portal-dashboard-header.jpg"));
const assetImgDealPortalDashboardHeaderJpgPathParts = ["assets", "img", "deal-portal-dashboard-header.jpg"] as const;
export type AssetImgDealPortalDashboardHeaderJpgPathParts = typeof assetImgDealPortalDashboardHeaderJpgPathParts;

const assetImgDealPortalDashboardHeaderJpgQuery = t.strict({});
export type AssetImgDealPortalDashboardHeaderJpgParams = Describe<typeof assetImgDealPortalDashboardHeaderJpgPath._A & typeof assetImgDealPortalDashboardHeaderJpgQuery._A>;

export type AssetImgDealPortalDashboardHeaderJpgRoute<A = {}> = { match: Match<AssetImgDealPortalDashboardHeaderJpgParams & A>; pathParts: AssetImgDealPortalDashboardHeaderJpgPathParts };

export function assetImgDealPortalDashboardHeaderJpgRoute(): AssetImgDealPortalDashboardHeaderJpgRoute;
export function assetImgDealPortalDashboardHeaderJpgRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgDealPortalDashboardHeaderJpgParams>): AssetImgDealPortalDashboardHeaderJpgRoute<t.TypeOf<A>>;
export function assetImgDealPortalDashboardHeaderJpgRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgDealPortalDashboardHeaderJpgParams>) {
  return {
    match: (q ? assetImgDealPortalDashboardHeaderJpgPath.then(query(t.intersection([assetImgDealPortalDashboardHeaderJpgQuery, q]))) : assetImgDealPortalDashboardHeaderJpgPath.then(query(assetImgDealPortalDashboardHeaderJpgQuery))).then(end),
    pathParts: assetImgDealPortalDashboardHeaderJpgPathParts,
  };
}

export const assetImgDealPortalDashboardHeaderJpg = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgDealPortalDashboardHeaderJpgRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgEmailBondlinkEmailLogoBannerPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("bondlink-email-logo-banner.png"));
const assetImgEmailBondlinkEmailLogoBannerPngPathParts = [
  "assets", "img", "email", "bondlink-email-logo-banner.png"
] as const;
export type AssetImgEmailBondlinkEmailLogoBannerPngPathParts = typeof assetImgEmailBondlinkEmailLogoBannerPngPathParts;

const assetImgEmailBondlinkEmailLogoBannerPngQuery = t.strict({});
export type AssetImgEmailBondlinkEmailLogoBannerPngParams = Describe<typeof assetImgEmailBondlinkEmailLogoBannerPngPath._A & typeof assetImgEmailBondlinkEmailLogoBannerPngQuery._A>;

export type AssetImgEmailBondlinkEmailLogoBannerPngRoute<A = {}> = { match: Match<AssetImgEmailBondlinkEmailLogoBannerPngParams & A>; pathParts: AssetImgEmailBondlinkEmailLogoBannerPngPathParts };

export function assetImgEmailBondlinkEmailLogoBannerPngRoute(): AssetImgEmailBondlinkEmailLogoBannerPngRoute;
export function assetImgEmailBondlinkEmailLogoBannerPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailBondlinkEmailLogoBannerPngParams>): AssetImgEmailBondlinkEmailLogoBannerPngRoute<t.TypeOf<A>>;
export function assetImgEmailBondlinkEmailLogoBannerPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailBondlinkEmailLogoBannerPngParams>) {
  return {
    match: (q ? assetImgEmailBondlinkEmailLogoBannerPngPath.then(query(t.intersection([assetImgEmailBondlinkEmailLogoBannerPngQuery, q]))) : assetImgEmailBondlinkEmailLogoBannerPngPath.then(query(assetImgEmailBondlinkEmailLogoBannerPngQuery))).then(end),
    pathParts: assetImgEmailBondlinkEmailLogoBannerPngPathParts,
  };
}

export const assetImgEmailBondlinkEmailLogoBannerPng = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgEmailBondlinkEmailLogoBannerPngRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgEmailBondlinkEmailDividerPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("bondlink-email-divider.png"));
const assetImgEmailBondlinkEmailDividerPngPathParts = ["assets", "img", "email", "bondlink-email-divider.png"] as const;
export type AssetImgEmailBondlinkEmailDividerPngPathParts = typeof assetImgEmailBondlinkEmailDividerPngPathParts;

const assetImgEmailBondlinkEmailDividerPngQuery = t.strict({});
export type AssetImgEmailBondlinkEmailDividerPngParams = Describe<typeof assetImgEmailBondlinkEmailDividerPngPath._A & typeof assetImgEmailBondlinkEmailDividerPngQuery._A>;

export type AssetImgEmailBondlinkEmailDividerPngRoute<A = {}> = { match: Match<AssetImgEmailBondlinkEmailDividerPngParams & A>; pathParts: AssetImgEmailBondlinkEmailDividerPngPathParts };

export function assetImgEmailBondlinkEmailDividerPngRoute(): AssetImgEmailBondlinkEmailDividerPngRoute;
export function assetImgEmailBondlinkEmailDividerPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailBondlinkEmailDividerPngParams>): AssetImgEmailBondlinkEmailDividerPngRoute<t.TypeOf<A>>;
export function assetImgEmailBondlinkEmailDividerPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailBondlinkEmailDividerPngParams>) {
  return {
    match: (q ? assetImgEmailBondlinkEmailDividerPngPath.then(query(t.intersection([assetImgEmailBondlinkEmailDividerPngQuery, q]))) : assetImgEmailBondlinkEmailDividerPngPath.then(query(assetImgEmailBondlinkEmailDividerPngQuery))).then(end),
    pathParts: assetImgEmailBondlinkEmailDividerPngPathParts,
  };
}

export const assetImgEmailBondlinkEmailDividerPng = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgEmailBondlinkEmailDividerPngRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgEmailIconBondPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-bond.png"));
const assetImgEmailIconBondPngPathParts = ["assets", "img", "email", "icon-bond.png"] as const;
export type AssetImgEmailIconBondPngPathParts = typeof assetImgEmailIconBondPngPathParts;

const assetImgEmailIconBondPngQuery = t.strict({});
export type AssetImgEmailIconBondPngParams = Describe<typeof assetImgEmailIconBondPngPath._A & typeof assetImgEmailIconBondPngQuery._A>;

export type AssetImgEmailIconBondPngRoute<A = {}> = { match: Match<AssetImgEmailIconBondPngParams & A>; pathParts: AssetImgEmailIconBondPngPathParts };

export function assetImgEmailIconBondPngRoute(): AssetImgEmailIconBondPngRoute;
export function assetImgEmailIconBondPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconBondPngParams>): AssetImgEmailIconBondPngRoute<t.TypeOf<A>>;
export function assetImgEmailIconBondPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconBondPngParams>) {
  return {
    match: (q ? assetImgEmailIconBondPngPath.then(query(t.intersection([assetImgEmailIconBondPngQuery, q]))) : assetImgEmailIconBondPngPath.then(query(assetImgEmailIconBondPngQuery))).then(end),
    pathParts: assetImgEmailIconBondPngPathParts,
  };
}

export const assetImgEmailIconBondPng = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgEmailIconBondPngRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgEmailIconBullhornPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-bullhorn.png"));
const assetImgEmailIconBullhornPngPathParts = ["assets", "img", "email", "icon-bullhorn.png"] as const;
export type AssetImgEmailIconBullhornPngPathParts = typeof assetImgEmailIconBullhornPngPathParts;

const assetImgEmailIconBullhornPngQuery = t.strict({});
export type AssetImgEmailIconBullhornPngParams = Describe<typeof assetImgEmailIconBullhornPngPath._A & typeof assetImgEmailIconBullhornPngQuery._A>;

export type AssetImgEmailIconBullhornPngRoute<A = {}> = { match: Match<AssetImgEmailIconBullhornPngParams & A>; pathParts: AssetImgEmailIconBullhornPngPathParts };

export function assetImgEmailIconBullhornPngRoute(): AssetImgEmailIconBullhornPngRoute;
export function assetImgEmailIconBullhornPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconBullhornPngParams>): AssetImgEmailIconBullhornPngRoute<t.TypeOf<A>>;
export function assetImgEmailIconBullhornPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconBullhornPngParams>) {
  return {
    match: (q ? assetImgEmailIconBullhornPngPath.then(query(t.intersection([assetImgEmailIconBullhornPngQuery, q]))) : assetImgEmailIconBullhornPngPath.then(query(assetImgEmailIconBullhornPngQuery))).then(end),
    pathParts: assetImgEmailIconBullhornPngPathParts,
  };
}

export const assetImgEmailIconBullhornPng = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgEmailIconBullhornPngRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgEmailIconCalendarPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-calendar.png"));
const assetImgEmailIconCalendarPngPathParts = ["assets", "img", "email", "icon-calendar.png"] as const;
export type AssetImgEmailIconCalendarPngPathParts = typeof assetImgEmailIconCalendarPngPathParts;

const assetImgEmailIconCalendarPngQuery = t.strict({});
export type AssetImgEmailIconCalendarPngParams = Describe<typeof assetImgEmailIconCalendarPngPath._A & typeof assetImgEmailIconCalendarPngQuery._A>;

export type AssetImgEmailIconCalendarPngRoute<A = {}> = { match: Match<AssetImgEmailIconCalendarPngParams & A>; pathParts: AssetImgEmailIconCalendarPngPathParts };

export function assetImgEmailIconCalendarPngRoute(): AssetImgEmailIconCalendarPngRoute;
export function assetImgEmailIconCalendarPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconCalendarPngParams>): AssetImgEmailIconCalendarPngRoute<t.TypeOf<A>>;
export function assetImgEmailIconCalendarPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconCalendarPngParams>) {
  return {
    match: (q ? assetImgEmailIconCalendarPngPath.then(query(t.intersection([assetImgEmailIconCalendarPngQuery, q]))) : assetImgEmailIconCalendarPngPath.then(query(assetImgEmailIconCalendarPngQuery))).then(end),
    pathParts: assetImgEmailIconCalendarPngPathParts,
  };
}

export const assetImgEmailIconCalendarPng = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgEmailIconCalendarPngRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgEmailIconChartDocumentPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-chart-document.png"));
const assetImgEmailIconChartDocumentPngPathParts = ["assets", "img", "email", "icon-chart-document.png"] as const;
export type AssetImgEmailIconChartDocumentPngPathParts = typeof assetImgEmailIconChartDocumentPngPathParts;

const assetImgEmailIconChartDocumentPngQuery = t.strict({});
export type AssetImgEmailIconChartDocumentPngParams = Describe<typeof assetImgEmailIconChartDocumentPngPath._A & typeof assetImgEmailIconChartDocumentPngQuery._A>;

export type AssetImgEmailIconChartDocumentPngRoute<A = {}> = { match: Match<AssetImgEmailIconChartDocumentPngParams & A>; pathParts: AssetImgEmailIconChartDocumentPngPathParts };

export function assetImgEmailIconChartDocumentPngRoute(): AssetImgEmailIconChartDocumentPngRoute;
export function assetImgEmailIconChartDocumentPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconChartDocumentPngParams>): AssetImgEmailIconChartDocumentPngRoute<t.TypeOf<A>>;
export function assetImgEmailIconChartDocumentPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconChartDocumentPngParams>) {
  return {
    match: (q ? assetImgEmailIconChartDocumentPngPath.then(query(t.intersection([assetImgEmailIconChartDocumentPngQuery, q]))) : assetImgEmailIconChartDocumentPngPath.then(query(assetImgEmailIconChartDocumentPngQuery))).then(end),
    pathParts: assetImgEmailIconChartDocumentPngPathParts,
  };
}

export const assetImgEmailIconChartDocumentPng = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgEmailIconChartDocumentPngRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgEmailIconConstructionPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-construction.png"));
const assetImgEmailIconConstructionPngPathParts = ["assets", "img", "email", "icon-construction.png"] as const;
export type AssetImgEmailIconConstructionPngPathParts = typeof assetImgEmailIconConstructionPngPathParts;

const assetImgEmailIconConstructionPngQuery = t.strict({});
export type AssetImgEmailIconConstructionPngParams = Describe<typeof assetImgEmailIconConstructionPngPath._A & typeof assetImgEmailIconConstructionPngQuery._A>;

export type AssetImgEmailIconConstructionPngRoute<A = {}> = { match: Match<AssetImgEmailIconConstructionPngParams & A>; pathParts: AssetImgEmailIconConstructionPngPathParts };

export function assetImgEmailIconConstructionPngRoute(): AssetImgEmailIconConstructionPngRoute;
export function assetImgEmailIconConstructionPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconConstructionPngParams>): AssetImgEmailIconConstructionPngRoute<t.TypeOf<A>>;
export function assetImgEmailIconConstructionPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconConstructionPngParams>) {
  return {
    match: (q ? assetImgEmailIconConstructionPngPath.then(query(t.intersection([assetImgEmailIconConstructionPngQuery, q]))) : assetImgEmailIconConstructionPngPath.then(query(assetImgEmailIconConstructionPngQuery))).then(end),
    pathParts: assetImgEmailIconConstructionPngPathParts,
  };
}

export const assetImgEmailIconConstructionPng = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgEmailIconConstructionPngRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgEmailIconDocumentPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-document.png"));
const assetImgEmailIconDocumentPngPathParts = ["assets", "img", "email", "icon-document.png"] as const;
export type AssetImgEmailIconDocumentPngPathParts = typeof assetImgEmailIconDocumentPngPathParts;

const assetImgEmailIconDocumentPngQuery = t.strict({});
export type AssetImgEmailIconDocumentPngParams = Describe<typeof assetImgEmailIconDocumentPngPath._A & typeof assetImgEmailIconDocumentPngQuery._A>;

export type AssetImgEmailIconDocumentPngRoute<A = {}> = { match: Match<AssetImgEmailIconDocumentPngParams & A>; pathParts: AssetImgEmailIconDocumentPngPathParts };

export function assetImgEmailIconDocumentPngRoute(): AssetImgEmailIconDocumentPngRoute;
export function assetImgEmailIconDocumentPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconDocumentPngParams>): AssetImgEmailIconDocumentPngRoute<t.TypeOf<A>>;
export function assetImgEmailIconDocumentPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconDocumentPngParams>) {
  return {
    match: (q ? assetImgEmailIconDocumentPngPath.then(query(t.intersection([assetImgEmailIconDocumentPngQuery, q]))) : assetImgEmailIconDocumentPngPath.then(query(assetImgEmailIconDocumentPngQuery))).then(end),
    pathParts: assetImgEmailIconDocumentPngPathParts,
  };
}

export const assetImgEmailIconDocumentPng = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgEmailIconDocumentPngRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgEmailIconGovernmentDocumentPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-government-document.png"));
const assetImgEmailIconGovernmentDocumentPngPathParts = [
  "assets", "img", "email", "icon-government-document.png"
] as const;
export type AssetImgEmailIconGovernmentDocumentPngPathParts = typeof assetImgEmailIconGovernmentDocumentPngPathParts;

const assetImgEmailIconGovernmentDocumentPngQuery = t.strict({});
export type AssetImgEmailIconGovernmentDocumentPngParams = Describe<typeof assetImgEmailIconGovernmentDocumentPngPath._A & typeof assetImgEmailIconGovernmentDocumentPngQuery._A>;

export type AssetImgEmailIconGovernmentDocumentPngRoute<A = {}> = { match: Match<AssetImgEmailIconGovernmentDocumentPngParams & A>; pathParts: AssetImgEmailIconGovernmentDocumentPngPathParts };

export function assetImgEmailIconGovernmentDocumentPngRoute(): AssetImgEmailIconGovernmentDocumentPngRoute;
export function assetImgEmailIconGovernmentDocumentPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconGovernmentDocumentPngParams>): AssetImgEmailIconGovernmentDocumentPngRoute<t.TypeOf<A>>;
export function assetImgEmailIconGovernmentDocumentPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconGovernmentDocumentPngParams>) {
  return {
    match: (q ? assetImgEmailIconGovernmentDocumentPngPath.then(query(t.intersection([assetImgEmailIconGovernmentDocumentPngQuery, q]))) : assetImgEmailIconGovernmentDocumentPngPath.then(query(assetImgEmailIconGovernmentDocumentPngQuery))).then(end),
    pathParts: assetImgEmailIconGovernmentDocumentPngPathParts,
  };
}

export const assetImgEmailIconGovernmentDocumentPng = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgEmailIconGovernmentDocumentPngRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgEmailIconGraphPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-graph.png"));
const assetImgEmailIconGraphPngPathParts = ["assets", "img", "email", "icon-graph.png"] as const;
export type AssetImgEmailIconGraphPngPathParts = typeof assetImgEmailIconGraphPngPathParts;

const assetImgEmailIconGraphPngQuery = t.strict({});
export type AssetImgEmailIconGraphPngParams = Describe<typeof assetImgEmailIconGraphPngPath._A & typeof assetImgEmailIconGraphPngQuery._A>;

export type AssetImgEmailIconGraphPngRoute<A = {}> = { match: Match<AssetImgEmailIconGraphPngParams & A>; pathParts: AssetImgEmailIconGraphPngPathParts };

export function assetImgEmailIconGraphPngRoute(): AssetImgEmailIconGraphPngRoute;
export function assetImgEmailIconGraphPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconGraphPngParams>): AssetImgEmailIconGraphPngRoute<t.TypeOf<A>>;
export function assetImgEmailIconGraphPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconGraphPngParams>) {
  return {
    match: (q ? assetImgEmailIconGraphPngPath.then(query(t.intersection([assetImgEmailIconGraphPngQuery, q]))) : assetImgEmailIconGraphPngPath.then(query(assetImgEmailIconGraphPngQuery))).then(end),
    pathParts: assetImgEmailIconGraphPngPathParts,
  };
}

export const assetImgEmailIconGraphPng = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgEmailIconGraphPngRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgEmailIconImagePngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-image.png"));
const assetImgEmailIconImagePngPathParts = ["assets", "img", "email", "icon-image.png"] as const;
export type AssetImgEmailIconImagePngPathParts = typeof assetImgEmailIconImagePngPathParts;

const assetImgEmailIconImagePngQuery = t.strict({});
export type AssetImgEmailIconImagePngParams = Describe<typeof assetImgEmailIconImagePngPath._A & typeof assetImgEmailIconImagePngQuery._A>;

export type AssetImgEmailIconImagePngRoute<A = {}> = { match: Match<AssetImgEmailIconImagePngParams & A>; pathParts: AssetImgEmailIconImagePngPathParts };

export function assetImgEmailIconImagePngRoute(): AssetImgEmailIconImagePngRoute;
export function assetImgEmailIconImagePngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconImagePngParams>): AssetImgEmailIconImagePngRoute<t.TypeOf<A>>;
export function assetImgEmailIconImagePngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconImagePngParams>) {
  return {
    match: (q ? assetImgEmailIconImagePngPath.then(query(t.intersection([assetImgEmailIconImagePngQuery, q]))) : assetImgEmailIconImagePngPath.then(query(assetImgEmailIconImagePngQuery))).then(end),
    pathParts: assetImgEmailIconImagePngPathParts,
  };
}

export const assetImgEmailIconImagePng = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgEmailIconImagePngRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgEmailIconIssuerPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-issuer.png"));
const assetImgEmailIconIssuerPngPathParts = ["assets", "img", "email", "icon-issuer.png"] as const;
export type AssetImgEmailIconIssuerPngPathParts = typeof assetImgEmailIconIssuerPngPathParts;

const assetImgEmailIconIssuerPngQuery = t.strict({});
export type AssetImgEmailIconIssuerPngParams = Describe<typeof assetImgEmailIconIssuerPngPath._A & typeof assetImgEmailIconIssuerPngQuery._A>;

export type AssetImgEmailIconIssuerPngRoute<A = {}> = { match: Match<AssetImgEmailIconIssuerPngParams & A>; pathParts: AssetImgEmailIconIssuerPngPathParts };

export function assetImgEmailIconIssuerPngRoute(): AssetImgEmailIconIssuerPngRoute;
export function assetImgEmailIconIssuerPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconIssuerPngParams>): AssetImgEmailIconIssuerPngRoute<t.TypeOf<A>>;
export function assetImgEmailIconIssuerPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconIssuerPngParams>) {
  return {
    match: (q ? assetImgEmailIconIssuerPngPath.then(query(t.intersection([assetImgEmailIconIssuerPngQuery, q]))) : assetImgEmailIconIssuerPngPath.then(query(assetImgEmailIconIssuerPngQuery))).then(end),
    pathParts: assetImgEmailIconIssuerPngPathParts,
  };
}

export const assetImgEmailIconIssuerPng = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgEmailIconIssuerPngRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgEmailIconLinkPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-link.png"));
const assetImgEmailIconLinkPngPathParts = ["assets", "img", "email", "icon-link.png"] as const;
export type AssetImgEmailIconLinkPngPathParts = typeof assetImgEmailIconLinkPngPathParts;

const assetImgEmailIconLinkPngQuery = t.strict({});
export type AssetImgEmailIconLinkPngParams = Describe<typeof assetImgEmailIconLinkPngPath._A & typeof assetImgEmailIconLinkPngQuery._A>;

export type AssetImgEmailIconLinkPngRoute<A = {}> = { match: Match<AssetImgEmailIconLinkPngParams & A>; pathParts: AssetImgEmailIconLinkPngPathParts };

export function assetImgEmailIconLinkPngRoute(): AssetImgEmailIconLinkPngRoute;
export function assetImgEmailIconLinkPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconLinkPngParams>): AssetImgEmailIconLinkPngRoute<t.TypeOf<A>>;
export function assetImgEmailIconLinkPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconLinkPngParams>) {
  return {
    match: (q ? assetImgEmailIconLinkPngPath.then(query(t.intersection([assetImgEmailIconLinkPngQuery, q]))) : assetImgEmailIconLinkPngPath.then(query(assetImgEmailIconLinkPngQuery))).then(end),
    pathParts: assetImgEmailIconLinkPngPathParts,
  };
}

export const assetImgEmailIconLinkPng = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgEmailIconLinkPngRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgEmailIconNewspaperPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-newspaper.png"));
const assetImgEmailIconNewspaperPngPathParts = ["assets", "img", "email", "icon-newspaper.png"] as const;
export type AssetImgEmailIconNewspaperPngPathParts = typeof assetImgEmailIconNewspaperPngPathParts;

const assetImgEmailIconNewspaperPngQuery = t.strict({});
export type AssetImgEmailIconNewspaperPngParams = Describe<typeof assetImgEmailIconNewspaperPngPath._A & typeof assetImgEmailIconNewspaperPngQuery._A>;

export type AssetImgEmailIconNewspaperPngRoute<A = {}> = { match: Match<AssetImgEmailIconNewspaperPngParams & A>; pathParts: AssetImgEmailIconNewspaperPngPathParts };

export function assetImgEmailIconNewspaperPngRoute(): AssetImgEmailIconNewspaperPngRoute;
export function assetImgEmailIconNewspaperPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconNewspaperPngParams>): AssetImgEmailIconNewspaperPngRoute<t.TypeOf<A>>;
export function assetImgEmailIconNewspaperPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconNewspaperPngParams>) {
  return {
    match: (q ? assetImgEmailIconNewspaperPngPath.then(query(t.intersection([assetImgEmailIconNewspaperPngQuery, q]))) : assetImgEmailIconNewspaperPngPath.then(query(assetImgEmailIconNewspaperPngQuery))).then(end),
    pathParts: assetImgEmailIconNewspaperPngPathParts,
  };
}

export const assetImgEmailIconNewspaperPng = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgEmailIconNewspaperPngRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgEmailIconPersonPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-person.png"));
const assetImgEmailIconPersonPngPathParts = ["assets", "img", "email", "icon-person.png"] as const;
export type AssetImgEmailIconPersonPngPathParts = typeof assetImgEmailIconPersonPngPathParts;

const assetImgEmailIconPersonPngQuery = t.strict({});
export type AssetImgEmailIconPersonPngParams = Describe<typeof assetImgEmailIconPersonPngPath._A & typeof assetImgEmailIconPersonPngQuery._A>;

export type AssetImgEmailIconPersonPngRoute<A = {}> = { match: Match<AssetImgEmailIconPersonPngParams & A>; pathParts: AssetImgEmailIconPersonPngPathParts };

export function assetImgEmailIconPersonPngRoute(): AssetImgEmailIconPersonPngRoute;
export function assetImgEmailIconPersonPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconPersonPngParams>): AssetImgEmailIconPersonPngRoute<t.TypeOf<A>>;
export function assetImgEmailIconPersonPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconPersonPngParams>) {
  return {
    match: (q ? assetImgEmailIconPersonPngPath.then(query(t.intersection([assetImgEmailIconPersonPngQuery, q]))) : assetImgEmailIconPersonPngPath.then(query(assetImgEmailIconPersonPngQuery))).then(end),
    pathParts: assetImgEmailIconPersonPngPathParts,
  };
}

export const assetImgEmailIconPersonPng = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgEmailIconPersonPngRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgEmailIconProgramTreePngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-program-tree.png"));
const assetImgEmailIconProgramTreePngPathParts = ["assets", "img", "email", "icon-program-tree.png"] as const;
export type AssetImgEmailIconProgramTreePngPathParts = typeof assetImgEmailIconProgramTreePngPathParts;

const assetImgEmailIconProgramTreePngQuery = t.strict({});
export type AssetImgEmailIconProgramTreePngParams = Describe<typeof assetImgEmailIconProgramTreePngPath._A & typeof assetImgEmailIconProgramTreePngQuery._A>;

export type AssetImgEmailIconProgramTreePngRoute<A = {}> = { match: Match<AssetImgEmailIconProgramTreePngParams & A>; pathParts: AssetImgEmailIconProgramTreePngPathParts };

export function assetImgEmailIconProgramTreePngRoute(): AssetImgEmailIconProgramTreePngRoute;
export function assetImgEmailIconProgramTreePngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconProgramTreePngParams>): AssetImgEmailIconProgramTreePngRoute<t.TypeOf<A>>;
export function assetImgEmailIconProgramTreePngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconProgramTreePngParams>) {
  return {
    match: (q ? assetImgEmailIconProgramTreePngPath.then(query(t.intersection([assetImgEmailIconProgramTreePngQuery, q]))) : assetImgEmailIconProgramTreePngPath.then(query(assetImgEmailIconProgramTreePngQuery))).then(end),
    pathParts: assetImgEmailIconProgramTreePngPathParts,
  };
}

export const assetImgEmailIconProgramTreePng = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgEmailIconProgramTreePngRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgEmailIconQuestionAndAnswerPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-question-and-answer.png"));
const assetImgEmailIconQuestionAndAnswerPngPathParts = [
  "assets", "img", "email", "icon-question-and-answer.png"
] as const;
export type AssetImgEmailIconQuestionAndAnswerPngPathParts = typeof assetImgEmailIconQuestionAndAnswerPngPathParts;

const assetImgEmailIconQuestionAndAnswerPngQuery = t.strict({});
export type AssetImgEmailIconQuestionAndAnswerPngParams = Describe<typeof assetImgEmailIconQuestionAndAnswerPngPath._A & typeof assetImgEmailIconQuestionAndAnswerPngQuery._A>;

export type AssetImgEmailIconQuestionAndAnswerPngRoute<A = {}> = { match: Match<AssetImgEmailIconQuestionAndAnswerPngParams & A>; pathParts: AssetImgEmailIconQuestionAndAnswerPngPathParts };

export function assetImgEmailIconQuestionAndAnswerPngRoute(): AssetImgEmailIconQuestionAndAnswerPngRoute;
export function assetImgEmailIconQuestionAndAnswerPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconQuestionAndAnswerPngParams>): AssetImgEmailIconQuestionAndAnswerPngRoute<t.TypeOf<A>>;
export function assetImgEmailIconQuestionAndAnswerPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconQuestionAndAnswerPngParams>) {
  return {
    match: (q ? assetImgEmailIconQuestionAndAnswerPngPath.then(query(t.intersection([assetImgEmailIconQuestionAndAnswerPngQuery, q]))) : assetImgEmailIconQuestionAndAnswerPngPath.then(query(assetImgEmailIconQuestionAndAnswerPngQuery))).then(end),
    pathParts: assetImgEmailIconQuestionAndAnswerPngPathParts,
  };
}

export const assetImgEmailIconQuestionAndAnswerPng = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgEmailIconQuestionAndAnswerPngRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgEmailIconRequestPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-request.png"));
const assetImgEmailIconRequestPngPathParts = ["assets", "img", "email", "icon-request.png"] as const;
export type AssetImgEmailIconRequestPngPathParts = typeof assetImgEmailIconRequestPngPathParts;

const assetImgEmailIconRequestPngQuery = t.strict({});
export type AssetImgEmailIconRequestPngParams = Describe<typeof assetImgEmailIconRequestPngPath._A & typeof assetImgEmailIconRequestPngQuery._A>;

export type AssetImgEmailIconRequestPngRoute<A = {}> = { match: Match<AssetImgEmailIconRequestPngParams & A>; pathParts: AssetImgEmailIconRequestPngPathParts };

export function assetImgEmailIconRequestPngRoute(): AssetImgEmailIconRequestPngRoute;
export function assetImgEmailIconRequestPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconRequestPngParams>): AssetImgEmailIconRequestPngRoute<t.TypeOf<A>>;
export function assetImgEmailIconRequestPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconRequestPngParams>) {
  return {
    match: (q ? assetImgEmailIconRequestPngPath.then(query(t.intersection([assetImgEmailIconRequestPngQuery, q]))) : assetImgEmailIconRequestPngPath.then(query(assetImgEmailIconRequestPngQuery))).then(end),
    pathParts: assetImgEmailIconRequestPngPathParts,
  };
}

export const assetImgEmailIconRequestPng = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgEmailIconRequestPngRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgEmailIconRoadshowPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-roadshow.png"));
const assetImgEmailIconRoadshowPngPathParts = ["assets", "img", "email", "icon-roadshow.png"] as const;
export type AssetImgEmailIconRoadshowPngPathParts = typeof assetImgEmailIconRoadshowPngPathParts;

const assetImgEmailIconRoadshowPngQuery = t.strict({});
export type AssetImgEmailIconRoadshowPngParams = Describe<typeof assetImgEmailIconRoadshowPngPath._A & typeof assetImgEmailIconRoadshowPngQuery._A>;

export type AssetImgEmailIconRoadshowPngRoute<A = {}> = { match: Match<AssetImgEmailIconRoadshowPngParams & A>; pathParts: AssetImgEmailIconRoadshowPngPathParts };

export function assetImgEmailIconRoadshowPngRoute(): AssetImgEmailIconRoadshowPngRoute;
export function assetImgEmailIconRoadshowPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconRoadshowPngParams>): AssetImgEmailIconRoadshowPngRoute<t.TypeOf<A>>;
export function assetImgEmailIconRoadshowPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconRoadshowPngParams>) {
  return {
    match: (q ? assetImgEmailIconRoadshowPngPath.then(query(t.intersection([assetImgEmailIconRoadshowPngQuery, q]))) : assetImgEmailIconRoadshowPngPath.then(query(assetImgEmailIconRoadshowPngQuery))).then(end),
    pathParts: assetImgEmailIconRoadshowPngPathParts,
  };
}

export const assetImgEmailIconRoadshowPng = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgEmailIconRoadshowPngRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgEmailIconSocialTwitterPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-social-twitter.png"));
const assetImgEmailIconSocialTwitterPngPathParts = ["assets", "img", "email", "icon-social-twitter.png"] as const;
export type AssetImgEmailIconSocialTwitterPngPathParts = typeof assetImgEmailIconSocialTwitterPngPathParts;

const assetImgEmailIconSocialTwitterPngQuery = t.strict({});
export type AssetImgEmailIconSocialTwitterPngParams = Describe<typeof assetImgEmailIconSocialTwitterPngPath._A & typeof assetImgEmailIconSocialTwitterPngQuery._A>;

export type AssetImgEmailIconSocialTwitterPngRoute<A = {}> = { match: Match<AssetImgEmailIconSocialTwitterPngParams & A>; pathParts: AssetImgEmailIconSocialTwitterPngPathParts };

export function assetImgEmailIconSocialTwitterPngRoute(): AssetImgEmailIconSocialTwitterPngRoute;
export function assetImgEmailIconSocialTwitterPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconSocialTwitterPngParams>): AssetImgEmailIconSocialTwitterPngRoute<t.TypeOf<A>>;
export function assetImgEmailIconSocialTwitterPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconSocialTwitterPngParams>) {
  return {
    match: (q ? assetImgEmailIconSocialTwitterPngPath.then(query(t.intersection([assetImgEmailIconSocialTwitterPngQuery, q]))) : assetImgEmailIconSocialTwitterPngPath.then(query(assetImgEmailIconSocialTwitterPngQuery))).then(end),
    pathParts: assetImgEmailIconSocialTwitterPngPathParts,
  };
}

export const assetImgEmailIconSocialTwitterPng = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgEmailIconSocialTwitterPngRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgEmailIconTextPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-text.png"));
const assetImgEmailIconTextPngPathParts = ["assets", "img", "email", "icon-text.png"] as const;
export type AssetImgEmailIconTextPngPathParts = typeof assetImgEmailIconTextPngPathParts;

const assetImgEmailIconTextPngQuery = t.strict({});
export type AssetImgEmailIconTextPngParams = Describe<typeof assetImgEmailIconTextPngPath._A & typeof assetImgEmailIconTextPngQuery._A>;

export type AssetImgEmailIconTextPngRoute<A = {}> = { match: Match<AssetImgEmailIconTextPngParams & A>; pathParts: AssetImgEmailIconTextPngPathParts };

export function assetImgEmailIconTextPngRoute(): AssetImgEmailIconTextPngRoute;
export function assetImgEmailIconTextPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconTextPngParams>): AssetImgEmailIconTextPngRoute<t.TypeOf<A>>;
export function assetImgEmailIconTextPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconTextPngParams>) {
  return {
    match: (q ? assetImgEmailIconTextPngPath.then(query(t.intersection([assetImgEmailIconTextPngQuery, q]))) : assetImgEmailIconTextPngPath.then(query(assetImgEmailIconTextPngQuery))).then(end),
    pathParts: assetImgEmailIconTextPngPathParts,
  };
}

export const assetImgEmailIconTextPng = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgEmailIconTextPngRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgEmailIconWebsitePngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-website.png"));
const assetImgEmailIconWebsitePngPathParts = ["assets", "img", "email", "icon-website.png"] as const;
export type AssetImgEmailIconWebsitePngPathParts = typeof assetImgEmailIconWebsitePngPathParts;

const assetImgEmailIconWebsitePngQuery = t.strict({});
export type AssetImgEmailIconWebsitePngParams = Describe<typeof assetImgEmailIconWebsitePngPath._A & typeof assetImgEmailIconWebsitePngQuery._A>;

export type AssetImgEmailIconWebsitePngRoute<A = {}> = { match: Match<AssetImgEmailIconWebsitePngParams & A>; pathParts: AssetImgEmailIconWebsitePngPathParts };

export function assetImgEmailIconWebsitePngRoute(): AssetImgEmailIconWebsitePngRoute;
export function assetImgEmailIconWebsitePngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconWebsitePngParams>): AssetImgEmailIconWebsitePngRoute<t.TypeOf<A>>;
export function assetImgEmailIconWebsitePngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconWebsitePngParams>) {
  return {
    match: (q ? assetImgEmailIconWebsitePngPath.then(query(t.intersection([assetImgEmailIconWebsitePngQuery, q]))) : assetImgEmailIconWebsitePngPath.then(query(assetImgEmailIconWebsitePngQuery))).then(end),
    pathParts: assetImgEmailIconWebsitePngPathParts,
  };
}

export const assetImgEmailIconWebsitePng = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgEmailIconWebsitePngRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgEmailLeafPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("leaf.png"));
const assetImgEmailLeafPngPathParts = ["assets", "img", "email", "leaf.png"] as const;
export type AssetImgEmailLeafPngPathParts = typeof assetImgEmailLeafPngPathParts;

const assetImgEmailLeafPngQuery = t.strict({});
export type AssetImgEmailLeafPngParams = Describe<typeof assetImgEmailLeafPngPath._A & typeof assetImgEmailLeafPngQuery._A>;

export type AssetImgEmailLeafPngRoute<A = {}> = { match: Match<AssetImgEmailLeafPngParams & A>; pathParts: AssetImgEmailLeafPngPathParts };

export function assetImgEmailLeafPngRoute(): AssetImgEmailLeafPngRoute;
export function assetImgEmailLeafPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailLeafPngParams>): AssetImgEmailLeafPngRoute<t.TypeOf<A>>;
export function assetImgEmailLeafPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailLeafPngParams>) {
  return {
    match: (q ? assetImgEmailLeafPngPath.then(query(t.intersection([assetImgEmailLeafPngQuery, q]))) : assetImgEmailLeafPngPath.then(query(assetImgEmailLeafPngQuery))).then(end),
    pathParts: assetImgEmailLeafPngPathParts,
  };
}

export const assetImgEmailLeafPng = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgEmailLeafPngRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgLogosBondlink2019SvgPath = lit("assets").then(lit("img")).then(lit("logos")).then(lit("bondlink-2019.svg"));
const assetImgLogosBondlink2019SvgPathParts = ["assets", "img", "logos", "bondlink-2019.svg"] as const;
export type AssetImgLogosBondlink2019SvgPathParts = typeof assetImgLogosBondlink2019SvgPathParts;

const assetImgLogosBondlink2019SvgQuery = t.strict({});
export type AssetImgLogosBondlink2019SvgParams = Describe<typeof assetImgLogosBondlink2019SvgPath._A & typeof assetImgLogosBondlink2019SvgQuery._A>;

export type AssetImgLogosBondlink2019SvgRoute<A = {}> = { match: Match<AssetImgLogosBondlink2019SvgParams & A>; pathParts: AssetImgLogosBondlink2019SvgPathParts };

export function assetImgLogosBondlink2019SvgRoute(): AssetImgLogosBondlink2019SvgRoute;
export function assetImgLogosBondlink2019SvgRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgLogosBondlink2019SvgParams>): AssetImgLogosBondlink2019SvgRoute<t.TypeOf<A>>;
export function assetImgLogosBondlink2019SvgRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgLogosBondlink2019SvgParams>) {
  return {
    match: (q ? assetImgLogosBondlink2019SvgPath.then(query(t.intersection([assetImgLogosBondlink2019SvgQuery, q]))) : assetImgLogosBondlink2019SvgPath.then(query(assetImgLogosBondlink2019SvgQuery))).then(end),
    pathParts: assetImgLogosBondlink2019SvgPathParts,
  };
}

export const assetImgLogosBondlink2019Svg = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgLogosBondlink2019SvgRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgLogosBondlink2019BlackSvgPath = lit("assets").then(lit("img")).then(lit("logos")).then(lit("bondlink-2019-black.svg"));
const assetImgLogosBondlink2019BlackSvgPathParts = ["assets", "img", "logos", "bondlink-2019-black.svg"] as const;
export type AssetImgLogosBondlink2019BlackSvgPathParts = typeof assetImgLogosBondlink2019BlackSvgPathParts;

const assetImgLogosBondlink2019BlackSvgQuery = t.strict({});
export type AssetImgLogosBondlink2019BlackSvgParams = Describe<typeof assetImgLogosBondlink2019BlackSvgPath._A & typeof assetImgLogosBondlink2019BlackSvgQuery._A>;

export type AssetImgLogosBondlink2019BlackSvgRoute<A = {}> = { match: Match<AssetImgLogosBondlink2019BlackSvgParams & A>; pathParts: AssetImgLogosBondlink2019BlackSvgPathParts };

export function assetImgLogosBondlink2019BlackSvgRoute(): AssetImgLogosBondlink2019BlackSvgRoute;
export function assetImgLogosBondlink2019BlackSvgRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgLogosBondlink2019BlackSvgParams>): AssetImgLogosBondlink2019BlackSvgRoute<t.TypeOf<A>>;
export function assetImgLogosBondlink2019BlackSvgRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgLogosBondlink2019BlackSvgParams>) {
  return {
    match: (q ? assetImgLogosBondlink2019BlackSvgPath.then(query(t.intersection([assetImgLogosBondlink2019BlackSvgQuery, q]))) : assetImgLogosBondlink2019BlackSvgPath.then(query(assetImgLogosBondlink2019BlackSvgQuery))).then(end),
    pathParts: assetImgLogosBondlink2019BlackSvgPathParts,
  };
}

export const assetImgLogosBondlink2019BlackSvg = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgLogosBondlink2019BlackSvgRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgLogosBondlink2019InvertedSvgPath = lit("assets").then(lit("img")).then(lit("logos")).then(lit("bondlink-2019-inverted.svg"));
const assetImgLogosBondlink2019InvertedSvgPathParts = ["assets", "img", "logos", "bondlink-2019-inverted.svg"] as const;
export type AssetImgLogosBondlink2019InvertedSvgPathParts = typeof assetImgLogosBondlink2019InvertedSvgPathParts;

const assetImgLogosBondlink2019InvertedSvgQuery = t.strict({});
export type AssetImgLogosBondlink2019InvertedSvgParams = Describe<typeof assetImgLogosBondlink2019InvertedSvgPath._A & typeof assetImgLogosBondlink2019InvertedSvgQuery._A>;

export type AssetImgLogosBondlink2019InvertedSvgRoute<A = {}> = { match: Match<AssetImgLogosBondlink2019InvertedSvgParams & A>; pathParts: AssetImgLogosBondlink2019InvertedSvgPathParts };

export function assetImgLogosBondlink2019InvertedSvgRoute(): AssetImgLogosBondlink2019InvertedSvgRoute;
export function assetImgLogosBondlink2019InvertedSvgRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgLogosBondlink2019InvertedSvgParams>): AssetImgLogosBondlink2019InvertedSvgRoute<t.TypeOf<A>>;
export function assetImgLogosBondlink2019InvertedSvgRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgLogosBondlink2019InvertedSvgParams>) {
  return {
    match: (q ? assetImgLogosBondlink2019InvertedSvgPath.then(query(t.intersection([assetImgLogosBondlink2019InvertedSvgQuery, q]))) : assetImgLogosBondlink2019InvertedSvgPath.then(query(assetImgLogosBondlink2019InvertedSvgQuery))).then(end),
    pathParts: assetImgLogosBondlink2019InvertedSvgPathParts,
  };
}

export const assetImgLogosBondlink2019InvertedSvg = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgLogosBondlink2019InvertedSvgRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgLogosBondlinkRoadshowLogoSvgPath = lit("assets").then(lit("img")).then(lit("logos")).then(lit("bondlink-roadshow-logo.svg"));
const assetImgLogosBondlinkRoadshowLogoSvgPathParts = ["assets", "img", "logos", "bondlink-roadshow-logo.svg"] as const;
export type AssetImgLogosBondlinkRoadshowLogoSvgPathParts = typeof assetImgLogosBondlinkRoadshowLogoSvgPathParts;

const assetImgLogosBondlinkRoadshowLogoSvgQuery = t.strict({});
export type AssetImgLogosBondlinkRoadshowLogoSvgParams = Describe<typeof assetImgLogosBondlinkRoadshowLogoSvgPath._A & typeof assetImgLogosBondlinkRoadshowLogoSvgQuery._A>;

export type AssetImgLogosBondlinkRoadshowLogoSvgRoute<A = {}> = { match: Match<AssetImgLogosBondlinkRoadshowLogoSvgParams & A>; pathParts: AssetImgLogosBondlinkRoadshowLogoSvgPathParts };

export function assetImgLogosBondlinkRoadshowLogoSvgRoute(): AssetImgLogosBondlinkRoadshowLogoSvgRoute;
export function assetImgLogosBondlinkRoadshowLogoSvgRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgLogosBondlinkRoadshowLogoSvgParams>): AssetImgLogosBondlinkRoadshowLogoSvgRoute<t.TypeOf<A>>;
export function assetImgLogosBondlinkRoadshowLogoSvgRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgLogosBondlinkRoadshowLogoSvgParams>) {
  return {
    match: (q ? assetImgLogosBondlinkRoadshowLogoSvgPath.then(query(t.intersection([assetImgLogosBondlinkRoadshowLogoSvgQuery, q]))) : assetImgLogosBondlinkRoadshowLogoSvgPath.then(query(assetImgLogosBondlinkRoadshowLogoSvgQuery))).then(end),
    pathParts: assetImgLogosBondlinkRoadshowLogoSvgPathParts,
  };
}

export const assetImgLogosBondlinkRoadshowLogoSvg = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgLogosBondlinkRoadshowLogoSvgRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgLogosBondlinkSocialSharePngPath = lit("assets").then(lit("img")).then(lit("logos")).then(lit("bondlink-social-share.png"));
const assetImgLogosBondlinkSocialSharePngPathParts = ["assets", "img", "logos", "bondlink-social-share.png"] as const;
export type AssetImgLogosBondlinkSocialSharePngPathParts = typeof assetImgLogosBondlinkSocialSharePngPathParts;

const assetImgLogosBondlinkSocialSharePngQuery = t.strict({});
export type AssetImgLogosBondlinkSocialSharePngParams = Describe<typeof assetImgLogosBondlinkSocialSharePngPath._A & typeof assetImgLogosBondlinkSocialSharePngQuery._A>;

export type AssetImgLogosBondlinkSocialSharePngRoute<A = {}> = { match: Match<AssetImgLogosBondlinkSocialSharePngParams & A>; pathParts: AssetImgLogosBondlinkSocialSharePngPathParts };

export function assetImgLogosBondlinkSocialSharePngRoute(): AssetImgLogosBondlinkSocialSharePngRoute;
export function assetImgLogosBondlinkSocialSharePngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgLogosBondlinkSocialSharePngParams>): AssetImgLogosBondlinkSocialSharePngRoute<t.TypeOf<A>>;
export function assetImgLogosBondlinkSocialSharePngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgLogosBondlinkSocialSharePngParams>) {
  return {
    match: (q ? assetImgLogosBondlinkSocialSharePngPath.then(query(t.intersection([assetImgLogosBondlinkSocialSharePngQuery, q]))) : assetImgLogosBondlinkSocialSharePngPath.then(query(assetImgLogosBondlinkSocialSharePngQuery))).then(end),
    pathParts: assetImgLogosBondlinkSocialSharePngPathParts,
  };
}

export const assetImgLogosBondlinkSocialSharePng = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgLogosBondlinkSocialSharePngRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgLogosLogoBondlinkPrimarySvgPath = lit("assets").then(lit("img")).then(lit("logos")).then(lit("logo-bondlink-primary.svg"));
const assetImgLogosLogoBondlinkPrimarySvgPathParts = ["assets", "img", "logos", "logo-bondlink-primary.svg"] as const;
export type AssetImgLogosLogoBondlinkPrimarySvgPathParts = typeof assetImgLogosLogoBondlinkPrimarySvgPathParts;

const assetImgLogosLogoBondlinkPrimarySvgQuery = t.strict({});
export type AssetImgLogosLogoBondlinkPrimarySvgParams = Describe<typeof assetImgLogosLogoBondlinkPrimarySvgPath._A & typeof assetImgLogosLogoBondlinkPrimarySvgQuery._A>;

export type AssetImgLogosLogoBondlinkPrimarySvgRoute<A = {}> = { match: Match<AssetImgLogosLogoBondlinkPrimarySvgParams & A>; pathParts: AssetImgLogosLogoBondlinkPrimarySvgPathParts };

export function assetImgLogosLogoBondlinkPrimarySvgRoute(): AssetImgLogosLogoBondlinkPrimarySvgRoute;
export function assetImgLogosLogoBondlinkPrimarySvgRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgLogosLogoBondlinkPrimarySvgParams>): AssetImgLogosLogoBondlinkPrimarySvgRoute<t.TypeOf<A>>;
export function assetImgLogosLogoBondlinkPrimarySvgRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgLogosLogoBondlinkPrimarySvgParams>) {
  return {
    match: (q ? assetImgLogosLogoBondlinkPrimarySvgPath.then(query(t.intersection([assetImgLogosLogoBondlinkPrimarySvgQuery, q]))) : assetImgLogosLogoBondlinkPrimarySvgPath.then(query(assetImgLogosLogoBondlinkPrimarySvgQuery))).then(end),
    pathParts: assetImgLogosLogoBondlinkPrimarySvgPathParts,
  };
}

export const assetImgLogosLogoBondlinkPrimarySvg = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgLogosLogoBondlinkPrimarySvgRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetImgLogosLogoBondlinkPrimaryFullColorSvgPath = lit("assets").then(lit("img")).then(lit("logos")).then(lit("logo-bondlink-primary-full-color.svg"));
const assetImgLogosLogoBondlinkPrimaryFullColorSvgPathParts = [
  "assets", "img", "logos", "logo-bondlink-primary-full-color.svg"
] as const;
export type AssetImgLogosLogoBondlinkPrimaryFullColorSvgPathParts = typeof assetImgLogosLogoBondlinkPrimaryFullColorSvgPathParts;

const assetImgLogosLogoBondlinkPrimaryFullColorSvgQuery = t.strict({});
export type AssetImgLogosLogoBondlinkPrimaryFullColorSvgParams = Describe<typeof assetImgLogosLogoBondlinkPrimaryFullColorSvgPath._A & typeof assetImgLogosLogoBondlinkPrimaryFullColorSvgQuery._A>;

export type AssetImgLogosLogoBondlinkPrimaryFullColorSvgRoute<A = {}> = { match: Match<AssetImgLogosLogoBondlinkPrimaryFullColorSvgParams & A>; pathParts: AssetImgLogosLogoBondlinkPrimaryFullColorSvgPathParts };

export function assetImgLogosLogoBondlinkPrimaryFullColorSvgRoute(): AssetImgLogosLogoBondlinkPrimaryFullColorSvgRoute;
export function assetImgLogosLogoBondlinkPrimaryFullColorSvgRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgLogosLogoBondlinkPrimaryFullColorSvgParams>): AssetImgLogosLogoBondlinkPrimaryFullColorSvgRoute<t.TypeOf<A>>;
export function assetImgLogosLogoBondlinkPrimaryFullColorSvgRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgLogosLogoBondlinkPrimaryFullColorSvgParams>) {
  return {
    match: (q ? assetImgLogosLogoBondlinkPrimaryFullColorSvgPath.then(query(t.intersection([assetImgLogosLogoBondlinkPrimaryFullColorSvgQuery, q]))) : assetImgLogosLogoBondlinkPrimaryFullColorSvgPath.then(query(assetImgLogosLogoBondlinkPrimaryFullColorSvgQuery))).then(end),
    pathParts: assetImgLogosLogoBondlinkPrimaryFullColorSvgPathParts,
  };
}

export const assetImgLogosLogoBondlinkPrimaryFullColorSvg = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetImgLogosLogoBondlinkPrimaryFullColorSvgRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetStylesheetsAdminCssPath = lit("assets").then(lit("stylesheets")).then(lit("admin.css"));
const assetStylesheetsAdminCssPathParts = ["assets", "stylesheets", "admin.css"] as const;
export type AssetStylesheetsAdminCssPathParts = typeof assetStylesheetsAdminCssPathParts;

const assetStylesheetsAdminCssQuery = t.strict({});
export type AssetStylesheetsAdminCssParams = Describe<typeof assetStylesheetsAdminCssPath._A & typeof assetStylesheetsAdminCssQuery._A>;

export type AssetStylesheetsAdminCssRoute<A = {}> = { match: Match<AssetStylesheetsAdminCssParams & A>; pathParts: AssetStylesheetsAdminCssPathParts };

export function assetStylesheetsAdminCssRoute(): AssetStylesheetsAdminCssRoute;
export function assetStylesheetsAdminCssRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetStylesheetsAdminCssParams>): AssetStylesheetsAdminCssRoute<t.TypeOf<A>>;
export function assetStylesheetsAdminCssRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetStylesheetsAdminCssParams>) {
  return {
    match: (q ? assetStylesheetsAdminCssPath.then(query(t.intersection([assetStylesheetsAdminCssQuery, q]))) : assetStylesheetsAdminCssPath.then(query(assetStylesheetsAdminCssQuery))).then(end),
    pathParts: assetStylesheetsAdminCssPathParts,
  };
}

export const assetStylesheetsAdminCss = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetStylesheetsAdminCssRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetSvgsChevronUpSvgPath = lit("assets").then(lit("svgs")).then(lit("chevron-up.svg"));
const assetSvgsChevronUpSvgPathParts = ["assets", "svgs", "chevron-up.svg"] as const;
export type AssetSvgsChevronUpSvgPathParts = typeof assetSvgsChevronUpSvgPathParts;

const assetSvgsChevronUpSvgQuery = t.strict({});
export type AssetSvgsChevronUpSvgParams = Describe<typeof assetSvgsChevronUpSvgPath._A & typeof assetSvgsChevronUpSvgQuery._A>;

export type AssetSvgsChevronUpSvgRoute<A = {}> = { match: Match<AssetSvgsChevronUpSvgParams & A>; pathParts: AssetSvgsChevronUpSvgPathParts };

export function assetSvgsChevronUpSvgRoute(): AssetSvgsChevronUpSvgRoute;
export function assetSvgsChevronUpSvgRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetSvgsChevronUpSvgParams>): AssetSvgsChevronUpSvgRoute<t.TypeOf<A>>;
export function assetSvgsChevronUpSvgRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetSvgsChevronUpSvgParams>) {
  return {
    match: (q ? assetSvgsChevronUpSvgPath.then(query(t.intersection([assetSvgsChevronUpSvgQuery, q]))) : assetSvgsChevronUpSvgPath.then(query(assetSvgsChevronUpSvgQuery))).then(end),
    pathParts: assetSvgsChevronUpSvgPathParts,
  };
}

export const assetSvgsChevronUpSvg = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetSvgsChevronUpSvgRoute().match.formatter.run(Route.empty, {}).toString(true)));

const assetSvgsTrashSvgPath = lit("assets").then(lit("svgs")).then(lit("trash.svg"));
const assetSvgsTrashSvgPathParts = ["assets", "svgs", "trash.svg"] as const;
export type AssetSvgsTrashSvgPathParts = typeof assetSvgsTrashSvgPathParts;

const assetSvgsTrashSvgQuery = t.strict({});
export type AssetSvgsTrashSvgParams = Describe<typeof assetSvgsTrashSvgPath._A & typeof assetSvgsTrashSvgQuery._A>;

export type AssetSvgsTrashSvgRoute<A = {}> = { match: Match<AssetSvgsTrashSvgParams & A>; pathParts: AssetSvgsTrashSvgPathParts };

export function assetSvgsTrashSvgRoute(): AssetSvgsTrashSvgRoute;
export function assetSvgsTrashSvgRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetSvgsTrashSvgParams>): AssetSvgsTrashSvgRoute<t.TypeOf<A>>;
export function assetSvgsTrashSvgRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetSvgsTrashSvgParams>) {
  return {
    match: (q ? assetSvgsTrashSvgPath.then(query(t.intersection([assetSvgsTrashSvgQuery, q]))) : assetSvgsTrashSvgPath.then(query(assetSvgsTrashSvgQuery))).then(end),
    pathParts: assetSvgsTrashSvgPathParts,
  };
}

export const assetSvgsTrashSvg = (): RelativeUrlInterface<"GET"> =>
  relativeUrlInterface("GET", unsafeFromRelativeUrlString(assetSvgsTrashSvgRoute().match.formatter.run(Route.empty, {}).toString(true)));