import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const issuerId = {
  _tag: `IssuerId`,
  name: `dimension1`
} as const;

export type IssuerIdTaggedC = t.TypeC<{
  _tag: t.LiteralC<`IssuerId`>
}>;
export const issuerIdTaggedC: IssuerIdTaggedC = t.type({
  _tag: t.literal(`IssuerId`)
});
export type IssuerIdTagged = t.TypeOf<IssuerIdTaggedC>;
export type IssuerId = IssuerIdTagged & typeof issuerId;
export type IssuerIdC = t.Type<IssuerId, IssuerIdTagged>;
export const issuerIdC: IssuerIdC = pipe(issuerIdTaggedC, c => new t.Type<IssuerId, IssuerIdTagged>(
  `IssuerId`,
  (u: unknown): u is IssuerId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerId> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerId }))),
  (x: IssuerId): IssuerIdTagged => ({ ...x, _tag: `IssuerId`}),
)) satisfies t.Type<IssuerId, unknown>;


export const bondlinkUserId = {
  _tag: `BondlinkUserId`,
  name: `dimension2`
} as const;

export type BondlinkUserIdTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondlinkUserId`>
}>;
export const bondlinkUserIdTaggedC: BondlinkUserIdTaggedC = t.type({
  _tag: t.literal(`BondlinkUserId`)
});
export type BondlinkUserIdTagged = t.TypeOf<BondlinkUserIdTaggedC>;
export type BondlinkUserId = BondlinkUserIdTagged & typeof bondlinkUserId;
export type BondlinkUserIdC = t.Type<BondlinkUserId, BondlinkUserIdTagged>;
export const bondlinkUserIdC: BondlinkUserIdC = pipe(bondlinkUserIdTaggedC, c => new t.Type<BondlinkUserId, BondlinkUserIdTagged>(
  `BondlinkUserId`,
  (u: unknown): u is BondlinkUserId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondlinkUserId> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondlinkUserId }))),
  (x: BondlinkUserId): BondlinkUserIdTagged => ({ ...x, _tag: `BondlinkUserId`}),
)) satisfies t.Type<BondlinkUserId, unknown>;


export const clientType = {
  _tag: `ClientType`,
  name: `dimension3`
} as const;

export type ClientTypeTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ClientType`>
}>;
export const clientTypeTaggedC: ClientTypeTaggedC = t.type({
  _tag: t.literal(`ClientType`)
});
export type ClientTypeTagged = t.TypeOf<ClientTypeTaggedC>;
export type ClientType = ClientTypeTagged & typeof clientType;
export type ClientTypeC = t.Type<ClientType, ClientTypeTagged>;
export const clientTypeC: ClientTypeC = pipe(clientTypeTaggedC, c => new t.Type<ClientType, ClientTypeTagged>(
  `ClientType`,
  (u: unknown): u is ClientType => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ClientType> => pipe(c.decode(u), E.map(x => ({ ...x, ...clientType }))),
  (x: ClientType): ClientTypeTagged => ({ ...x, _tag: `ClientType`}),
)) satisfies t.Type<ClientType, unknown>;


export const issuerName = {
  _tag: `IssuerName`,
  name: `dimension4`
} as const;

export type IssuerNameTaggedC = t.TypeC<{
  _tag: t.LiteralC<`IssuerName`>
}>;
export const issuerNameTaggedC: IssuerNameTaggedC = t.type({
  _tag: t.literal(`IssuerName`)
});
export type IssuerNameTagged = t.TypeOf<IssuerNameTaggedC>;
export type IssuerName = IssuerNameTagged & typeof issuerName;
export type IssuerNameC = t.Type<IssuerName, IssuerNameTagged>;
export const issuerNameC: IssuerNameC = pipe(issuerNameTaggedC, c => new t.Type<IssuerName, IssuerNameTagged>(
  `IssuerName`,
  (u: unknown): u is IssuerName => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerName> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerName }))),
  (x: IssuerName): IssuerNameTagged => ({ ...x, _tag: `IssuerName`}),
)) satisfies t.Type<IssuerName, unknown>;


export const bankId = {
  _tag: `BankId`,
  name: `dimension5`
} as const;

export type BankIdTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BankId`>
}>;
export const bankIdTaggedC: BankIdTaggedC = t.type({
  _tag: t.literal(`BankId`)
});
export type BankIdTagged = t.TypeOf<BankIdTaggedC>;
export type BankId = BankIdTagged & typeof bankId;
export type BankIdC = t.Type<BankId, BankIdTagged>;
export const bankIdC: BankIdC = pipe(bankIdTaggedC, c => new t.Type<BankId, BankIdTagged>(
  `BankId`,
  (u: unknown): u is BankId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BankId> => pipe(c.decode(u), E.map(x => ({ ...x, ...bankId }))),
  (x: BankId): BankIdTagged => ({ ...x, _tag: `BankId`}),
)) satisfies t.Type<BankId, unknown>;


export const allGACustomDimensionC = [issuerIdC, bondlinkUserIdC, clientTypeC, issuerNameC, bankIdC] as const;
export const allGACustomDimensionNames = [`IssuerId`, `BondlinkUserId`, `ClientType`, `IssuerName`, `BankId`] as const;
export type GACustomDimensionName = (typeof allGACustomDimensionNames)[number];

export type GACustomDimensionCU = t.UnionC<[IssuerIdC, BondlinkUserIdC, ClientTypeC, IssuerNameC, BankIdC]>;
export type GACustomDimensionU = IssuerId | BondlinkUserId | ClientType | IssuerName | BankId;
export const GACustomDimensionCU: GACustomDimensionCU = t.union([issuerIdC, bondlinkUserIdC, clientTypeC, issuerNameC, bankIdC]) satisfies t.Type<GACustomDimensionU, unknown>;

export const gACustomDimensionOrd: Ord.Ord<GACustomDimensionU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allGACustomDimension = [issuerId, bondlinkUserId, clientType, issuerName, bankId] as const;
export type GACustomDimensionMap<A> = { [K in GACustomDimensionName]: A };


