import type { PropsWithChildren, ReactElement } from "react";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import type { SVGString } from "*.svg";

import type { Breakpoint } from "@scripts/bondlinkStatic";
import { E } from "@scripts/fp-ts";
import { pick } from "@scripts/fp-ts/struct";
import { portalTable } from "@scripts/generated/assets/stylesheets/components/_portal-table";
import type { PageU } from "@scripts/generated/domaintables/pages";
import type { PageConfig } from "@scripts/generated/models/pageConfig";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { InfoTip } from "@scripts/react/components/InfoTip";
import type { KlassProp } from "@scripts/react/util/classnames";
import { klass, klassConditional, klassPropO, mergeKlassPropO } from "@scripts/react/util/classnames";
import { delProp } from "@scripts/util/delProp";
import { uniqueAriaId } from "@scripts/util/labelOrAriaLabel";

import type { SitesJumpLink } from "../SidebarLinks";
import { Grid, GridCol } from "./Grid";
import { Subheader } from "./Subheaders";
export type SectionProps = PropsWithChildren<{
  title: O.Option<string>;
  klasses?: KlassProp;
  suppressDivider?: Breakpoint;
  sectionId?: string;
  noSpacing?: true;
  headerDivider?: true;
  icon?: SVGString;
  actionArea?: ReactElement;
}>;

export type SectionWithTipProps = SectionProps & {
  tip: O.Option<string>;
};

const suppressDivider = (breakpoint: Breakpoint | undefined) => pipe(
  breakpoint,
  O.fromNullable,
  O.map((bp) => bp === "xs" ? "bt-0 pt-0" : `bt-${bp}-0 pt-0`)
);

type AccentDividerSectionJumpLinkProps = {
  jumpLink: SitesJumpLink;
  pages: ReadonlyArray<PageConfig<PageU>>;
  hideTitle?: true;
};

type AccentDividerSectionIdProps = Pick<SectionProps, "title" | "sectionId">;

type AccentDividerSectionProps =
  & PropsWithChildren<Pick<SectionProps, "klasses" | "suppressDivider" | "noSpacing" | "headerDivider" | "icon" | "actionArea">>
  & (AccentDividerSectionJumpLinkProps | AccentDividerSectionIdProps);

const isJumpLinkProps = (p: AccentDividerSectionProps): p is AccentDividerSectionJumpLinkProps => "jumpLink" in p;

const AccentDividerSectionTitle = (props: Pick<SectionProps, "title" | "headerDivider" | "icon" | "actionArea">) => mapOrEmpty((title: string) =>
  <Subheader title={title} klasses={["mb-1", props.headerDivider ? "bb-0125 pb-1" : "bb-0"]} type="default" icon={pipe(O.fromNullable(props.icon), O.map(E.right))} link={O.none} >
    {props.actionArea}
  </Subheader>
)(props.title);

export const AccentDividerSection = (props: AccentDividerSectionProps) =>
  <section
    {...klassPropO(["interpolate-divider-accent", suppressDivider(props.suppressDivider)])(props.klasses)}
    id={isJumpLinkProps(props) ? props.jumpLink.sectionId : props.sectionId}
  >
    <AccentDividerSectionTitle
      {...pick("headerDivider", "icon", "actionArea")(props)}
      title={isJumpLinkProps(props) ? O.fromPredicate(() => !(props.hideTitle ?? false))(props.jumpLink.text(props.pages)) : props.title}
    />
    {props.children}
  </section>;

const DividerSectionTitle = (props: Pick<SectionProps, "title" | "headerDivider">) => mapOrEmpty((title: string) =>
  <h3 id={uniqueAriaId(title)} {...klassConditional("bb-0125 pb-1", "mb-1")(props.headerDivider ?? false)}>{title}</h3>
)(props.title);
export const DividerSection = (props: SectionProps) =>
  <div
    {...klassPropO(["interpolate-divider", suppressDivider(props.suppressDivider), props.noSpacing ? "no-spacing" : ""])(props.klasses)}
    id={props.sectionId}
  >
    <DividerSectionTitle title={props.title} />
    {props.children}
  </div>;

const ModalSectionTitle = (props: Pick<SectionProps, "title" | "headerDivider">) => mapOrEmpty((title: string) =>
  <h5 id={uniqueAriaId(title)} {...klassConditional("bb-0125 pb-1", "mb-1")(props.headerDivider ?? false)}>{title}</h5>
)(props.title);
export const ModalSection = (props: SectionProps) =>
  <div
    {...klassPropO([props.noSpacing ? "no-spacing" : "", "mb-15"])(props.klasses)}
    id={props.sectionId}
  >
    <ModalSectionTitle title={props.title} />
    {props.children}
  </div>;


export const LayoutSection = (props: SectionProps) => <DividerSection {...props} klasses={mergeKlassPropO("layout-section")(props.klasses)} />;
export const SmallSection = (props: SectionProps) => <DividerSection {...props} klasses={mergeKlassPropO("section-small")(props.klasses)} />;

export const SectionWithTip = (props: SectionWithTipProps) => <DividerSection
  {...delProp(props, "children", "tip", "title")}
  title={O.none}
>
  <Grid attrs={O.some(".grid-sx-15")} klasses={["toolbar", "header", "flex-col", "flex-md-row"]}>
    <GridCol cols={[".c-24", ".c-md-20"]} klasses={O.some("w-100")}>
      <DividerSectionTitle {...pick("title", "headerDivider")(props)} />
      {props.children}
    </GridCol>
    {mapOrEmpty((tip: string) =>
      <GridCol cols={[".c-24", ".c-md-4"]} klasses={["mt-1", "mt-md-0"]}>
        <InfoTip tip={tip} />
      </GridCol>
    )(props.tip)}
  </Grid>
</DividerSection>;

export const PortalTableSectionWithTip = (props: SectionWithTipProps) => <AccentDividerSection
  {...delProp(props, "children", "tip", "title")}
  title={O.none}
>
  <div {...klass(portalTable[".portal-table-header"])}>
    <Grid attrs={O.some(".grid-sx-15")} klasses={["toolbar", "header", "flex-col", "flex-md-row"]}>
      <GridCol cols={[".c-24", ".c-md-20"]} klasses={O.some("w-100")}>
        <AccentDividerSectionTitle {...pick("title", "headerDivider")(props)} />
        {props.children}
      </GridCol>
      {mapOrEmpty((tip: string) =>
        <GridCol cols={[".c-24", ".c-md-4"]} klasses={["mt-1", "mt-md-0"]}>
          <InfoTip tip={tip} />
        </GridCol>
      )(props.tip)}
    </Grid>
  </div>
</AccentDividerSection>;

export const PortalTableSection = (props: SectionProps) => <AccentDividerSection
  {...delProp(props, "children", "title")}
  title={O.none}
>
  <div {...klass(portalTable[".portal-table-header"])}>
    <Grid attrs={O.none} klasses={["toolbar", "header", "flex-col", "flex-md-row"]}>
      <GridCol cols={[".c-24"]} klasses={O.some("w-100")}>
        <AccentDividerSectionTitle {...pick("title", "headerDivider")(props)} />
        {props.children}
      </GridCol>
    </Grid>
  </div>
</AccentDividerSection>;
