export * from "fp-ts/lib/TaskEither";
import type { Either } from "fp-ts/lib/Either";
import type { TaskEither } from "fp-ts/lib/TaskEither";
import * as TE from "fp-ts/lib/TaskEither";

import { tap } from "@scripts/util/tap";

export const run = <E, A>(ma: TaskEither<E, A>): Promise<Either<E, A>> => ma();

export const biTap = <L, R>(onLeft: (left: L) => void, onRight: (right: R) => void) => TE.bimap(
  tap(onLeft),
  tap(onRight)
);
