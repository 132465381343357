import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const issuer = {
  _tag: `Issuer`,
  id: 1,
  personaName: `Issuer`
} as const;

export type IssuerTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Issuer`>
}>;
export const issuerTaggedC: IssuerTaggedC = t.type({
  _tag: t.literal(`Issuer`)
});
export type IssuerTagged = t.TypeOf<IssuerTaggedC>;
export type Issuer = IssuerTagged & typeof issuer;
export type IssuerC = t.Type<Issuer, IssuerTagged>;
export const issuerC: IssuerC = pipe(issuerTaggedC, c => new t.Type<Issuer, IssuerTagged>(
  `Issuer`,
  (u: unknown): u is Issuer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Issuer> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuer }))),
  (x: Issuer): IssuerTagged => ({ ...x, _tag: `Issuer`}),
)) satisfies t.Type<Issuer, unknown>;


export const investorIndividual = {
  _tag: `InvestorIndividual`,
  id: 2,
  personaName: `Investor (Individual)`
} as const;

export type InvestorIndividualTaggedC = t.TypeC<{
  _tag: t.LiteralC<`InvestorIndividual`>
}>;
export const investorIndividualTaggedC: InvestorIndividualTaggedC = t.type({
  _tag: t.literal(`InvestorIndividual`)
});
export type InvestorIndividualTagged = t.TypeOf<InvestorIndividualTaggedC>;
export type InvestorIndividual = InvestorIndividualTagged & typeof investorIndividual;
export type InvestorIndividualC = t.Type<InvestorIndividual, InvestorIndividualTagged>;
export const investorIndividualC: InvestorIndividualC = pipe(investorIndividualTaggedC, c => new t.Type<InvestorIndividual, InvestorIndividualTagged>(
  `InvestorIndividual`,
  (u: unknown): u is InvestorIndividual => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InvestorIndividual> => pipe(c.decode(u), E.map(x => ({ ...x, ...investorIndividual }))),
  (x: InvestorIndividual): InvestorIndividualTagged => ({ ...x, _tag: `InvestorIndividual`}),
)) satisfies t.Type<InvestorIndividual, unknown>;


export const ratingAgency = {
  _tag: `RatingAgency`,
  id: 3,
  personaName: `Rating Agency / Credit Guarantor`
} as const;

export type RatingAgencyTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RatingAgency`>
}>;
export const ratingAgencyTaggedC: RatingAgencyTaggedC = t.type({
  _tag: t.literal(`RatingAgency`)
});
export type RatingAgencyTagged = t.TypeOf<RatingAgencyTaggedC>;
export type RatingAgency = RatingAgencyTagged & typeof ratingAgency;
export type RatingAgencyC = t.Type<RatingAgency, RatingAgencyTagged>;
export const ratingAgencyC: RatingAgencyC = pipe(ratingAgencyTaggedC, c => new t.Type<RatingAgency, RatingAgencyTagged>(
  `RatingAgency`,
  (u: unknown): u is RatingAgency => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RatingAgency> => pipe(c.decode(u), E.map(x => ({ ...x, ...ratingAgency }))),
  (x: RatingAgency): RatingAgencyTagged => ({ ...x, _tag: `RatingAgency`}),
)) satisfies t.Type<RatingAgency, unknown>;


export const bankingInvestmentBanker = {
  _tag: `BankingInvestmentBanker`,
  id: 4,
  personaName: `Banking (Investment Banker)`
} as const;

export type BankingInvestmentBankerTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BankingInvestmentBanker`>
}>;
export const bankingInvestmentBankerTaggedC: BankingInvestmentBankerTaggedC = t.type({
  _tag: t.literal(`BankingInvestmentBanker`)
});
export type BankingInvestmentBankerTagged = t.TypeOf<BankingInvestmentBankerTaggedC>;
export type BankingInvestmentBanker = BankingInvestmentBankerTagged & typeof bankingInvestmentBanker;
export type BankingInvestmentBankerC = t.Type<BankingInvestmentBanker, BankingInvestmentBankerTagged>;
export const bankingInvestmentBankerC: BankingInvestmentBankerC = pipe(bankingInvestmentBankerTaggedC, c => new t.Type<BankingInvestmentBanker, BankingInvestmentBankerTagged>(
  `BankingInvestmentBanker`,
  (u: unknown): u is BankingInvestmentBanker => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BankingInvestmentBanker> => pipe(c.decode(u), E.map(x => ({ ...x, ...bankingInvestmentBanker }))),
  (x: BankingInvestmentBanker): BankingInvestmentBankerTagged => ({ ...x, _tag: `BankingInvestmentBanker`}),
)) satisfies t.Type<BankingInvestmentBanker, unknown>;


export const municipalAdvisor = {
  _tag: `MunicipalAdvisor`,
  id: 5,
  personaName: `Municipal Advisor`
} as const;

export type MunicipalAdvisorTaggedC = t.TypeC<{
  _tag: t.LiteralC<`MunicipalAdvisor`>
}>;
export const municipalAdvisorTaggedC: MunicipalAdvisorTaggedC = t.type({
  _tag: t.literal(`MunicipalAdvisor`)
});
export type MunicipalAdvisorTagged = t.TypeOf<MunicipalAdvisorTaggedC>;
export type MunicipalAdvisor = MunicipalAdvisorTagged & typeof municipalAdvisor;
export type MunicipalAdvisorC = t.Type<MunicipalAdvisor, MunicipalAdvisorTagged>;
export const municipalAdvisorC: MunicipalAdvisorC = pipe(municipalAdvisorTaggedC, c => new t.Type<MunicipalAdvisor, MunicipalAdvisorTagged>(
  `MunicipalAdvisor`,
  (u: unknown): u is MunicipalAdvisor => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MunicipalAdvisor> => pipe(c.decode(u), E.map(x => ({ ...x, ...municipalAdvisor }))),
  (x: MunicipalAdvisor): MunicipalAdvisorTagged => ({ ...x, _tag: `MunicipalAdvisor`}),
)) satisfies t.Type<MunicipalAdvisor, unknown>;


export const media = {
  _tag: `Media`,
  id: 6,
  personaName: `Media / Non-Financial / Other`
} as const;

export type MediaTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Media`>
}>;
export const mediaTaggedC: MediaTaggedC = t.type({
  _tag: t.literal(`Media`)
});
export type MediaTagged = t.TypeOf<MediaTaggedC>;
export type Media = MediaTagged & typeof media;
export type MediaC = t.Type<Media, MediaTagged>;
export const mediaC: MediaC = pipe(mediaTaggedC, c => new t.Type<Media, MediaTagged>(
  `Media`,
  (u: unknown): u is Media => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Media> => pipe(c.decode(u), E.map(x => ({ ...x, ...media }))),
  (x: Media): MediaTagged => ({ ...x, _tag: `Media`}),
)) satisfies t.Type<Media, unknown>;


export const counsel = {
  _tag: `Counsel`,
  id: 7,
  personaName: `Counsel`
} as const;

export type CounselTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Counsel`>
}>;
export const counselTaggedC: CounselTaggedC = t.type({
  _tag: t.literal(`Counsel`)
});
export type CounselTagged = t.TypeOf<CounselTaggedC>;
export type Counsel = CounselTagged & typeof counsel;
export type CounselC = t.Type<Counsel, CounselTagged>;
export const counselC: CounselC = pipe(counselTaggedC, c => new t.Type<Counsel, CounselTagged>(
  `Counsel`,
  (u: unknown): u is Counsel => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Counsel> => pipe(c.decode(u), E.map(x => ({ ...x, ...counsel }))),
  (x: Counsel): CounselTagged => ({ ...x, _tag: `Counsel`}),
)) satisfies t.Type<Counsel, unknown>;


export const investorInstitutional = {
  _tag: `InvestorInstitutional`,
  id: 8,
  personaName: `Investor (Institutional)`
} as const;

export type InvestorInstitutionalTaggedC = t.TypeC<{
  _tag: t.LiteralC<`InvestorInstitutional`>
}>;
export const investorInstitutionalTaggedC: InvestorInstitutionalTaggedC = t.type({
  _tag: t.literal(`InvestorInstitutional`)
});
export type InvestorInstitutionalTagged = t.TypeOf<InvestorInstitutionalTaggedC>;
export type InvestorInstitutional = InvestorInstitutionalTagged & typeof investorInstitutional;
export type InvestorInstitutionalC = t.Type<InvestorInstitutional, InvestorInstitutionalTagged>;
export const investorInstitutionalC: InvestorInstitutionalC = pipe(investorInstitutionalTaggedC, c => new t.Type<InvestorInstitutional, InvestorInstitutionalTagged>(
  `InvestorInstitutional`,
  (u: unknown): u is InvestorInstitutional => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InvestorInstitutional> => pipe(c.decode(u), E.map(x => ({ ...x, ...investorInstitutional }))),
  (x: InvestorInstitutional): InvestorInstitutionalTagged => ({ ...x, _tag: `InvestorInstitutional`}),
)) satisfies t.Type<InvestorInstitutional, unknown>;


export const bankingUnderwriting = {
  _tag: `BankingUnderwriting`,
  id: 9,
  personaName: `Banking (Underwriting)`
} as const;

export type BankingUnderwritingTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BankingUnderwriting`>
}>;
export const bankingUnderwritingTaggedC: BankingUnderwritingTaggedC = t.type({
  _tag: t.literal(`BankingUnderwriting`)
});
export type BankingUnderwritingTagged = t.TypeOf<BankingUnderwritingTaggedC>;
export type BankingUnderwriting = BankingUnderwritingTagged & typeof bankingUnderwriting;
export type BankingUnderwritingC = t.Type<BankingUnderwriting, BankingUnderwritingTagged>;
export const bankingUnderwritingC: BankingUnderwritingC = pipe(bankingUnderwritingTaggedC, c => new t.Type<BankingUnderwriting, BankingUnderwritingTagged>(
  `BankingUnderwriting`,
  (u: unknown): u is BankingUnderwriting => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BankingUnderwriting> => pipe(c.decode(u), E.map(x => ({ ...x, ...bankingUnderwriting }))),
  (x: BankingUnderwriting): BankingUnderwritingTagged => ({ ...x, _tag: `BankingUnderwriting`}),
)) satisfies t.Type<BankingUnderwriting, unknown>;


export const bankingSales = {
  _tag: `BankingSales`,
  id: 10,
  personaName: `Banking (Sales)`
} as const;

export type BankingSalesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BankingSales`>
}>;
export const bankingSalesTaggedC: BankingSalesTaggedC = t.type({
  _tag: t.literal(`BankingSales`)
});
export type BankingSalesTagged = t.TypeOf<BankingSalesTaggedC>;
export type BankingSales = BankingSalesTagged & typeof bankingSales;
export type BankingSalesC = t.Type<BankingSales, BankingSalesTagged>;
export const bankingSalesC: BankingSalesC = pipe(bankingSalesTaggedC, c => new t.Type<BankingSales, BankingSalesTagged>(
  `BankingSales`,
  (u: unknown): u is BankingSales => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BankingSales> => pipe(c.decode(u), E.map(x => ({ ...x, ...bankingSales }))),
  (x: BankingSales): BankingSalesTagged => ({ ...x, _tag: `BankingSales`}),
)) satisfies t.Type<BankingSales, unknown>;


export const investorRIAWealthMgmt = {
  _tag: `InvestorRIAWealthMgmt`,
  id: 11,
  personaName: `Investor (RIA / Wealth Mgmt.)`
} as const;

export type InvestorRIAWealthMgmtTaggedC = t.TypeC<{
  _tag: t.LiteralC<`InvestorRIAWealthMgmt`>
}>;
export const investorRIAWealthMgmtTaggedC: InvestorRIAWealthMgmtTaggedC = t.type({
  _tag: t.literal(`InvestorRIAWealthMgmt`)
});
export type InvestorRIAWealthMgmtTagged = t.TypeOf<InvestorRIAWealthMgmtTaggedC>;
export type InvestorRIAWealthMgmt = InvestorRIAWealthMgmtTagged & typeof investorRIAWealthMgmt;
export type InvestorRIAWealthMgmtC = t.Type<InvestorRIAWealthMgmt, InvestorRIAWealthMgmtTagged>;
export const investorRIAWealthMgmtC: InvestorRIAWealthMgmtC = pipe(investorRIAWealthMgmtTaggedC, c => new t.Type<InvestorRIAWealthMgmt, InvestorRIAWealthMgmtTagged>(
  `InvestorRIAWealthMgmt`,
  (u: unknown): u is InvestorRIAWealthMgmt => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InvestorRIAWealthMgmt> => pipe(c.decode(u), E.map(x => ({ ...x, ...investorRIAWealthMgmt }))),
  (x: InvestorRIAWealthMgmt): InvestorRIAWealthMgmtTagged => ({ ...x, _tag: `InvestorRIAWealthMgmt`}),
)) satisfies t.Type<InvestorRIAWealthMgmt, unknown>;


export const commercialLender = {
  _tag: `CommercialLender`,
  id: 12,
  personaName: `Commercial Lender`
} as const;

export type CommercialLenderTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CommercialLender`>
}>;
export const commercialLenderTaggedC: CommercialLenderTaggedC = t.type({
  _tag: t.literal(`CommercialLender`)
});
export type CommercialLenderTagged = t.TypeOf<CommercialLenderTaggedC>;
export type CommercialLender = CommercialLenderTagged & typeof commercialLender;
export type CommercialLenderC = t.Type<CommercialLender, CommercialLenderTagged>;
export const commercialLenderC: CommercialLenderC = pipe(commercialLenderTaggedC, c => new t.Type<CommercialLender, CommercialLenderTagged>(
  `CommercialLender`,
  (u: unknown): u is CommercialLender => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CommercialLender> => pipe(c.decode(u), E.map(x => ({ ...x, ...commercialLender }))),
  (x: CommercialLender): CommercialLenderTagged => ({ ...x, _tag: `CommercialLender`}),
)) satisfies t.Type<CommercialLender, unknown>;


export const allUserPersonaC = [issuerC, investorIndividualC, ratingAgencyC, bankingInvestmentBankerC, municipalAdvisorC, mediaC, counselC, investorInstitutionalC, bankingUnderwritingC, bankingSalesC, investorRIAWealthMgmtC, commercialLenderC] as const;
export const allUserPersonaNames = [`Issuer`, `InvestorIndividual`, `RatingAgency`, `BankingInvestmentBanker`, `MunicipalAdvisor`, `Media`, `Counsel`, `InvestorInstitutional`, `BankingUnderwriting`, `BankingSales`, `InvestorRIAWealthMgmt`, `CommercialLender`] as const;
export type UserPersonaName = (typeof allUserPersonaNames)[number];

export type UserPersonaCU = t.UnionC<[IssuerC, InvestorIndividualC, RatingAgencyC, BankingInvestmentBankerC, MunicipalAdvisorC, MediaC, CounselC, InvestorInstitutionalC, BankingUnderwritingC, BankingSalesC, InvestorRIAWealthMgmtC, CommercialLenderC]>;
export type UserPersonaU = Issuer | InvestorIndividual | RatingAgency | BankingInvestmentBanker | MunicipalAdvisor | Media | Counsel | InvestorInstitutional | BankingUnderwriting | BankingSales | InvestorRIAWealthMgmt | CommercialLender;
export const UserPersonaCU: UserPersonaCU = t.union([issuerC, investorIndividualC, ratingAgencyC, bankingInvestmentBankerC, municipalAdvisorC, mediaC, counselC, investorInstitutionalC, bankingUnderwritingC, bankingSalesC, investorRIAWealthMgmtC, commercialLenderC]) satisfies t.Type<UserPersonaU, unknown>;

export const userPersonaOrd: Ord.Ord<UserPersonaU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allUserPersona = [issuer, investorIndividual, ratingAgency, bankingInvestmentBanker, municipalAdvisor, media, counsel, investorInstitutional, bankingUnderwriting, bankingSales, investorRIAWealthMgmt, commercialLender] as const;
export type UserPersonaMap<A> = { [K in UserPersonaName]: A };


