import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const day = {
  _tag: `Day`,
  id: 1
} as const;

export type DayTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Day`>
}>;
export const dayTaggedC: DayTaggedC = t.type({
  _tag: t.literal(`Day`)
});
export type DayTagged = t.TypeOf<DayTaggedC>;
export type Day = DayTagged & typeof day;
export type DayC = t.Type<Day, DayTagged>;
export const dayC: DayC = pipe(dayTaggedC, c => new t.Type<Day, DayTagged>(
  `Day`,
  (u: unknown): u is Day => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Day> => pipe(c.decode(u), E.map(x => ({ ...x, ...day }))),
  (x: Day): DayTagged => ({ ...x, _tag: `Day`}),
)) satisfies t.Type<Day, unknown>;


export const quarter = {
  _tag: `Quarter`,
  id: 2
} as const;

export type QuarterTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Quarter`>
}>;
export const quarterTaggedC: QuarterTaggedC = t.type({
  _tag: t.literal(`Quarter`)
});
export type QuarterTagged = t.TypeOf<QuarterTaggedC>;
export type Quarter = QuarterTagged & typeof quarter;
export type QuarterC = t.Type<Quarter, QuarterTagged>;
export const quarterC: QuarterC = pipe(quarterTaggedC, c => new t.Type<Quarter, QuarterTagged>(
  `Quarter`,
  (u: unknown): u is Quarter => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Quarter> => pipe(c.decode(u), E.map(x => ({ ...x, ...quarter }))),
  (x: Quarter): QuarterTagged => ({ ...x, _tag: `Quarter`}),
)) satisfies t.Type<Quarter, unknown>;


export const month = {
  _tag: `Month`,
  id: 3
} as const;

export type MonthTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Month`>
}>;
export const monthTaggedC: MonthTaggedC = t.type({
  _tag: t.literal(`Month`)
});
export type MonthTagged = t.TypeOf<MonthTaggedC>;
export type Month = MonthTagged & typeof month;
export type MonthC = t.Type<Month, MonthTagged>;
export const monthC: MonthC = pipe(monthTaggedC, c => new t.Type<Month, MonthTagged>(
  `Month`,
  (u: unknown): u is Month => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Month> => pipe(c.decode(u), E.map(x => ({ ...x, ...month }))),
  (x: Month): MonthTagged => ({ ...x, _tag: `Month`}),
)) satisfies t.Type<Month, unknown>;


export const year = {
  _tag: `Year`,
  id: 4
} as const;

export type YearTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Year`>
}>;
export const yearTaggedC: YearTaggedC = t.type({
  _tag: t.literal(`Year`)
});
export type YearTagged = t.TypeOf<YearTaggedC>;
export type Year = YearTagged & typeof year;
export type YearC = t.Type<Year, YearTagged>;
export const yearC: YearC = pipe(yearTaggedC, c => new t.Type<Year, YearTagged>(
  `Year`,
  (u: unknown): u is Year => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Year> => pipe(c.decode(u), E.map(x => ({ ...x, ...year }))),
  (x: Year): YearTagged => ({ ...x, _tag: `Year`}),
)) satisfies t.Type<Year, unknown>;


export const week = {
  _tag: `Week`,
  id: 5
} as const;

export type WeekTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Week`>
}>;
export const weekTaggedC: WeekTaggedC = t.type({
  _tag: t.literal(`Week`)
});
export type WeekTagged = t.TypeOf<WeekTaggedC>;
export type Week = WeekTagged & typeof week;
export type WeekC = t.Type<Week, WeekTagged>;
export const weekC: WeekC = pipe(weekTaggedC, c => new t.Type<Week, WeekTagged>(
  `Week`,
  (u: unknown): u is Week => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Week> => pipe(c.decode(u), E.map(x => ({ ...x, ...week }))),
  (x: Week): WeekTagged => ({ ...x, _tag: `Week`}),
)) satisfies t.Type<Week, unknown>;


export const allDateQualifierC = [dayC, quarterC, monthC, yearC, weekC] as const;
export const allDateQualifierNames = [`Day`, `Quarter`, `Month`, `Year`, `Week`] as const;
export type DateQualifierName = (typeof allDateQualifierNames)[number];

export type DateQualifierCU = t.UnionC<[DayC, QuarterC, MonthC, YearC, WeekC]>;
export type DateQualifierU = Day | Quarter | Month | Year | Week;
export const DateQualifierCU: DateQualifierCU = t.union([dayC, quarterC, monthC, yearC, weekC]) satisfies t.Type<DateQualifierU, unknown>;

export const dateQualifierOrd: Ord.Ord<DateQualifierU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allDateQualifier = [day, quarter, month, year, week] as const;
export type DateQualifierMap<A> = { [K in DateQualifierName]: A };


export const dayToDay = {
  _tag: `DayToDay`,
  format: `Day to Day`,
  name: `dayToDay`
} as const;

export type DayToDayTaggedC = t.TypeC<{
  _tag: t.LiteralC<`DayToDay`>
}>;
export const dayToDayTaggedC: DayToDayTaggedC = t.type({
  _tag: t.literal(`DayToDay`)
});
export type DayToDayTagged = t.TypeOf<DayToDayTaggedC>;
export type DayToDay = DayToDayTagged & typeof dayToDay;
export type DayToDayC = t.Type<DayToDay, DayToDayTagged>;
export const dayToDayC: DayToDayC = pipe(dayToDayTaggedC, c => new t.Type<DayToDay, DayToDayTagged>(
  `DayToDay`,
  (u: unknown): u is DayToDay => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DayToDay> => pipe(c.decode(u), E.map(x => ({ ...x, ...dayToDay }))),
  (x: DayToDay): DayToDayTagged => ({ ...x, _tag: `DayToDay`}),
)) satisfies t.Type<DayToDay, unknown>;


export const ongoing = {
  _tag: `Ongoing`,
  format: `Ongoing`,
  name: `ongoing`
} as const;

export type OngoingTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Ongoing`>
}>;
export const ongoingTaggedC: OngoingTaggedC = t.type({
  _tag: t.literal(`Ongoing`)
});
export type OngoingTagged = t.TypeOf<OngoingTaggedC>;
export type Ongoing = OngoingTagged & typeof ongoing;
export type OngoingC = t.Type<Ongoing, OngoingTagged>;
export const ongoingC: OngoingC = pipe(ongoingTaggedC, c => new t.Type<Ongoing, OngoingTagged>(
  `Ongoing`,
  (u: unknown): u is Ongoing => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Ongoing> => pipe(c.decode(u), E.map(x => ({ ...x, ...ongoing }))),
  (x: Ongoing): OngoingTagged => ({ ...x, _tag: `Ongoing`}),
)) satisfies t.Type<Ongoing, unknown>;


export const allDateQualifierNDC = [dayToDayC, ongoingC] as const;
export const allDateQualifierNDNames = [`DayToDay`, `Ongoing`] as const;
export type DateQualifierNDName = (typeof allDateQualifierNDNames)[number];

export type DateQualifierNDCU = t.UnionC<[DayToDayC, OngoingC]>;
export type DateQualifierNDU = DayToDay | Ongoing;
export const DateQualifierNDCU: DateQualifierNDCU = t.union([dayToDayC, ongoingC]) satisfies t.Type<DateQualifierNDU, unknown>;

export const dateQualifierNDOrd: Ord.Ord<DateQualifierNDU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allDateQualifierND = [dayToDay, ongoing] as const;
export type DateQualifierNDMap<A> = { [K in DateQualifierNDName]: A };


